import { ApiMethod } from '../types/api.types';
import {
    clientLabelAssociationParamsSchema,
    clientLabelBodySchema,
    clientLabelParamsSchema,
    clientLabelQuerySchema,
    clientLabelUpdateBody
} from '../types/payloads/client-label.payload';
import { workspaceParamsSchema } from '../types/payloads/workspace.payload';
import { workspaceAcl } from '../utils/acl';

export const API_CLIENT_LABEL = {
    LIST: {
        method: ApiMethod.GET,
        path: '/workspaces/:workspaceName/client-labels',
        handlerMethod: 'listClientLabels',
        zod: {
            params: workspaceParamsSchema,
            query: clientLabelQuerySchema
        }
    },
    POST: {
        method: ApiMethod.POST,
        path: '/workspaces/:workspaceName/client-labels',
        workspaceRoles: workspaceAcl.labels.manage,
        handlerMethod: 'postClientLabel',
        zod: {
            params: workspaceParamsSchema,
            query: clientLabelQuerySchema,
            body: clientLabelBodySchema
        }
    },
    PUT: {
        method: ApiMethod.PUT,
        path: '/workspaces/:workspaceName/client-labels/:uid',
        handlerMethod: 'updateClientLabel',
        workspaceRoles: workspaceAcl.labels.manage,
        zod: {
            params: clientLabelParamsSchema,
            query: clientLabelQuerySchema,
            body: clientLabelUpdateBody
        }
    },
    DELETE: {
        method: ApiMethod.DELETE,
        path: '/workspaces/:workspaceName/client-labels/:uid',
        handlerMethod: 'deleteClientLabel',
        workspaceRoles: workspaceAcl.labels.manage,
        zod: {
            params: clientLabelParamsSchema,
            query: clientLabelQuerySchema
        }
    }
} as const;

export const API_CLIENT_LABEL_ASSOCIATION = {
    POST: {
        method: ApiMethod.POST,
        path: '/workspaces/:workspaceName/client-labels/:uid/associations/:associationUuid',
        handlerMethod: 'insertAssociation' as 'insertAssociation',
        zod: {
            params: clientLabelAssociationParamsSchema,
            query: clientLabelQuerySchema
        }
    },
    DELETE: {
        method: ApiMethod.DELETE,
        path: '/workspaces/:workspaceName/client-labels/:uid/associations/:associationUuid',
        handlerMethod: 'deleteAssociation' as 'deleteAssociation',
        zod: {
            params: clientLabelAssociationParamsSchema,
            query: clientLabelQuerySchema
        }
    }
} as const;
