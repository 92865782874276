import { z } from 'zod';

export const transcoderSpecificSchema = z.object({
    transcoderUuid: z.string().uuid()
});

export type TranscoderSpecificSchema = z.infer<typeof transcoderSpecificSchema>;

export const transcoderStartBody = z.object({
    expiry: z.coerce.date(),
    instanceClass: z.string(),
    idleTimeout: z.number()
});

export type TranscoderStartBody = z.infer<typeof transcoderStartBody>;
