import React, { useEffect } from 'react';
import { CLIENT_USER } from '@he-novation/config/paths/modals.constants';
import { ClientUser } from '@he-novation/config/types/client.types';
import { ButtonTone } from '@he-novation/design-system/components/buttons/Button/Button';
import { userReportFetch } from '@he-novation/front-shared/async/client.async';
import { saveAs } from 'file-saver';
import { useAtomValue } from 'jotai';

import { workspaceNameAtom } from '$atoms/workspace-atoms';
import { DataLayoutWorkspaceMembers } from '$components/DataLayout/DataLayoutWorkspaceMembers/DataLayoutWorkspaceMembers';
import { useSearchState } from '$components/HOC/withSearchState';
import { SubHeader } from '$components/layout/Header/SubHeader/SubHeader';
import { useDataLayout } from '$hooks/useDataLayout';
import { useModal } from '$hooks/useModal';
import { useTranslate } from '$hooks/useTranslate';
import { useWorkspaceAdminMembers } from '$hooks/useWorkspaceMembers';
import { useWorkspaceAdminMembersSocket } from '$hooks/useWorkspaceMembersSocket';

export function SettingsWorkspaceMembers() {
    const { openModal } = useModal();

    const workspaceName = useAtomValue(workspaceNameAtom);

    const { search, setSearch, onSearchInput } = useSearchState();
    const { workspaceAdminMembers, fetchWorkspaceAdminMembers } = useWorkspaceAdminMembers();

    useWorkspaceAdminMembersSocket();

    useEffect(() => {
        fetchWorkspaceAdminMembers(workspaceName);
    }, []);

    const { t } = useTranslate();

    const dataLayout = useDataLayout({
        groupers: [
            {
                key: 'status',
                label: t('common.Status'),
                grouperFunction: (items: ClientUser[]) => {
                    return [
                        {
                            key: 'enabled',
                            header: t('common.Enabled'),
                            items: items.filter((i) => !i.expires && !i.deleteRequested)
                        },
                        {
                            key: 'disabled',
                            header: t('common.Disabled'),
                            items: items.filter((i) => i.expires && !i.deleteRequested)
                        },
                        {
                            key: 'deleted',
                            header: t('common.Delete'),
                            items: items.filter((i) => i.deleteRequested)
                        }
                    ];
                }
            }
        ],
        layoutPrefsName: 'settings_workspace_members'
    });
    return (
        <>
            <SubHeader
                subClassName={'settings-sub-header'}
                title={t('contact.Workspace members')}
                groupers={[
                    {
                        grouper: dataLayout.grouper,
                        setGrouper: dataLayout.setGrouper,
                        groupers: dataLayout.groupers,
                        saveLayoutPrefs: dataLayout.saveLayoutPrefs
                    }
                ]}
                actions={[
                    {
                        children: t('contact.Export'),
                        tone: ButtonTone.Outlined,
                        actions: [
                            {
                                children: t('contact.Export to CSV'),
                                onClick: () => {
                                    const csvHeader = [
                                        'lastname',
                                        'firstname',
                                        'email',
                                        'phone',
                                        'role',
                                        'firm',
                                        'workspaceRole'
                                    ];
                                    const csvHeaderI18n = [
                                        t('contact.Lastname'),
                                        t('contact.Firstname'),
                                        t('common.E-mail'),
                                        t('contact.Phone number'),
                                        t('contact.Job'),
                                        t('contact.Company'),
                                        t('settings.Licenses')
                                    ];
                                    const csvHeaderRights = [
                                        'castAdmin',
                                        'companyAdd',
                                        'itemAdd',
                                        'projectAdd',
                                        'projectAdmin'
                                    ];

                                    const csvHeaderRightsI18n = [
                                        t('cast.Cast administrator'),
                                        t('settings.Create companies'),
                                        t('settings.Create items'),
                                        t('project.Create project'),
                                        t('project.Manage project')
                                    ];

                                    let csvContent = csvHeaderI18n.join(',') + ',';
                                    csvContent += csvHeaderRightsI18n.join(',') + '\n';
                                    csvContent += workspaceAdminMembers
                                        .map(
                                            (cu) =>
                                                csvHeader
                                                    .map((v) => {
                                                        if (cu?.[v] === 'undefined') return '';
                                                        if (cu?.[v] === '0000-00-00') return '';
                                                        return cu?.[v];
                                                    })
                                                    .join(',') +
                                                ',' +
                                                csvHeaderRights
                                                    .map((v) => {
                                                        return cu?.clientRights?.[v] ? 1 : 0;
                                                    })
                                                    .join(',')
                                        )
                                        .join('\n');

                                    saveAs(
                                        new Blob([csvContent], { type: 'text/csv' }),
                                        `${t('common.Users')}.csv`
                                    );
                                }
                            },
                            {
                                children: t('contact.Export to xlsx'),

                                onClick: () => {
                                    userReportFetch(workspaceName, 'report.xlsx');
                                }
                            }
                        ]
                    },
                    {
                        children: t('common.Add'),
                        tone: ButtonTone.Primary,
                        onClick: () => {
                            openModal(CLIENT_USER);
                        }
                    }
                ]}
                search={{
                    search,
                    setSearch,
                    onSearchInput
                }}
            />
            <DataLayoutWorkspaceMembers
                items={workspaceAdminMembers}
                search={search}
                dataLayout={dataLayout}
                manage={true}
            />
        </>
    );
}
