import { sanitizedString } from '@he-novation/utils/zod.utils';
import z from 'zod';
import { ClientUserRole } from '../db/enums';
import { LocaleList } from '../i18n.types';
import { userWorkspaceRightsSchema } from './workspace.payload';

export const clientNameAndLocaleSchema = z.object({
    clientName: sanitizedString(),
    locale: z.enum(['fr', 'de', 'en'])
});

export type ClientNameAndLocaleSchema = z.infer<typeof clientNameAndLocaleSchema>;

export const clientSpecificSchema = z.object({
    clientUuid: z.string()
});

export type ClientSpecificSchema = z.infer<typeof clientSpecificSchema>;

export const clientSpecificSchemaOptional = z.object({
    clientUuid: z.string().optional()
});

export type ClientSpecificSchemaOptional = z.infer<typeof clientSpecificSchemaOptional>;

export const clientOIDCBody = z.object({
    clientId: z.string().optional(),
    clientSecret: z.string().optional(),
    issuer: z.string().optional(),
    PKCE: z.boolean().optional(),
    button: z.string().optional(),
    strict: z.boolean().optional()
});

export type ClientOIDCBody = z.infer<typeof clientOIDCBody>;

export const clientMemberDeleteBody = z.object({
    delete_requested: z.coerce.date().optional()
});

export type ClientMemberDeleteBody = z.infer<typeof clientMemberDeleteBody>;

export const clientMemberSpecificSchema = z.object({
    userUuid: z.string()
});

export type ClientMemberSpecificSchema = z.infer<typeof clientMemberSpecificSchema>;

export const clientDomainCreateBody = z.object({
    name: z.string()
});

export type ClientDomainCreateBody = z.infer<typeof clientDomainCreateBody>;

export const clientDomainSpecificSchema = z.object({
    clientDomainUuid: z.string()
});

export type ClientDomainSpecificSchema = z.infer<typeof clientDomainSpecificSchema>;

export const workspaceBodySchema = z.object({
    name: z.string().optional(),
    displayName: z.string().min(3).max(30),
    primaryColor: z.string().optional()
});

export type WorkspaceBody = z.infer<typeof workspaceBodySchema>;

export const clientUserUpdateBody = z.object({
    translationCredits: z.number().optional(),
    role: z.nativeEnum(ClientUserRole).optional(),
    clientRights: userWorkspaceRightsSchema
});

export type ClientUserUpdateBody = z.infer<typeof clientUserUpdateBody>;

export const clientProfileSchema = z.object({
    email: z.string().email().optional(),
    firstname: z.string().optional(),
    lastname: z.string().optional(),
    phone: z.string().optional(),
    firm: z.string().optional(),
    city: z.string().optional(),
    country: z.string().optional()
});

export type ClientProfileSchema = z.infer<typeof clientProfileSchema>;

export const clientNameOptionalSchema = z.object({
    clientName: z.string().nullable().optional()
});

export type ClientNameOptionalSchema = z.infer<typeof clientNameOptionalSchema>;

export const clientNameSchema = z.object({
    clientName: z.string()
});

export type ClientNameSchema = z.infer<typeof clientNameSchema>;

export const clientPreferencesSchema = z.object({
    //@deprecated
    bypass_ingest: z.coerce.boolean().optional(),
    //@deprecated
    css: z.string().optional(),
    locale: z.enum(LocaleList).default('en'),
    //@deprecated
    mail: z
        .object({
            reset_password: z.coerce.boolean().optional()
        })
        .optional(),
    mail_prefix: z.string().nullable().optional(),
    //@deprecated
    pagination: z.any().optional(),
    ui: z.any().optional(),
    //@deprecated
    user_default: z
        .object({
            mail: z
                .object({
                    notify_digest: z.string()
                })
                .optional(),
            livenotify_desktop: z.coerce.boolean().optional(),
            livenotify_email: z.coerce.boolean().optional(),
            livenotify: z
                .object({
                    access_grant: z.coerce.boolean(),
                    comment_add: z.coerce.boolean(),
                    file_download: z.coerce.boolean(),
                    file_export: z.coerce.boolean(),
                    file_new: z.coerce.boolean(),
                    file_set_final: z.coerce.boolean()
                })
                .optional()
        })
        .optional(),
    //@deprecated
    fs: z
        .object({
            show_hidden: z.coerce.boolean().optional()
        })
        .optional(),
    //@deprecated
    client_uuid: z.string().uuid().optional(),
    //@deprecated
    css_color_main: z.string().optional(),
    //@deprecated
    activity_last: z.string().optional(),
    //@deprecated
    disabledTips: z
        .object({
            player: z.coerce.boolean()
        })
        .optional(),
    //@deprecated
    finder_view_type: z.string().optional(),
    //@deprecated
    newsletter: z
        .object({
            news: z.coerce.boolean(),
            tips: z.coerce.boolean()
        })
        .optional(),
    labels: z.union([z.array(z.string()), z.literal(''), z.string()]).optional(),
    tags: z.union([z.array(z.string()), z.literal(''), z.string()]).optional(),
    //@deprecated
    totp_enabled: z.union([z.literal(0), z.literal(1)]).optional(),
    forceTotp: z.coerce.boolean().optional(), // TODO remove?
    translationCredits: z.coerce.number().optional(),
    translationCreditsTotal: z.coerce.number().optional(),
    //@deprecated
    client_name: z.string().optional(),
    //@deprecated
    ipAddress: z.string().optional(),
    admittanceTerms: sanitizedString({ html: true, optional: true })
});

export type ClientPreferencesSchema = z.infer<typeof clientPreferencesSchema>;

export const defaultCastStyleSchema = z.object({
    userFileUid: z.string().optional(),
    logoUserFileUid: z.string().optional(),
    backgroundColor: z.string().optional()
});

export type DefaultCastStyleSchema = z.infer<typeof defaultCastStyleSchema>;

export const clientTermsSchema = z.object({
    terms: sanitizedString({ html: true })
});
