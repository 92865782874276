import { officeLink } from '@he-novation/config/paths/herawFrontUris';
import { Asset } from '@he-novation/config/types/asset.types';
import { AssetStatus } from '@he-novation/config/types/db/enums';
import { FileState } from '@he-novation/config/types/file.types';
import { getSignedUrl } from '@he-novation/front-shared/async/asset.async';
import {
    asyncFileVersionFetch,
    asyncFileVersionSubtitlesFetch,
    fileEncryptedAccessTokenRequest
} from '@he-novation/front-shared/async/file.async';
import { fetchFolder, fetchFolderPath } from '@he-novation/front-shared/async/folder.async';
import { FolderState } from '@he-novation/front-shared/types/folder.front-types';
import { v4 as uuidV4 } from 'uuid';
import { mapFetchAssetsToUpdate } from './file/maps/mapFetchAssetsToUpdate';
import { mapFetchFileToUpdate } from './file/maps/mapFetchFileToUpdate';

export const fetchFileView = async (
    workspaceName: string,
    {
        uuid,
        version,
        versionToCompare
    }: {
        uuid: string;
        version: number;
        versionToCompare?: string;
    }
) => {
    let fileState: Partial<FileState> = mapFetchFileToUpdate(
        await asyncFileVersionFetch({ workspaceName, uuid, version })
    );

    const [folder, breadcrumb] = await Promise.all([
        fetchFolder(workspaceName, { uuid: fileState.parent! }),
        fetchFolderPath(workspaceName, fileState.parent!).catch(() => [])
    ]);

    const folderState: Partial<FolderState> = { folder };

    fileState.breadcrumb = breadcrumb.map((b) => ({ ...b }));
    fileState.breadcrumb.push({ name: fileState.name! });

    fileState.highlightedVersion = version;

    if (folder.project) {
        fileState.breadcrumb = fileState.breadcrumb.map((bc) => ({
            ...bc,
            projectUuid: folder.project!.uuid
        }));
    }

    if (fileState.type === 'office') {
        const asset = fileState.assets!.find((asset: Asset) => asset.version === fileState.version);
        if (asset) {
            const signedUrl = await getSignedUrl(workspaceName, asset.uuid);
            fileState.source = officeLink(signedUrl);
        }
    } else {
        const m3u8: Asset | undefined =
            fileState.assets &&
            fileState.assets.find(
                ({ type, mimeType }) =>
                    mimeType === 'application/vnd.apple.mpegurl' && type === 'player'
            );

        if (m3u8 && m3u8.metadata?.encryption?.algorithm === 'aes-128') {
            fileState.encryptionRequestToken = uuidV4();
            fileState.encryptionAccessToken = await fileEncryptedAccessTokenRequest(
                fileState.encryptionRequestToken!
            ).then(({ access_token }) => access_token);
        }

        fileState = {
            ...fileState,
            ...mapFetchAssetsToUpdate(
                fileState.type!,
                fileState.assets!,
                !!m3u8?.metadata?.encryption
            )
        } as FileState;
    }

    if (typeof versionToCompare !== 'undefined') {
        const fileVersionToCompare = parseInt(versionToCompare);
        fileState.comparisonAsset = fileState.assets!.find(
            (asset: Asset) =>
                asset.type === 'player' && asset.version === fileVersionToCompare && asset.url
        );

        if (fileState.type === 'video')
            fileState.comparisonSubtitles = await asyncFileVersionSubtitlesFetch(
                workspaceName,
                fileState.uuid!,
                fileVersionToCompare
            );

        if (fileState.type === 'office' && fileState.comparisonAsset) {
            const signedUrl = await getSignedUrl(workspaceName, fileState.comparisonAsset.uuid);
            fileState.comparisonAsset.url = officeLink(signedUrl);
        }
    }

    fileState.activeAsset = fileState.assets!.find(
        (asset: Asset) =>
            asset.type === 'player' &&
            asset.version === fileState.version &&
            asset.url &&
            asset.status === AssetStatus.READY
    );

    return {
        fileState,
        folderState,
        configState: {
            activeBranding: fileState.clientName
        }
    };
};
