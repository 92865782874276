import { z } from 'zod';
import { ApiMethod } from '../../types/api.types';
import { fileMimeTypeQuery } from '../../types/payloads/file.payload';

export const API_MIME_TYPE = {
    INFER: {
        method: ApiMethod.POST,
        path: '/mime-type',
        handlerMethod: 'inferMimeType',
        contentType: 'application/octet-stream',
        zod: {
            query: fileMimeTypeQuery,
            body: z.any()
        },
        isPublic: true,
        responses: {
            200: {
                description: 'The inferred mime type of the file',
                schema: z.object({ mimeType: z.string().nullable() })
            }
        }
    }
} as const;
