import styles from './DataLayoutSorter.module.css';
import React, { useState } from 'react';
import { Icon } from '@he-novation/design-system/components/graphics/Icon/Icon';
import cn from 'classnames';

import { DataLayoutColumn } from '$components/DataLayout/DataLayout.types';
import { useTranslate } from '$hooks/useTranslate';

type DataLayoutSorterProps<T extends DataLayoutColumn<any, any, any>> = {
    columns: T[];
    sorting?: [column: T, desc: boolean] | null;
    setSorting: (sorting: [column: T, desc: boolean]) => void;
    onSort?: (column: T, desc: boolean) => void;
};
export function DataLayoutSorter<T extends DataLayoutColumn<any, any, any>>({
    columns,
    sorting,
    setSorting,
    onSort
}: DataLayoutSorterProps<T>) {
    const [active, setActive] = useState(false);
    const { t } = useTranslate();
    return (
        <div className={cn(styles.sorter, 'data-layout-sorter')}>
            <button
                className={cn(styles.trigger, active && styles.active)}
                onClick={() => setActive(!active)}
            >
                {t('common.Sort by')}:&nbsp;
                {sorting?.[0]?.header}
                <Icon icon="chevron" />
            </button>
            {active && (
                <ul>
                    {columns.map((c, i) => (
                        <li key={i}>
                            <button
                                className={cn(
                                    sorting?.[0].key === c.key &&
                                        (sorting[1] ? styles.desc : styles.asc)
                                )}
                                onClick={() => {
                                    const desc = !!(sorting && !sorting[1]);
                                    setSorting([c, desc]);
                                    onSort?.(c, desc);
                                }}
                            >
                                {c.header}
                                {sorting?.[0].key === c.key && <Icon icon="chevron" />}
                            </button>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
}
