import { z } from 'zod';
import { ROLES } from '../constants/projects.constants';
import { TEAM_STATUS } from '../constants/teams.constants';
import { contentUserSchema } from './user.types';

export const teamSchema = z.object({
    uuid: z.string().uuid(),
    name: z.string(),
    color: z.string().nullable(),
    status: z.nativeEnum(TEAM_STATUS).optional(),
    castTeamAccess: z.boolean().optional(),
    ownTeam: z.boolean().optional()
});

export type Team = z.infer<typeof teamSchema>;

export type AccessibleTeam = Team & {
    isAccessible: boolean;
};

export const teamMemberSchema = contentUserSchema.extend({
    locale: z.union([z.literal('fr'), z.literal('en'), z.literal('de')]),
    role: z.nativeEnum(ROLES),
    canExport: z.boolean().optional(),
    canDownload: z.boolean().optional(),
    workspaces: z.array(
        z.object({
            uuid: z.string(),
            name: z.string()
        })
    )
});

export type TeamMember = z.infer<typeof teamMemberSchema>;

export const teamWithMembersSchema = teamSchema.extend({
    members: z.array(teamMemberSchema)
});

export type TeamWithMembers = z.infer<typeof teamWithMembersSchema>;

export const folderTeamSchema = z.object({
    uuid: z.string().uuid(),
    name: z.string()
});
export type FolderTeam = z.infer<typeof folderTeamSchema>;
