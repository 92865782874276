import type { ClientStatus } from './db/enums';
import { type ClientLabel, ClientLabelType } from './clientLabel.types';

export enum WorkspaceFeature {
    DAM = 'dam',
    Projects = 'projects',
    Calendar = 'calendar',
    Security = 'security',
    API = 'api',
    CustomFields = 'custom-fields',
    Customization = 'customization'
}

export type WorkspaceWithSubscription = {
    name: string;
    stripeSubscriptionId: string;
    features: WorkspaceFeature[];
    storage: number;
    licenses: number;
    basicLicenses: number;
    status: ClientStatus;
};

export type WorkspaceLabels = {
    [ClientLabelType.Event]: ClientLabel<ClientLabelType.Event>[];
    [ClientLabelType.Contact]: ClientLabel<ClientLabelType.Contact>[];
    [ClientLabelType.Item]: ClientLabel<ClientLabelType.Item>[];
    [ClientLabelType.Cast]: ClientLabel<ClientLabelType.Cast>[];
};
