import styles from './WorkspaceMemberOption.module.scss';
import React from 'react';
import { AvatarSize, AvatarUser } from '../../../../avatars/Avatar/AvatarUser';
import type { MixedWorkspaceMemberPickerUser } from './WorkspaceMemberPicker';

type WorkspaceMemberOptionProps = {
    item: MixedWorkspaceMemberPickerUser;
};

export function WorkspaceMemberOption({ item }: WorkspaceMemberOptionProps) {
    return (
        <div className={styles.option}>
            <AvatarUser {...item} size={AvatarSize.Tiny} />
            {(item.firstname || item.lastname) && (
                <span className={styles.name}>{`${item.firstname} ${item.lastname}`}</span>
            )}
            <span className={styles.email}>{item.email}</span>
        </div>
    );
}
