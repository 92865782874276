import { ClientTier } from '@he-novation/config/types/db/enums';

import { useTranslate } from '$hooks/useTranslate';

interface WorkspaceSubscriptionTypeProps {
    tier: ClientTier;
    subscriptionType?: string | null;
}

export function WorkspaceSubscriptionType({
    tier,
    subscriptionType
}: WorkspaceSubscriptionTypeProps) {
    const { t } = useTranslate();

    return (
        <h2 className="text-text_focus text-2xl">
            {t(`settings.__SUBSCRIPTION_TIER_${tier.toUpperCase()}`)}
            {subscriptionType && (
                <span className="text-primary mb-2.5 block text-xl">
                    {t(`settings.__SUBSCRIPTION_${subscriptionType.toUpperCase()}`)}
                </span>
            )}
        </h2>
    );
}
