import { z } from 'zod';
import { ApiMethod } from '../types/api.types';
import {
    clientDomainSchema,
    clientOIDCSchema,
    clientSchema,
    clientStatsSchema,
    clientStorageSchema,
    clientStylesSchema,
    clientUserSchema
} from '../types/client.types';
import { ClientUserRole } from '../types/db/enums';
import {
    clientDomainCreateBody,
    clientOIDCBody,
    clientPreferencesSchema,
    clientTermsSchema,
    clientUserUpdateBody,
    defaultCastStyleSchema,
    workspaceBodySchema
} from '../types/payloads/client.payload';
import {
    workspaceAdminUpdateBodySchema,
    workspaceAndLocaleParamsSchema,
    workspaceDomainParamsSchema,
    workspaceOwnerBodySchema,
    workspaceParamsSchema,
    workspaceUserBodySchema,
    workspaceUserParamsSchema,
    workspaceUserTransferParamsSchema
} from '../types/payloads/workspace.payload';
import { userTypeSchema } from '../types/user.types';
import { workspaceUserSchema } from '../types/workspace-team.types';
import { workspaceAcl } from '../utils/acl';

export const API_WORKSPACE = {
    LIST: {
        method: ApiMethod.GET,
        path: '/workspaces',
        handlerMethod: 'findClients',
        superAdminOnly: true,
        responses: {
            200: {
                description: 'The list of clients',
                schema: z.array(clientSchema)
            }
        }
    },
    POST: {
        method: ApiMethod.POST,
        path: '/workspaces',
        handlerMethod: 'createWorkspace',
        passResAndNextToHandler: true,
        zod: {
            body: workspaceBodySchema
        },
        responses: {
            200: {
                description: 'The created client',
                schema: clientSchema
            }
        }
    },
    GET: {
        method: ApiMethod.GET,
        path: '/workspaces/:workspaceName',
        handlerMethod: 'getClient',
        zod: {
            params: workspaceParamsSchema
        },
        allowNoMfa: true,
        responses: {
            200: {
                description: 'The client',
                schema: clientSchema
            }
        }
    },
    PUT: {
        method: ApiMethod.PUT,
        path: '/workspaces/:workspaceName',
        handlerMethod: 'updateClient',
        zod: {
            params: workspaceParamsSchema,
            body: workspaceAdminUpdateBodySchema
        },
        responses: {
            200: {
                description: 'The updated client',
                schema: clientSchema
            }
        }
    },
    CURRENT_PUT: {
        method: ApiMethod.PUT,
        path: '/workspaces/:workspaceName/current',
        handlerMethod: 'updateUserCurrentWorkspace',
        passResAndNextToHandler: true,
        zod: {
            params: workspaceParamsSchema
        },
        responses: {
            200: {
                description: 'The updated client',
                schema: clientSchema
            }
        }
    }
} as const;

export const API_WORKSPACE_STORAGE = {
    GET: {
        method: ApiMethod.GET,
        path: '/workspaces/:workspaceName/storage',
        handlerMethod: 'findWorkspaceStorage',
        zod: {
            params: workspaceParamsSchema
        },
        responses: {
            200: {
                description: 'The client storage',
                schema: clientStorageSchema
            }
        }
    } as const
};

export const API_WORKSPACE_STATS = {
    GET: {
        method: ApiMethod.GET,
        path: '/workspaces/:workspaceName/stats',
        handlerMethod: 'getStats',
        workspaceRoles: workspaceAcl.workspaces.manage,
        zod: {
            params: workspaceParamsSchema
        },
        responses: {
            200: {
                description: 'The client stats',
                schema: clientStatsSchema
            }
        }
    },
    USER_REPORT_GET: {
        method: ApiMethod.GET,
        path: '/workspaces/:workspaceName/stats/users',
        handlerMethod: 'getUserReport',
        workspaceRoles: workspaceAcl.workspaces.manage,
        passResAndNextToHandler: true,
        zod: {
            params: workspaceParamsSchema
        }
    }
} as const;

export const API_WORKSPACE_STYLES = {
    GET: {
        method: ApiMethod.GET,
        path: '/workspaces/:workspaceName/styles',
        handlerMethod: 'getStyles',
        zod: {
            params: workspaceParamsSchema
        },
        responses: {
            200: {
                description: 'The client styles',
                schema: clientStylesSchema
            }
        }
    },
    PUT: {
        method: ApiMethod.PUT,
        path: '/workspaces/:workspaceName/styles',
        handlerMethod: 'setStyles',
        workspaceRoles: workspaceAcl.workspaces.manage,
        zod: {
            params: workspaceParamsSchema,
            body: z.object({
                primary: z.string()
            })
        }
    }
} as const;

export const API_WORKSPACE_USER = {
    LIST: {
        method: ApiMethod.GET,
        path: '/workspaces/:workspaceName/users',
        handlerMethod: 'getMembers',
        zod: {
            params: workspaceParamsSchema,
            query: z.object({ admin: z.coerce.boolean() }).optional()
        },
        responses: {
            200: {
                description: 'The list of workspace users',
                schema: z.array(clientUserSchema).or(z.array(workspaceUserSchema))
            }
        }
    },
    POST: {
        method: ApiMethod.POST,
        path: '/workspaces/:workspaceName/users',
        handlerMethod: 'postWorkspaceUser',
        workspaceRoles: workspaceAcl.workspaces.memberAdd,
        zod: {
            params: workspaceParamsSchema,
            body: workspaceUserBodySchema
        },
        responses: {
            200: {
                description: 'The created workspace user',
                schema: userTypeSchema
            }
        }
    },
    DELETE: {
        method: ApiMethod.DELETE,
        path: '/workspaces/:workspaceName/users/:userUuid',
        handlerMethod: 'deleteWorkspaceUser',
        workspaceRoles: workspaceAcl.workspaces.manage,
        zod: {
            params: workspaceUserParamsSchema
        }
    },
    PUT: {
        method: ApiMethod.PUT,
        path: '/workspaces/:workspaceName/users/:userUuid',
        handlerMethod: 'putWorkspaceUser',
        workspaceRoles: workspaceAcl.workspaces.manage,
        zod: {
            params: workspaceUserParamsSchema,
            body: clientUserUpdateBody
        }
    },
    TRANSFER: {
        method: ApiMethod.PUT,
        path: '/workspaces/:workspaceName/users/:userUuid/transfer/:userDstUuid',
        handlerMethod: 'transferUser' as 'transferUser',
        workspaceRoles: workspaceAcl.workspaces.manage,
        zod: {
            params: workspaceUserTransferParamsSchema
        }
    }
} as const;

export const API_WORKSPACE_PREFERENCES = {
    PATCH: {
        method: ApiMethod.PATCH,
        path: '/workspaces/:workspaceName/preferences',
        handlerMethod: 'updateClientPreferences',
        passResAndNextToHandler: true,
        workspaceRoles: workspaceAcl.workspaces.manage,
        zod: {
            params: workspaceParamsSchema,
            body: clientPreferencesSchema.partial()
        }
    }
} as const;

export const API_WORKSPACE_TERMS = {
    PUT: {
        method: ApiMethod.PUT,
        path: '/workspaces/:workspaceName/terms/:locale',
        handlerMethod: 'setClientTerms' as 'setClientTerms',
        workspaceRoles: workspaceAcl.workspaces.manage,
        zod: {
            body: clientTermsSchema,
            params: workspaceAndLocaleParamsSchema
        }
    }
} as const;

export const API_WORKSPACE_OIDC = {
    GET: {
        method: ApiMethod.GET,
        path: '/workspaces/:workspaceName/oidc',
        handlerMethod: 'findClientOIDC',
        workspaceRoles: workspaceAcl.workspaces.manage,
        allowNoMfa: true,
        zod: {
            params: workspaceParamsSchema
        },
        responses: {
            200: {
                description: 'The client OIDC configuration',
                schema: clientOIDCSchema.nullable()
            }
        }
    },
    PATCH: {
        method: ApiMethod.PATCH,
        path: '/workspaces/:workspaceName/oidc',
        zod: {
            params: workspaceParamsSchema,
            body: clientOIDCBody
        },
        handlerMethod: 'updateClientOIDC',
        workspaceRoles: workspaceAcl.workspaces.manage
    }
};

export const API_WORKSPACE_DOMAIN = {
    LIST: {
        method: ApiMethod.GET,
        path: '/workspaces/:workspaceName/domains',
        handlerMethod: 'findClientDomains',
        workspaceRoles: workspaceAcl.workspaces.manage,
        zod: {
            params: workspaceParamsSchema
        },
        responses: {
            200: {
                description: 'The list of client domains',
                schema: clientDomainSchema.array()
            }
        }
    },
    POST: {
        method: ApiMethod.POST,
        path: '/workspaces/:workspaceName/domains',
        handlerMethod: 'addClientDomain',
        workspaceRoles: workspaceAcl.workspaces.manage,
        zod: {
            params: workspaceParamsSchema,
            body: clientDomainCreateBody
        },
        responses: {
            200: {
                description: 'The created client domain',
                schema: clientDomainSchema
            }
        }
    },
    PUT: {
        method: ApiMethod.PUT,
        path: '/workspaces/:workspaceName/domains/:domainUuid',
        handlerMethod: 'refreshClientDomain',
        workspaceRoles: workspaceAcl.workspaces.manage,
        zod: {
            params: workspaceDomainParamsSchema
        }
    },
    DELETE: {
        method: ApiMethod.DELETE,
        path: '/workspaces/:workspaceName/domains/:domainUuid',
        handlerMethod: 'deleteClientDomain',
        workspaceRoles: workspaceAcl.workspaces.manage,
        zod: {
            params: workspaceDomainParamsSchema
        }
    }
} as const;

export const API_WORKSPACE_CAST_STYLE = {
    GET: {
        method: ApiMethod.GET,
        path: '/workspaces/:workspaceName/cast-style',
        handlerMethod: 'getDefaultCastStyle' as 'getDefaultCastStyle',
        zod: {
            params: workspaceDomainParamsSchema
        }
    },
    PUT: {
        method: ApiMethod.PUT,
        path: '/workspaces/:workspaceName/cast-style',
        handlerMethod: 'setDefaultCastStyle' as 'setDefaultCastStyle',
        workspaceRoles: workspaceAcl.workspaces.manage,
        zod: {
            params: workspaceParamsSchema,
            body: defaultCastStyleSchema
        }
    }
};

export const API_WORKSPACE_OWNERSHIP = {
    TRANSFER: {
        method: ApiMethod.POST,
        path: '/workspaces/:workspaceName/ownership',
        handlerMethod: 'transferOwnership',
        zod: {
            params: workspaceParamsSchema,
            body: workspaceOwnerBodySchema
        },
        responses: {
            204: {
                description: 'The workspace ownership has been transferred'
            }
        }
    } as const
};
