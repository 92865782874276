import { sanitizedString } from '@he-novation/utils/zod.utils';
import { z } from 'zod';
import { workspaceParamsSchema } from './workspace.payload';

export const workspaceTeamParamsSchema = workspaceParamsSchema.extend({
    teamUuid: z.string().uuid()
});

export type WorkspaceTeamParams = z.infer<typeof workspaceTeamParamsSchema>;

export const oaWorkspaceTeamParamsSchema = workspaceParamsSchema.extend({
    teamUuid: z.string().uuid()
});

export const workspaceTeamBodySchema = z.object({
    name: sanitizedString({ min: 0, max: 128 })
});

export type WorkspaceTeamBody = z.infer<typeof workspaceTeamBodySchema>;

export const workspaceTeamUserParamsSchema = workspaceTeamParamsSchema.extend({
    userUuid: z.string().uuid()
});

export type WorkspaceTeamUserParams = z.infer<typeof workspaceTeamUserParamsSchema>;

export const workspaceTeamUsersBodySchema = z.object({
    uuids: z.array(z.string().uuid())
});

export type WorkspaceTeamUsersBody = z.infer<typeof workspaceTeamUsersBodySchema>;
