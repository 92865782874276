import { ApiMethod } from '../types/api.types';
import { auth2FAUpdatePayload, authPasswordUpdatePayload } from '../types/payloads/auth.payload';

export const API_AUTH_SESSION = {
    DELETE: {
        method: ApiMethod.DELETE,
        path: '/auth/session',
        passResAndNextToHandler: true,
        handlerMethod: 'logout',
        allowNoMfa: true
    }
} as const;

export const API_AUTH_PASSWORD = {
    PUT: {
        method: ApiMethod.PUT,
        path: '/auth/password',
        passResAndNextToHandler: true,
        handlerMethod: 'updatePassword',
        zod: {
            body: authPasswordUpdatePayload
        },
        allowNoMfa: true
    }
} as const;

export const API_AUTH_2FA = {
    PUT: {
        method: ApiMethod.PUT,
        path: '/auth/two-factor',
        handlerMethod: 'update2FA',
        passResAndNextToHandler: true,
        zod: {
            body: auth2FAUpdatePayload
        },
        allowNoMfa: true
    }
} as const;
