import {
    WSWorkspaceAdminEventUserAdded,
    WSWorkspaceAdminEventUserRemoved,
    WSWorkspaceAdminEventUserUpdated,
    WSWorkspaceEventTeamCreated,
    WSWorkspaceEventTeamDeleted,
    WSWorkspaceEventTeamUpdated,
    WSWorkspaceEventUserAdded,
    WSWorkspaceEventUserRemoved,
    WSWorkspaceEventUserUpdated
} from '@he-novation/config/types/websockets/workspace.ws.types';
import { WorkspaceTeamWithMembers } from '@he-novation/config/types/workspace-team.types';
import update from 'immutability-helper';
import { useAtomValue } from 'jotai';
import { useSetAtom } from 'jotai/index';

import {
    workspaceAdminMembersAtom,
    workspaceMembersAtom,
    workspaceNameAtom,
    workspaceTeamsAtom
} from '$atoms/workspace-atoms';
import { useSocketIO } from '$hooks/useSocketIO';

export function useWorkspaceMembersSocket() {
    const workspaceName = useAtomValue(workspaceNameAtom);
    const setWorkspaceMembers = useSetAtom(workspaceMembersAtom);
    const setWorkspaceTeams = useSetAtom(workspaceTeamsAtom);

    useSocketIO({
        socket: 'workspaceUsers',
        room: workspaceName,
        actions: {
            sioWorkspaceMemberAdded: ({ workspaceUser }: WSWorkspaceEventUserAdded) =>
                setWorkspaceMembers((members) =>
                    [...members, workspaceUser].sort((a, b) => a.email.localeCompare(b.email))
                ),
            sioWorkspaceMemberRemoved: ({ userUuid }: WSWorkspaceEventUserRemoved) => {
                setWorkspaceMembers((members) => members.filter((m) => m.uuid !== userUuid));
                setWorkspaceTeams((teams) => {
                    if (!teams) return null;

                    return teams.reduce((acc: WorkspaceTeamWithMembers[], team) => {
                        return [
                            ...acc,
                            {
                                ...team,
                                members: team.members.filter((member) => member.uuid !== userUuid)
                            }
                        ];
                    }, []);
                });
            },
            sioWorkspaceMemberUpdated: ({ workspaceUser }: WSWorkspaceEventUserUpdated) => {
                setWorkspaceMembers((members) => {
                    const index = members.findIndex((m) => m.uuid === workspaceUser.uuid);
                    if (index === -1) return members;

                    return update(members, {
                        [index]: {
                            $set: workspaceUser
                        }
                    });
                });
                setWorkspaceTeams((teams) => {
                    if (!teams) return null;

                    return teams.reduce((acc: WorkspaceTeamWithMembers[], team) => {
                        const index = team.members.findIndex(
                            (member) => member.uuid === workspaceUser.uuid
                        );
                        if (index !== -1) {
                            team.members[index] = workspaceUser;
                        }
                        return [...acc, team];
                    }, []);
                });
            }
        }
    });

    useSocketIO({
        socket: 'workspaceTeams',
        room: workspaceName,
        actions: {
            sioWorkspaceTeamCreated: ({ team }: WSWorkspaceEventTeamCreated) =>
                setWorkspaceTeams((teams) => (teams ? [...teams, { ...team, members: [] }] : null)),
            sioWorkspaceTeamDeleted: ({ teamUuid }: WSWorkspaceEventTeamDeleted) =>
                setWorkspaceTeams((teams) => teams?.filter((t) => t.uuid !== teamUuid) || null),
            sioWorkspaceTeamUpdated: ({ team }: WSWorkspaceEventTeamUpdated) =>
                setWorkspaceTeams((teams) => {
                    if (!teams) return null;

                    const index = teams?.findIndex((t) => t.uuid === team.uuid);
                    if (index === -1) return teams;
                    const members = team.members || teams[index].members;

                    return update(teams, {
                        [index]: {
                            $merge: { ...team, members }
                        }
                    });
                })
        }
    });
}

export function useWorkspaceAdminMembersSocket() {
    const workspaceName = useAtomValue(workspaceNameAtom);
    const setWorkspaceAdminMembers = useSetAtom(workspaceAdminMembersAtom);

    useSocketIO({
        socket: 'workspaceAdminUsers',
        room: workspaceName,
        actions: {
            sioWorkspaceAdminMemberAdded: ({ clientUser }: WSWorkspaceAdminEventUserAdded) => {
                setWorkspaceAdminMembers((members) =>
                    [...members, clientUser].sort((a, b) => a.email.localeCompare(b.email))
                );
            },
            sioWorkspaceAdminMemberRemoved: ({ userUuid }: WSWorkspaceAdminEventUserRemoved) =>
                setWorkspaceAdminMembers((members) => members.filter((m) => m.uuid !== userUuid)),
            sioWorkspaceAdminMemberUpdated: ({ clientUser }: WSWorkspaceAdminEventUserUpdated) =>
                setWorkspaceAdminMembers((members) => {
                    const index = members.findIndex((m) => m.uuid === clientUser.uuid);
                    if (index === -1) return members;

                    return update(members, {
                        [index]: {
                            $set: clientUser
                        }
                    });
                })
        }
    });
}
