import { z } from 'zod';
import { CustomFieldAssociationType, CustomFieldType } from '../db/enums';
import { workspaceParamsSchema } from './workspace.payload';

export const customFieldParamsSchema = workspaceParamsSchema.extend({
    customFieldUuid: z.string().uuid()
});

export const customFieldOptionParamsSchema = customFieldParamsSchema.extend({
    customFieldOptionUuid: z.string().uuid()
});

export const createCustomFieldOptionBodySchema = z.object({
    label: z.string(),
    position: z.number().optional(),
    color: z
        .string()
        .refine((v) => v.match(/^#(?:[0-9a-f]{2}){3,4}$/i), {
            message:
                'Invalid color format. Expected: 6 or 8 character hex color code (e.g. #ff0000 or #ff0000ff)'
        })
        .optional()
        .nullable()
});

export type CreateCustomFieldOptionBody = z.infer<typeof createCustomFieldOptionBodySchema>;

export const updateCustomFieldOptionBodySchema = createCustomFieldOptionBodySchema.partial();

export type UpdateCustomFieldOptionBody = z.infer<typeof updateCustomFieldOptionBodySchema>;

export const baseCustomFieldBodySchema = z.object({
    name: z.string(),
    type: z.nativeEnum(CustomFieldType),
    multiple: z.boolean().optional(),
    options: z.array(createCustomFieldOptionBodySchema).optional()
});

export const createCustomFieldBodySchema = baseCustomFieldBodySchema
    .extend({
        associations: z.array(z.nativeEnum(CustomFieldAssociationType)).nonempty(),
        associationTargetIdentifier: z.string().optional()
    })
    .refine(
        (schema) => schema.multiple && schema.type === CustomFieldType.SELECT,
        `only ${CustomFieldType.SELECT} fields can be multiple`
    )
    .refine(
        (schema) =>
            !schema.associationTargetIdentifier ||
            (schema.associationTargetIdentifier && schema.associations.length === 1),
        'association target must be provided for a unique association type'
    )
    .refine(
        (schema) => new Set(schema.associations).size === schema.associations.length,
        'association types must be unique'
    );

export type CreateCustomFieldBody = z.infer<typeof createCustomFieldBodySchema>;

export const updateCustomFieldBodySchema = z.object({ name: z.string().optional() });

export type UpdateCustomFieldBody = z.infer<typeof updateCustomFieldBodySchema>;

export const customFieldValuesBodySchema = z.record(z.string(), z.unknown().nullable());

export type CustomFieldValuesBody = z.infer<typeof customFieldValuesBodySchema>;

export const customFieldAssociationParamsSchema = customFieldParamsSchema.extend({
    associationUuid: z.string().uuid()
});
