import { UserPrefs, UserState } from '@he-novation/config/types/user.types';
import { compose, pick, pipe, property } from 'lodash/fp';
import { CLIENT_PRIVATE_NAME } from '../config/constants';
import { USER } from './userActions';

import { ReduxState } from '$redux/store';

const stateSelector: (state: ReduxState) => UserState = property(USER);

export const localeSelector = pipe(stateSelector, ({ locale }) => locale);

export const currentUserSelector = pipe(stateSelector, (user) => ({ currentUser: user }));

export const currentUserUuidSelector = pipe(stateSelector, ({ uuid }) => ({
    currentUserUuid: uuid
}));
export const userUuidSelector = pipe(stateSelector, property('uuid'), (userUuid: string) => ({
    userUuid
}));

export const isPrivateSelector = compose(
    ({ clientName }) => ({
        isPrivate: clientName === CLIENT_PRIVATE_NAME
    }),
    stateSelector
);

export const preferencesSelector: (state: ReduxState) => { preferences: UserPrefs } = pipe(
    stateSelector,
    property('preferences'),
    (preferences?: UserPrefs) => {
        if (!preferences) preferences = { ui: {} };
        if (!preferences.ui) preferences.ui = {};
        return { preferences };
    }
);

export const preferencesSortersAndGroupersSelector = (preferencesGrouperAndSorterName: string) =>
    pipe(
        stateSelector,
        property(`preferences.ui.sortersAndGroupers.${preferencesGrouperAndSorterName}`),
        (preferencesSortersAndGroupers) => ({
            preferencesSortersAndGroupers: preferencesSortersAndGroupers || {}
        })
    );

export const rightsPreferencesSelector = pipe(
    stateSelector,
    property('preferences.rights'),
    (rightsPreferences = {}) => ({ rightsPreferences })
);

export const uiPreferencesSelector = pipe(
    stateSelector,
    property('preferences.ui'),
    (uiPreferences) => ({ uiPreferences })
);

export const totpEnabledSelector = pipe(stateSelector, property('totp_enabled'), (totpEnabled) => ({
    totpEnabled: !!totpEnabled
}));

export const requestAndAccessTokenSelector = pipe(
    stateSelector,
    pick(['accessToken', 'requestToken'])
);

export type UserInfos = {
    city?: string;
    clientName: string;
    clientUuid: string;
    country?: string;
    created: Date;
    email: string;
    firm?: string;
    firstname?: string;
    isSuperAdmin?: boolean;
    lastname?: string;
    passwordReset?: Date | null;
    phone?: string;
    picture?: string;
    role?: string;
    uuid: string;
};

export const userInfosSelector = pipe(
    stateSelector,
    pick([
        'city',
        'clientName',
        'clientUuid',
        'country',
        'created',
        'email',
        'firm',
        'firstname',
        'isSuperAdmin',
        'lastname',
        'passwordReset',
        'phone',
        'picture',
        'role',
        'uuid'
    ]),
    (userInfos: UserInfos) => ({ userInfos })
);

export const emailSelector = pipe(stateSelector, pick('email'));

export const userInfosAsInfosSelector = pipe(userInfosSelector, ({ userInfos }) => ({
    infos: userInfos
}));

export const userEventsSelector = pipe(stateSelector, property('events'), (userEvents) => ({
    userEvents
}));
