import { z } from 'zod';

export enum DataLayoutDisplayMode {
    List = 'list',
    Grid = 'grid'
}

export const dataLayoutPrefsSchema = z.object({
    sorter: z
        .object({
            key: z.string(),
            order: z.union([z.literal('ASC'), z.literal('DSC')])
        })
        .optional(),
    grouper: z.string().nullable().optional(),
    displayMode: z.nativeEnum(DataLayoutDisplayMode).optional(),
    currentGroupsOpened: z.array(z.string()).optional(),
    activeColumns: z.array(z.string()).optional(),
    sort: z
        .tuple([
            z.string(), // columnKey
            z.boolean() // reversed
        ])
        .optional()
});

export type DataLayoutPrefs = z.infer<typeof dataLayoutPrefsSchema>;
