import styles from './CastPreview.module.css';
import React from 'react';
import cn from 'classnames';
import { PreviewData } from '../useFormCast';

import { CastPreviewPlaceHolder } from '$components/form/FormCast/CastPreview/CastPreviewPlaceHolder';

type CastPreviewProps = PreviewData & {
    className?: string;
};

export const CastPreview: React.FC<CastPreviewProps> = ({
    backgroundColor,
    textColor,
    bgUserFile,
    logoUserFile,
    className
}) => {
    const logo = logoUserFile?.url;

    const backgroundImage = bgUserFile?.url ? `url(${bgUserFile.url})` : undefined;

    return (
        <div
            className={cn(styles.castPreview, className)}
            style={{
                backgroundImage
            }}
        >
            <div
                className={styles.background}
                style={{
                    background:
                        `linear-gradient(${backgroundColor}33, ${backgroundColor} 100%)` ||
                        '#16181d'
                }}
            >
                <header>
                    {logo && <img src={logo} alt="" />}
                    <h3 style={{ color: textColor || undefined }}>{'CAST'}</h3>
                </header>
                <ul className={styles.previewPlaceholders}>
                    {Array.from(Array(5).keys()).map((k) => (
                        <CastPreviewPlaceHolder key={k} textColor={textColor} />
                    ))}
                </ul>
            </div>
        </div>
    );
};
