import { useCallback } from 'react';
import { CONFIRM } from '@he-novation/config/paths/modals.constants';
import type { FileFull } from '@he-novation/config/types/file.types';
import { asyncFileCopy, asyncFileMove } from '@he-novation/front-shared/async/file.async';
import { asyncFolderCopyTree, folderMove } from '@he-novation/front-shared/async/folder.async';
import {
    FrontFolderContentFile,
    FrontFolderContentFileStatus,
    FrontFolderContentFileTranscodingCopying
} from '@he-novation/front-shared/types/file.front-types';
import {
    FrontFolderChild,
    FrontFolderContentFolder,
    FrontFolderContentGhost
} from '@he-novation/front-shared/types/folder.front-types';
import update from 'immutability-helper';
import { useAtomValue, useSetAtom } from 'jotai';
import { cloneDeep } from 'lodash/fp';
import * as uuidV4 from 'uuid';

import {
    FolderClipboard,
    folderClipboardAtom,
    FolderClipboardTarget,
    folderContentWithGhostsAtom,
    folderGhostsAtom,
    foldersStore,
    folderUniqueContentAtom,
    folderUuidAtom
} from '$atoms/folder-atoms';
import { workspaceNameAtom } from '$atoms/workspace-atoms';
import { useModal } from '$hooks/useModal';
import { useTranslate } from '$hooks/useTranslate';

export function useFolderContent() {
    const { t } = useTranslate();
    const workspaceName = useAtomValue(workspaceNameAtom);

    const setFolderClipboard = useSetAtom(folderClipboardAtom);
    const folderContent = useAtomValue(folderContentWithGhostsAtom, {
        store: foldersStore
    });
    const setFolderContent = useSetAtom(folderUniqueContentAtom, {
        store: foldersStore
    });

    const currentFolderUuid = useAtomValue(folderUuidAtom, { store: foldersStore });

    const { openModal, closeModal } = useModal();

    const updateFolderContentItem = useCallback((itemUuid: string, operation: any) => {
        setFolderContent((folderContent) => {
            if (!folderContent) return folderContent;
            const itemIndex = folderContent.findIndex((c) => c.uuid === itemUuid);
            if (itemIndex === -1) return folderContent;

            return update(folderContent, {
                [itemIndex]: operation
            });
        });
    }, []);

    const deleteFolderContentItem = useCallback((itemUuid: string) => {
        setFolderContent((folderContent) => {
            if (!folderContent) return folderContent;
            return folderContent.filter((f) => itemUuid !== f.uuid);
        });
    }, []);

    const doPaste = useCallback(
        async ({ data, type, projectUuid }: FolderClipboard, target: FolderClipboardTarget) => {
            const { foldersToCopy, filesToCopy } = data.reduce(
                (acc, f) => {
                    if (f?.type === 'folder') {
                        acc.foldersToCopy.push(f);
                    } else if (f?.type === 'file') {
                        acc.filesToCopy.push({ ...f, newUuid: uuidV4.v4() });
                    }
                    return acc;
                },
                {
                    foldersToCopy: [] as FrontFolderContentFolder[],
                    filesToCopy: [] as (FrontFolderContentFile & { newUuid: string })[]
                }
            );

            if (currentFolderUuid && currentFolderUuid === target.folderUuid) {
                setFolderClipboard(null);
                setFolderContent((folderContent) => {
                    const content = (folderContent ?? []).concat(
                        filesToCopy.map((item) => ({
                            ...item,
                            uuid: item.newUuid,
                            created: new Date(item.created),
                            updated: new Date(item.updated),
                            notes: projectUuid !== target.projectUuid ? 0 : item.notes,
                            processingStatus:
                                type === 'cut'
                                    ? item.processingStatus
                                    : ({
                                          status: FrontFolderContentFileStatus.COPYING
                                      } as FrontFolderContentFileTranscodingCopying)
                        }))
                    );
                    return content;
                });
            }

            switch (type) {
                case 'tree':
                    await doCopyTree(workspaceName, target.folderUuid, foldersToCopy);
                    return;
                case 'copy':
                    await doCopy(
                        workspaceName,
                        target.folderUuid,
                        filesToCopy,
                        updateFolderContentItem,
                        deleteFolderContentItem
                    );
                    break;
                case 'cut':
                    doMove(workspaceName, target.folderUuid, filesToCopy, foldersToCopy);
                    break;
            }
        },
        [currentFolderUuid, updateFolderContentItem, deleteFolderContentItem]
    );

    const pasteIntoFolder = useCallback(
        async (clipboard: FolderClipboard | undefined | null, target: FolderClipboardTarget) => {
            if (!clipboard) return;

            const data = cloneDeep(clipboard.data);

            if (clipboard.type === 'cut') {
                const containsAtLeastOneFolderOrOneFileWithNotes = !!data.find(
                    (i) => i && (i.type === 'folder' || (i.type === 'file' && i.notes > 0))
                );
                if (
                    clipboard.projectUuid !== target.projectUuid &&
                    containsAtLeastOneFolderOrOneFileWithNotes
                ) {
                    openModal(CONFIRM, {
                        title: t('common.Warning'),
                        message: t(
                            'folder.You are about to change the context of folders and/or files. If you do, the associated notes will be deleted.'
                        ),
                        displayLoader: true,
                        onSubmit: async () => {
                            await doPaste(clipboard, target);
                            closeModal();
                        },
                        closeModal
                    });
                    return;
                }
            }

            await doPaste(clipboard, target);
        },
        [doPaste]
    );

    const dropIntoFolder = useCallback(
        async (
            content: (FrontFolderChild | undefined)[],
            target: FolderClipboardTarget,
            originProjectUuid?: string
        ) => {
            return pasteIntoFolder(
                {
                    data: content.filter((c) => !!c && (c.type === 'file' || c.type === 'folder')),
                    type: 'cut',
                    projectUuid: originProjectUuid
                },
                target
            );
        },
        [pasteIntoFolder]
    );

    return {
        folderContent,
        setFolderContent,
        deleteFolderContentItem,
        pasteIntoFolder,
        dropIntoFolder,
        updateFolderContentItem
    };
}

export function folderContentGhostCreate(ghost: FrontFolderContentGhost) {
    foldersStore.set(folderGhostsAtom, (folderGhosts) => [...folderGhosts, ghost]);
}

export function folderContentGhostDelete(ghostUuid: string) {
    foldersStore.set(folderGhostsAtom, (folderGhosts) =>
        folderGhosts.filter((g) => ghostUuid !== g.uuid)
    );
}

async function doCopy(
    workspaceName: string,
    folderUuid: string,
    filesToCopy: (FrontFolderContentFile & { newUuid: string })[],
    updateFolderContentItem: (itemUuid: string, operation: any) => void,
    deleteFolderContentItem: (itemUuid: string) => void
) {
    // split filesToCopy in batches of 5 files
    let fileExists = false;
    const sourceBatches: (FrontFolderContentFile & { newUuid: string })[][] = [];
    for (const file of filesToCopy) {
        if (sourceBatches.length === 0 || sourceBatches[sourceBatches.length - 1].length === 5) {
            sourceBatches.push([]);
        }
        sourceBatches[sourceBatches.length - 1].push(file);
    }
    for (const sourceBatch of sourceBatches) {
        const resultBatch: (FileFull | (FrontFolderContentFile & { error?: string }))[] =
            await Promise.all(
                sourceBatch.map(async (f) => {
                    try {
                        return await asyncFileCopy(
                            workspaceName,
                            f.uuid.replace(/-copy$/, ''),
                            folderUuid,
                            f.newUuid
                        );
                    } catch (e) {
                        return { ...f, error: e?.error?.message };
                    }
                })
            );
        sourceBatch.forEach((sourceFile, i) => {
            if ('error' in resultBatch[i]) {
                if (resultBatch[i].error === 'ERR_FILE_ALREADY_EXISTS') fileExists = true;
                deleteFolderContentItem(sourceFile.uuid);
            }
        });
    }

    return fileExists;
}

async function doMove(
    workspaceName: string,
    folderUuid: string,
    filesToCopy: FrontFolderContentFile[],
    foldersToCopy: FrontFolderContentFolder[]
) {
    let fileExists = false;
    await Promise.all(
        filesToCopy.map(async (f) => {
            try {
                await asyncFileMove(workspaceName, f.uuid, folderUuid);
            } catch (e) {
                fileExists = true;
                if (e?.error?.message === 'ERR_FILE_ALREADY_EXISTS') fileExists = true;
                return { ...f, error: e?.error?.message };
            }
        })
    );
    await Promise.all(foldersToCopy.map((f) => folderMove(workspaceName, f.uuid, folderUuid)));

    return fileExists;
}

async function doCopyTree(
    workspaceName: string,
    folderUuid: string,
    foldersToCopy: FrontFolderContentFolder[]
) {
    await Promise.all(
        foldersToCopy.map((f) => {
            return asyncFolderCopyTree(workspaceName, f.uuid, folderUuid, {
                renameIfExists: true
            });
        })
    );
}
