import type React from 'react';
import { useEffect, useState } from 'react';
import { Cast } from '@he-novation/config/types/cast.types';
import { ClientLabelType } from '@he-novation/config/types/clientLabel.types';
import { UserFile } from '@he-novation/config/types/user-file.types';
import { asyncClientLabelsFetch } from '@he-novation/front-shared/async/client-labels.async';
import update, { Spec } from 'immutability-helper';
import { useAtom, useAtomValue } from 'jotai';

import { workspaceCastLabelsAtom, workspaceNameAtom } from '$atoms/workspace-atoms';

type File = { uid: string; thumbnail: string; url: string; type: string };

export type PreviewData = {
    name?: string | null;
    backgroundColor?: string | null;
    textColor?: string | null;
    bgUserFile?: { url: string | null } | null;
    logoUserFile?: { url: string | null } | null;
};

export type SetPreviewData = React.Dispatch<React.SetStateAction<PreviewData>>;

export function useFormCast(cast?: Partial<Cast>) {
    const [hasExpiry, setHasExpiry] = useState(!!cast?.expires || false);
    const [hasMaxViews, setHasMaxViews] = useState(!!cast?.maxViews || false);
    const [hasPassword, setHasPassword] = useState(!!cast?.password || false);
    const [file, setFile] = useState<File | null>();
    const [logoFile, setLogoFile] = useState<File | null>();
    const [previewData, setPreviewData] = useState<PreviewData>({
        textColor: '#ffffff',
        backgroundColor: '#16181d',
        ...cast
    });

    const [userFile, setUserFile] = useState<Omit<UserFile, 'isPrivate'> | undefined | null>(
        cast?.bgUserFile
    );
    const [logoUserFile, setLogoUserFile] = useState<
        Omit<UserFile, 'isPrivate'> | undefined | null
    >(cast?.logoUserFile);

    const [backgroundColor, setBackgroundColor] = useState<string | null>(
        cast?.backgroundColor || null
    );

    const [isLoading, setIsLoading] = useState(false);
    const [selectedLabels, setSelectedLabels] = useState(cast?.labels || []);
    const workspaceName = useAtomValue(workspaceNameAtom);
    const [workspaceCastLabels, setWorkspaceCastLabels] = useAtom(workspaceCastLabelsAtom);

    useEffect(() => {
        setPreviewData((prev) => {
            const _update: Spec<PreviewData, never> = {};
            if (userFile) {
                _update.bgUserFile = { $set: { url: userFile.url } };
            }
            if (logoUserFile) {
                _update.logoUserFile = { $set: { url: logoUserFile.url } };
            }
            if (backgroundColor) {
                _update.backgroundColor = { $set: backgroundColor };
            }
            return update(prev, _update);
        });
    }, [userFile, logoUserFile, backgroundColor]);

    useEffect(() => {
        asyncClientLabelsFetch(workspaceName, ClientLabelType.Cast).then(setWorkspaceCastLabels);
    }, []);

    return {
        hasExpiry,
        setHasExpiry,
        hasMaxViews,
        setHasMaxViews,
        hasPassword,
        setHasPassword,
        file,
        setFile,
        logoFile,
        setLogoFile,
        previewData,
        setPreviewData,
        userFile,
        setUserFile,
        logoUserFile,
        setLogoUserFile,
        backgroundColor,
        setBackgroundColor,
        isLoading,
        setIsLoading,
        clientCastLabels: workspaceCastLabels,
        selectedLabels,
        setSelectedLabels
    };
}
