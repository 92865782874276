import './SelectableGroup.scss';
import multiActionsButtonListStyle from '@he-novation/design-system/components/buttons/MultiActionButton/MultiActionButtonList.module.css';
import React, { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { parentHasClass } from '@he-novation/design-system/utils/dom/parentHasClass';
import offset from '@he-novation/front-shared/utils/offset';
import cn from 'classnames';
import { useAtom, useSetAtom } from 'jotai';
import { isDraggingFolderContentAtom } from '../../atoms/folder-atoms';
import { draggingOverAtom } from '../../atoms/selection-atoms';
import type { WindowingOptions } from '../../hooks/useWindowing';

import { useDataLayoutChildSelection } from '$components/DataLayout/components/DataLayoutChildren/useDataLayoutChildSelection';
import { SelectionAtom } from '$components/DataLayout/DataLayout.types';
import { SelectionOptions } from '$components/Selectable/SelectionHandler';

type SelectableGroupProps = SelectionOptions & {
    id: string;
    Tag?: React.ElementType;
    className?: string;
    children?: ReactNode | ReactNode[];
    onScroll?: React.UIEventHandler;
    wrapperRef: React.RefObject<HTMLElement>;
    role?: string;
    style?: React.CSSProperties;
    noAreaSelection?: boolean;
    childrenClassName: string;
    selectionAtom: SelectionAtom;
    onDrop?: (e: DragEvent) => void;
    items: string[];
    windowingOptions?: WindowingOptions;
};

function getScrollParent(node: Element | Document | null): Element | null {
    if (node === null || node instanceof Document) return null;
    const overflowY = node && window.getComputedStyle(node).overflowY;
    const isScrollable = overflowY !== 'visible' && overflowY !== 'hidden';
    if (isScrollable && node.scrollHeight > node.clientHeight) {
        return node;
    } else {
        return getScrollParent(node.parentNode as Element | Document | null);
    }
}
function getOffsetCoordinates(wrapper: HTMLElement, _x: number, _y: number): [number, number] {
    const o = offset(wrapper);
    const x = _x - o.left;
    const y = _y - o.top;
    return [x, y];
}

export function eventTargetToId(target: EventTarget | null, childrenClassName: string) {
    return target instanceof HTMLElement
        ? (target.closest(`.${childrenClassName}`) as HTMLElement)?.dataset.id || null
        : null;
}

function areaSelectionOverlapsRect(
    item: HTMLElement,
    [left, top, right, bottom]: [number, number, number, number],
    [fixedOffsetX, fixedOffsetY]: [number, number],
    selectionPaddingX?: number
) {
    const itemRect = offset(item);
    const offsetRect = [itemRect.left - fixedOffsetX, itemRect.top - fixedOffsetY];
    selectionPaddingX = selectionPaddingX || 0;
    return (
        offsetRect[0] - selectionPaddingX < right &&
        offsetRect[0] + itemRect.width + selectionPaddingX > left &&
        offsetRect[1] < bottom &&
        offsetRect[1] + itemRect.height > top
    );
}

function getOrderedAreaSelectionCoordinates(
    start: [number, number],
    end: [number, number]
): [number, number, number, number] {
    const smallestX = Math.min(start[0], end[0]);
    const largestX = Math.max(start[0], end[0]);
    const smallestY = Math.min(start[1], end[1]);
    const largestY = Math.max(start[1], end[1]);
    return [smallestX, smallestY, largestX, largestY];
}

const DRAGGING_MOUSE_OFFSET = 50;

export default function SelectableGroup({
    children,
    childrenClassName,
    className,
    DraggedComponent,
    onItemDrop,
    noAreaSelection,
    Tag,
    id,
    role,
    style,
    onScroll,
    wrapperRef: outRef,
    selectionAtom,
    selectionPaddingX,
    onDrop,
    items
}: SelectableGroupProps) {
    if (!Tag) Tag = 'div';
    const [dragging, setDragging] = useAtom(isDraggingFolderContentAtom);
    const [selecting, setSelecting] = useState(false);
    const areaSelectionRef = useRef<HTMLDivElement>(null);
    const draggedRef = useRef<HTMLElement>(null);
    const inRef = useRef<HTMLElement>(null);
    const selection = useRef({
        mouseDown: false,
        shouldDrag: false,
        mouseCoords: [0, 0] as [number, number],
        start: null as [number, number] | null,
        end: null as [number, number] | null,
        fixedOffsetY: 0,
        fixedOffsetX: 0,
        dragging: null as string[] | null,
        delayOverlapCheck: false,
        bubble: false,
        selecting: false
    });

    const { shiftSelect } = useDataLayoutChildSelection(selectionAtom);

    const ref = outRef || inRef;
    const [selected, setSelected] = useAtom(selectionAtom);
    const setDraggingOver = useSetAtom(draggingOverAtom);

    useEffect(() => {
        const scrollParent = getScrollParent(ref.current!);
        function onMouseMove(e: MouseEvent) {
            if (noAreaSelection || e.button > 0) return;
            if (!selection.current.mouseDown) return;

            if (
                !selecting &&
                !selection.current.dragging &&
                (Math.abs(e.clientX - selection.current.mouseCoords[0]) > 5 ||
                    Math.abs(e.clientY - selection.current.mouseCoords[1]) > 5)
            ) {
                if (selection.current.shouldDrag) {
                    setSelecting((selection.current.selecting = false));
                    setDragging(true);
                    setSelected((selected) => {
                        const target =
                            e.target instanceof HTMLElement
                                ? (e.target.closest(`.${childrenClassName}`) as HTMLElement)
                                : null;
                        if (!target) return selected;

                        let _selected = [...selected];
                        if (!selected.includes(target.dataset.id!)) {
                            _selected = [target.dataset.id!];
                        }
                        selection.current.dragging = _selected;
                        return _selected;
                    });
                } else {
                    setSelecting((selection.current.selecting = true));
                }
            }

            if (selection.current.selecting || selection.current.dragging)
                selection.current.mouseCoords = [e.clientX, e.clientY];

            if (!selection.current.selecting) {
                if (!selection.current.dragging) return;
                if (draggedRef.current) {
                    draggedRef.current.style.left = `${e.clientX + DRAGGING_MOUSE_OFFSET}px`;
                    draggedRef.current.style.top = `${e.clientY + DRAGGING_MOUSE_OFFSET}px`;
                }
                const id = eventTargetToId(e.target, childrenClassName);
                setDraggingOver(id);
                return;
            }

            selection.current.end = getOffsetCoordinates(ref.current!, e.clientX, e.clientY);
            const areaSelection = getOrderedAreaSelectionCoordinates(
                selection.current.start!,
                selection.current.end
            );
            const w = areaSelection[2] - areaSelection[0];
            const h = areaSelection[3] - areaSelection[1];
            const areaSelectionEl = areaSelectionRef.current!;
            areaSelectionEl.style.display = w > 1 || h > 1 ? 'block' : 'none';

            areaSelectionEl.style.left = `${areaSelection[0]}px`;
            areaSelectionEl.style.top = `${areaSelection[1]}px`;
            areaSelectionEl.style.width = `${w}px`;
            areaSelectionEl.style.height = `${h}px`;

            const scrolledAreaSelectionRect = areaSelection.map(
                (n, i) => n - ref.current![i % 2 ? 'scrollTop' : 'scrollLeft']
            ) as [number, number, number, number];

            if (!selection.current.delayOverlapCheck) {
                selection.current.delayOverlapCheck = true;

                const domItems = Array.from(
                    ref.current!.querySelectorAll(`.${childrenClassName}`)
                ) as HTMLElement[];

                setSelected((selected) => {
                    const selectedWindowedItems = selected.filter(
                        (id) => !domItems.find((el) => el.dataset.id === id)
                    );
                    const selectedDomItems = domItems
                        .filter((item) =>
                            areaSelectionOverlapsRect(
                                item,
                                scrolledAreaSelectionRect,
                                [selection.current.fixedOffsetX, selection.current.fixedOffsetY],
                                selectionPaddingX
                            )
                        )
                        .map((i) => i.dataset.id!);
                    return [
                        ...(selectedDomItems.length ? selectedWindowedItems : []),
                        ...selectedDomItems
                    ];
                });

                setTimeout(() => (selection.current.delayOverlapCheck = false), 20);
            }

            if (!scrollParent) return;

            const scrollParentRect = scrollParent.getBoundingClientRect();
            if (selection.current.start && selection.current.start[1] < selection.current.end[1]) {
                if (e.clientY > scrollParentRect.top + scrollParentRect.height - 100)
                    scrollParent.scrollTop = scrollParent.scrollTop + 4;
            } else {
                if (e.clientY < scrollParentRect.top + 100)
                    scrollParent.scrollTop = scrollParent.scrollTop - 4;
            }
        }

        function onMouseUp(e: MouseEvent) {
            areaSelectionRef.current!.style.display = 'none';
            selection.current.mouseDown = false;
            if (
                !selection.current.dragging &&
                (noAreaSelection || e.button > 0 || !selection.current.bubble)
            )
                return;

            if (
                e.target instanceof HTMLElement &&
                (e.target.classList.contains('ignore-mouseup') ||
                    parentHasClass(e.target, 'display-mode'))
            )
                return;
            const wasDragging = selection.current.dragging;
            selection.current.dragging = null;
            setDragging(false);
            setDraggingOver(null);
            selection.current.shouldDrag = false;
            if (!selection.current.selecting) {
                setSelected((selected) => {
                    if (!selected.length) return selected;

                    if (!wasDragging && !e.ctrlKey && !e.shiftKey) {
                        return [];
                    }
                    return selected;
                });

                return;
            }

            setSelecting((selection.current.selecting = false));
        }

        window.addEventListener('mousemove', onMouseMove);
        window.addEventListener('mouseup', onMouseUp);

        if (ref.current) {
            selection.current.fixedOffsetY = ref.current!.scrollTop + offset(ref.current).top;
            selection.current.fixedOffsetX = ref.current!.scrollTop + offset(ref.current).left;
        }

        return () => {
            window.removeEventListener('mousemove', onMouseMove);
            window.removeEventListener('mouseup', onMouseUp);
        };
    }, [noAreaSelection]);

    const onMouseDown = useCallback(
        (e: React.MouseEvent<HTMLInputElement>) => {
            if (
                e.button > 0 ||
                parentHasClass(e.target, 'ignore-mouseup') ||
                parentHasClass(e.target, 'display-mode') ||
                parentHasClass(e.target, multiActionsButtonListStyle.multiActionButtonList)
            )
                return;

            const targetItemId = eventTargetToId(e.target, childrenClassName);

            if (targetItemId) {
                selection.current.mouseDown = true;
                e.preventDefault();
                if (e.shiftKey) {
                    selection.current.selecting = true;

                    shiftSelect(items, targetItemId);
                }
                selection.current.shouldDrag = true;
                selection.current.mouseCoords = [e.clientX, e.clientY];
            }

            if (noAreaSelection) return;
            selection.current.mouseDown = true;
            selection.current.mouseCoords = [e.clientX, e.clientY];

            selection.current.start = getOffsetCoordinates(ref.current!, e.clientX, e.clientY);
            selection.current.end = selection.current.start;
        },
        [noAreaSelection, items]
    );

    return (
        <>
            <Tag
                id={id}
                role={role}
                ref={ref}
                onDrop={onDrop}
                className={cn('c-selectable-group', { 'is-selecting': selecting }, className)}
                style={{ position: 'relative', ...style }}
                onMouseDown={onMouseDown}
                onScroll={onScroll}
                onMouseUp={(e: React.MouseEvent<HTMLElement>) => {
                    selection.current.mouseDown = false;
                    const querySelector = `.${childrenClassName}`;
                    selection.current.bubble = true;
                    const selectableItemId = eventTargetToId(e.target, childrenClassName);
                    if (e.button > 0 || !selectableItemId) return;

                    const items = Array.from(
                        ref.current!.querySelectorAll(querySelector)
                    ) as HTMLElement[];

                    const element = items.find((item) => item.dataset.id === selectableItemId);
                    const elementId = element?.dataset.id;
                    if (!elementId) return;
                    selection.current.bubble = false;
                    if (parentHasClass(e.target, 'ignore-mouseup')) return;
                    if (e.button > 0) {
                        if (selected.length < 2) setSelected([]);
                        setSelected((selected) => {
                            if (selected.includes(elementId)) {
                                return selected.filter((s) => s !== elementId);
                            }
                            return [...selected, elementId];
                        });
                        return;
                    }

                    if (selection.current.dragging) {
                        e.preventDefault();
                        onItemDrop?.(selection.current.dragging, elementId);
                    } else if (!selection.current.selecting) {
                        setSelected((selected) => {
                            if (!(e.ctrlKey || e.metaKey)) {
                                return [elementId];
                            }
                            if (selected.includes(elementId)) {
                                return selected.filter((s) => s !== elementId);
                            }
                            return [...selected, elementId];
                        });
                    }
                    setDragging(false);

                    setSelecting((selection.current.selecting = false));
                    selection.current.shouldDrag = false;
                }}
            >
                <div
                    ref={areaSelectionRef}
                    style={{
                        position: 'absolute',
                        zIndex: '2',
                        border: '1px solid rgba(255,255,255, .5)',
                        background: 'rgba(255,255,255,.1)',
                        display: 'none',
                        borderRadius: '4px'
                    }}
                />
                {children}
            </Tag>

            {dragging &&
                DraggedComponent &&
                ReactDOM.createPortal(
                    <DraggedComponent selectionAtom={selectionAtom} ref={draggedRef} />,
                    document.body
                )}
        </>
    );
}
