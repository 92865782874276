import styles from './HeaderNavigation.module.css';
import React, { SyntheticEvent } from 'react';
import { useSelector } from 'react-redux';
import { Button, ButtonProps } from '@he-novation/design-system/components/buttons/Button/Button';
import cn from 'classnames';

import { isSmallScreenSelector } from '$redux/config/configSelectors';
import { routeSelector } from '$redux/route/routeSelectors';

export type HeaderNavLink = ButtonProps & {
    matchRoute?: (activeRoute: string) => boolean;
};

export type HeaderNav = (HeaderNavLink | null | false | undefined)[];

type HeaderNavigationProps = {
    nav: HeaderNav;
    onNavClick?: (e: SyntheticEvent<unknown, MouseEvent>) => void;
    className?: string;
};
export function HeaderNavigation({ nav, onNavClick, className }: HeaderNavigationProps) {
    const { route } = useSelector(routeSelector);
    const { isSmallScreen } = useSelector(isSmallScreenSelector);
    return (
        <nav className={cn(styles.nav, className)}>
            <ul>
                {nav
                    .filter((v) => v)
                    .map((link: HeaderNavLink, i) => (
                        <li key={i}>
                            <Button
                                href={link.href}
                                icon={isSmallScreen ? link.icon : null}
                                tooltip={link.tooltip}
                                disabled={link.disabled}
                                onClick={(e: SyntheticEvent<unknown, MouseEvent>) => {
                                    link.onClick?.(e);
                                    onNavClick?.(e);
                                }}
                                className={cn(
                                    className,
                                    isSmallScreen && styles.smallScreen,

                                    link.matchRoute?.(route) && styles.selected + ' is-active'
                                )}
                            >
                                {link.children}
                            </Button>
                        </li>
                    ))}
            </ul>
        </nav>
    );
}
