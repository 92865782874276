import styles from './SettingsWorkspaceSubscription.module.css';
import { useEffect, useState } from 'react';
import { workspaceUpgradeLink } from '@he-novation/config/paths/herawFrontUris';
import {
    CONFIRM,
    PAYMENT_METHOD_UPDATE,
    SUBSCRIPTION_INVOICES
} from '@he-novation/config/paths/modals.constants';
import { ClientTier } from '@he-novation/config/types/db/enums';
import type { PaymentMethod, Subscription } from '@he-novation/config/types/subscription.types';
import { Button, ButtonTone } from '@he-novation/design-system/components/buttons/Button/Button';
import {
    asyncCancelSubscription,
    asyncFetchSubscription,
    asyncPaymentMethodFetch
} from '@he-novation/front-shared/async/subscription.async';
import cn from 'classnames';
import { useAtomValue } from 'jotai';
import { workspaceAtom } from '../../../atoms/workspace-atoms';
import Accordion from '../../../components/Accordion/Accordion';
import { SubHeader } from '../../../components/layout/Header/SubHeader/SubHeader';
import { useModal } from '../../../hooks/useModal';
import { useTranslate } from '../../../hooks/useTranslate';
import { SubscriptionFeatures } from './components/SubscriptionFeatures';
import { SubscriptionPaymentError } from './components/SubscriptionPaymentError';
import { WorkspaceSubscriptionType } from './components/WorkspaceSubscriptionType';

export function SettingsWorkspaceSubscription() {
    const { t, locale } = useTranslate();
    const workspace = useAtomValue(workspaceAtom);
    const [subscription, setSubscription] = useState<Subscription>();
    const [paymentMethod, setPaymentMethod] = useState<PaymentMethod | null>(null);
    const { openModal, closeModal } = useModal();

    useEffect(() => {
        if (!workspace) return;
        asyncFetchSubscription(workspace.name).then((subscription) => {
            setSubscription(subscription);
            if (subscription.stripe) {
                asyncPaymentMethodFetch(workspace.name).then(setPaymentMethod);
            }
        });
    }, []);

    if (!workspace || workspace.tier === ClientTier.enterprise || !subscription) return null;

    return (
        <div className={styles.subscription}>
            <SubHeader
                subClassName={'settings-sub-header'}
                title={t('settings.Workspace subscription')}
            />
            <Accordion title={t('settings.Workspace plan')} isOpen noToggle icon="world">
                {subscription.stripe && subscription.stripe.paymentStatus !== 'paid' && (
                    <SubscriptionPaymentError stripe={subscription.stripe} />
                )}
                <div className={styles.plan}>
                    <WorkspaceSubscriptionType
                        tier={workspace.tier}
                        subscriptionType={subscription.subscriptionType}
                    />
                    <SubscriptionFeatures
                        maxProjects={subscription.maxProjects}
                        licenses={subscription.licenses}
                        storage={subscription.storage || 0}
                        locale={locale}
                    />
                    {subscription.stripe?.schedule && (
                        <>
                            <p className={styles.warning}>
                                {t(
                                    'settings.Your subscription will change at the end of the current billing period on {{date}}',
                                    {
                                        date: subscription.stripe.schedule.endDate.toLocaleDateString(
                                            locale
                                        )
                                    }
                                )}
                            </p>

                            <WorkspaceSubscriptionType
                                tier={ClientTier.teams}
                                subscriptionType={subscription.stripe.schedule.options.type}
                            />
                            <SubscriptionFeatures
                                maxProjects={subscription.stripe.schedule.options.maxProjects}
                                licenses={subscription.stripe.schedule.options.licenses}
                                storage={subscription.stripe.schedule.options.storage}
                                locale={locale}
                            />
                        </>
                    )}
                    {subscription.stripe && (
                        <div className={styles.price}>
                            {subscription.stripe.total / 100}€
                            <span>
                                {t(
                                    subscription.stripe.periodicity === 'monthly'
                                        ? 'settings.per month'
                                        : 'settings.per year'
                                )}
                            </span>
                        </div>
                    )}
                </div>
                {subscription.stripe && (
                    <>
                        <Button
                            tone={ButtonTone.Primary}
                            href={workspaceUpgradeLink(workspace.name)}
                            style={{ marginTop: '20px' }}
                        >
                            {t('settings.Update workspace subscription')}
                        </Button>
                        <Button
                            style={{ marginLeft: '10px' }}
                            tone={ButtonTone.Secondary}
                            onClick={() =>
                                openModal(SUBSCRIPTION_INVOICES, {
                                    workspaceName: workspace.name
                                })
                            }
                        >
                            {t('settings.Invoices')}
                        </Button>
                    </>
                )}
            </Accordion>
            {paymentMethod?.details && (
                <Accordion
                    title={t('settings.Payment method')}
                    isOpen
                    noToggle
                    icon={['file', 'magnifier']}
                >
                    <p className={cn(styles.brand)}>{paymentMethod.details.brand}</p>
                    <p>
                        {t('settings.Credit card ending with {{n}}', {
                            n: `**** ${paymentMethod.details.last4}`
                        })}
                    </p>
                    <p
                        className={
                            paymentMethod.details.expiry.getTime() < Date.now() ? styles.danger : ''
                        }
                    >
                        {t('settings.Expires on {{date}}', {
                            date: paymentMethod.details.expiry.toLocaleDateString(locale, {
                                month: '2-digit',
                                year: 'numeric'
                            })
                        })}
                    </p>
                    <Button
                        onClick={() => openModal(PAYMENT_METHOD_UPDATE)}
                        tone={ButtonTone.Secondary}
                    >
                        {t('settings.Change payment method')}
                    </Button>
                </Accordion>
            )}
            {subscription?.stripe && (
                <Accordion title={t('settings.DANGER ZONE')} icon="trash" isOpen noToggle>
                    <p>
                        {t(
                            'settings.Cancelling your subscription will delete all your data and projects at the end of your billing cycle'
                        )}
                        .
                    </p>
                    <p>
                        {t(
                            'settings.All your data, projects, and files will be permanently erased. This action cannot be undone, and your data cannot be recovered'
                        )}
                        .
                    </p>
                    <p>{t('settings.Proceed with caution')}.</p>
                    <Button
                        tone={ButtonTone.Alert}
                        onClick={() =>
                            openModal(CONFIRM, {
                                title: t('settings.Cancel subscription'),
                                message: t(
                                    'settings.Are you sure you want to cancel your subscription?'
                                ),
                                onSubmit: async () => {
                                    await asyncCancelSubscription(workspace.name).then(
                                        setSubscription
                                    );
                                    closeModal();
                                },
                                alert: true,
                                displayLoader: true
                            })
                        }
                    >
                        {t('settings.Cancel workspace subscription')}
                    </Button>
                </Accordion>
            )}
        </div>
    );
}

export default SettingsWorkspaceSubscription;
