import { Asset } from '@he-novation/config/types/asset.types';
import { compose, find, map, pipe, property } from 'lodash/fp';

const findSD: (assets: Asset[]) => Asset | undefined = find(
    ({ quality, type }) => type === 'player' && quality === 'sd'
);
const getSD = pipe(findSD, property('url'));

const getSizeSD = pipe(
    find(({ quality, type }) => type === 'player' && quality === 'sd'),
    property('exifResult.ImageSize'),
    (s = '') => s.replace(' ', 'x')
);

const getSizeHD = pipe(
    find(({ quality, type }) => type === 'player' && quality === 'hd'),
    property('exifResult.ImageSize'),
    (s = '') => s.replace(' ', 'x')
);

const findHD: (assets: Asset[]) => Asset | undefined = find(
    ({ quality, type }) => type === 'player' && quality === 'hd'
);

const findThumbnail = pipe(
    find(({ type }) => type === 'thumbnail'),
    property('url')
);

const getHD = pipe(findHD, property('url'));

const getPreviewUrl = compose(
    property('url'),
    find((asset) => property('quality')(asset) === 'preview')
);

const mapFetchAssetsResponseToPDF = (assets: Asset[]) => ({
    sourceSD: null,
    sourceHD: null,
    sizeHD: getSizeHD(assets),
    sizeSD: getSizeSD(assets),
    pages: assets
        .filter(({ type, quality }) => type === 'player' && quality === 'sd')
        .map(({ collectionId: id, url }) => ({
            id,
            sourceSD: url,
            sourceHD: (
                assets.find(
                    ({ collectionId, quality }) => collectionId === id && quality === 'hd'
                ) || {}
            ).url,
            sizeHD: getSizeHD(assets),
            sizeSD: getSizeSD(assets)
        }))
});

const mapFetchAssetsResponseToVideo = (assets: Asset[], encrypted?: boolean) => {
    let videoSize: number[] | boolean = [1920, 1080];
    let vs: boolean | number[] | undefined = false;

    try {
        const sd = findSD(assets);
        if (!sd?.ffprobeResult) throw new Error('SD or ffprobeResult not found');
        vs = encrypted
            ? !!encrypted
            : sd.ffprobeResult?.VideoSize?.split('x').map((v) => parseInt(v));
    } catch (e) {
        // SD not ready

        const hd = findHD(assets);
        if (hd?.ffprobeResult) {
            vs = encrypted
                ? !!encrypted
                : hd.ffprobeResult.VideoSize?.split('x').map((v) => parseInt(v));
        }
    }

    if (vs) {
        videoSize = vs;
    }

    return {
        previewUrl: getPreviewUrl(assets),
        width: videoSize[0],
        height: videoSize[1]
    };
};

const mapFetchAssetsResponseToImage = (assets: Asset[]) => {
    const sourceSD = getSD(assets);
    const sourceHD = getHD(assets);
    const sizeHD = getSizeHD(assets);
    const sizeSD = getSizeSD(assets);
    return {
        sourceSD,
        sourceHD,
        sizeHD,
        sizeSD,
        thumbnail: findThumbnail(assets),
        bitRateHD: null,
        bitRateSD: null,
        pages: [
            {
                id: 0,
                sourceSD,
                sourceHD,
                sizeHD,
                sizeSD
            }
        ]
    };
};

const mapFetchAssetsResponseToHTML = (assets: Asset[]) => ({
    source: assets.find(({ type }) => type === 'player')?.url
});

export const mapFetchAssetsToUpdate = (type: string, response: Asset[], encrypted?: boolean) => {
    try {
        switch (type) {
            case 'image':
                return mapFetchAssetsResponseToImage(response);
            case 'html':
                return mapFetchAssetsResponseToHTML(response);
            case 'video':
                return mapFetchAssetsResponseToVideo(response, encrypted);
            case 'pdf':
                return mapFetchAssetsResponseToPDF(response);
            case 'office':
                return {};
        }
        return {};
    } catch (e) {
        console.error(e);
        return {
            error: 'Mapping error'
        };
    }
};
