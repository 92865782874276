import React from 'react';
import { Client } from '@he-novation/config/types/client.types';
import { DataLayoutDisplayMode } from '@he-novation/config/types/dataLayout.types';
import { bytesToSize } from '@he-novation/utils/bytes';

import { DataLayout } from '$components/DataLayout/DataLayout';
import {
    filterSearch,
    useDataLayoutClientsActionsMenu
} from '$components/DataLayout/DataLayoutClients/DataLayoutClients.utils';
import { sorterFunctionFactory } from '$components/DataLayout/sorterFunctions';
import { useTranslate } from '$hooks/useTranslate';

type DataLayoutClientsProps = {
    items: Client[];
    search?: string;
    updateClient: (clients: Client) => void;
};

export function DataLayoutClients({ items, search, updateClient }: DataLayoutClientsProps) {
    const itemToActions = useDataLayoutClientsActionsMenu(updateClient);
    const { t, locale } = useTranslate();
    return (
        <DataLayout
            id="data-layout-clients"
            displayMode={DataLayoutDisplayMode.List}
            selectionAtom={undefined}
            extra={{ search, updateClient }}
            itemToId={(item: Client) => item.uuid}
            itemToActions={itemToActions}
            columns={[
                {
                    key: 'name',
                    header: t('common.Name'),
                    width: 300,
                    grow: true,
                    sort: sorterFunctionFactory('alphanumeric', 'lastname')
                },
                {
                    key: 'created',
                    width: 200,
                    header: t('common.Creation date'),
                    sort: sorterFunctionFactory('date', 'created')
                },
                {
                    key: 'storage.total',
                    header: t('common.Storage'),
                    width: 150,
                    sort: sorterFunctionFactory('number', 'storage'),
                    render: (item: Client) =>
                        item.storage.total === -1 ? (
                            <span dangerouslySetInnerHTML={{ __html: '&#8734;' }} />
                        ) : (
                            bytesToSize(item.storage.total, locale)
                        )
                },
                {
                    key: 'licenses',
                    header: t('settings.Licenses'),
                    width: 150,
                    sort: sorterFunctionFactory('number', 'licenses')
                }
            ]}
            items={filterSearch(items, search)}
        />
    );
}
