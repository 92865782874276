import { z } from 'zod';
import { ApiMethod } from '../../types/api.types';
import { userVerifySchema } from '../../types/payloads/user.payload';

export const API_USER_PUBLIC = {
    POST: {
        method: ApiMethod.POST,
        path: '/users',
        handlerMethod: 'signUp',
        passResAndNextToHandler: true,
        isPublic: true,
        zod: {
            body: z.object({
                email: z.string().email(),
                firstname: z.string().optional(),
                lastname: z.string().optional(),
                firm: z.string().optional(),
                phone: z.string().optional(),
                role: z.string().optional(),
                locale: z.string(),
                recaptcha: z.string(),
                plan: z.string().optional(),
                picture: z.string().optional(),
                password: z.string()
            })
        },
        allowNoMfa: true
    },
    VERIFY_EMAIL: {
        method: ApiMethod.POST,
        path: '/user/verify',
        handlerMethod: 'verifyEmail',
        isPublic: true,
        passResAndNextToHandler: true,
        zod: {
            body: userVerifySchema
        },
        responses: {
            200: {
                description: 'User exists/is connected or call fails',
                schema: z.object({
                    status: z.union([z.literal('exists'), z.literal('connected')])
                })
            }
        }
    }
} as const;
