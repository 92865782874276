import React from 'react';
import { Button } from '@he-novation/design-system/components/buttons/Button/Button';

type CastPreviewPlaceHolderProps = {
    textColor;
    download?: boolean | number;
};

export const CastPreviewPlaceHolder: React.FC<CastPreviewPlaceHolderProps> = ({
    textColor,
    download
}) => {
    return (
        <li className="cast-preview-placeholder">
            <div className="placeholder-bg">
                {!!download && <Button icon="download" role="none" type="button" />}
            </div>
            <div className="placeholder-title" style={{ background: textColor }}></div>
        </li>
    );
};
