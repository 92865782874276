import { FormTrigger } from '@he-novation/config/types/db/enums';
import type { ProjectFolderTree } from '@he-novation/config/types/folder.types';
import { Form } from '@he-novation/config/types/form.types';
import { FrontFolderContentFile } from '@he-novation/front-shared/types/file.front-types';
import {
    FrontFolderContent,
    FrontFolderContentFolder,
    FrontFolderContentGhost
} from '@he-novation/front-shared/types/folder.front-types';
import { atom } from 'jotai';
import { getDefaultStore } from 'jotai/index';
import uniqBy from 'lodash/uniqBy';

type Setter<T> = (setter: (value: T) => T) => void;
type ValueOrSetter<T> = T | ((value: T) => T);

export type SetFolderContent = Setter<FrontFolderContent>;
export type SetFolderGhosts = Setter<FrontFolderContentGhost[]>;
export type SetProjectFolderTree = Setter<ProjectFolderTree>;

export type FolderClipboardTarget = {
    folderUuid: string;
    projectUuid?: string;
};

export type FolderClipboard = {
    data: (FrontFolderContentFile | FrontFolderContentFolder)[];
    type: 'copy' | 'tree' | 'cut';
    projectUuid?: string;
};

export const folderUuidAtom = atom<string | null>(null);
export const folderGhostsAtom = atom<FrontFolderContentGhost[]>([]);

const folderContentAtom = atom<FrontFolderContent | null>(null);
export const folderUniqueContentAtom = atom(
    (get) => get(folderContentAtom),
    (get, set, value: ValueOrSetter<FrontFolderContent | null>) => {
        const newContent = typeof value === 'function' ? value(get(folderContentAtom)) : value;
        set(
            folderContentAtom,
            uniqBy(newContent, (c) => c.uuid)
        );
    }
);

export const folderClipboardAtom = atom<FolderClipboard | null>(null);

export const folderContentWithGhostsAtom = atom((get) => {
    const folderContent = get(folderContentAtom);
    if (!folderContent) return null;

    const folderUuid = get(folderUuidAtom);
    if (!folderUuid) return folderContent;
    return folderContent.concat(get(folderGhostsAtom).filter((g) => g.folderUuid === folderUuid));
});

export const foldersStore = getDefaultStore();

export const isDraggingFolderContentAtom = atom<boolean>(false);

export const projectFolderTreeAtom = atom<ProjectFolderTree | null>(null);

export const folderFormsAtom = atom<Form[]>([]);

export const folderUploadFormAtom = atom((get) =>
    get(folderFormsAtom).find((f) => f.trigger === FormTrigger.UPLOAD)
);
