import { z } from 'zod';
import { ApiMethod } from '../../types/api.types';
import { assetSchema } from '../../types/asset.types';
import { castCommonSchema, castFileResponseSchema } from '../../types/cast.types';
import { versionAssetRecapSchema } from '../../types/file.types';
import { commentSchema, noteSchema } from '../../types/note.types';
import {
    archiveCreationBodySchema,
    castArchiveCreationParamsSchema,
    castArchiveCreationQuerySchema
} from '../../types/payloads/archive.payload';
import { assetUploadCompleteBody, assetUuidSchema } from '../../types/payloads/asset.payload';
import {
    castBreadcrumbSchema,
    castCommentAssetParamsSchema,
    castCommentAttachmentCreateBody,
    castCommentParamsSchema,
    castFileDownloadParamsSchema,
    castFileDownloadUrlQuerySchema,
    castFileVersionParamsSchema,
    castNoteParamsSchema,
    castPublicFileParamsSchema,
    castPublicFolderParamsSchema,
    castPublicPasswordQuerySchema,
    castPublicSpecificParamsSchema,
    castPublicViewQuerySchema,
    castSubtitleQuerySchema,
    castSubtitlesParamsSchema
} from '../../types/payloads/cast.payload';
import {
    fileAssetDownloadUrlSchema,
    fileParamsSchema,
    filePasswordQuery,
    fileVersionParamsSchema
} from '../../types/payloads/file.payload';
import { passwordQuery } from '../../types/payloads/folder.payload';
import { castCommentCreateBody, castNoteCreateBody } from '../../types/payloads/note.payload';
import { subtitleParamsSchema, subtitleQuerySchema } from '../../types/payloads/subtitle.payload';
import { fileSubtitlesFindResponse } from '../../types/responses/file.responses';
import { attachmentUploadUrlResponse } from '../../types/responses/note.responses';

export const API_CAST_PUBLIC_VIEW = {
    GET: {
        method: ApiMethod.GET,
        path: '/public-casts/:castUid',
        handlerMethod: 'getCastPublicView',
        isPublic: true,
        zod: {
            params: castPublicSpecificParamsSchema,
            query: castPublicViewQuerySchema
        },
        responses: {
            200: {
                description: 'The cast public view',
                schema: castCommonSchema
            }
        }
    },
    PUT_VIEWS: {
        method: ApiMethod.PUT,
        path: '/public-casts/:castUid/views',
        handlerMethod: 'increaseCastViews',
        isPublic: true,
        zod: {
            params: castPublicSpecificParamsSchema
        },
        responses: {
            204: {
                description: 'The cast views were successfully updated'
            }
        }
    },
    GET_FILE: {
        method: ApiMethod.GET,
        path: '/public-casts/:castUid/files/:castFileUuid',
        handlerMethod: 'findCastFile',
        isPublic: true,
        zod: {
            params: castPublicFileParamsSchema,
            query: castPublicPasswordQuerySchema
        },
        responses: {
            200: {
                description: 'The cast file details',
                schema: castFileResponseSchema
            }
        }
    },
    GET_FILE_DOWNLOAD_URL: {
        method: ApiMethod.GET,
        path: '/public-casts/:castUid/cast-files/:castFileUuid/url',
        handlerMethod: 'getCastFileDownloadUrl',
        isPublic: true,
        zod: {
            params: castFileDownloadParamsSchema,
            query: castFileDownloadUrlQuerySchema
        },
        responses: {
            200: {
                description: 'The download url of the asset',
                schema: fileAssetDownloadUrlSchema
            }
        }
    },
    GET_BREADCRUMB: {
        method: ApiMethod.GET,
        path: '/public-casts/:castUid/folders/:castFolderUuid/breadcrumb',
        handlerMethod: 'getCastPublicBreadcrumb',
        isPublic: true,
        zod: {
            params: castPublicFolderParamsSchema,
            query: castPublicPasswordQuerySchema
        },
        responses: {
            200: {
                description: 'The cast breadcrumb',
                schema: z.array(castBreadcrumbSchema)
            }
        }
    }
} as const;

export const API_CAST_NOTE = {
    LIST: {
        method: ApiMethod.GET,
        path: '/public-casts/:castUid/cast-files/:castFileUuid/notes',
        handlerMethod: 'listCastNotes',
        isPublic: true,
        zod: {
            params: castPublicFileParamsSchema
        },
        responses: {
            200: {
                description: 'List of notes',
                schema: z.record(z.string().uuid(), noteSchema)
            }
        }
    },
    POST: {
        method: ApiMethod.POST,
        path: '/public-casts/:castUid/cast-files/:castFileUuid/notes',
        handlerMethod: 'postCastNote',
        isPublic: true,
        zod: {
            params: castPublicFileParamsSchema,
            body: castNoteCreateBody,
            query: passwordQuery
        },
        responses: {
            200: {
                description: 'Note created',
                schema: noteSchema
            }
        }
    },
    COMMENT_POST: {
        method: ApiMethod.POST,
        path: '/public-casts/:castUid/cast-files/:castFileUuid/notes/:noteUuid/comments', // TODO /casts/:castUid/files/:castFileUuid/versions/:fileVersion/notes/:noteUuid/comments
        handlerMethod: 'postCastComment', // TODO postCastNoteComment
        isPublic: true,
        zod: {
            body: castCommentCreateBody,
            params: castNoteParamsSchema,
            query: passwordQuery
        },
        responses: {
            200: {
                description: 'Comment created',
                schema: commentSchema
            }
        }
    },
    COMMENT_ASSET_POST: {
        method: ApiMethod.POST,
        path: '/public-casts/:castUid/cast-files/:castFileUuid/notes/:noteUuid/comments/:commentUuid/attachment',
        handlerMethod: 'postCastCommentAttachment',
        isPublic: true,
        zod: {
            params: castCommentParamsSchema,
            body: castCommentAttachmentCreateBody
        },
        responses: {
            200: {
                description: 'Attachment uploaded',
                schema: attachmentUploadUrlResponse
            }
        }
    },

    COMMENT_ASSET_UPLOAD_PATCH: {
        description: 'Completes a multipart upload',
        method: ApiMethod.PATCH,
        path: '/public-casts/:castUid/cast-files/:castFileUuid/notes/:noteUuid/comments/:commentUuid/assets/:assetUuid/upload',
        handlerMethod: 'patchCastCommentAssetUpload',
        isPublic: true,
        zod: {
            params: castCommentAssetParamsSchema,
            body: assetUploadCompleteBody
        },
        responses: {
            200: {
                description: 'Asset uploaded',
                schema: assetSchema
            }
        }
    }
} as const;

export const API_CAST_PUBLIC_ARCHIVE = {
    POST: {
        method: ApiMethod.POST,
        path: '/public-casts/:castUid/archives',
        handlerMethod: 'createCastArchive',
        isPublic: true,
        zod: {
            params: castArchiveCreationParamsSchema,
            query: castArchiveCreationQuerySchema,
            body: archiveCreationBodySchema
        },
        responses: {
            204: {
                description: 'Archive created'
            }
        }
    }
};

export const API_CAST_PUBLIC_VERSIONS = {
    LIST: {
        method: ApiMethod.GET,
        path: '/public-casts/:castUid/files/:fileUuid/versions',
        handlerMethod: 'listFileVersions',
        isPublic: true,
        zod: {
            params: fileParamsSchema
        },
        responses: {
            200: {
                description: 'List of file versions',
                schema: z.array(versionAssetRecapSchema)
            }
        }
    }
} as const;

export const API_CAST_PUBLIC_ASSET = {
    PATCH: {
        description: 'Completes a multipart upload',
        method: ApiMethod.PATCH,
        path: '/public-casts/:castUid/assets/:assetUuid/upload',
        handlerMethod: 'completeMultiPartUpload' as 'completeMultiPartUpload',
        isPublic: true,
        zod: {
            params: assetUuidSchema,
            body: assetUploadCompleteBody
        },
        responses: {
            200: {
                description: 'Asset uploaded',
                schema: assetSchema
            }
        }
    }
};

export const API_CAST_PUBLIC_SUBTITLES = {
    LIST: {
        method: ApiMethod.GET,
        path: '/public-casts/:castUid/cast-files/:castFileUuid/subtitles',
        handlerMethod: 'listFileSubtitles',
        isPublic: true,
        zod: {
            params: castFileVersionParamsSchema,
            query: filePasswordQuery
        },
        responses: {
            200: {
                description: 'List of subtitles for a file',
                schema: fileSubtitlesFindResponse
            }
        }
    },

    GET: {
        method: ApiMethod.GET,
        path: '/public-casts/:castUid/subtitles/:subtitleUuid',
        handlerMethod: 'getSubtitles',
        passResAndNextToHandler: true,
        isPublic: true,
        zod: {
            params: castSubtitlesParamsSchema,
            query: castSubtitleQuerySchema
        },
        description: 'Get a subtitle',
        responses: {
            200: {
                description: 'Subtitle output in the format passed in the query',
                schema: z.string(),
                contentTypes: [
                    'text/vtt',
                    'application/x-subrip',
                    'application/octet-stream',
                    'text/plain'
                ]
            }
        }
    }
} as const;
