import { z } from 'zod';
import { DataLayoutDisplayMode } from '../dataLayout.types';
import { folderSpecificOptional } from './folder.payload';

export const emailSchema = z.object({
    email: z.string().email()
});

export type EmailSchema = z.infer<typeof emailSchema>;

export const userUuidSchema = z.object({
    userUuid: z.string()
});

export type UserUuidSchema = z.infer<typeof userUuidSchema>;

export const userInfoSchema = z.object({
    firstname: z.string().optional(),
    lastname: z.string().optional(),
    email: z.string().email().optional(),
    role: z.string().optional(),
    firm: z.string().optional(),
    phone: z.string().optional(),
    picture: z.string().optional(),
    targetUserUuid: z.string().uuid().optional(),
    locale: z.string().optional()
});

export type UserInfoSchema = z.infer<typeof userInfoSchema>;

export const userStorageQuery = folderSpecificOptional.extend({
    sum: z.coerce.boolean().optional()
});

export type UserStorageQuery = z.infer<typeof userStorageQuery>;

export const notificationValueSchema = z.union([
    z.boolean(),
    z.literal('email'),
    z.literal('desktop')
]);

export const userPreferencesSchema = z.object({
    timezone: z.string().optional(),
    immersiveMode: z.boolean().optional(),
    rights: z
        .object({
            role: z.string().optional(),
            download: z.boolean().optional(),
            export: z.boolean().optional()
        })
        .optional(),
    trigger_bundles: z.boolean().optional(),
    trigger_metadata: z.boolean().optional().nullable(),
    encode_legacy: z.boolean().optional(),
    calendar: z
        .object({
            timeSlots: z
                .object({
                    start: z.number(),
                    end: z.number()
                })
                .optional(),
            globalPlanning: z
                .object({
                    grouper: z.string().optional(),
                    itemColorType: z.string().optional(),
                    grouperFilters: z.array(z.string()).optional()
                })
                .optional()
        })
        .optional(),
    ui: z
        .object({
            dataLayouts: z
                .record(
                    z.string(),
                    z.object({
                        sorter: z
                            .object({
                                key: z.string(),
                                order: z.enum(['ASC', 'DSC'])
                            })
                            .optional(),
                        grouper: z.string().optional().nullable(),
                        displayMode: z.nativeEnum(DataLayoutDisplayMode).optional(),
                        currentGroupsOpened: z.array(z.coerce.string()).optional(),
                        activeColumns: z.array(z.string()).optional(),
                        sort: z.tuple([z.string(), z.boolean()]).optional()
                    })
                )
                .optional(),
            sortersAndGroupers: z
                .record(
                    z.string(),
                    z.object({
                        grouper: z.string().optional().nullable(),
                        currentGroupsOpened: z.array(z.coerce.string()).optional().nullable(),
                        display: z.string().optional().nullable(),
                        groupFilters: z
                            .record(z.string(), z.array(z.string()).nullable())
                            .optional()
                    })
                )
                .optional(),
            loop: z.boolean().optional(),
            note_default_draft: z.boolean().optional(),
            previewOfficeMs: z.boolean().optional(),
            video_preview: z.boolean().optional(),
            videoSorter: z.string().optional(),
            tooltips: z.boolean().optional(),
            alwaysHD: z.boolean().optional(),
            displayProjectSize: z.boolean().optional(),
            playerBackgroundColor: z.string().optional(),
            playerSidePanel: z.boolean().optional(),
            activeProjectFolderTree: z.boolean().optional(),
            calendarZoom: z.string().optional()
        })
        .optional(),
    newsletter: z
        .object({
            news: z.boolean().optional(),
            tips: z.boolean().optional()
        })
        .optional(),
    mail: z
        .object({
            notify_digest: z.string().optional(),
            reset_password: z.boolean().optional(),
            notify_final_voted_for: z.boolean().optional(),
            invite_copy: z.boolean().optional()
        })
        .optional(),
    tags: z.array(z.string()).optional(),
    labels: z.array(z.string()).optional()
});

export type UserPreferencesSchema = z.infer<typeof userPreferencesSchema>;

export const userVerifySchema = z.object({
    email: z.string().email(),
    password: z.string().optional().nullable(),
    recaptcha: z.string()
});

export type UserVerifySchema = z.infer<typeof userVerifySchema>;

export const userNotificationSettingsSchema = z.object({
    digestFrequencyMinutes: z.number(),
    eventCreated: z.boolean(),
    eventInvitation: z.boolean(),
    eventUpdated: z.boolean(),
    fileCreated: z.boolean(),
    fileFinalized: z.boolean(),
    fileDownloaded: z.boolean(),
    fileUnfinalized: z.boolean(),
    commentCreated: z.boolean(),
    memberJoined: z.boolean(),
    taskAssigned: z.boolean(),
    taskCreated: z.boolean(),
    taskUpdated: z.boolean()
});

export type UserNotificationSettingsSchema = z.infer<typeof userNotificationSettingsSchema>;
