import { NoteMetadata } from '@he-novation/config/types/note.medatata.types';
import { CameraCoordinates, SceneLightning } from '@he-novation/config/types/threeDimensions.types';

import { AreaSelectionAtom } from '$atoms/file-atoms/area-selection-atom';
import { mapAreaSelectionToMetadata } from '$components/Controls/AreaSelection/AreaSelection.helpers';

export default ({
    activeAudioTrack,
    activeSubtitles,
    tcIn,
    tcOut,
    areaSelection,
    camera,
    lightning,
    height,
    width,
    frame,
    page
}: {
    activeAudioTrack?: number;
    activeSubtitles?: string[];
    tcIn?: string;
    tcOut?: string;
    areaSelection?: AreaSelectionAtom;
    camera?: CameraCoordinates;
    lightning?: SceneLightning;
    height?: number;
    width?: number;
    frame?: number;
    page?: number;
}): NoteMetadata => ({
    activeAudioTrack: tcIn ? activeAudioTrack : undefined,
    activeSubtitles: tcIn ? activeSubtitles : undefined,
    tcIn,
    tcOut,
    original: {
        ImageHeight: height || 0,
        ImageWidth: width || 0
    },
    frame,
    page,
    camera,
    lightning,
    ...(areaSelection ? mapAreaSelectionToMetadata(areaSelection) : {})
});
