import type { BaseFileType } from '../../file.types';
import type { BaseProjectType } from '../../project.types';
import type { BaseUserType } from '../../user.types';
import type { BaseLogPayload } from './log.payload';

export enum TaskLogKey {
    TaskAdd = 'task_add',
    TaskEdit = 'task_edit'
}

export interface BaseTaskLogPayload extends Omit<BaseLogPayload, 'project'> {
    task: {
        uuid: string;
        description?: string;
        assignees?: Omit<BaseUserType, 'locale'>[];
    };
    file?: BaseFileType;
    project: BaseProjectType;
}

export interface LogTaskAddPayload extends BaseTaskLogPayload {
    key: TaskLogKey.TaskAdd;
}
export interface LogTaskEditPayload extends BaseTaskLogPayload {
    key: TaskLogKey.TaskEdit;
}

export type LogTaskPayload = LogTaskAddPayload | LogTaskEditPayload;

export type TaskLogKeyToPayload<T extends TaskLogKey> = LogTaskPayload extends { type: T }
    ? Extract<LogTaskPayload, { type: T }>
    : never;

export type TaskLogExtra = { task: BaseTaskLogPayload['task'] };
