import styles from './Button.module.scss';
import React, {
    ComponentType,
    type CSSProperties,
    ForwardedRef,
    JSX,
    ReactNode,
    SyntheticEvent
} from 'react';
import classNames from 'classnames';
import { RouterLink } from '../../../configuration';
import type { IconSize } from '../../../enums';
import { Theme, WidgetTone } from '../../../enums';
import { Direction } from '../../../utils/getAbsolutePosition';
import { Icon, IconProp } from '../../graphics/Icon/Icon';
import { Loader, LoaderSize } from '../../widgets/Loader/Loader';
import { Tooltip } from '../../widgets/Tooltip/Tooltip';

export enum ButtonTone {
    Primary = 'primary',
    Secondary = 'secondary',
    Outlined = 'outlined',
    Light = 'light',
    Medium = 'medium',
    Alert = 'alert',
    Hoverable = 'hoverable',
    HoverableLight = 'hoverable-light',
    Plain = 'plain',
    Neutral = 'neutral',
    FormField = 'form-field-b',
    Dark = 'dark'
}

export type ButtonProps = {
    [a: string]: any;
    className?: string | false | null;
    icon?: SVGElement | IconProp;
    img?: string;
    iconSize?: IconSize;
    onClick?: (e: SyntheticEvent<unknown, MouseEvent>) => void;
    number?: number;
    numberStyle?: CSSProperties;
    style?: CSSProperties;
    tagName?: ComponentType | keyof JSX.IntrinsicElements;
    tone?: ButtonTone;
    external?: boolean;
    theme?: Theme;
    isLoading?: boolean;
    type?: 'button' | 'submit';
    tooltip?: string;
    tooltipOptions?: {
        direction?: Direction;
        hasArrow?: boolean;
        confirm?: () => void;
        tone?: WidgetTone;
    };
    children?: ReactNode | ReactNode[];
    iconAfter?: boolean;
    disabled?: boolean;
};

export const Button = React.forwardRef(function ForwardedButton(
    {
        children,
        tagName: Component,
        className,
        icon,
        iconSize,
        img,
        number,
        numberStyle,
        onClick,
        style,
        theme = Theme.Dark,
        tone,
        isLoading,
        external,
        tooltip,
        tooltipOptions,
        iconAfter,
        disabled,
        ...rest
    }: ButtonProps,
    ref: ForwardedRef<any>
) {
    if (!Component) {
        Component = rest.href
            ? external || rest.target === '_blank'
                ? 'a'
                : RouterLink
            : 'button';
    }

    const buttonProps = {
        ref,
        className: classNames(
            styles.button,
            'c-btn',
            tone,
            theme,
            className,
            isLoading && styles.loading,
            iconAfter && styles.iconAfter,
            disabled && styles.disabled
        ),
        onClick,
        style,
        disabled: Component === 'button' ? disabled : undefined,
        ...rest
    };

    let _icon: ReactNode | SVGElement;
    if (icon) {
        _icon = icon instanceof SVGElement ? icon : <Icon icon={icon} stroke="white" />;
    }

    const content = (
        <>
            {isLoading && <Loader size={LoaderSize.Small} theme={theme} />}
            {img && <img src={img} alt="" />}
            {_icon}
            {children && <span>{children}</span>}
            {typeof number !== 'undefined' && (
                <span className="number" style={numberStyle}>
                    {number}
                </span>
            )}
        </>
    );

    return tooltip ? (
        <Tooltip tagName={Component} content={tooltip} {...buttonProps} {...tooltipOptions}>
            {content}
        </Tooltip>
    ) : (
        //@ts-expect-error too complex
        <Component {...buttonProps}>{content}</Component>
    );
});
