import { buildApiCall } from '@he-novation/config/apis/api-fetch';
import { API_LOG } from '@he-novation/config/apis/log-api';
import { LogSearchBody } from '@he-novation/config/types/payloads/logs/log.payload';

export const asyncLogsSearch = (body: LogSearchBody) =>
    buildApiCall(API_LOG.SEARCH_POST)({ body }).then((r: any) =>
        r.map((l) => {
            const o = {
                ...l,
                created: l.created && new Date(l.created)
            };
            if (o.event) {
                o.event.startDate = o.event.startDate && new Date(o.event.startDate);
                o.event.endDate = o.event.endDate && new Date(o.event.endDate);
            }
            return o;
        })
    );

export const asyncLogsDeleteOld = () => buildApiCall(API_LOG.DELETE_OLD);
