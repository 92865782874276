import styles from './WorkspaceRole.module.css';
import React from 'react';
import { ClientUserRole } from '@he-novation/config/types/db/enums';
import { colorToRgb } from '@he-novation/utils/colors';

import { useTranslate } from '$hooks/useTranslate';

export const roleToColor = (role: ClientUserRole) => {
    switch (role) {
        case ClientUserRole.ADMINISTRATOR:
            return '#04FB80';
        case ClientUserRole.BASIC:
            return '#999999';
        case ClientUserRole.USER:
            return '#04CEFB';
    }
};

export const roleToBgColor = (role: ClientUserRole) => {
    const rgb = colorToRgb(roleToColor(role));
    return `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, 0.2)`;
};

export const roleToLabel = (role: ClientUserRole) => {
    switch (role) {
        case ClientUserRole.ADMINISTRATOR:
            return 'Workspace admin';
        case ClientUserRole.BASIC:
            return 'Basic';
        case ClientUserRole.USER:
            return 'Standard';
    }
};

export function WorkspaceRole({ role }: { role: ClientUserRole }) {
    const { t } = useTranslate();
    return (
        <div className={styles.container}>
            <div
                className={styles.badge}
                style={{
                    color: roleToColor(role),
                    backgroundColor: roleToBgColor(role),
                    borderColor: roleToColor(role)
                }}
            >
                <span>{t(`common.${roleToLabel(role)}`)}</span>
            </div>
        </div>
    );
}
