import React from 'react';
import { useSelector } from 'react-redux';
import { DataLayoutPrefs } from '@he-novation/config/types/dataLayout.types';
import { ActionType } from '@he-novation/design-system/components/buttons/MultiActionButton/MultiActionButton';
import { currentUserUuidSelector } from '../../../../redux/user/userSelectors';

import { OptionsButton } from '$components/Buttons/OptionsButton/OptionsButton';
import { DataLayoutGrouper, DataLayoutGrouperKey } from '$components/DataLayout/DataLayout.types';
import { useTranslate } from '$hooks/useTranslate';
import { isSmallScreenSelector } from '$redux/config/configSelectors';

export type DataLayoutGrouperButtonProps<T> = {
    grouper?: DataLayoutGrouper<T>;
    setGrouper: React.Dispatch<React.SetStateAction<DataLayoutGrouper<T> | undefined>>;
    groupers: DataLayoutGrouper<T>[];
    saveLayoutPrefs?: (userUuid: string, layoutPrefs: DataLayoutPrefs) => void;
    onGrouperChange?: (groupKey?: DataLayoutGrouperKey | null) => void;
    className?: string;
};

export function DataLayoutGrouperButton<T>({
    grouper,
    setGrouper,
    groupers,
    saveLayoutPrefs,
    onGrouperChange,
    className
}: DataLayoutGrouperButtonProps<T>) {
    const { t } = useTranslate();

    const noneGrouper = { label: t('common.None'), key: 'none' };

    const { isSmallScreen } = useSelector(isSmallScreenSelector);
    const { currentUserUuid } = useSelector(currentUserUuidSelector);

    if (isSmallScreen) {
        return null;
    }

    const actions: ActionType[] = [noneGrouper, ...groupers].map((option) => ({
        children: option.label,
        onClick: () => {
            const selectedGrouper = groupers.find((g) => g.key === option.key);
            const isAlreadyActive = grouper?.key === selectedGrouper?.key;
            const newGrouper = isAlreadyActive ? undefined : selectedGrouper;

            setGrouper(() => newGrouper);
            saveLayoutPrefs?.(currentUserUuid, {
                grouper: newGrouper?.key || null
            });
            onGrouperChange?.(newGrouper?.key);
        }
    }));

    return (
        <OptionsButton
            options={actions}
            label={t('common.Group by')}
            selectedLabel={grouper?.label}
            className={className}
        />
    );
}
