import type { MutableRefObject, RefObject } from 'react';
import { offset } from './dom/offset';

export enum DirectionX {
    Left = 'left',
    LeftInner = 'left-inner',
    Right = 'right',
    RightInner = 'right-inner',
    Center = 'center'
}

export enum DirectionY {
    Top = 'top',
    TopInner = 'inner-top',
    Middle = 'middle',
    Bottom = 'bottom',
    BottomInner = 'inner-bottom'
}

export type Direction = [DirectionX, DirectionY];

export function getContentAbsolutePosition(
    position: [number, number],
    content: HTMLElement,
    direction: Direction = [DirectionX.LeftInner, DirectionY.Top],
    triggerSize = [0, 0],
    contentSizedByTrigger?: boolean
): { left: number; top: number; width?: number; visibility?: 'visible' } {
    const contentWidth = contentSizedByTrigger
        ? Math.max(triggerSize[0], content.offsetWidth)
        : content.offsetWidth;

    let left = position[0];
    switch (direction[0]) {
        case DirectionX.Left:
            left -= contentWidth;
            break;
        case DirectionX.RightInner:
            left += triggerSize[0] - contentWidth;
            break;
        case DirectionX.Right:
            left += triggerSize[0];
            break;
        case DirectionX.Center:
            left -= contentWidth * 0.5 - triggerSize[0] * 0.5;
            break;
    }

    let top = position[1];
    switch (direction[1]) {
        case DirectionY.Bottom:
            top += triggerSize[1];
            break;
        case DirectionY.BottomInner:
            top += triggerSize[1] - content.offsetHeight;
            break;
        case DirectionY.Top:
            top -= content.offsetHeight;
            break;
        case DirectionY.TopInner:
            break;
        case DirectionY.Middle:
            top -= content.offsetHeight * 0.5 - triggerSize[1] * 0.5;
    }

    if (left + contentWidth > window.innerWidth) {
        left = Math.max(Math.min(position[0] - contentWidth, left), 0);
    }

    top = Math.max(Math.min(window.innerHeight - content.offsetHeight, top), 0);

    return {
        left,
        top,
        width: contentSizedByTrigger ? contentWidth : undefined,
        visibility: 'visible'
    };
}

const getElementAbsolutePosition = (
    button: HTMLElement,
    content: HTMLElement,
    direction: Direction = [DirectionX.LeftInner, DirectionY.Top],
    contentSizedByTrigger?: boolean,
    parent?: HTMLElement | null,
    mousePosition?: [number?, number?]
): { left: number; top: number; width?: number; visibility?: 'visible' } => {
    const _offset = offset(button, true, parent);
    const position = getContentAbsolutePosition(
        [_offset.left, _offset.top],
        content,
        direction,
        [button.offsetWidth, button.offsetHeight],
        contentSizedByTrigger
    );
    if (mousePosition) {
        if (mousePosition[0] !== undefined) {
            position.left = mousePosition[0];
        }
        if (mousePosition[1] !== undefined) {
            position.top = mousePosition[1];
        }
    }
    return position;
};

export const getAbsolutePosition = (
    triggerRef:
        | MutableRefObject<HTMLElement | undefined | null>
        | RefObject<HTMLElement | undefined>,
    absoluteRef:
        | MutableRefObject<HTMLElement | undefined | null>
        | RefObject<HTMLElement | undefined>,
    direction: Direction = [DirectionX.LeftInner, DirectionY.Top],
    contentSizedByTrigger?: boolean,
    parentRef?: MutableRefObject<HTMLElement | null>,
    mousePosition?: [number?, number?]
): { left?: number; top?: number; width?: number; visibility?: 'visible' } => {
    if (!triggerRef.current) return {};
    const button = triggerRef.current;
    const content = absoluteRef.current;
    if (!content) return {};
    return getElementAbsolutePosition(
        button,
        content,
        direction,
        contentSizedByTrigger,
        parentRef?.current,
        mousePosition
    );
};
