import { sanitizedString } from '@he-novation/utils/zod.utils';
import { z } from 'zod';
import { FolderRole } from '../folder.types';
import { workspaceParamsSchema } from '../payloads/workspace.payload';
import { oaWorkspaceNameSchema } from './workspace.open-api.types';

export const oaFolderSchema = z.object({
    uuid: z.string(),
    name: z.string(),
    role: z.nativeEnum(FolderRole),
    created: z.date(),
    updated: z.date().nullable(),
    isPublic: z.boolean(),
    isEncrypted: z.boolean(),
    publicPassword: z.string().nullable().optional(),
    publicDownload: z.boolean(),
    canDownload: z.boolean(),
    canExport: z.boolean(),
    labels: z.array(z.string()),
    tags: z.array(z.string()),
    admittance: z.boolean(),
    castTriggers: z.array(
        z.object({
            castUid: z.string(),
            castName: z.string(),
            castFolderUuid: z.string().nullable().optional()
        })
    ),
    folder: z
        .object({
            uuid: z.string(),
            name: z.string(),
            isPublic: z.boolean()
        })
        .nullable()
        .optional(),
    project: z
        .object({
            uuid: z.string(),
            name: z.string(),
            isProjectRoot: z.boolean(),
            folderUuid: z.string()
        })
        .nullable()
        .optional(),
    user: z.object({
        uuid: z.string(),
        firstname: z.string().optional().nullable(),
        lastname: z.string().optional().nullable(),
        email: z.string(),
        picture: z.string().nullable().optional(),
        locale: z.union([z.literal('fr'), z.literal('en'), z.literal('de')])
    }),
    creator: z.object({
        uuid: z.string(),
        firstname: z.string().optional().nullable(),
        lastname: z.string().optional().nullable(),
        email: z.string(),
        picture: z.string().nullable().optional()
    }),

    isSharedRoot: z.boolean().optional(),
    metadata: z.any(),
    watermark: z.boolean(),
    watermarkUrl: z.string().nullable().optional(),
    exportMode: z.enum(['never', 'always', 'final']),
    defaultPresets: z.any(),
    expires: z.date().optional().nullable(),
    client: z.object({
        uuid: z.string(),
        name: z.string()
    }),
    handlesHtmlBundles: z.array(z.string())
});

export type OAFolder = z.infer<typeof oaFolderSchema>;

export const oaPasswordQuery = z.object({
    p: sanitizedString({ optional: true })
});

export type OAPasswordQuery = z.infer<typeof oaPasswordQuery>;

export const oaFolderSpecificOptionalSchema = z.object({
    folderUuid: z.string().uuid()
});

export type OAFolderSpecificOptionalSchema = z.infer<typeof oaFolderSpecificOptionalSchema>;

export const oaWorkspaceNameAndOptionalFolderUuidSchema = oaWorkspaceNameSchema.extend({
    folderUuid: z.string().uuid().optional()
});

export type OAWorkspaceNameAndOptionalFolderUuidSchema = z.infer<
    typeof oaWorkspaceNameAndOptionalFolderUuidSchema
>;

export const oaDeprecatedFolderSpecificSchema = z.object({
    folderUuid: z.string().uuid()
});

export const oaFolderUuidSchema = z.object({
    folderUuid: z.string().uuid()
});

export const oaFolderParamsSchema = workspaceParamsSchema.extend({
    folderUuid: z.string().uuid()
});

export type OAFolderSpecificSchema = z.infer<typeof oaDeprecatedFolderSpecificSchema>;

export const oaFolderInviteBody = z.object({
    message: sanitizedString({ optional: true, nullable: true }),
    members: z.array(
        z.object({
            uuid: z.string().uuid(),
            email: z.string().email(),
            role: z.nativeEnum(FolderRole),
            canDownload: z.boolean().optional(),
            canExport: z.boolean().optional()
        })
    )
});

export type OAFolderInviteBody = z.infer<typeof oaFolderInviteBody>;

export const oaFolderCreateBody = z.object({
    parentFolderUuid: z.string().uuid(),
    name: z.string(),
    copyParentProperties: z.coerce.boolean().optional(),
    copyParentMembers: z.coerce.boolean().optional(),
    copyParentMembersPending: z.coerce.boolean().optional(),
    labels: z.array(z.string()).optional(),
    teamUuids: z.array(z.string()).optional()
});

export type OAFolderCreateBody = z.infer<typeof oaFolderCreateBody>;

export const oaFolderTeamsBody = z.object({
    teamUuids: z.array(z.string().uuid())
});

export type OAFolderTeamsBody = z.infer<typeof oaFolderTeamsBody>;

export const oaOptionalFolderUuidSchema = z.object({
    folderUuid: z.string().uuid().optional()
});
