export const strToQueryParams = (str: string) => {
    const queryParams: Record<string, string | null> = {};
    if (str.indexOf('?') > -1) {
        const query = (str.split('?').pop() as string).split('&');
        query.forEach((q) => {
            const [key, value] = q.split('=');
            queryParams[key] = value || null;
        });
    }
    return queryParams;
};

export const isEmail = (email: string) =>
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
    );

export const isStringValueTruthy = (stringValue?: string) =>
    !!stringValue && /\s*(1|true)\s*/.test(stringValue);

export const removeAccents = (str: string) =>
    str
        .normalize('NFD') // split an accented letter in the base letter and the accent
        .replace(/[\u0300-\u036f]/g, ''); // remove all previously split accents

export const slugify = (str: string) =>
    removeAccents(str).replace(/\s+/g, '-').replace(/\+/g, '').toLowerCase();

export const parseIfString = (str: unknown) => (typeof str === 'string' ? JSON.parse(str) : str);

export function parseContentLinks(content: string) {
    return content.replace(
        /(https?:\/\/[^\s]+)/g,
        '<a href="$1" target="_blank" referrerpolicy="no-referrer" onClick="(function(e) {e.stopPropagation()})()">$1</a>'
    );
}
