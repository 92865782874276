import styles from './WorkspaceMenuButton.module.css';
import React from 'react';
import { useSelector } from 'react-redux';
import { WORKSPACE_CREATION, workspaceCreateLink } from '@he-novation/config/paths/herawFrontUris';
import { Button, ButtonTone } from '@he-novation/design-system/components/buttons/Button/Button';
import { MultiActionButton } from '@he-novation/design-system/components/buttons/MultiActionButton/MultiActionButton';
import { DirectionX, DirectionY } from '@he-novation/design-system/utils/getAbsolutePosition';

import { useWorkspaceMenuActions } from '$components/Buttons/WorkspaceMenuButton/useWorkspaceMenuActions';
import {
    WorkspaceIcon,
    WorkspaceIconSize
} from '$components/Buttons/WorkspaceMenuButton/WorkspaceIcon';
import { useTranslate } from '$hooks/useTranslate';
import { routeSelector } from '$redux/route/routeSelectors';

export function WorkspaceMenuButton() {
    const { workspaceMenuActions, currentWorkspace } = useWorkspaceMenuActions(false);
    const { configRoute } = useSelector(routeSelector);
    const { t } = useTranslate();

    return currentWorkspace ? (
        <MultiActionButton
            className={styles.workspaceMenuButton}
            contentClassName={styles.workspaceMenu}
            actions={workspaceMenuActions}
            fireIfSingle
            direction={[DirectionX.Right, DirectionY.TopInner]}
        >
            <WorkspaceIcon
                name={currentWorkspace.displayName}
                primary={currentWorkspace.primaryColor}
                logoUrl={currentWorkspace.logoUrl}
                size={WorkspaceIconSize.Medium}
            />
        </MultiActionButton>
    ) : (
        <Button
            className={styles.createWorkspace}
            icon="plus"
            tone={ButtonTone.Primary}
            href={workspaceCreateLink()}
            tooltip={t('new.Create workspace')}
            tooltipOptions={{ direction: [DirectionX.Right, DirectionY.TopInner] }}
            disabled={configRoute === WORKSPACE_CREATION}
        />
    );
}
