import { Asset } from '@he-novation/config/types/asset.types';
import { FileUpdateBody } from '@he-novation/config/types/payloads/file.payload';
import { SubtitleGenerateBody } from '@he-novation/config/types/payloads/subtitle.payload';
import {
    asyncFileRestore,
    asyncFileVersionSubtitlesFetch,
    asyncFileVersionUpdate
} from '@he-novation/front-shared/async/file.async';
import { asyncSubtitlesGenerate } from '../../../async/asyncSubtitles';

import { WS_PREFIX_IN } from '$constants/webSocket.constants';
import { asyncActionSuccess } from '$helpers/asyncAction';
import { Translator } from '$hooks/useTranslate';
import { openFeedbackModalFromAtom } from '$redux/helpers';

export const FILE = 'FILE';
export const HIGHLIGHT_VERSION = `${FILE}/HIGHLIGHT_VERSION`;
export const IFRAME_CAPTURE_SET = `${FILE}/IFRAME_CAPTURE_SET`;
export const PLAY = `${FILE}/PLAY`;
export const PAUSE = `${FILE}/PAUSE`;

export const FILE_SET_ACTIVE_ASSET = `${FILE}/SET_ACTIVE_ASSET`;
export const FILE_UPDATE = `${FILE}/UPDATE`;
export const SET_ACTIVE_SUBTITLES = `${FILE}/SET_ACTIVE_SUBTITLES`;
export const SET_FINAL = `${FILE}/SET_FINAL`;
export const SET_PAGE = `${FILE}/SET_PAGE`;
export const SET_WIDTH_HEIGHT = `${FILE}/SET_WIDTH_HEIGHT`;
export const SUBTITLES_FETCH = `${FILE}/SUBTITLES_FETCH`;
export const SUBTITLES_GENERATE = `${FILE}/SUBTITLES_GENERATE`;
export const SUBTITLES_SET_TIMESTAMP = `${FILE}/SUBTITLES_SET_TIMESTAMP`;
export const SUBTITLES_TOGGLE = `${FILE}/SUBTITLES_TOGGLE`;

export const WS_SUBTITLES_UPDATE = `${WS_PREFIX_IN}/${FILE}/SUBTITLES_UPDATE`;

export const fileRestore = async (workspaceName: string, fileUuid: string, t: Translator) => {
    try {
        await asyncFileRestore(workspaceName, fileUuid);
    } catch (e) {
        if (e?.message) {
            openFeedbackModalFromAtom(t('common.Sorry, something went wrong'));
        }
    }
};

export const iframeCaptureSet = (iframeCapture) => ({
    type: IFRAME_CAPTURE_SET,
    iframeCapture
});

export const fileUpdate =
    (
        workspaceName: string,
        uuid: string,
        version: number,
        body: FileUpdateBody,
        success?: () => void
    ) =>
    async (dispatch) => {
        dispatch({ type: FILE_UPDATE });
        const file = await asyncFileVersionUpdate(workspaceName, uuid, version, body);
        dispatch({
            type: asyncActionSuccess(FILE_UPDATE),
            uuid,
            update: { ...body, updated: file.updated ? new Date(file.updated) : new Date() }
        });
        if (typeof success === 'function') success();
    };

export const setPage = (page: number) => ({
    type: SET_PAGE,
    page
});

export const highlightVersion = (highlightedVersion) => ({
    type: HIGHLIGHT_VERSION,
    highlightedVersion
});

export const play = () => ({
    type: PLAY
});

export const pause = () => ({
    type: PAUSE
});

export const setActiveSubtitles = (activeSubtitles) => ({
    type: SET_ACTIVE_SUBTITLES,
    activeSubtitles
});

export const subtitlesFetch =
    (
        workspaceName: string,
        fileUuid: string,
        fileVersion: number,
        password?: string,
        cb?: (subtitles: any) => void
    ) =>
    async (dispatch) => {
        dispatch({ type: SUBTITLES_FETCH });
        const subtitles = await asyncFileVersionSubtitlesFetch(
            workspaceName,
            fileUuid,
            fileVersion,
            password
        );
        dispatch({ type: asyncActionSuccess(SUBTITLES_FETCH), subtitles });
        cb?.(subtitles);
    };

export const setSubtitlesTimeStamp = () => ({
    type: SUBTITLES_SET_TIMESTAMP,
    subtitlesTimeStamp: new Date().getTime()
});

export const setWidthHeight = (width: number, height: number) => ({
    type: SET_WIDTH_HEIGHT,
    width,
    height
});

export const toggleSubtitles = (uuid) => ({
    type: SUBTITLES_TOGGLE,
    uuid
});

export const subtitlesGenerate =
    (workspaceName: string, data: SubtitleGenerateBody, cb?: (subtitles: any) => void) =>
    async (dispatch) => {
        dispatch({ type: SUBTITLES_GENERATE });
        const subtitles = await asyncSubtitlesGenerate(workspaceName, data);
        dispatch({ type: asyncActionSuccess(SUBTITLES_GENERATE), subtitles });
        cb?.(subtitles);
    };

export function setActiveAsset(asset: Asset) {
    return {
        type: FILE_SET_ACTIVE_ASSET,
        asset
    };
}
