import styles from './RadioTab.module.css';
import React from 'react';
import { FieldComponentProps } from 'react-modular-forms';
import cn from 'classnames';
import { FormField } from '../../FormField';

type RadioTabProps = FieldComponentProps & {
    options: { label: string; value: string }[];
    style: 'tabs' | 'bg5';
    className?: string;
};
export function RadioToggle({
    formId,
    id,
    options,
    onChange,
    name,
    value,
    style,
    className
}: RadioTabProps) {
    return (
        <div className={cn(styles.radioTab, styles[style], className)}>
            {options.map((o) => {
                return (
                    <FormField
                        key={`${id || name}-${o.value}`}
                        formId={formId}
                        id={`${id || name}-${o.value}`}
                        name={name}
                        label={o.label}
                        type="radio"
                        onChange={onChange}
                        value={o.value}
                        checked={value === o.value}
                    />
                );
            })}
        </div>
    );
}
