import { SidePanelCalendarFilters } from './SidePanelCalendarFilters/SidePanelCalendarFilters';
import { SidePanelFolder } from './SidePanelFolderSettings/SidePanelFolder';
import { SidePanelItemKits } from './SidePanelItemKits/SidePanelItemKits';
import { SidePanelProjectNotifications } from './SidePanelProjectNotifications/SidePanelProjectNotifications';

import SidePanelFile from '$components/SidePanel/SidePanelFile/SidePanelFile';
import { SidePanelFilters } from '$components/SidePanel/SidePanelFilters/SidePanelFilters';
import SidePanelWorkspaceTeams from '$components/SidePanel/SidePanelWorkspaceTeams/SidePanelWorkspaceTeams';
import {
    CALENDAR_FILTERS,
    FILE_PANEL,
    FILTERS_PANEL,
    FOLDER_SETTINGS,
    ITEM_KITS,
    PROJECT_NOTIFICATIONS,
    WORKSPACE_TEAMS
} from '$constants/constants.sidePanels';

export const sidePanels = {
    [FILE_PANEL]: {
        Component: SidePanelFile
    },
    [FILTERS_PANEL]: {
        Component: SidePanelFilters
    },
    [FOLDER_SETTINGS]: {
        Component: SidePanelFolder
    },
    [ITEM_KITS]: {
        Component: SidePanelItemKits
    },
    [CALENDAR_FILTERS]: {
        Component: SidePanelCalendarFilters
    },
    [PROJECT_NOTIFICATIONS]: {
        Component: SidePanelProjectNotifications
    },
    [WORKSPACE_TEAMS]: {
        Component: SidePanelWorkspaceTeams
    }
};

export default sidePanels;
