import sanitizeHTML from 'sanitize-html';
import { z, type ZodCatch, type ZodEnum, type ZodNullable, ZodOptional, type ZodString } from 'zod';

export const coercedBoolean = (noCatch?: boolean) => {
    let _enum:
        | ZodEnum<['0', '1', 'true', 'false']>
        | ZodCatch<ZodEnum<['0', '1', 'true', 'false']>> = z.enum(['0', '1', 'true', 'false']);
    if (!noCatch) {
        _enum = _enum.catch('false');
    }
    return z
        .union([z.boolean(), _enum])
        .optional()
        .transform((value) => {
            if (typeof value === 'boolean') return value;
            return value === 'true' || value === '1';
        });
};

export function sanitizedString<N extends boolean = false, O extends boolean = false>({
    html,
    optional,
    min,
    max,
    nullable
}: { html?: boolean; optional?: O; min?: number; max?: number; nullable?: N } = {}) {
    let v = z.string();
    if (min) v = v.min(min);
    if (max) v = v.max(max);
    const v2 = optional ? v.optional() : v;

    const v3 = nullable ? v2.nullable() : v2;

    return v3.transform((value) => {
        return (
            (optional || nullable) && !value
                ? value
                : sanitizeHTML(
                      value || '',
                      html
                          ? undefined
                          : {
                                allowedTags: [],
                                allowedAttributes: {}
                            }
                  )
        ) as z.infer<
            O extends true
                ? N extends true
                    ? ZodNullable<ZodOptional<ZodString>>
                    : ZodOptional<ZodString>
                : N extends true
                  ? ZodNullable<ZodString>
                  : ZodString
        >;
    });
}
