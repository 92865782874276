import type { AuthenticatedRequest, NextFunction, Request, Response } from 'express';
import type { z, ZodSchema, ZodTypeAny } from 'zod';
import type { ClientUserRole } from './db/enums';

export enum ApiMethod {
    GET = 'GET',
    PATCH = 'PATCH',
    POST = 'POST',
    PUT = 'PUT',
    DELETE = 'DELETE'
}
export type ZodValidationObject = ZodTypeAny | undefined;

type ZodValidation = {
    query?: ZodTypeAny;
    body?: ZodTypeAny;
    params?: ZodTypeAny;
};

type OpenApiResponse = {
    description: string;
    schema?: ZodSchema<any>;
    contentTypes?: readonly string[];
};

type Responses = {
    [code: number]: OpenApiResponse;
};

export type RouteDefinition<
    Q extends ZodValidationObject,
    B extends ZodValidationObject,
    P extends ZodValidationObject
> = {
    method: ApiMethod;
    path: string | readonly string[];
    handlerMethod: string;
    zod?: {
        query?: Q;
        body?: B;
        params?: P;
    };
    parseMultipart?: boolean;
    contentType?: string;
    mw?: ((req: Request, res: Response, next: NextFunction) => void)[];
    isPublic?: boolean;
    superAdminOnly?: boolean;
    workspaceRoles?: readonly ClientUserRole[];
    passResAndNextToHandler?: boolean;
    publicApi?: boolean | 'deprecated';
    description?: string;
    responses?: Responses;
    group?: string;
    ignoreZod?: boolean; // if public api needs to display a schema but it's validation is handled elsewhere
    ignoreMultipart?: boolean; // if public api needs to display a multipart schema but it is handled elsewhere
    allowNoMfa?: boolean;
};

export type InferZodField<
    R extends RouteDefinition<ZodValidationObject, ZodValidationObject, ZodValidationObject>,
    K extends keyof ZodValidation
> = R['zod'] extends ZodValidation
    ? R['zod'][K] extends ZodTypeAny
        ? z.infer<R['zod'][K]>
        : {}
    : {};

export type InferZodPayload<
    R extends RouteDefinition<ZodValidationObject, ZodValidationObject, ZodValidationObject>,
    K extends keyof ZodValidation
> = R['zod'] extends ZodValidation
    ? R['zod'][K] extends ZodTypeAny
        ? z.infer<R['zod'][K]>
        : undefined
    : undefined;

export type ApiResponse<
    R extends RouteDefinition<ZodValidationObject, ZodValidationObject, ZodValidationObject>
> = R['responses'] extends Responses
    ? R['responses'][200]['schema'] extends ZodSchema<any, any, any>
        ? z.infer<R['responses'][200]['schema']>
        : unknown
    : unknown;

export type RouteDefinitionDefault = RouteDefinition<
    ZodValidationObject,
    ZodValidationObject,
    ZodValidationObject
>;

type HandlerRequest<R extends RouteDefinitionDefault> = R['isPublic'] extends true
    ? Request<InferZodField<R, 'params'>, any, InferZodField<R, 'body'>, InferZodField<R, 'query'>>
    : AuthenticatedRequest<
          InferZodField<R, 'params'>,
          InferZodField<R, 'query'>,
          InferZodField<R, 'body'>,
          R['path'] extends
              | `/workspaces/:workspaceName${string}`
              | readonly `/workspaces/:workspaceName${string}`[]
              | `/public/v1/workspaces/:workspaceName${string}`
              | readonly `/public/v1/workspaces/:workspaceName${string}`[]
              ? true
              : false
      >;

type HandlerRes<R extends RouteDefinitionDefault> = R['passResAndNextToHandler'] extends true
    ? Response
    : undefined;
type HandlerNext<R extends RouteDefinitionDefault> = R['passResAndNextToHandler'] extends true
    ? NextFunction
    : undefined;

export type HandlerSignature<R extends RouteDefinitionDefault> = (
    req: HandlerRequest<R>,
    res: HandlerRes<R>,
    next: HandlerNext<R>
) => R['passResAndNextToHandler'] extends true ? Promise<unknown> : Promise<ApiResponse<R>>;
