import { Asset } from '../asset.types';

export enum WSCastEventType {
    FileCreated = 'file/created',
    FolderCreated = 'folder/created'
}

export interface WSCastEventFolderCreated {
    type: WSCastEventType.FolderCreated;
    castUid: string;
    castFolderUuid?: string;
    uuid: string;
    name: string;
    created: Date;
    user: {
        email: string;
        firstname?: string | null;
        lastname?: string | null;
    };
}

export interface WSCastEventFileCreated {
    type: WSCastEventType.FileCreated;
    castFileUuid: string;
    uuid: string;
    name: string;
    version: number;
    created: Date;
    assets: Asset[];
    folder: {
        uuid: string;
        name: string;
    };
    user: {
        email: string;
        firstname?: string | null;
        lastname?: string | null;
    };
}
