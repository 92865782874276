import { buildApiCall } from '@he-novation/config/apis/api-fetch';
import { API_SUBTITLE_SPOTL } from '@he-novation/config/apis/subtitle-api';
import { SubtitleGenerateBody } from '@he-novation/config/types/payloads/subtitle.payload';

export const asyncSubtitlesGenerate = (workspaceName: string, body: SubtitleGenerateBody) =>
    buildApiCall(API_SUBTITLE_SPOTL.GENERATE_POST)({
        params: {
            workspaceName
        },
        body
    });

export const asyncSubtitlesAvailableLanguagesFetch = (workspaceName: string) =>
    buildApiCall(API_SUBTITLE_SPOTL.LANGUAGES_GET)({
        params: {
            workspaceName
        }
    });
