import { deburr, property } from 'lodash/fp';

export default function objectSearchFactory(properties: string[], extraCheck?: Function) {
    return function <T>(list: T[], search?: string): T[] {
        if (!search) return list;
        search = deburr(search).toLowerCase();

        return list.filter((obj) => {
            for (let i = 0, iLength = properties.length; i < iLength; i++) {
                if (deburr(property(properties[i])(obj)).toLowerCase().indexOf(search) > -1)
                    return true;
            }
            return !!(extraCheck && extraCheck(obj, search));
        });
    };
}
