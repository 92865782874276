import styles from './TranscoderPIDGraph.module.css';
import React, { useEffect, useRef, useState } from 'react';
import { PIDData } from '@he-novation/config/types/transcoding';

type TranscoderPIDGraphProps = {
    pidData: PIDData;
};

const CURVE_MARGINS = [50.5, 50.5];
function computeLeft(
    index: number,
    timestamps: number[],
    end: number,
    msPerPixels: number,
    durationMS: number
) {
    const tickTimeMS = durationMS - (end - timestamps[index]);
    return tickTimeMS / msPerPixels + CURVE_MARGINS[0];
}

function renderGraph(canvas: HTMLCanvasElement, pidData: PIDData) {
    const CANVAS_WIDTH = canvas.width;
    const CANVAS_HEIGHT = canvas.height;
    canvas.width = CANVAS_WIDTH;
    canvas.height = CANVAS_HEIGHT;

    const CANVAS_WIDTH_NO_MARGIN = CANVAS_WIDTH - CURVE_MARGINS[0] * 2;

    const minCapacity = Math.min(...pidData.POINTS_CAPACITY);
    const maxCapacity = Math.max(...pidData.POINTS_CAPACITY);

    const unitHeight =
        (CANVAS_HEIGHT - CURVE_MARGINS[1] * 2) / Math.max(...pidData.POINTS_LOAD, maxCapacity);

    const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;

    ctx.clearRect(0, 0, CANVAS_WIDTH, CANVAS_HEIGHT);

    const end = pidData.TIMESTAMPS[pidData.TIMESTAMPS.length - 1];
    let durationMS = end - pidData.TIMESTAMPS[0];

    let msPerPixels = Math.ceil(durationMS / CANVAS_WIDTH_NO_MARGIN);

    let startIndex = 0;
    while (durationMS / msPerPixels > CANVAS_WIDTH_NO_MARGIN) {
        startIndex++;
        durationMS = end - pidData.TIMESTAMPS[startIndex];
        msPerPixels = Math.ceil(durationMS / CANVAS_WIDTH_NO_MARGIN);
    }

    const timestamps = pidData.TIMESTAMPS.slice(startIndex);
    const pointsLoad = pidData.POINTS_LOAD.slice(startIndex);
    const pointsCapacity = pidData.POINTS_CAPACITY.slice(startIndex);

    let previousLeft: number | undefined;
    ctx.font = '13px Arial';
    for (let i = 0; i < timestamps.length; i++) {
        const left = computeLeft(i, timestamps, end, msPerPixels, durationMS);
        if (previousLeft && left - previousLeft < 100) continue;
        previousLeft = left;

        ctx.fillStyle = 'lightgrey';
        ctx.textAlign = 'center';
        ctx.fillText(
            new Date(timestamps[i]).toLocaleTimeString(),
            left,
            CANVAS_HEIGHT - CURVE_MARGINS[1] + 20
        );

        ctx.beginPath();
        ctx.strokeStyle = 'lightgrey';
        ctx.setLineDash([5, 15]);
        ctx.moveTo(left, CANVAS_HEIGHT - CURVE_MARGINS[1]);
        ctx.lineTo(left, CURVE_MARGINS[1]);
        ctx.stroke();
        ctx.setLineDash([]);
    }

    ctx.font = '15px Arial';
    ctx.strokeStyle = 'cyan';
    ctx.beginPath();
    ctx.moveTo(CURVE_MARGINS[0], CANVAS_HEIGHT - CURVE_MARGINS[1]);
    let max = 0;
    for (let i = 0; i < pointsLoad.length; i++) {
        if (pointsLoad[i] > max) max = pointsLoad[i];
        const left = computeLeft(i, timestamps, end, msPerPixels, durationMS);
        ctx.lineTo(left, CANVAS_HEIGHT - pointsLoad[i] * unitHeight - CURVE_MARGINS[1]);
    }
    ctx.stroke();

    ctx.strokeStyle = 'hotpink';
    ctx.beginPath();
    ctx.moveTo(
        CURVE_MARGINS[0],
        CANVAS_HEIGHT - (pointsCapacity[0] ? pointsCapacity[0] * unitHeight : 0) - CURVE_MARGINS[1]
    );
    for (let i = 0; i < pointsCapacity.length; i++) {
        const _capacity = Math.max(minCapacity, pointsCapacity[i]);
        const left = computeLeft(i, timestamps, end, msPerPixels, durationMS);
        ctx.lineTo(left, CANVAS_HEIGHT - _capacity * unitHeight - CURVE_MARGINS[1]);
    }
    ctx.stroke();

    ctx.beginPath();
    ctx.strokeStyle = 'grey';
    ctx.moveTo(CURVE_MARGINS[0], CURVE_MARGINS[1]);
    ctx.lineTo(CURVE_MARGINS[0], CANVAS_HEIGHT - CURVE_MARGINS[1]);
    ctx.moveTo(CURVE_MARGINS[0], CANVAS_HEIGHT - CURVE_MARGINS[1]);
    ctx.lineTo(CANVAS_WIDTH - CURVE_MARGINS[0], CANVAS_HEIGHT - CURVE_MARGINS[1]);
    ctx.stroke();

    ctx.textAlign = 'right';

    const yCoordinateFor0 = CANVAS_HEIGHT - CURVE_MARGINS[1];

    ctx.fillText('0', CURVE_MARGINS[0] - 5, yCoordinateFor0);
    ctx.fillText(
        (max / 2).toString(),
        CURVE_MARGINS[0] - 5,
        yCoordinateFor0 - unitHeight * (max / 2)
    );
    ctx.fillText(max.toString(), CURVE_MARGINS[0] - 5, CURVE_MARGINS[1]);
    ctx.fillText(
        minCapacity.toString(),
        CURVE_MARGINS[0] - 5,
        yCoordinateFor0 - unitHeight * minCapacity
    );

    ctx.textAlign = 'left';
    ctx.fillStyle = 'cyan';
    ctx.fillText('Current tasks (points)', 0, CANVAS_HEIGHT - CURVE_MARGINS[1] + 45);
    ctx.fillStyle = 'hotpink';
    ctx.fillText('Transcoding capacity (points)', 170, CANVAS_HEIGHT - CURVE_MARGINS[1] + 45);
}

export function TranscoderPIDGraph({ pidData }: TranscoderPIDGraphProps) {
    const ref = useRef<HTMLCanvasElement>(null);
    const wrapperRef = useRef<HTMLDivElement>(null);
    const [size, setSize] = useState<[number, number]>([0, 0]);
    const counterRef = useRef<HTMLDivElement>(null);

    const lastTimestamp = useRef<number | null>(null);

    useEffect(() => {
        if (!wrapperRef.current || !ref.current) return;
        const width = wrapperRef.current.offsetWidth;
        const height = wrapperRef.current.offsetHeight;
        setSize([width, height]);
    }, []);

    useEffect(() => {
        if (!ref.current || !pidData) return;
        renderGraph(ref.current, pidData);
    }, [size[0], size[1], pidData]);

    useEffect(() => {
        lastTimestamp.current = pidData?.TIMESTAMPS[pidData.TIMESTAMPS.length - 1] || null;
    }, [pidData?.TIMESTAMPS.length]);

    const [nextTickApprox, setNextTickApprox] = useState<number | null>(null);

    useEffect(() => {
        const i = setInterval(() => {
            if (!lastTimestamp.current) return;
            const now = Date.now();
            const nextTick = lastTimestamp.current + 50_000;
            setNextTickApprox(Math.ceil(Math.max(nextTick - now, 0) / 1000));
        }, 1000);

        return () => {
            clearInterval(i);
        };
    }, []);

    return (
        <div ref={wrapperRef} className={styles.graph}>
            {nextTickApprox !== null && (
                <div ref={counterRef} className={styles.counter}>
                    Next tick in: ~{nextTickApprox}s
                </div>
            )}
            <canvas ref={ref} width={size[0]} height={size[1]} />
        </div>
    );
}
