import type { BaseFileType } from '../../file.types';
import { BaseFolderType } from '../../folder.types';
import type { BaseUserType } from '../../user.types';
import { BaseFileLogPayload } from './log.payload';
export enum FileLogKey {
    FileAccess = 'file_access',
    FileConvertToVersion = 'file_convert_to_version',
    FileDelete = 'file_delete',
    FileDownload = 'file_download',
    FileExport = 'file_export',
    FileMove = 'file_move',
    FileNew = 'file_new',
    FileSetFinal = 'file_set_final',
    FileRestore = 'file_restore',
    FileSubtitleAdd = 'file_subtitle_add',
    FileUpdate = 'file_update',
    FileVersionDelete = 'file_version_delete',
    FileVersionRestore = 'file_version_restore',
    FileVoteFinal = 'file_vote_final'
}
export interface LogFileAccessPayload extends BaseFileLogPayload {
    key: FileLogKey.FileAccess;
    hidden: boolean;
}
export interface LogFileConvertToVersionPayload extends BaseFileLogPayload {
    key: FileLogKey.FileConvertToVersion;
    targetFile: BaseFileType & { newVersion: number; thumbnail?: string };
}

export interface LogFileDeletePayload extends BaseFileLogPayload {
    key: FileLogKey.FileDelete;
}
export interface LogFileDownloadPayload extends Omit<BaseFileLogPayload, 'requestUser'> {
    key: FileLogKey.FileDownload;
    requestUser?: BaseUserType | null;
}

export interface LogFileExportPayload extends BaseFileLogPayload {
    key: FileLogKey.FileExport;
    plugin: string;
}

export interface LogFileMovePayload extends BaseFileLogPayload {
    key: FileLogKey.FileMove;
    targetFolder: BaseFolderType;
}

export interface LogFileNewPayload extends BaseFileLogPayload {
    key: FileLogKey.FileNew;
}
export interface LogFileRestorePayload extends BaseFileLogPayload {
    key: FileLogKey.FileRestore;
}

export interface LogFileSetFinalPayload extends BaseFileLogPayload {
    key: FileLogKey.FileSetFinal;
    final: boolean;
}

export interface LogFileSubtitleAddPayload extends BaseFileLogPayload {
    key: FileLogKey.FileSubtitleAdd;
    subtitle: {
        uuid: string;
        name: string;
        locale: string;
    };
}

export type FileChanges = {
    name?: [string, string];
    final?: [boolean, boolean];
};

export interface LogFileUpdatePayload extends BaseFileLogPayload {
    key: FileLogKey.FileUpdate;
    changes: FileChanges;
}
export interface LogFileVersionDeletePayload extends BaseFileLogPayload {
    key: FileLogKey.FileVersionDelete;
    deletedVersion: number;
}

export interface LogFileVersionRestorePayload extends BaseFileLogPayload {
    key: FileLogKey.FileVersionRestore;
    deletedVersion: number;
}

export interface LogFileVoteFinalPayload extends BaseFileLogPayload {
    key: FileLogKey.FileVoteFinal;
    voteFor?: BaseUserType;
}

export type LogFilePayload =
    | LogFileAccessPayload
    | LogFileConvertToVersionPayload
    | LogFileDeletePayload
    | LogFileExportPayload
    | LogFileNewPayload
    | LogFileDownloadPayload
    | LogFileMovePayload
    | LogFileRestorePayload
    | LogFileSetFinalPayload
    | LogFileSubtitleAddPayload
    | LogFileUpdatePayload
    | LogFileVersionDeletePayload
    | LogFileVersionRestorePayload
    | LogFileVoteFinalPayload;
