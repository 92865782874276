import { z } from 'zod';
import { ClientStatus, ClientTier } from './db/enums';
import { clientPreferencesSchema } from './payloads/client.payload';
import { userWorkspaceRightsSchema } from './payloads/workspace.payload';
import { defaultCastStyleSchema } from './cast.types';
import { LocaleList } from './i18n.types';
import { WorkspaceFeature } from './workspace.types';
import { workspaceUserSchema } from './workspace-team.types';

export const baseClientSchema = z.object({
    uuid: z.string(),
    name: z.string()
});

export type BaseClientType = z.infer<typeof baseClientSchema>;

export const clientSchema = z.object({
    created: z.date(),
    defaultCastStyle: defaultCastStyleSchema,
    locale: z.enum(LocaleList),
    mailPrefix: z.string().nullable(),
    name: z.string(),
    displayName: z.string(),
    storage: z.object({
        total: z.number()
    }),
    uuid: z.string(),
    features: z.array(z.nativeEnum(WorkspaceFeature)),
    ownerUuid: z.string(),
    tier: z.nativeEnum(ClientTier),
    status: z.nativeEnum(ClientStatus),
    canEnableFreeTrial: z.boolean()
});

export type Client = z.infer<typeof clientSchema>;

export const clientUserSchema = workspaceUserSchema.extend({
    clientRights: userWorkspaceRightsSchema.optional(),
    deleteRequested: z.date().optional().nullable(),
    expires: z.date().optional().nullable(),
    lastUsed: z.date().nullable(),
    locale: z.union([z.literal('de'), z.literal('en'), z.literal('fr')]),
    storage: z.object({
        total: z.number(),
        used: z.number()
    }),
    apiKey: z.string().uuid().nullable().optional(),
    timezone: z.string().nullable(),
    translationCredits: z.number(),
    translationCreditsTotal: z.number()
});

export type ClientUser = z.infer<typeof clientUserSchema>;

export const clientOIDCSchema = z.object({
    issuer: z.string(),
    clientId: z.string(),
    clientSecret: z.string().nullable().optional(),
    PKCE: z.boolean(),
    button: z.string().nullable().optional(),
    strict: z.boolean()
});

export type ClientOIDC = z.infer<typeof clientOIDCSchema>;

export const clientStorageSchema = z.object({
    total: z.number(),
    used: z.number(),
    available: z.number()
});

export type ClientStorage = z.infer<typeof clientStorageSchema>;

export const clientDomainSchema = z.object({
    uuid: z.string(),
    name: z.string(),
    created: z.date().nullable(),
    updated: z.date().nullable(),
    confirmationPassed: z.boolean(),
    confirmationExpires: z.date().nullable(),
    confirmationUuid: z.string()
});

export type ClientDomain = z.infer<typeof clientDomainSchema>;

export type ClientPreferences = z.infer<typeof clientPreferencesSchema>;

export const DEFAULT_CLIENT_PREFERENCES = {
    locale: 'en'
} satisfies ClientPreferences;

export const clientLicensesStatsSchema = z.object({
    used: z.number(),
    total: z.number()
});

export type ClientLicensesStats = z.infer<typeof clientLicensesStatsSchema>;

export const clientLicensesSchema = z.object({
    standardLicenses: clientLicensesStatsSchema,
    basicLicenses: clientLicensesStatsSchema
});

export type ClientLicenses = z.infer<typeof clientLicensesSchema>;

export const clientStatsSchema = z.object({
    projects: z.number(),
    tasks: z.number(),
    files: z.number(),
    licenses: clientLicensesSchema,
    storage: clientStorageSchema
});
export type ClientStats = z.infer<typeof clientStatsSchema>;

export const clientStylesSchema = z.object({
    primary: z.string()
});
