import { combineReducers } from 'redux';
import { FOLDER } from '../storeNames';
import { FILE } from './file/fileActions';
import fileReducer from './file/fileReducer';
import folderReducer from './folder/folderReducer';
import { PROJECTS } from './projects/projectsActions';
import projectsReducer from './projects/projectsReducer';

export default combineReducers({
    [FILE]: fileReducer,
    [FOLDER]: folderReducer,
    [PROJECTS]: projectsReducer
});
