import styles from './NoWorkspace.module.css';
import React from 'react';
import { WORKSPACE_CREATION } from '@he-novation/config/paths/herawFrontUris';
import { Button, ButtonTone } from '@he-novation/design-system/components/buttons/Button/Button';
import { logout } from '@he-novation/front-shared/async/user.async';
import { useTranslate } from '../../hooks/useTranslate';
export function NoWorkspace() {
    const { t } = useTranslate();
    return (
        <div className={styles.view}>
            <img src="/assets/logo.png" alt="" />
            <div className={styles.content}>
                <h1>{t('new.Welcome to HERAW!')}</h1>
                <p>{t('new.__WELCOME_TEXT_0')}</p>
                <p>{t('new.__WELCOME_TEXT_1')}</p>
                <p>{t('new.__WELCOME_TEXT_2')}</p>
                <div className={styles.buttons}>
                    <Button tone={ButtonTone.Primary} href={WORKSPACE_CREATION}>
                        {t('new.Create my workspace')}
                    </Button>
                    <Button tone={ButtonTone.Hoverable} onClick={() => logout()}>
                        {t('common.Sign out')}
                    </Button>
                </div>
            </div>
        </div>
    );
}
