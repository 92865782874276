import styles from './FormSubtitle.module.css';
import React, { FormEvent, ReactNode, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { MappedSubtitleEntry } from '@he-novation/config/types/subtitle.types';
import { Button, ButtonTone } from '@he-novation/design-system/components/buttons/Button/Button';
import { CustomControlType } from '@he-novation/design-system/components/form/Editor/EditorCustomControls';
import {
    FormField,
    ModularForm
} from '@he-novation/design-system/components/form/FormField/FormField';
import { dropFrames, timeCodeToSeconds } from '@he-novation/lib-timecodes';
import cn from 'classnames';
import { useAtomValue } from 'jotai/index';
import { videoAtom } from '../../../../../atoms/file-atoms/video-atom';

import TimeCodes from '$components/form/fields/TimeCodes/TimeCodes';
import { useTranslate } from '$hooks/useTranslate';
import { toggleKeyboardListeners } from '$redux/ui/uiActions';

export type FormSubtitleOutput = {
    uuid?: string;
    timecodeIn: string;
    timecodeOut: string;
    content: string;
    metadata: { align: string };
};

type FormSubtitleProps = {
    subtitleEntry?: MappedSubtitleEntry;
    formId: string;
    visualIdentifier?: ReactNode;
    multiActionsButton?: ReactNode;
    disabled?: boolean;
    onSubmit: (e: FormEvent<HTMLFormElement>, data: FormSubtitleOutput) => void;
    onCancel: () => void;
    className?: string;
    style?: unknown;
};

export function FormSubtitle({
    subtitleEntry: _subtitleEntry,
    formId,
    visualIdentifier,
    multiActionsButton,
    disabled = false,
    onSubmit,
    onCancel,
    className,
    style
}: FormSubtitleProps) {
    const dispatch = useDispatch();

    const videoState = useAtomValue(videoAtom);
    const { t } = useTranslate();

    const subtitleEntry = useMemo<Omit<MappedSubtitleEntry, 'uuid'> & { uuid?: string }>(() => {
        if (_subtitleEntry) return _subtitleEntry;

        return {
            start: videoState.currentTime,
            end: videoState.currentTime,
            timeIn: videoState.currentTime,
            timeOut: videoState.currentTime,
            timecodeIn: videoState.timecodeWithTimecodeStart,
            timecodeOut: videoState.timecodeWithTimecodeStart,
            content: ''
        };
    }, [_subtitleEntry]);

    const [initialValue] = useState(subtitleEntry.content);
    const [align, setAlign] = useState<string>(
        (subtitleEntry.metadata?.align as CanvasTextAlign) || 'center'
    );

    const subtitlesContentId = 'side-panel-subtitles-editor';

    return (
        <ModularForm
            id={formId}
            style={style}
            className={cn(styles.subtitleForm, disabled && 'disabled', className)}
            onSubmit={(e, data) => {
                e.preventDefault();
                dispatch(toggleKeyboardListeners(true));
                onSubmit(e, {
                    uuid: subtitleEntry.uuid,
                    timecodeIn: data.tcIn,
                    timecodeOut: data.tcOut,
                    content: data[subtitlesContentId],
                    metadata: { align }
                } as FormSubtitleOutput);
            }}
        >
            <header className={styles.subtitleHeader}>
                {visualIdentifier}

                <TimeCodes
                    formId={formId}
                    className={styles.timecodes}
                    tcIn={subtitleEntry.timecodeIn}
                    tcOut={subtitleEntry.timecodeOut}
                    timecode={videoState.timecodeWithTimecodeStart}
                    timeCodeToSeconds={(timecode) =>
                        timeCodeToSeconds(timecode, videoState.frameRate)
                    }
                    mask={`__:__:__${dropFrames(videoState.frameRate) ? ';' : ':'}__`}
                    disabled={disabled}
                />

                {multiActionsButton}
            </header>

            <div className={cn(styles.subtitleContent, align)}>
                <FormField
                    id={subtitlesContentId}
                    type="rich-text"
                    formId={formId}
                    disabled={disabled}
                    readOnly={disabled}
                    onFocus={() => {
                        if (!disabled) dispatch(toggleKeyboardListeners(false));
                    }}
                    onBlur={() => dispatch(toggleKeyboardListeners(true))}
                    customControls={[
                        {
                            type: CustomControlType.Tab,
                            value: align,
                            onClick: (_, align: string) => setAlign(align),
                            values: [
                                {
                                    id: 'align-left',
                                    icon: 'text_left',
                                    value: 'left'
                                },
                                {
                                    id: 'align-center',
                                    icon: 'text_center',
                                    value: 'center'
                                },
                                {
                                    id: 'align-right',
                                    icon: 'text_right',
                                    value: 'right'
                                }
                            ]
                        }
                    ]}
                    quillOptions={{
                        modules: {
                            toolbar: [
                                ['bold', 'italic', 'underline'],
                                [
                                    {
                                        color: [
                                            'white',
                                            'lime',
                                            'cyan',
                                            'red',
                                            'yellow',
                                            'magenta',
                                            'blue',
                                            'black'
                                        ]
                                    },
                                    {
                                        background: ['red', 'white', 'black', 'blue']
                                    }
                                ]
                            ]
                        }
                    }}
                    html={initialValue?.replace(/\n/g, '<br />')}
                />

                {!disabled && (
                    <div className={styles.buttons}>
                        <Button
                            tone={ButtonTone.Medium}
                            type={'button'}
                            onClick={() => {
                                dispatch(toggleKeyboardListeners(true));
                                onCancel?.();
                            }}
                        >
                            {t('common.Cancel')}
                        </Button>
                        <Button tone={ButtonTone.Primary}>{t('common.Save')}</Button>
                    </div>
                )}
            </div>
        </ModularForm>
    );
}
