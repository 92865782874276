import { sanitizedString } from '@he-novation/utils/zod.utils';
import { z } from 'zod';
import { Group } from '../paths/herawApiPathGroups';
import { ApiMethod } from '../types/api.types';
import { assetSchema } from '../types/asset.types';
import { TaskStatus } from '../types/db/enums';
import { oaProjectParamsSchema } from '../types/open-api/project.open-api.types';
import { projectParamsSchema } from '../types/payloads/project.payload';
import { taskCreateBody, taskParamsSchema, taskUpdateBody } from '../types/payloads/task.payload';
import { workspaceParamsSchema } from '../types/payloads/workspace.payload';
import { taskCreateOrUpdateResponse } from '../types/responses/task.responses';
import { taskSchema } from '../types/task.types';

export const API_TASK = {
    LIST: {
        method: ApiMethod.GET,
        path: '/workspaces/:workspaceName/tasks',
        handlerMethod: 'listTasks',
        zod: {
            params: workspaceParamsSchema,
            query: z.object({
                startDate: z.date().optional(),
                endDate: z.date().optional()
            })
        },
        responses: {
            200: {
                description: 'Tasks',
                schema: taskSchema.array()
            }
        }
    },
    POST: {
        method: ApiMethod.POST,
        path: '/workspaces/:workspaceName/projects/:projectUuid/tasks',
        handlerMethod: 'postTask',
        zod: {
            params: projectParamsSchema,
            body: taskCreateBody
        },
        responses: {
            200: { description: 'Task created', schema: taskCreateOrUpdateResponse }
        }
    },
    POST_OA: {
        method: ApiMethod.POST,
        path: '/public/v1/workspaces/:workspaceName/projects/:projectUuid/tasks',
        handlerMethod: 'postTask',
        parseMultipart: true,
        zod: {
            params: oaProjectParamsSchema,
            body: taskCreateBody
        },
        description: 'Create a task',
        responses: {
            201: { description: 'Task created', schema: taskSchema }
        },
        publicApi: true,
        group: Group.Tasks
    },
    POST_OA_DEPRECATED: {
        method: ApiMethod.POST,
        path: '/public/v1/task',
        handlerMethod: 'postTask',
        parseMultipart: true,
        zod: {
            params: z.object({ projectUuid: z.never() }),
            body: taskCreateBody.extend({ projectUuid: z.string().uuid() })
        },
        description: 'Create a task',
        responses: {
            201: { description: 'Task created', schema: taskSchema }
        },
        publicApi: 'deprecated',
        group: Group.Tasks
    },
    GET: {
        method: ApiMethod.GET,
        path: '/workspaces/:workspaceName/projects/:projectUuid/tasks/:taskUuid',
        handlerMethod: 'getTask',
        zod: {
            params: taskParamsSchema
        },
        responses: {
            200: {
                description: 'Task',
                schema: taskSchema
            }
        }
    },
    PATCH: {
        method: ApiMethod.PATCH,
        path: '/workspaces/:workspaceName/projects/:projectUuid/tasks/:taskUuid',
        handlerMethod: 'patchTask',
        zod: {
            params: taskParamsSchema,
            body: taskUpdateBody
        },
        responses: {
            200: {
                description: 'Task updated',
                schema: taskCreateOrUpdateResponse
            }
        }
    },
    UPDATE_MULTIPLE_STATUS_ORDERING: {
        method: ApiMethod.PUT,
        path: '/workspaces/:workspaceName/tasks-rearrange',
        handlerMethod: 'updateMultipleStatusAndOrdering',
        zod: {
            params: workspaceParamsSchema,
            body: z.object({
                browserSessionUuid: z.string().uuid(),
                changes: z.array(
                    z.object({
                        taskUuid: z.string().uuid(),
                        status: z.nativeEnum(TaskStatus),
                        ordering: z.number().int()
                    })
                )
            })
        }
    },
    DELETE: {
        method: ApiMethod.DELETE,
        path: '/workspaces/:workspaceName/projects/:projectUuid/tasks/:taskUuid',
        handlerMethod: 'deleteTask',
        zod: {
            params: taskParamsSchema
        }
    }
} as const;

export const API_TASK_ASSETS = {
    GET: {
        method: ApiMethod.GET,
        path: '/task-assets',
        handlerMethod: 'getTaskAssets',
        responses: {
            200: {
                description: 'Task assets',
                schema: assetSchema.array()
            }
        }
    }
} as const;
