import styles from './SettingsWorkspaceMiscellaneous.module.css';
import React, { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import {
    CLIENT_DOMAIN,
    DELETE_CONFIRM,
    FEEDBACK
} from '@he-novation/config/paths/modals.constants';
import { ClientDomain } from '@he-novation/config/types/client.types';
import { Locale, LocaleList } from '@he-novation/config/types/i18n.types';
import { Button, ButtonTone } from '@he-novation/design-system/components/buttons/Button/Button';
import {
    FormField,
    FormFields,
    ModularForm
} from '@he-novation/design-system/components/form/FormField/FormField';
import { Tabs, TabsMode } from '@he-novation/design-system/components/widgets/Tabs/Tabs';
import {
    asyncClientDomainDelete,
    asyncClientDomainRefresh,
    asyncClientDomainsFetch,
    asyncClientTermsSet,
    asyncWorkspaceAdminClientUpdate,
    clientFetchTerms,
    clientPreferencesUpdate
} from '@he-novation/front-shared/async/client.async';
import cn from 'classnames';
import update from 'immutability-helper';
import { useAtom, useAtomValue } from 'jotai';
import debounce from 'lodash/debounce';

import { workspaceAtom, workspaceNameAtom } from '$atoms/workspace-atoms';
import Accordion from '$components/Accordion/Accordion';
import { SubHeader } from '$components/layout/Header/SubHeader/SubHeader';
import { useModal } from '$hooks/useModal';
import { useTranslate } from '$hooks/useTranslate';

export function SettingsWorkspaceMiscellaneous() {
    const { openModal, closeModal } = useModal();

    const workspaceName = useAtomValue(workspaceNameAtom);
    const [workspace, setWorkspace] = useAtom(workspaceAtom);

    const [terms, setTerms] = useState<Record<Locale, string | null>>(
        Object.assign(LocaleList.map((l) => ({ l: null })))
    );
    const [termsLocale, setTermsLocale] = useState<Locale>(workspace?.locale || 'en');
    const [termsValue, setTermsValue] = useState<string>('');
    const [submittingTerms, setSubmittingTerms] = useState(false);

    const { t } = useTranslate();

    useEffect(() => {
        if (termsLocale && workspaceName) {
            if (!terms[termsLocale]) {
                clientFetchTerms(workspaceName, termsLocale).then((localizedTerms) => {
                    setTerms({ ...terms, [termsLocale]: localizedTerms });
                    setTermsValue(localizedTerms || '');
                });
            } else {
                setTermsValue(terms[termsLocale]);
            }
        }
    }, [termsLocale, workspaceName]);

    const [domains, setDomains] = useState<ClientDomain[]>([
        // {
        //     confirmationExpires: new Date('2024-08-08T09:13:08.580Z'),
        //     confirmationPassed: true,
        //     confirmationUuid: '26938b5f-ab96-43b2-bc2c-0211d1cacefd',
        //     created: new Date('2024-08-01T09:13:08.580Z'),
        //     name: 'domain.test.com',
        //     updated: new Date('2024-08-01T09:13:08.580Z'),
        //     uuid: 'efac195e-1d5f-4dbc-9df9-864639fa7805'
        // }
    ]);

    useEffect(() => {
        asyncClientDomainsFetch(workspaceName)
            .catch(() => [])
            .then((domains) => setDomains(domains));
    }, []);

    const openModalClientDomain = useCallback(
        () =>
            openModal(CLIENT_DOMAIN, {
                onDomainAdded: (r) =>
                    setDomains(
                        update(domains, {
                            $push: [r]
                        })
                    )
            }),
        []
    );

    const openModalDeleteDomain = useCallback(
        (clientDomainUuid: string, callback: (clientDomainUuid: string) => void) =>
            openModal(DELETE_CONFIRM, {
                onDelete: async () => {
                    await asyncClientDomainDelete(workspaceName, clientDomainUuid);
                    if (typeof callback === 'function') callback(clientDomainUuid);
                    closeModal();
                },
                message: t('common.Are you sure you want to delete this?')
            }),
        []
    );

    const feedback = useCallback(
        (message: string) =>
            openModal(
                FEEDBACK,
                {
                    timeout: 3000,
                    message,
                    disableOverlay: true,
                    alignTop: true
                },
                null,
                true
            ),
        []
    );

    const updateMailPrefix = debounce(async (mailPrefix) => {
        await clientPreferencesUpdate(workspaceName, { mail_prefix: mailPrefix });
        setWorkspace((w) => ({ ...w!, mailPrefix }));
    }, 500);

    return (
        <>
            <SubHeader subClassName={'settings-sub-header'} title={t('common.Settings')} />

            <Accordion title={t('settings.Notifications')} isOpen={true}>
                <FormField
                    id={'settings-client-mail-prefix'}
                    type={'text'}
                    label={t('settings.E-mail prefix')}
                    onChange={async (e: SyntheticEvent<HTMLInputElement>) =>
                        updateMailPrefix(e.currentTarget.value)
                    }
                    value={workspace?.mailPrefix}
                />
            </Accordion>

            <Accordion title={t('settings.Languages')} isOpen={true}>
                <FormField
                    type={'select'}
                    id="settings-client-locale"
                    name={'locale'}
                    label={t('settings.Language')}
                    value={workspace?.locale || 'en'}
                    onChange={async (e: SyntheticEvent<HTMLInputElement>) => {
                        const locale = e.currentTarget.value as Locale;
                        await clientPreferencesUpdate(workspaceName, { locale });
                        setWorkspace((w) => ({ ...w!, locale }));
                    }}
                >
                    <option value={'en'} key={'en'}>
                        English
                    </option>
                    <option value={'fr'} key={'fr'}>
                        Français
                    </option>
                    <option value={'de'} key={'de'}>
                        Deutsch
                    </option>
                </FormField>
            </Accordion>

            <Accordion title={t('project.Projects')} isOpen={true}>
                <FormFields
                    formId="client-settings-projects"
                    fields={[
                        {
                            type: 'checkbox',
                            id: 'settings-client-projects-castTeamAccess',
                            name: 'projects.castTeamAccess',
                            label: t('settings.Default team access for Casts notes and tasks'),
                            checked: !!workspace!.defaultCastTeamAccess,
                            onChange: async (e: SyntheticEvent<HTMLInputElement>) =>
                                await asyncWorkspaceAdminClientUpdate(workspaceName, {
                                    defaultCastTeamAccess: e.currentTarget.checked
                                }),
                            className: 'is-switch'
                        },
                        {
                            type: 'checkbox',
                            id: 'settings-client-projects-disableProjectCasts',
                            name: 'projects.disableProjectCasts',
                            label: t('settings.Disable casts in projects by default'),
                            checked: !!workspace!.disableProjectCasts,
                            onChange: async (e: SyntheticEvent<HTMLInputElement>) =>
                                await asyncWorkspaceAdminClientUpdate(workspaceName, {
                                    disableProjectCasts: e.currentTarget.checked
                                }),
                            className: 'is-switch'
                        }
                    ]}
                />
            </Accordion>

            <Accordion title={t('settings.Domains')} isOpen={true} icon={'at'}>
                <p>
                    {t(
                        'settings.Automatically add users to your account through their email addresses domain.'
                    )}
                    <br />
                    {t(
                        'settings.Each user invited to a content with an email address corresponding to the following list will be automatically associated with your account.'
                    )}
                    <br />
                    <br />
                    {t(
                        'settings.Each new domain name must be confirmed by validating the link from an email address postmaster (example : postmaster@heraw.com)'
                    )}
                </p>

                <Button tone={ButtonTone.Primary} onClick={openModalClientDomain}>
                    {t('common.Add')}
                </Button>

                {domains && (
                    <ul className={styles.domains}>
                        {domains.map((d) => (
                            <li key={d.uuid}>
                                <span
                                    className={cn(
                                        styles.status,
                                        d.confirmationPassed ? styles.green : styles.red
                                    )}
                                />
                                {d.name}{' '}
                                <span className={styles.actions}>
                                    {!d.confirmationPassed && (
                                        <Button
                                            tone={ButtonTone.Hoverable}
                                            className="reorder"
                                            type="button"
                                            onClick={() =>
                                                asyncClientDomainRefresh(
                                                    workspaceName,
                                                    d.uuid
                                                ).then(() =>
                                                    feedback(
                                                        t(
                                                            'settings.You will soon receive an email with the confirmation link to approve this domain name.'
                                                        )
                                                    )
                                                )
                                            }
                                        >
                                            {t('common.Reload')}
                                        </Button>
                                    )}
                                    <Button
                                        className="delete"
                                        type="button"
                                        icon="trash"
                                        tone={ButtonTone.Alert}
                                        onClick={() =>
                                            openModalDeleteDomain(d.uuid, () => {
                                                setDomains(
                                                    update(domains, {
                                                        $splice: [
                                                            [
                                                                domains.findIndex(
                                                                    ({ uuid }) => uuid === d.uuid
                                                                ),
                                                                1
                                                            ]
                                                        ]
                                                    })
                                                );
                                            })
                                        }
                                    >
                                        {t('common.Delete')}
                                    </Button>
                                </span>
                            </li>
                        ))}
                    </ul>
                )}
            </Accordion>

            <Accordion title={t('settings.Terms of service')} isOpen={true}>
                <ModularForm
                    id="client-terms"
                    onSubmit={async (e, data) => {
                        e.preventDefault();
                        setSubmittingTerms(true);
                        const content = data['client-terms-editor'];
                        setTerms({ ...terms, [termsLocale]: content });
                        await asyncClientTermsSet(workspaceName, termsLocale, content);
                        setSubmittingTerms(false);
                    }}
                >
                    <Tabs
                        activeTab={termsLocale}
                        mode={TabsMode.Visibility}
                        tabs={LocaleList.map((l) => ({
                            id: l,
                            label: localeToTab(l),
                            onClick: () => setTermsLocale(l)
                        }))}
                    />
                    <FormField
                        html={termsValue}
                        formId="client-terms"
                        name="client-terms-editor"
                        type="rich-text"
                    />
                    <Button tone={ButtonTone.Primary} isLoading={submittingTerms}>
                        {t('settings.Update terms')}
                    </Button>
                </ModularForm>
            </Accordion>
        </>
    );
}

function localeToTab(locale: Locale) {
    switch (locale) {
        case 'en':
            return 'English';
        case 'de':
            return 'Deutsch';
        default:
            return 'Français';
    }
}
