import React, { useCallback, useEffect, useState } from 'react';
import { CLIENT } from '@he-novation/config/paths/modals.constants';
import { Client } from '@he-novation/config/types/client.types';
import { ButtonTone } from '@he-novation/design-system/components/buttons/Button/Button';
import { Loader } from '@he-novation/design-system/components/widgets/Loader/Loader';
import { asyncClientsFetch } from '@he-novation/front-shared/async/client.async';
import update from 'immutability-helper';

import { DataLayoutClients } from '$components/DataLayout/DataLayoutClients/DataLayoutClients';
import { useSearchState } from '$components/HOC/withSearchState';
import { SubHeader } from '$components/layout/Header/SubHeader/SubHeader';
import { useModal } from '$hooks/useModal';

export function SettingsAdminWorkspaces() {
    const { openModal } = useModal();
    const [clients, setClients] = useState<Client[]>();
    const { search, setSearch, onSearchInput } = useSearchState();

    useEffect(() => {
        asyncClientsFetch().then((clients) =>
            setClients(clients.map((c) => ({ ...c, created: new Date(c.created) })))
        );
    }, []);

    const updateWorkspace = useCallback(
        (client: Client) => {
            if (!clients) return;

            const updatedClientIndex = clients.findIndex((i) => i.uuid === client.uuid);

            if (updatedClientIndex > -1) {
                const updatedClients = update(clients, {
                    [updatedClientIndex]: {
                        $set: client
                    }
                });
                setClients(updatedClients);
            } else {
                setClients([...clients, client]);
            }
        },
        [clients]
    );

    return (
        <>
            <SubHeader
                subClassName={'settings-sub-header'}
                title={'Workspaces'}
                actions={[
                    {
                        tone: ButtonTone.Primary,
                        children: 'Create workspace',
                        onClick: () => openModal(CLIENT, { updateWorkspace })
                    }
                ]}
                search={{
                    search,
                    setSearch,
                    onSearchInput
                }}
            />

            {clients ? (
                <DataLayoutClients items={clients} updateClient={updateWorkspace} search={search} />
            ) : (
                <Loader />
            )}
        </>
    );
}
