import styles from './SettingsAdminTranscoders.module.css';
import React, { useCallback, useEffect, useState } from 'react';
import { TRANSCODER_SPAWN } from '@he-novation/config/paths/modals.constants';
import { TranscoderTaskStatus } from '@he-novation/config/types/db/enums';
import type { PIDData, Transcoder, TranscodingTask } from '@he-novation/config/types/transcoding';
import { ButtonTone } from '@he-novation/design-system/components/buttons/Button/Button';
import {
    asyncTranscoderPIDDataFetch,
    asyncTranscodersFetch,
    asyncTranscoderUnassignedTasksFetch
} from '@he-novation/front-shared/async/transcoders.async';
import { SubHeader } from '../../../../components/layout/Header/SubHeader/SubHeader';
import { useModal } from '../../../../hooks/useModal';
import { TranscoderPIDGraph } from './TranscoderPIDGraph/TranscoderPIDGraph';
import { TranscoderPointsTable } from './TranscoderPointsTable';
import { TranscodersTable } from './TranscodersTable';

export function SettingsAdminTranscoders() {
    const [transcoders, setTranscoders] = useState<Transcoder[]>([]);
    const [unassignedTasks, setUnassignedTasks] = useState<
        Omit<TranscodingTask, 'transcoderUuid'>[]
    >([]);
    const [pidData, setPidData] = useState<PIDData | null>(null);

    const { openModal } = useModal();

    const fetchTranscoders = useCallback(() => {
        asyncTranscodersFetch().then(setTranscoders);
        asyncTranscoderUnassignedTasksFetch().then(setUnassignedTasks);
    }, []);

    useEffect(() => {
        asyncTranscoderPIDDataFetch().then(setPidData);
        const i = setInterval(() => {
            asyncTranscoderPIDDataFetch().then((data) => {
                setPidData(data);
            });
        }, 5_000);
        return () => {
            clearInterval(i);
        };
    }, []);

    useEffect(() => {
        fetchTranscoders();
    }, [pidData?.TIMESTAMPS.length]);

    const { transcodingPoints, transcodingTaskPoints, runningTaskPoints } = transcoders.reduce(
        (acc, t) => ({
            transcodingPoints: acc.transcodingPoints + t.transcodingPoints,
            transcodingTaskPoints:
                acc.transcodingTaskPoints + t.tasks.reduce((acc, t) => acc + t.points, 0),
            runningTaskPoints:
                acc.runningTaskPoints +
                t.tasks.reduce(
                    (acc, t) => (t.status === TranscoderTaskStatus.RUNNING ? acc + t.points : acc),
                    0
                )
        }),
        {
            transcodingPoints: 0,
            transcodingTaskPoints: 0,
            runningTaskPoints: 0
        }
    );
    const unassignedTaskPoints = unassignedTasks.reduce((acc, t) => acc + t.points, 0);

    return (
        <>
            <SubHeader
                subClassName={'settings-sub-header'}
                title={'Transcoders'}
                actions={[
                    {
                        tone: ButtonTone.Primary,
                        children: 'Spawn transcoder',
                        onClick: () =>
                            openModal(TRANSCODER_SPAWN, {
                                onClose: fetchTranscoders
                            })
                    }
                ]}
            />
            <TranscoderPointsTable
                className={styles.transcoders}
                transcodingPoints={transcodingPoints}
                transcodingTaskPoints={transcodingTaskPoints}
                runningTaskPoints={runningTaskPoints}
                unassignedTasks={unassignedTasks}
                unassignedTaskPoints={unassignedTaskPoints}
            />

            <TranscodersTable transcoders={transcoders} fetchTranscoders={fetchTranscoders} />
            {pidData && <TranscoderPIDGraph pidData={pidData} />}
        </>
    );
}
