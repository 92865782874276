import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { castLink, projectCastLink } from '@he-novation/config/paths/herawFrontUris';
import { PASSWORD_FORM } from '@he-novation/config/paths/modals.constants';
import type { PublicCastColors } from '@he-novation/config/types/cast.types';
import { FileStatus } from '@he-novation/config/types/db/enums';
import {
    WSCastEventFileCreated,
    WSCastEventFolderCreated
} from '@he-novation/config/types/websockets/cast.ws.types';
import { colorAverage } from '@he-novation/config/utils/colors';
import {
    asyncCastFileDelete,
    asyncCastFileFetch,
    asyncCastFolderDelete,
    asyncPrivateCastSelect,
    asyncPublicCastSelect
} from '@he-novation/front-shared/async/cast.async';
import { fetchCastNotes } from '@he-novation/front-shared/async/note.async';
import { mapCastToFrontCast } from '@he-novation/front-shared/mappers/cast.mappers';
import {
    FrontCast,
    FrontCastFolderChild,
    FrontCastFolderItemFile,
    FrontCastFolderItemFolder
} from '@he-novation/front-shared/types/cast.front-types';
import update from 'immutability-helper';
import { useAtom, useSetAtom } from 'jotai';
import { notesAtom } from '../atoms/note-atoms';
import { castWorkspaceAtom } from '../atoms/workspace-atoms';
import { asyncActionSuccess } from '../helpers/asyncAction';
import { PUBLIC_FILE_VIEW_FETCH } from '../redux/content/contentActions';
import { mapFetchFileToUpdate } from '../redux/content/file/maps/mapFetchFileToUpdate';
import mapFetchNoteToUpdate from '../redux/content/note/map/mapFetchNoteToUpdate';

import { castAtom, castColorsAtom, castPasswordAtom, castUidAtom } from '$atoms/cast-atoms';
import { useModal } from '$hooks/useModal';
import { useSocketIO } from '$hooks/useSocketIO';
import { currentUserUuidSelector } from '$redux/user/userSelectors';

export function useCast() {
    const { openModal, closeModal } = useModal();

    const setCastUid = useSetAtom(castUidAtom);
    const [cast, setCast] = useAtom(castAtom);
    const [castPassword, setCastPassword] = useAtom(castPasswordAtom);
    const [castColors, setCastColors] = useAtom(castColorsAtom);
    const setNotes = useSetAtom(notesAtom);
    const setCastWorkspace = useSetAtom(castWorkspaceAtom);

    const [expired, setExpired] = useState<boolean>(false);
    const [notFound, setNotFound] = useState<boolean>(false);
    const dispatch = useDispatch();

    function fetchPublicCastFile(castUid: string, castFileUuid: string, password?: string) {
        return asyncCastFileFetch(castUid, castFileUuid, password || castPassword)
            .then(({ file, cast }) => {
                setCastWorkspace({ name: file.workspace.name });

                setCast({ ...mapCastToFrontCast(cast), isPrivate: false });

                dispatch({
                    type: asyncActionSuccess(PUBLIC_FILE_VIEW_FETCH),
                    fileState: mapFetchFileToUpdate(file)
                });

                fetchCastNotes({ castUid, castFileUuid }).then((castNotes) => {
                    setNotes((notes) =>
                        update(notes, {
                            $set: mapFetchNoteToUpdate(castNotes).list
                        })
                    );
                });
            })
            .catch((e) => {
                if (e.name !== 'PASSWORD') throw e;
                openModal(PASSWORD_FORM, {
                    onSubmit: (data) => {
                        setCastPassword(data.password);
                        setTimeout(() => {
                            fetchPublicCastFile(castUid, castFileUuid, data.password).then(() =>
                                closeModal()
                            );
                        }, 10);
                    }
                });
            });
    }

    return {
        cast,
        setCast,
        expired,
        notFound,
        colors: castColors || {
            itemBackgroundColor: 'rgba(0,0,0,0.5)',
            textColor: '#ffffff',
            textColorFaded: 'rgba(255,255,255,0.5)',
            background: 'var(--color-bg-2)',
            backgroundGradient: null,
            logo: null
        },
        fetchPrivateCast(workspaceName: string, castUid: string, castFolderUuid?: string) {
            setCastUid(castUid);
            asyncPrivateCastSelect(workspaceName, castUid, castFolderUuid)
                .then(setCast)
                .catch((e) => {
                    if (e.name === 'CAST_NOT_FOUND') setNotFound(true);
                    throw e;
                });
        },

        fetchPublicCast(castUid: string, castFolderUuid?: string) {
            setCastUid(castUid);
            asyncPublicCastSelect(castUid, castFolderUuid, false, castPassword)
                .then((c) => {
                    setCast(c);
                    setCastColors(computeCastColors(c));
                })
                .catch((e) => {
                    if (e.name === 'PASSWORD') {
                        openModal(PASSWORD_FORM, {
                            onSubmit: (data) => {
                                setCastPassword(data.password);
                                return asyncPublicCastSelect(
                                    castUid,
                                    castFolderUuid,
                                    false,
                                    data.password
                                ).then((c) => {
                                    setCast(c);
                                    setCastColors(computeCastColors(c));
                                    closeModal();
                                });
                            }
                        });
                    }
                    if (e.name === 'EXPIRED') setExpired(true);
                    if (e.name === 'CAST_NOT_FOUND') setNotFound(true);
                });
        },

        fetchPublicCastFile,

        deleteCastContent(workspaceName: string, castUid: string, items: FrontCastFolderChild[]) {
            const promises: Promise<unknown>[] = [];
            const toRestore: FrontCastFolderChild[] = [];
            for (const item of items) {
                if (item.type === 'file') {
                    promises.push(
                        asyncCastFileDelete(workspaceName, castUid, item.castFileUuid).catch(() => {
                            toRestore.push(item);
                        })
                    );
                } else {
                    promises.push(
                        asyncCastFolderDelete(workspaceName, castUid, item.uuid).catch(() => {
                            toRestore.push(item);
                        })
                    );
                }
            }
            setCast((c) => {
                if (!c) return c;
                return update(c, {
                    content: {
                        $set: c.content.filter(
                            ({ uuid }) => !items.find((item) => item.uuid === uuid)
                        )
                    }
                });
            });

            Promise.allSettled(promises).then(() => {
                if (toRestore.length) {
                    setCast((c) => {
                        if (!c) return c;
                        return update(c, {
                            content: {
                                $push: toRestore
                            }
                        });
                    });
                }
            });
        }
    };
}

export function computeCastColors(cast?: FrontCast): PublicCastColors {
    const castBackground = cast?.backgroundColor || '#0d111a';
    const avg = colorAverage(castBackground);

    const background = cast?.bgUserFile?.url
        ? `url('${cast.bgUserFile.url}') center  top / cover no-repeat`
        : 'var(--color-bg-2)';
    const colors =
        avg < 127
            ? {
                  itemBackgroundColor: 'rgba(0,0,0,0.5)',
                  textColor: '#ffffff',
                  textColorFaded: 'rgba(255,255,255,0.5)'
              }
            : {
                  itemBackgroundColor: 'rgba(255,255,255,0.5)',
                  textColor: '#000000',
                  textColorFaded: 'rgba(0,0,0,0.5)'
              };

    return {
        ...colors,
        background,
        backgroundGradient: cast?.backgroundColor
            ? `linear-gradient(to top, ${cast?.backgroundColor}, rgb(from ${cast.backgroundColor} r g b / 20%)`
            : null,
        logo: cast?.logoUserFile?.url
    };
}

export function useCastSockets(
    workspaceName: string,
    castUid: string,
    castFolderUuid?: string,
    projectUuid?: string
) {
    const { subscribe, unsubscribe } = useSocketIO();
    const setCast = useSetAtom(castAtom);

    const { currentUserUuid } = useSelector(currentUserUuidSelector);

    useEffect(() => {
        if (!currentUserUuid) return;

        subscribe!({
            socket: 'casts',
            room: castFolderUuid || castUid,
            actions: {
                onFolderCreated(data: WSCastEventFolderCreated) {
                    setCast((c) => {
                        if (!c) return c;
                        const folder: FrontCastFolderItemFolder = {
                            name: data.name,
                            uuid: data.uuid,
                            user: data.user,
                            created: new Date(data.created),
                            href: projectUuid
                                ? projectCastLink(workspaceName, projectUuid, castUid, data.uuid)
                                : castLink(workspaceName, castUid, data.uuid),
                            type: 'folder'
                        };
                        return update(c, {
                            content: {
                                $push: [folder]
                            }
                        });
                    });
                },
                onFileCreated(data: WSCastEventFileCreated) {
                    setCast((c) => {
                        if (!c) return c;
                        const file: FrontCastFolderItemFile = {
                            castFileUuid: data.castFileUuid,
                            name: data.name,
                            uuid: data.uuid,
                            assets: data.assets,
                            user: data.user,
                            folder: data.folder,
                            created: new Date(data.created),
                            href: projectUuid
                                ? projectCastLink(workspaceName, projectUuid, castUid, data.uuid)
                                : castLink(workspaceName, castUid, data.uuid),
                            version: 0,
                            type: 'file',
                            mimeType:
                                data.assets.find((a) => a.quality === 'source')?.mimeType || '',
                            isPlayable: data.assets.some((a) => a.type === 'player'),
                            status: FileStatus.default
                        };
                        return update(c, {
                            content: {
                                $push: [file]
                            }
                        });
                    });
                }
            }
        });

        return () => {
            if (currentUserUuid) unsubscribe();
        };
    }, [castUid, castFolderUuid]);
}
