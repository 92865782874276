import { z } from 'zod';

export const oaUploadCreateResponse = z.object({
    asset: z.object({
        uuid: z.string()
    }),
    uploadId: z.string(),
    links: z.array(
        z.object({
            part: z.number(),
            url: z.string(),
            size: z.number()
        })
    )
});

export type OAUploadCreateResponse = z.infer<typeof oaUploadCreateResponse>;
