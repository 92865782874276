import { BaseEventLogPayload } from './log.payload';

export enum EventLogKey {
    EventAdd = 'event_add',
    EventEdit = 'event_edit',
    EventDelete = 'event_delete'
}
export interface LogEventAddPayload extends BaseEventLogPayload {
    key: EventLogKey.EventAdd;
}
export interface LogEventEditPayload extends BaseEventLogPayload {
    key: EventLogKey.EventEdit;
}
export interface LogEventDeletePayload extends BaseEventLogPayload {
    key: EventLogKey.EventDelete;
}

export type LogEventPayload = LogEventAddPayload | LogEventEditPayload | LogEventDeletePayload;
