export const INTEGRATIONS = [
    {
        type: 'gcalendar',
        configurable: true,
        oauth: true
    },
    {
        type: 'outlook',
        configurable: true,
        oauth: true
    }
];
