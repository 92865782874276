export function findOrPush<T>(arr: T[], predicate: (item: T) => boolean, push: () => T): T {
    const found = arr.find(predicate);
    if (found) {
        return found;
    }
    const newItem = push();
    arr.push(newItem);
    return newItem;
}

export function isNonEmpty<T, A extends [T, ...T[]]>(array: T[]): array is A {
    return array.length > 0;
}
