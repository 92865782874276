import styles from './HeaderActions.module.css';
import React, { ReactNode, type SyntheticEvent } from 'react';
import { Button } from '@he-novation/design-system/components/buttons/Button/Button';
import {
    ActionType,
    MultiActionButton
} from '@he-novation/design-system/components/buttons/MultiActionButton/MultiActionButton';
import { zIndexMenu } from '@he-novation/design-system/constants/constants.style';
import cn from 'classnames';

import {
    DataLayoutDisplayModeButton,
    DataLayoutDisplayModeButtonProps
} from '$components/DataLayout/components/DataLayoutDisplayModeButton';
import {
    DataLayoutGrouperButton,
    DataLayoutGrouperButtonProps
} from '$components/DataLayout/components/DataLayoutGrouperButton/DataLayoutGrouperButton';
import { FiltersCheckboxProps } from '$components/Filters/FilterCheckboxes';
import { ResponsiveFilterCheckboxes } from '$components/Filters/ResponsiveFilterCheckboxes';
import SearchForm from '$components/SearchForm/SearchForm';

export type HeaderActionType =
    | ActionType
    | false
    | null
    | undefined
    | { type: 'react'; content: ReactNode };

export type HeaderActionsProps = {
    actions?: HeaderActionType[];
    className?: string;
    filters?: FiltersCheckboxProps[];
    groupers?: DataLayoutGrouperButtonProps<unknown>[];
    displayMode?: DataLayoutDisplayModeButtonProps;
    search?: {
        search?: string;
        setSearch?: (s: string) => void;
        onSearchInput?: (e: SyntheticEvent<HTMLInputElement>) => void;
        onSearchSubmit?: (e: SyntheticEvent<HTMLFormElement>, value: string) => void;
    };
};

export function HeaderActions({
    actions,
    className,
    filters,
    groupers,
    displayMode,
    search
}: HeaderActionsProps) {
    return (
        <div className={cn(styles.actions, className)}>
            {filters && <ResponsiveFilterCheckboxes filters={filters} />}

            {groupers && (
                <ul>
                    {groupers.map((grouper, i) => (
                        <DataLayoutGrouperButton
                            key={'grouper_' + i}
                            grouper={grouper.grouper}
                            setGrouper={grouper.setGrouper}
                            groupers={grouper.groupers}
                            saveLayoutPrefs={grouper.saveLayoutPrefs}
                        />
                    ))}
                </ul>
            )}

            {displayMode && (
                <DataLayoutDisplayModeButton
                    icons={displayMode.icons}
                    displayMode={displayMode.displayMode}
                    setDisplayMode={displayMode.setDisplayMode}
                />
            )}

            {search && (
                <SearchForm
                    className={styles.search}
                    value={search.search}
                    onInput={search.onSearchInput}
                    onSubmit={search.onSearchSubmit}
                    onReset={() => search.setSearch?.('')}
                />
            )}

            {actions && actions.length > 0 && (
                <ul className={styles.actions}>
                    {actions.map((el, i) => {
                        if (!el) return null;
                        return (
                            <li key={'action_' + i}>
                                {el.type === 'react' ? (
                                    el.content
                                ) : 'actions' in el && el.actions ? (
                                    <MultiActionButton
                                        {...el}
                                        actions={el.actions}
                                        contentZIndex={zIndexMenu}
                                    />
                                ) : (
                                    <Button {...el} />
                                )}
                            </li>
                        );
                    })}
                </ul>
            )}
        </div>
    );
}
