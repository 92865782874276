const isJsonString = (str: unknown): str is string => {
    if (!str) return false;
    try {
        JSON.parse(str as string);
    } catch (e) {
        return false;
    }
    return true;
};
export default isJsonString;
