import styles from './MemberRole.module.css';
import React from 'react';
import { ROLES } from '@he-novation/config/constants/projects.constants';
import { FolderRole } from '@he-novation/config/types/folder.types';

import { useTranslate } from '$hooks/useTranslate';

type MemberRoleProps = { role: ROLES | FolderRole };

export function MemberRole({ role }: MemberRoleProps) {
    const { t } = useTranslate();

    let roleName: string;
    let description: string | undefined = undefined;

    switch (role) {
        case ROLES.MANAGER:
        case FolderRole.ROLE_MANAGER:
            roleName = t('common.Administrator');
            description = t('common.__ADMINISTRATOR_ROLE_DESCRIPTION');
            break;
        case ROLES.PROVIDER:
        case FolderRole.ROLE_PROVIDER:
            roleName = t('common.Contributor');
            description = t('common.__CONTRIBUTOR_ROLE_DESCRIPTION');
            break;
        case ROLES.REPORTER:
        case FolderRole.ROLE_REPORTER:
            roleName = t('common.Reviewer');
            description = t('common.__REVIEWER_ROLE_DESCRIPTION');
            break;
        case ROLES.SPECTATOR:
        case FolderRole.ROLE_SPECTATOR:
            roleName = t('common.Spectator');
            description = t('common.__SPECTATOR_ROLE_DESCRIPTION');
            break;
    }

    return (
        <div className={styles.memberRole}>
            <h4>{roleName}</h4>
            {description && <p>{description}</p>}
        </div>
    );
}
