import { z } from 'zod';
import { ApiMethod } from '../types/api.types';
import { integrationSchema } from '../types/integration.types';
import {
    integrationAuthSchema,
    integrationBodySchema,
    integrationParams
} from '../types/payloads/integration.payload';

export const API_INTEGRATION = {
    LIST: {
        method: ApiMethod.GET,
        path: '/integrations',
        handlerMethod: 'findPluginConfigurations' as 'findPluginConfigurations',
        allowNoMfa: true,
        responses: {
            200: {
                description: 'List of user integrations',
                schema: z.array(integrationSchema)
            }
        }
    },
    POST: {
        method: ApiMethod.POST,
        path: '/integrations',
        handlerMethod: 'postIntegration' as 'postIntegration',
        zod: {
            body: integrationBodySchema
        },
        responses: {
            200: {
                description: 'The created integration',
                schema: integrationSchema
            }
        }
    },
    PATCH: {
        method: ApiMethod.PATCH,
        path: '/integrations/:pluginUuid',
        handlerMethod: 'patchIntegration' as 'patchIntegration',
        zod: {
            params: integrationParams,
            body: integrationBodySchema
        },
        responses: {
            200: {
                description: 'The updated integration',
                schema: integrationSchema
            }
        }
    },
    DELETE: {
        method: ApiMethod.DELETE,
        path: '/integrations/:pluginUuid',
        handlerMethod: 'deleteIntegration' as 'deleteIntegration',
        zod: {
            params: integrationParams
        }
    }
};

export const APIT_INTEGRATION_AUTH_URL = {
    GET: {
        method: ApiMethod.GET,
        path: '/integrations/auth/:type',
        handlerMethod: 'getIntegrationAuthUrl' as 'getIntegrationAuthUrl',
        passResAndNextToHandler: true as true,
        zod: {
            params: integrationAuthSchema
        }
    }
};
