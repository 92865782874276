import {
    API_NOTE,
    API_NOTE_COMMENT,
    API_NOTE_COMMENT_ATTACHMENT
} from '@he-novation/config/apis/note-api';
import { API_CAST_NOTE } from '@he-novation/config/apis/public/cast-public-api';
import type { Comment } from '@he-novation/config/types/note.types';
import { Note } from '@he-novation/config/types/note.types';
import {
    type AssetUploadCompleteBody,
    BaseUploadPayload
} from '@he-novation/config/types/payloads/asset.payload';
import {
    type CastCommentCreateBody,
    type CastNoteCreateBody,
    CommentCreateBody,
    CommentEditBody,
    NoteCreateBody
} from '@he-novation/config/types/payloads/note.payload';
import { dateAsUTCDate, setLatestHour } from '@he-novation/utils/datetime';
import { buildApiCall } from '../../config/apis/api-fetch';

export const deleteNote = (workspaceName: string, noteUuid: string) =>
    buildApiCall(API_NOTE.DELETE)({
        params: { workspaceName, noteUuid }
    });

export const fetchNotes = (
    workspaceName: string,
    fileUuid: string
): Promise<Record<string, Note>> =>
    buildApiCall(API_NOTE.LIST)({
        params: { workspaceName, fileUuid }
    });

export const fetchCastNotes = ({
    castUid,
    castFileUuid
}: {
    castUid: string;
    castFileUuid: string;
}) =>
    buildApiCall(API_CAST_NOTE.LIST)({
        params: { castUid, castFileUuid }
    });

export const asyncNoteCreate = (workspaceName: string, body: NoteCreateBody) => {
    if (body.estimatedEndDate)
        body.estimatedEndDate = setLatestHour(dateAsUTCDate(body.estimatedEndDate as Date), true);
    return buildApiCall(API_NOTE.POST)({
        params: { workspaceName },
        body
    });
};
export const asyncCastNoteCreate = (
    castUid: string,
    castFileUuid: string,
    body: CastNoteCreateBody,
    password?: string
) => {
    if (body.estimatedEndDate)
        body.estimatedEndDate = setLatestHour(dateAsUTCDate(body.estimatedEndDate as Date), true);
    return buildApiCall(API_CAST_NOTE.POST)({
        params: { castUid, castFileUuid },
        query: { p: password },
        body
    });
};

export const asyncCommentCreate = (
    workspaceName: string,
    noteUuid: string,
    { content, draft, notify }: CommentCreateBody
): Promise<Comment> =>
    buildApiCall(API_NOTE_COMMENT.POST)({
        params: { workspaceName, noteUuid },
        body: {
            content,
            draft,
            notify
        }
    });

export const asyncCastCommentCreate = (
    castUid: string,
    castFileUuid: string,
    noteUuid: string,
    body: CastCommentCreateBody,
    password?: string
) => {
    return buildApiCall(API_CAST_NOTE.COMMENT_POST)({
        params: { castUid, castFileUuid, noteUuid },
        query: { p: password },
        body
    });
};

export const asyncCommentEdit = (
    workspaceName: string,
    noteUuid: string,
    commentUuid: string,
    { content, draft, tags }: CommentEditBody
) =>
    buildApiCall(API_NOTE_COMMENT.PATCH)({
        params: { workspaceName, noteUuid, commentUuid },
        body: {
            content,
            draft,
            tags
        }
    });

export function asyncCommentDelete(workspaceName: string, noteUuid: string, commentUuid: string) {
    return buildApiCall(API_NOTE_COMMENT.DELETE)({
        params: { workspaceName, noteUuid, commentUuid }
    });
}

export const asyncInitUploadAttachments = (
    workspaceName: string,
    noteUuid: string,
    commentUuid: string,
    attachments: BaseUploadPayload[]
) =>
    buildApiCall(API_NOTE_COMMENT_ATTACHMENT.POST)({
        params: { workspaceName, noteUuid, commentUuid },
        body: {
            files: attachments
        }
    });
export const asyncInitCastUploadAttachments = (
    castUid: string,
    castFileUuid: string,
    noteUuid: string,
    commentUuid: string,
    attachments: BaseUploadPayload[],
    recaptcha?: string
) =>
    buildApiCall(API_CAST_NOTE.COMMENT_ASSET_POST)({
        params: { castUid, castFileUuid, noteUuid, commentUuid },
        body: {
            files: attachments,
            castFileUuid,
            recaptcha
        }
    });

export const asyncCastAssetUploadComplete = (
    castUid: string,
    castFileUuid: string,
    noteUuid: string,
    commentUuid: string,
    assetUuid: string,
    body: AssetUploadCompleteBody
) =>
    buildApiCall(API_CAST_NOTE.COMMENT_ASSET_UPLOAD_PATCH)({
        params: {
            castUid,
            castFileUuid,
            noteUuid,
            commentUuid,
            assetUuid
        },
        body
    });

export const asyncOpenApiNotesFetch = (apiKey: string, fileUuid: string, fileVersion: number) =>
    buildApiCall(API_NOTE.LIST_OA_DEPRECATED)({
        params: { fileUuid, fileVersion },
        options: {
            headers: {
                Authorization: `Bearer ${apiKey}`
            }
        }
    });
