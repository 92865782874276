import styles from './SearchForm.module.css';
import React, { ChangeEvent, SyntheticEvent } from 'react';
import { useDispatch } from 'react-redux';
import { ModularForm } from '@he-novation/design-system/components/form/FormField/FormField';
import { SearchField } from '@he-novation/design-system/components/form/SearchField/SearchField';
import cn from 'classnames';

import { toggleKeyboardListeners } from '$redux/ui/uiActions';

type SearchFormProps = {
    value?: string | null;
    onInput?: (e: ChangeEvent<HTMLInputElement>) => void;
    onSubmit?: (e: SyntheticEvent<HTMLFormElement>, value: string) => void;
    onReset?: () => void;
    isActive?: boolean;
    className?: string;
};

export default function SearchForm({
    value,
    onInput,
    onSubmit,
    onReset,
    isActive,
    className
}: SearchFormProps) {
    const dispatch = useDispatch();

    const toggleListeners = (isFocused: boolean) => dispatch(toggleKeyboardListeners(!isFocused));

    const formId = 'search-form';

    return (
        <ModularForm
            id={formId}
            className={cn(styles.searchForm, className)}
            onSubmit={(e, data) => {
                if (typeof onSubmit === 'function') onSubmit(e, data.search);
                else e.preventDefault();
            }}
        >
            <SearchField
                formId={formId}
                value={value}
                isActive={isActive}
                onInput={onInput}
                onReset={onReset}
                onFocus={toggleListeners}
            />
            <input type="submit" />
        </ModularForm>
    );
}
