import styles from './GraphicPicker.module.scss';
import React, { ChangeEvent, MouseEvent, useEffect, useRef, useState } from 'react';
import { FieldComponentProps } from 'react-modular-forms';
import cn from 'classnames';
import { zIndexMenu } from '../../../../../constants/constants.style';
import { useTranslate } from '../../../../../hooks/useTranslate';
import { DirectionX, DirectionY } from '../../../../../utils/getAbsolutePosition';
import { AbsoluteContentButton } from '../../../../buttons/AbsoluteContentButton/AbsoluteContentButton';
import { Button, ButtonTone } from '../../../../buttons/Button/Button';
import { Icon } from '../../../../graphics/Icon/Icon';
import { FormField } from '../../FormField';

export type GraphicItem = {
    uid: string;
    url?: string;
    thumbnailUrl?: string;
};

export type GraphicPickerProps = FieldComponentProps & {
    formId?: string;
    name: string;
    value?: GraphicItem;
    values: GraphicItem[];
    onAdd?: (file: File) => void;
    onChange?: (item: GraphicItem | null) => void;
    onRemove?: (item: GraphicItem) => void;
    isMenuOpen?: boolean;
};

export function GraphicPicker({
    formId,
    name,
    value,
    values,
    onAdd,
    onChange,
    onRemove,
    isMenuOpen = false
}: GraphicPickerProps) {
    const { t } = useTranslate();

    const inputFileRef = useRef<HTMLInputElement>(null);

    const [selectedItem, setSelectedItem] = useState<GraphicItem | undefined | null>(value);

    useEffect(() => {
        setSelectedItem(value);
    }, [value]);

    const selectedGraphic = selectedItem ? (
        <img src={selectedItem.thumbnailUrl || selectedItem.url || ''} alt=" " />
    ) : (
        <span className={styles.placeholder}>{t('None')}</span>
    );

    const onAddItem = (e: ChangeEvent<HTMLInputElement>) => {
        const selectedFiles = e.currentTarget.files;
        if (selectedFiles && selectedFiles.length > 0) {
            if (selectedFiles[0].type.startsWith('image/')) {
                onAdd?.(selectedFiles[0]);
            }
        }
    };

    const onSelectItem = (e: MouseEvent<HTMLDivElement>, item: GraphicItem) => {
        e.preventDefault();
        e.stopPropagation();

        if (selectedItem && item.uid === selectedItem.uid) {
            setSelectedItem(null);
            onChange?.(null);
        } else {
            setSelectedItem(item);
            onChange?.(item);
        }
    };

    const onRemoveItem = (e: MouseEvent<HTMLElement>, item: GraphicItem) => {
        e.preventDefault();
        e.stopPropagation();

        if (selectedItem && item.uid === selectedItem.uid) {
            setSelectedItem(null);
            onChange?.(null);
        }

        onRemove?.(item);
    };

    return values.length > 0 ? (
        <AbsoluteContentButton
            className={styles.graphicPicker}
            direction={[DirectionX.LeftInner, DirectionY.Bottom]}
            tagName={Button}
            type={'button'}
            tone={ButtonTone.FormField}
            closeOnClickOutside={true}
            isVisible={isMenuOpen}
            triggersOnClick={true}
            triggersOnHover={false}
            onClick={(e) => e.preventDefault()}
            content={
                <div className={styles.items}>
                    {onAdd && (
                        <div
                            key={'add-item'}
                            className={cn(styles.item, styles.add)}
                            onClick={() => {
                                inputFileRef.current?.click();
                            }}
                        >
                            <input
                                type="file"
                                ref={inputFileRef}
                                accept="image/*"
                                onChange={onAddItem}
                            />
                            <Icon icon={'plus'} />
                        </div>
                    )}

                    {values.map((item) => {
                        const url = item.thumbnailUrl || item.url;
                        if (!url) return;
                        const isSelected = selectedItem && item.uid === selectedItem.uid;
                        return (
                            <div
                                key={item.uid}
                                className={cn(styles.item, isSelected && 'is-selected')}
                                onClick={(e) => onSelectItem(e, item)}
                            >
                                {<img src={url} alt=" " />}
                                <a
                                    className={styles.delete}
                                    href="#"
                                    onClick={(e) => onRemoveItem(e, item)}
                                >
                                    x
                                </a>
                            </div>
                        );
                    })}
                </div>
            }
            contentClassName={cn(styles.graphicPickerMenu, 'graphics-picker-menu')}
            contentZIndex={zIndexMenu}
        >
            <div className={styles.selectedGraphic}>{selectedGraphic}</div>
            <Icon icon="chevron" />
            {formId && (
                <FormField formId={formId} type={'hidden'} name={name} value={selectedItem?.uid} />
            )}
        </AbsoluteContentButton>
    ) : (
        <Button
            className={styles.graphicPicker}
            tone={ButtonTone.FormField}
            type={'button'}
            onClick={(e) => {
                e.stopPropagation();
                inputFileRef.current?.click();
            }}
        >
            <div className={styles.selectedGraphic}>{selectedGraphic}</div>
            <Icon icon="chevron" />
            <input type="file" ref={inputFileRef} accept="image/*" onChange={onAddItem} />
        </Button>
    );
}
