import { z } from 'zod';
import { CustomFieldAssociationType, CustomFieldType } from './db/enums';

export const customFieldOptionSchema = z.object({
    uuid: z.string().uuid(),
    label: z.string(),
    color: z.string().optional().nullable()
});

export type CustomFieldOption = z.infer<typeof customFieldOptionSchema>;

export const customFieldAssociationSchema = z.object({
    uuid: z.string().uuid(),
    type: z.nativeEnum(CustomFieldAssociationType),
    targetIdentifier: z.string().optional().nullable()
});

export type CustomFieldAssociation = z.infer<typeof customFieldAssociationSchema>;

export const customFieldSchema = z.object({
    uuid: z.string().uuid(),
    name: z.string(),
    type: z.nativeEnum(CustomFieldType),
    isMultiple: z.boolean().optional(),
    options: z.array(customFieldOptionSchema).optional()
});

export type CustomField = z.infer<typeof customFieldSchema>;

export const customFieldWithAssociationsSchema = customFieldSchema.extend({
    associations: z.array(customFieldAssociationSchema)
});

export type CustomFieldWithAssociations = z.infer<typeof customFieldWithAssociationsSchema>;

export const customFieldValuesSchema = z.record(z.string(), z.unknown());

export type CustomFieldValues = z.infer<typeof customFieldValuesSchema>;
