import { z } from 'zod';
import { Asset, assetSchema } from './asset.types';
import { noteMetadataSchema } from './note.medatata.types';
import { taskSchema } from './task.types';
import { ContentUser, contentUserSchema } from './user.types';

export const commentSchema = z.object({
    uuid: z.string(),
    content: z.string(),
    draft: z.boolean(),
    isImportant: z.boolean().optional(),
    created: z.date(),
    updated: z.date().nullable(),
    tags: z.array(z.string()).optional(),
    postedAs: z.string().nullable().optional(),
    user: z.union([
        z.object({
            email: z.string(),
            uuid: z.string().optional()
        }),
        contentUserSchema
    ]),
    assets: z.array(assetSchema),
    note: z.object({
        uuid: z.string()
    }),
    file: z.object({
        uuid: z.string(),
        name: z.string(),
        version: z.number()
    })
});

export type CommentSchema = z.infer<typeof commentSchema>;

export type Comment = {
    uuid: string;
    content: string;
    draft: boolean;
    created: Date;
    updated: Date | null;
    postedAs?: string | null;
    user: ContentUser | { email: string; uuid?: undefined };
    assets: Asset[];
    isImportant?: boolean;
    tags?: string[];
    note: { uuid: string };
    file: {
        uuid: string;
        name: string;
        version: number;
    };
    castFileUuid?: string | null;
};

export const noteSchema = z.object({
    uuid: z.string(),
    created: z.date(),
    updated: z.date().nullable(),
    type: z.string(),
    metadata: noteMetadataSchema,
    assets: z.array(assetSchema),
    user: z
        .union([
            contentUserSchema,
            z.object({
                email: z.string(),
                uuid: z.string().optional(),
                lastname: z.string().optional(),
                firstname: z.string().optional()
            })
        ])
        .nullable(),
    task: taskSchema.omit({ note: true, workspace: true, assets: true }).nullable(),
    team: z
        .object({
            name: z.string(),
            uuid: z.string(),
            color: z.string()
        })
        .nullable(),
    comments: z.array(commentSchema.omit({ note: true, file: true })),
    file: z.object({
        uuid: z.string(),
        name: z.string(),
        version: z.number()
    }),
    cast: z
        .object({
            uuid: z.string(),
            name: z.string()
        })
        .nullable(),
    castFileUuid: z.string().nullable()
});

export type Note = z.infer<typeof noteSchema>;

export type NoteSorting = 'created' | 'timecode' | 'page';
export type NoteSorter = { value: NoteSorting; label: string };
