import { buildApiCall } from '@he-novation/config/apis/api-fetch';
import {
    API_CAST_FILES,
    API_CAST_FOLDERS,
    API_CAST_PRIVATE_VIEW,
    API_CASTS,
    API_FILE_CASTS,
    API_FOLDER_CAST_TRIGGERS
} from '@he-novation/config/apis/cast-api';
import { Cast, FileCast, FolderCast } from '@he-novation/config/types/cast.types';
import { CastCreateBody, CastUpdateBody } from '@he-novation/config/types/payloads/cast.payload';
import { Subtitle } from '@he-novation/config/types/subtitle.types';
import {
    API_CAST_PUBLIC_SUBTITLES,
    API_CAST_PUBLIC_VIEW
} from '../../config/apis/public/cast-public-api';
import { mapCastToFrontCast } from '../mappers/cast.mappers';
import { FrontCast } from '../types/cast.front-types';
import { download } from './file.async';

export const asyncCastsFetch = (workspaceName: string, projectUuid?: string): Promise<Cast[]> =>
    buildApiCall(API_CASTS.LIST)({ params: { workspaceName }, query: { projectUuid } })
        .catch((e) => {
            console.error(e);
            return [];
        })
        .then((casts) =>
            casts.map((c) => ({
                ...c,
                created: new Date(c.created),
                expires: c.expires && new Date(c.expires)
            }))
        );

export function asyncCastsSearch(
    workspaceName: string,
    { name, page, resultsPerPage }: { name?: string; page?: number; resultsPerPage?: number }
) {
    return buildApiCall(API_CASTS.SEARCH)({
        params: { workspaceName },
        body: {
            name,
            page: page || 0,
            resultsPerPage: resultsPerPage || 20
        }
    });
}

export const asyncCastFetch = (workspaceName: string, castUid: string): Promise<Cast> =>
    buildApiCall(API_CASTS.GET)({ params: { workspaceName, castUid } }).then((c) => ({
        ...c,
        created: new Date(c.created),
        expires: c.expires && new Date(c.expires)
    }));

export const asyncCastFoldersSelect = (workspaceName: string, castUid: string) =>
    buildApiCall(API_CAST_FOLDERS.LIST)({ params: { workspaceName, castUid } });

export const asyncPrivateCastSelect = (
    workspaceName: string,
    castUid: string,
    castFolderUuid?: string
): Promise<FrontCast> =>
    buildApiCall(API_CAST_PRIVATE_VIEW.GET)({
        params: { workspaceName, castUid },
        query: { castFolderUuid }
    }).then((r) => mapCastToFrontCast(r, true));

export const asyncPublicCastSelect = (
    castUid: string,
    castFolderUuid?: string | null,
    recursive?: boolean,
    p?: string | null
): Promise<FrontCast> =>
    buildApiCall(API_CAST_PUBLIC_VIEW.GET)({
        params: { castUid },
        query: { castFolderUuid: castFolderUuid || undefined, recursive, p: p || undefined }
    }).then((r) => mapCastToFrontCast(r, false));

export const asyncCastFileFetch = (castUid: string, castFileUuid: string, p?: string) =>
    buildApiCall(API_CAST_PUBLIC_VIEW.GET_FILE)({
        params: { castUid, castFileUuid },
        query: { p }
    }).then(({ cast, file }) => ({
        cast,
        file: {
            ...file,
            created: file.created ? new Date(file.created) : new Date(),
            updated: file.updated ? new Date(file.updated) : null
        }
    }));

export const asyncCastCreate = (workspaceName: string, payload: CastCreateBody): Promise<Cast> =>
    buildApiCall(API_CASTS.POST)({ params: { workspaceName }, body: payload }).then((r) => ({
        ...r,
        created: new Date(r.created),
        expires: r.expires && new Date(r.expires)
    }));

export const asyncCastEdit = (
    workspaceName: string,
    castUid: string,
    payload: CastUpdateBody
): Promise<Cast> =>
    buildApiCall(API_CASTS.PATCH)({ params: { workspaceName, castUid }, body: payload }).then(
        (r) => ({
            ...r,
            created: new Date(r.created),
            expires: r.expires && new Date(r.expires)
        })
    );

export const asyncCastDelete = (workspaceName: string, castUid: string) =>
    buildApiCall(API_CASTS.DELETE)({ params: { workspaceName, castUid } });

export const asyncCastFolderCreate = (
    workspaceName: string,
    castUid: string,
    castFolderUuid: string | undefined,
    name: string
) =>
    buildApiCall(API_CAST_FOLDERS.POST)({
        params: { workspaceName, castUid },
        body: { castFolderUuid, name }
    });

export const asyncCastFolderUpdate = (
    workspaceName: string,
    castUid: string,
    castFolderUuid: string,
    name: string
) =>
    buildApiCall(API_CAST_FOLDERS.PUT)({
        params: { workspaceName, castUid, castFolderUuid },
        body: { name }
    });

export const asyncCastFolderDelete = (
    workspaceName: string,
    castUid: string,
    castFolderUuid: string
) => buildApiCall(API_CAST_FOLDERS.DELETE)({ params: { workspaceName, castUid, castFolderUuid } });

export const asyncCastFileCreate = (
    workspaceName: string,
    castUid: string,
    castFolderUuid: string | undefined,
    fileUuid: string
) =>
    buildApiCall(API_CAST_FILES.POST)({
        params: { workspaceName, castUid },
        body: { castFolderUuid, fileUuid }
    });

export const asyncCastFileDelete = (workspaceName: string, castUid: string, castFileUuid: string) =>
    buildApiCall(API_CAST_FILES.DELETE)({ params: { workspaceName, castUid, castFileUuid } });

export const asyncFileCastsFetch = (workspaceName: string, fileUuid: string): Promise<FileCast[]> =>
    buildApiCall(API_FILE_CASTS.LIST)({ params: { workspaceName, fileUuid } });

export const asyncIncreaseCastViews = (castUid: string) =>
    buildApiCall(API_CAST_PUBLIC_VIEW.PUT_VIEWS)({ params: { castUid } });

export const asyncCastShare = (
    workspaceName: string,
    emails: string[],
    cast: { name: string; uid: string },
    message?: string
) =>
    buildApiCall(API_CASTS.SHARE)({
        params: { workspaceName, castUid: cast.uid },
        body: { emails, castName: cast.name, message }
    });

export const asyncFolderCastTriggerCreate = (
    workspaceName: string,
    folderUuid: string,
    castUid: string,
    castFolderUuid?: string
) =>
    buildApiCall(API_FOLDER_CAST_TRIGGERS.POST)({
        params: { workspaceName, folderUuid },
        body: { castUid, castFolderUuid }
    });

export const asyncFolderCastTriggerDelete = (
    workspaceName: string,
    folderUuid: string,
    folderCastTriggerUid: string
) =>
    buildApiCall(API_FOLDER_CAST_TRIGGERS.DELETE)({
        params: { workspaceName, folderUuid, folderCastTriggerUid }
    });

export const asyncCastsFetchFromFolder = (
    workspaceName: string,
    folderUuid: string
): Promise<FolderCast[]> =>
    buildApiCall(API_FOLDER_CAST_TRIGGERS.LIST)({ params: { workspaceName, folderUuid } }).catch(
        (e) => {
            console.error('Failed to fetch casts from folder', e);
            return [];
        }
    );

export const asyncCastFileAssetDownloadUrlFetch = (
    castUid: string,
    castFileUuid: string,
    quality: string,
    password?: string
) =>
    buildApiCall(API_CAST_PUBLIC_VIEW.GET_FILE_DOWNLOAD_URL)({
        params: { castUid, castFileUuid },
        query: { quality, p: password }
    });

export const asyncCastFileAssetDownload = async (
    castUid: string,
    castFileUuid: string,
    quality: string,
    name: string,
    password?: string
) => {
    const link = await asyncCastFileAssetDownloadUrlFetch(castUid, castFileUuid, quality, password);
    download(link.url, name);
};

export const asyncCastFileVersionSubtitlesFetch = (
    castUid: string,
    castFileUuid: string,
    password?: string | null
): Promise<Subtitle[]> =>
    buildApiCall(API_CAST_PUBLIC_SUBTITLES.LIST)({
        params: { castUid, castFileUuid },
        query: { p: password || undefined }
    });
