import type React from 'react';
import type { MouseEventHandler } from 'react';
import { useRef } from 'react';
import { getParentWithClass } from '../utils/dom/parentHasClass';

const MIN_INTERVAL = 20;

export const useDoubleClickHandler = <T extends string | undefined>(
    callback: (e: React.MouseEvent<Element, MouseEvent>, element: Element) => void | null,
    options?: {
        msInterval?: number;
        className?: T;
    }
): MouseEventHandler<Element> => {
    const lastClicked = useRef<Date | null>(null);
    const msInterval = options?.msInterval || 300;
    const className = options?.className;
    const previousTarget = useRef<Element | null>(null);
    return function onClick(e: React.MouseEvent<Element, MouseEvent>) {
        if (!callback) return;

        let target = e.currentTarget;

        if (className) {
            target =
                (e.target instanceof HTMLElement || e.target instanceof SVGElement) &&
                getParentWithClass(e.target, className);
            if (!target) return;
        }

        if (target !== previousTarget.current) {
            lastClicked.current = null;
        }

        previousTarget.current = target;
        const d = new Date();
        const now = d.getTime();

        const delta = now - (lastClicked.current?.getTime() || now);

        if (!delta || delta > msInterval || delta < MIN_INTERVAL) {
            lastClicked.current = d;
            return;
        }
        lastClicked.current = null;
        return callback(e, target);
    };
};
