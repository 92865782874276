import { sanitizedString } from '@he-novation/utils/zod.utils';
import { z } from 'zod';
import { workspaceParamsSchema } from './workspace.payload';

export const itemParamsSchema = z
    .object({
        itemUuid: z.string()
    })
    .merge(workspaceParamsSchema);

export type ItemSpecificSchema = z.infer<typeof itemParamsSchema>;

export const itemCreateBodySchema = z.object({
    name: sanitizedString(),
    category: sanitizedString({ optional: true, nullable: true }),
    serialNumber: sanitizedString({ optional: true, nullable: true }),
    description: sanitizedString({ optional: true, nullable: true }),
    clientItemLabelUids: z.array(z.string()).optional().nullable()
});

export type ItemCreateBody = z.infer<typeof itemCreateBodySchema>;

export const itemUpdateBodySchema = itemCreateBodySchema.partial();

export type ItemUpdateBody = z.infer<typeof itemUpdateBodySchema>;

export const itemStartEndQuerySchema = z.object({
    start: z.coerce.number().optional(),
    end: z.coerce.number().optional()
});

export type ItemStartEndSchema = z.infer<typeof itemStartEndQuerySchema>;

export const itemPictureUploadLinkBody = z.object({
    size: z.number(),
    contentType: z.string()
});
export type ItemPictureUploadLinkBody = z.infer<typeof itemPictureUploadLinkBody>;

export const itemKitSpecificSchema = z
    .object({
        itemKitUuid: z.string()
    })
    .merge(workspaceParamsSchema);

export type ItemKitSpecificSchema = z.infer<typeof itemKitSpecificSchema>;

export const itemKitCreateBodySchema = z.object({
    name: z.string()
});

export type ItemKitCreateBody = z.infer<typeof itemKitCreateBodySchema>;

export const itemKitUpdateBodySchema = z.object({
    name: z.string()
});

export type ItemKitUpdateBody = z.infer<typeof itemKitUpdateBodySchema>;

export const itemKitItemSpecificSchema = itemKitSpecificSchema.merge(itemParamsSchema);

export type ItemKitItemSpecificSchema = z.infer<typeof itemKitItemSpecificSchema>;

export const itemKitAddItemBodySchema = z.object({
    uuid: z.string()
});

export type ItemKitAddItemBody = z.infer<typeof itemKitAddItemBodySchema>;
