import { Group } from '../paths/herawApiPathGroups';
import { ApiMethod } from '../types/api.types';
import { assetSchema } from '../types/asset.types';
import { customFieldValuesSchema } from '../types/custom-field.types';
import {
    oaProjectCreateBodySchema,
    oaProjectParamsSchema,
    oaProjectSchema,
    oaProjectUpdateBodySchema,
    oaProjectUpdateStatusBodySchema,
    oaProjectUuidSchema
} from '../types/open-api/project.open-api.types';
import { customFieldValuesBodySchema } from '../types/payloads/custom-field.payload';
import { folderParamsSchema } from '../types/payloads/folder.payload';
import {
    projectCreateBody,
    projectParamsSchema,
    projectSearchBodySchema,
    projectUpdateBody
} from '../types/payloads/project.payload';
import { userNotificationSettingsSchema } from '../types/payloads/user.payload';
import {
    oaWorkspaceParamsSchema,
    workspaceParamsSchema
} from '../types/payloads/workspace.payload';
import {
    paginatedProjectSearchResultSchema,
    projectSchema,
    projectWithTasksSchema
} from '../types/project.types';
import { taskSchema } from '../types/task.types';

export const API_PROJECT = {
    LIST: {
        method: ApiMethod.GET,
        path: '/workspaces/:workspaceName/projects',
        handlerMethod: 'listProjects',
        zod: {
            params: workspaceParamsSchema
        },
        responses: {
            200: {
                schema: projectSchema.array(),
                description: 'List of projects'
            }
        }
    },
    LIST_OA: {
        method: ApiMethod.GET,
        path: '/public/v1/workspaces/:workspaceName/projects',
        handlerMethod: 'listProjects',
        publicApi: true,
        description: 'List all the projects in a workspace',
        group: Group.Projects,
        zod: {
            params: oaWorkspaceParamsSchema
        },
        responses: {
            200: {
                schema: oaProjectSchema.array(),
                description: 'List of projects'
            }
        }
    },
    LIST_OA_DEPRECATED: {
        method: ApiMethod.GET,
        path: '/public/v1/projects',
        handlerMethod: 'listProjects',
        publicApi: 'deprecated',
        description: 'List all the projects in a workspace',
        group: Group.Projects,
        responses: {
            200: {
                schema: oaProjectSchema.array(),
                description: 'List of projects'
            }
        }
    },
    GET: {
        method: ApiMethod.GET,
        path: '/workspaces/:workspaceName/projects/:projectUuid',
        handlerMethod: 'getProject',
        zod: {
            params: projectParamsSchema
        },
        responses: {
            200: {
                schema: projectWithTasksSchema,
                description: 'The requested project'
            }
        }
    },
    GET_OA: {
        method: ApiMethod.GET,
        path: '/public/v1/workspaces/:workspaceName/projects/:projectUuid',
        handlerMethod: 'getProject',
        publicApi: true,
        description: 'Returns a single project',
        group: Group.Projects,
        zod: {
            params: oaProjectParamsSchema
        },
        responses: {
            200: {
                schema: oaProjectSchema,
                description: 'The requested project'
            },
            404: {
                description: 'Project not found'
            }
        }
    },
    POST: {
        method: ApiMethod.POST,
        path: '/workspaces/:workspaceName/projects',
        handlerMethod: 'createProject',
        zod: {
            params: workspaceParamsSchema,
            body: projectCreateBody
        },
        responses: {
            200: {
                schema: projectSchema,
                description: 'Created project'
            }
        }
    },
    POST_OA: {
        method: ApiMethod.POST,
        path: '/public/v1/workspaces/:workspaceName/projects',
        handlerMethod: 'createProject',
        publicApi: true,
        description: 'Creates a new project',
        group: Group.Projects,
        zod: {
            params: workspaceParamsSchema,
            body: oaProjectCreateBodySchema
        },
        responses: {
            200: {
                schema: oaProjectSchema,
                description: 'Created project'
            }
        }
    },
    POST_OA_DEPRECATED: {
        method: ApiMethod.POST,
        path: '/public/v1/projects',
        handlerMethod: 'createProjectOADeprecated',
        publicApi: 'deprecated',
        description: 'Creates a new project',
        group: Group.Projects,
        zod: {
            body: oaProjectCreateBodySchema
        },
        responses: {
            200: {
                schema: oaProjectSchema,
                description: 'Created project'
            }
        }
    },
    PATCH: {
        method: ApiMethod.PATCH,
        path: '/workspaces/:workspaceName/projects/:projectUuid',
        handlerMethod: 'patchProject',
        zod: {
            params: projectParamsSchema,
            body: projectUpdateBody
        }
    },
    PATCH_OA: {
        method: ApiMethod.PATCH,
        path: '/public/v1/workspaces/:workspaceName/projects/:projectUuid',
        handlerMethod: 'patchProject',
        publicApi: true,
        description: 'Updates a project',
        group: Group.Projects,
        zod: {
            params: oaProjectParamsSchema,
            body: oaProjectUpdateBodySchema
        },
        responses: {
            204: {
                description: 'Project successfully updated.'
            }
        }
    },
    DELETE: {
        method: ApiMethod.DELETE,
        path: '/workspaces/:workspaceName/projects/:projectUuid',
        handlerMethod: 'deleteProject',
        zod: {
            params: projectParamsSchema
        },
        responses: {
            204: {
                description: 'Project successfully deleted.'
            }
        }
    },
    DELETE_OA: {
        method: ApiMethod.DELETE,
        path: '/public/v1/workspaces/:workspaceName/projects/:projectUuid',
        handlerMethod: 'deleteProject',
        publicApi: true,
        description: 'Deletes a project',
        group: Group.Projects,
        zod: {
            params: oaProjectParamsSchema
        },
        responses: {
            204: {
                description: 'Project successfully deleted.'
            }
        }
    },
    STATUS_PUT_OA_DEPRECATED: {
        method: ApiMethod.PUT,
        path: '/public/v1/projects/:projectUuid/status',
        handlerMethod: 'updateProjectStatusDeprecated',
        publicApi: true,
        description: 'Updates a project status',
        group: Group.Projects,
        zod: {
            body: oaProjectUpdateStatusBodySchema,
            params: oaProjectUuidSchema
        },
        responses: {
            204: {
                description: 'Project status successfully updated.'
            }
        }
    },
    CSS_GET: {
        method: ApiMethod.GET,
        path: '/workspaces/:workspaceName/projects/:projectUuid/css',
        handlerMethod: 'getProjectCSS',
        contentType: 'text/css',
        zod: {
            params: projectParamsSchema
        }
    },
    FOLDER_TREE_GET: {
        method: ApiMethod.GET,
        path: '/workspaces/:workspaceName/projects/:projectUuid/folder-tree',
        handlerMethod: 'getProjectFolderTree',
        zod: {
            params: projectParamsSchema
        }
    },
    STATS_GET: {
        method: ApiMethod.GET,
        path: '/workspaces/:workspaceName/projects/:projectUuid/stats',
        handlerMethod: 'findStats',
        zod: {
            params: projectParamsSchema
        }
    },
    STATS_XLSX_GET: {
        method: ApiMethod.GET,
        path: '/workspaces/:workspaceName/projects/:projectUuid/stats.xlsx',
        passResAndNextToHandler: true,
        handlerMethod: 'findStatsXLSX',
        zod: {
            params: projectParamsSchema
        }
    },
    SEARCH_OA: {
        method: ApiMethod.POST,
        path: '/public/v1/workspaces/:workspaceName/search/projects',
        zod: {
            params: workspaceParamsSchema,
            body: projectSearchBodySchema
        },
        handlerMethod: 'searchProjects',
        description: 'Search for projects',
        group: Group.Projects,
        publicApi: true,
        responses: {
            200: {
                schema: paginatedProjectSearchResultSchema,
                description: 'List of projects'
            }
        }
    },
    PROJECT_CONVERT_POST: {
        method: ApiMethod.POST,
        path: '/workspaces/:workspaceName/project-convert/:folderUuid',
        handlerMethod: 'convertFolderToProject',
        zod: {
            params: folderParamsSchema
        },
        responses: {
            200: {
                schema: projectSchema,
                description: 'Project successfully created from folder'
            }
        }
    }
} as const;

export const API_PROJECT_TASKS = {
    LIST: {
        method: ApiMethod.GET,
        path: '/workspaces/:workspaceName/projects/:projectUuid/tasks',
        handlerMethod: 'getTasks' as 'getTasks',
        zod: {
            params: projectParamsSchema
        },
        responses: {
            200: {
                schema: taskSchema.array(),
                description: 'List of tasks'
            }
        }
    }
};

export const API_PROJECT_TASK_ASSETS = {
    LIST: {
        method: ApiMethod.GET,
        path: '/workspaces/:workspaceName/projects/:projectUuid/task-assets',
        handlerMethod: 'getTaskAssets' as 'getTaskAssets',
        zod: {
            params: projectParamsSchema
        },
        response: {
            200: {
                schema: assetSchema.array(),
                description: 'List of task assets'
            }
        }
    }
};

export const API_PROJECT_EVENT_LABELS = {
    LIST: {
        method: ApiMethod.GET,
        path: '/workspaces/:workspaceName/projects/:projectUuid/client-event-labels',
        handlerMethod: 'getClientEventLabels' as 'getClientEventLabels',
        zod: {
            params: projectParamsSchema
        }
    }
};

export const API_PROJECT_NOTIFICATIONS = {
    GET: {
        method: ApiMethod.GET,
        path: '/workspaces/:workspaceName/projects/:projectUuid/notifications',
        handlerMethod:
            'findProjectUserNotificationSettings' as 'findProjectUserNotificationSettings',
        zod: {
            params: projectParamsSchema
        },
        responses: {
            200: {
                schema: userNotificationSettingsSchema.nullable(),
                description: 'Project notification settings'
            }
        }
    },
    PUT: {
        method: ApiMethod.PUT,
        path: '/workspaces/:workspaceName/projects/:projectUuid/notifications',
        handlerMethod: 'putProjectUserNotificationSettings' as 'putProjectUserNotificationSettings',
        zod: {
            params: projectParamsSchema,
            body: userNotificationSettingsSchema
        }
    },
    PUT_ALL: {
        method: ApiMethod.PUT,
        path: '/workspaces/:workspaceName/project-notifications',
        handlerMethod:
            'putAllProjectUserNotificationSettings' as 'putAllProjectUserNotificationSettings',
        zod: {
            params: workspaceParamsSchema,
            body: userNotificationSettingsSchema
        }
    },
    GET_DEFAULT: {
        method: ApiMethod.GET,
        path: '/workspaces/:workspaceName/project-notifications/default',
        handlerMethod:
            'findDefaultProjectUserNotificationSettings' as 'findDefaultProjectUserNotificationSettings',
        zod: {
            params: workspaceParamsSchema
        },
        responses: {
            200: {
                schema: userNotificationSettingsSchema.nullable(),
                description: 'Default project notification settings'
            }
        }
    },
    PUT_DEFAULT: {
        method: ApiMethod.PUT,
        path: '/workspaces/:workspaceName/project-notifications/default',
        handlerMethod:
            'putDefaultProjectUserNotificationSettings' as 'putDefaultProjectUserNotificationSettings',
        zod: {
            params: workspaceParamsSchema,
            body: userNotificationSettingsSchema
        }
    }
} as const;

export const API_PROJECT_CUSTOM_FIELD_VALUES = {
    LIST_OA: {
        method: ApiMethod.GET,
        path: '/public/v1/workspaces/:workspaceName/projects/:projectUuid/custom-fields',
        handlerMethod: 'listProjectCustomFields',
        publicApi: true,
        group: Group.Projects,
        description: 'List all the custom field values in a project',
        zod: {
            params: oaProjectParamsSchema
        },
        responses: {
            200: {
                description: 'List of custom field values',
                schema: customFieldValuesSchema
            }
        }
    },
    PUT_OA: {
        method: ApiMethod.PUT,
        path: '/public/v1/workspaces/:workspaceName/projects/:projectUuid/custom-fields',
        handlerMethod: 'setProjectCustomFields',
        publicApi: true,
        description: 'Set custom field values to a project',
        group: Group.Projects,
        zod: {
            params: oaProjectParamsSchema,
            body: customFieldValuesBodySchema
        },
        responses: {
            204: {
                description: 'Custom field values successfully set'
            }
        }
    }
};
