import { SyntheticEvent, useState } from 'react';
import { compose, withProps, withStateHandlers } from 'recompose';

export type SearchStateProps = {
    search: string;
    setSearch: (search: string) => void;
    onSearchInput: (e: SyntheticEvent<HTMLInputElement>) => void;
};

export default compose(
    withStateHandlers(
        {
            search: ''
        },
        {
            setSearch: () => (search) => ({ search })
        }
    ),
    withProps(({ setSearch }) => ({
        onSearchInput: (e) => setSearch(e.target.value)
    }))
);

export const useSearchState = () => {
    const [search, setSearch] = useState<string>();
    return { search, setSearch, onSearchInput: (e) => setSearch(e.target.value) };
};
