import { z } from 'zod';
import { Group } from '../paths/herawApiPathGroups';
import { ApiMethod } from '../types/api.types';
import { customFieldValuesSchema } from '../types/custom-field.types';
import { fileFullSchema, fileVotesSchema, versionAssetRecapSchema } from '../types/file.types';
import {
    oaDeprecatedFileSpecificParams,
    oaFileCreateBody,
    oaFileCreateResponse,
    oaFileParamsSchema,
    oaFileStatusUpdateBody,
    oaFileUuidSchema,
    oaFileVersionParamsSchema
} from '../types/open-api/file.open-api.types';
import { oaPasswordQuery } from '../types/open-api/folder.open-api.types';
import { customFieldValuesBodySchema } from '../types/payloads/custom-field.payload';
import {
    fileAssetDownloadUrlQuerySchema,
    fileAssetDownloadUrlSchema,
    fileBulkDeleteBody,
    fileCopyOrMoveBodySchema,
    fileCreateBody,
    fileFinalVoteBody,
    fileParamsSchema,
    filePasswordQuery,
    fileStatusUpdateBody,
    fileUpdateBody,
    fileVersionAddBody,
    fileVersionParamsSchema
} from '../types/payloads/file.payload';
import { workspaceParamsSchema } from '../types/payloads/workspace.payload';
import { fileCreateResponse, fileSubtitlesFindResponse } from '../types/responses/file.responses';

export const API_FILE = {
    POST: {
        method: ApiMethod.POST,
        path: '/workspaces/:workspaceName/files',
        handlerMethod: 'createFile' as 'createFile',
        zod: {
            params: workspaceParamsSchema,
            body: fileCreateBody
        },
        responses: {
            200: {
                description: 'The created file',
                schema: fileCreateResponse
            }
        }
    },
    POST_OA: {
        method: ApiMethod.POST,
        path: '/public/v1/workspaces/:workspaceName/files',
        handlerMethod: 'createFile' as 'createFile',
        publicApi: true,
        group: Group.Files,
        description: 'Creates a new file entry to be uploaded',
        zod: {
            params: workspaceParamsSchema,
            body: oaFileCreateBody
        },
        responses: {
            200: {
                description: 'The created file',
                schema: oaFileCreateResponse
            }
        }
    },
    POST_OA_DEPRECATED: {
        method: ApiMethod.POST,
        path: '/public/v1/file',
        handlerMethod: 'createFile' as 'createFile',
        publicApi: true,
        group: Group.Files,
        description: 'Creates a new file entry to be uploaded',
        zod: {
            body: oaFileCreateBody
        },
        responses: {
            200: {
                description: 'The created file',
                schema: oaFileCreateResponse
            }
        }
    },
    CONVERT_TO_VERSION: {
        method: ApiMethod.PUT,
        path: '/workspaces/:workspaceName/files/:fileUuid/to-version',
        handlerMethod: 'convertFileToVersion' as 'convertFileToVersion',
        zod: {
            params: fileParamsSchema,
            body: fileVersionAddBody
        },
        responses: {
            204: {
                description: 'The file has been successfully converted to a version'
            }
        }
    },
    COPY: {
        method: ApiMethod.PUT,
        path: '/workspaces/:workspaceName/files/:fileUuid/copy',
        handlerMethod: 'copyFile',
        zod: {
            params: fileParamsSchema,
            body: fileCopyOrMoveBodySchema
        },
        responses: {
            200: {
                description: 'The file has been successfully copied',
                schema: fileFullSchema
            }
        }
    },
    DELETE_OA: {
        method: ApiMethod.DELETE,
        path: '/public/v1/workspaces/:workspaceName/files/:fileUuid',
        handlerMethod: 'deleteFile' as 'deleteFile',
        publicApi: true,
        group: Group.Files,
        description: 'Deletes a file',
        zod: {
            params: oaFileParamsSchema
        },
        responses: {
            204: {
                description: 'File deleted'
            }
        }
    },
    DELETE_OA_DEPRECATED: {
        method: ApiMethod.DELETE,
        path: '/public/v1/file/:fileUuid',
        handlerMethod: 'deleteFile' as 'deleteFile',
        publicApi: true,
        group: Group.Files,
        description: 'Deletes a file',
        zod: {
            params: oaFileUuidSchema
        },
        responses: {
            204: {
                description: 'File deleted'
            }
        }
    },
    TRUE_DELETE_OA: {
        method: ApiMethod.DELETE,
        path: '/public/v1/workspaces/:workspaceName/files/:fileUuid/delete',
        handlerMethod: 'trueDeleteFile' as 'trueDeleteFile',
        publicApi: true,
        group: Group.Files,
        description: 'Delete file permanently and destroy all assets',
        zod: {
            params: oaFileParamsSchema
        },
        responses: {
            204: {
                description: 'File deleted'
            }
        }
    },
    TRUE_DELETE_OA_DEPRECATED: {
        method: ApiMethod.DELETE,
        path: '/public/v1/file/:fileUuid/delete',
        handlerMethod: 'trueDeleteFile' as 'trueDeleteFile',
        publicApi: true,
        group: Group.Files,
        description: 'Delete file permanently and destroy all assets',
        zod: {
            params: oaFileUuidSchema
        },
        responses: {
            204: {
                description: 'File deleted'
            }
        }
    },
    ENCODE: {
        method: ApiMethod.PUT,
        path: '/workspaces/:workspaceName/files/:fileUuid/encode',
        handlerMethod: 'triggerFileEncoding' as 'triggerFileEncoding',
        zod: {
            params: fileParamsSchema
        }
    },
    MOVE: {
        method: ApiMethod.PUT,
        path: '/workspaces/:workspaceName/files/:fileUuid/move',
        handlerMethod: 'moveFile' as 'moveFile',
        zod: {
            params: fileParamsSchema,
            body: fileCopyOrMoveBodySchema
        },
        responses: {
            204: {
                description: 'The file has been successfully moved'
            }
        }
    },
    RESTORE: {
        method: ApiMethod.PUT,
        path: '/workspaces/:workspaceName/files/:fileUuid/restore',
        handlerMethod: 'restoreFile' as 'restoreFile',
        zod: {
            params: fileParamsSchema
        }
    },
    UPDATE_STATUS: {
        method: ApiMethod.PUT,
        path: '/workspaces/:workspaceName/files/:fileUuid/status',
        handlerMethod: 'updateFileStatus' as 'updateFileStatus',
        zod: {
            params: fileParamsSchema,
            body: fileStatusUpdateBody
        },
        responses: {
            204: {
                description: 'The file status has been successfully updated'
            }
        }
    },
    UPDATE_STATUS_OA: {
        method: ApiMethod.PUT,
        path: '/public/v1/workspaces/:workspaceName/file/:fileUuid/status',
        handlerMethod: 'updateFileStatus' as 'updateFileStatus',
        publicApi: true,
        group: Group.Files,
        description: 'Update the status of a file',
        zod: {
            params: oaFileParamsSchema,
            body: oaFileStatusUpdateBody
        },
        responses: {
            204: {
                description: 'The file status has been successfully updated'
            }
        }
    }
} as const;

export const API_FILE_VERSION = {
    LIST: {
        method: ApiMethod.GET,
        path: '/workspaces/:workspaceName/files/:fileUuid/versions',
        handlerMethod: 'listFileVersions' as 'listFileVersions',
        zod: {
            params: fileParamsSchema
        },
        responses: {
            200: {
                description: 'List of file versions',
                schema: z.array(versionAssetRecapSchema)
            }
        }
    },
    GET: {
        method: ApiMethod.GET,
        path: '/workspaces/:workspaceName/files/:fileUuid/versions/:fileVersion',
        handlerMethod: 'getFileVersion' as 'getFileVersion',
        zod: {
            params: fileVersionParamsSchema,
            query: filePasswordQuery
        },
        responses: {
            200: {
                description: 'The file version',
                schema: fileFullSchema
            }
        }
    },
    GET_OA: {
        method: ApiMethod.GET,
        path: '/public/v1/workspaces/:workspaceName/files/:fileUuid/versions/:fileVersion',
        handlerMethod: 'getFileVersion' as 'getFileVersion',
        publicApi: true,
        description: 'Get a file version',
        group: Group.Files,
        zod: {
            params: oaFileVersionParamsSchema,
            query: oaPasswordQuery
        },
        responses: {
            200: {
                description: 'The file version',
                schema: fileFullSchema
            }
        }
    },
    GET_OA_DEPRECATED: {
        method: ApiMethod.GET,
        path: '/public/v1/file/:fileUuid/v/:fileVersion',
        handlerMethod: 'getFileVersion' as 'getFileVersion',
        publicApi: true,
        description: 'Get a file version',
        group: Group.Files,
        zod: {
            params: oaDeprecatedFileSpecificParams,
            query: oaPasswordQuery
        },
        responses: {
            200: {
                description: 'The file version',
                schema: fileFullSchema
            }
        }
    },
    POST: {
        method: ApiMethod.POST,
        path: '/workspaces/:workspaceName/files/:fileUuid/versions',
        handlerMethod: 'createFileVersion' as 'createFileVersion',
        zod: {
            params: fileParamsSchema,
            body: fileCreateBody
        },
        responses: {
            200: {
                description: 'Version created',
                schema: fileCreateResponse
            }
        }
    },
    POST_OA: {
        method: ApiMethod.POST,
        path: '/public/v1/workspaces/:workspaceName/files/:fileUuid/versions',
        handlerMethod: 'createFileVersion' as 'createFileVersion',
        publicApi: true,
        group: Group.Files,
        description: 'Creates a new version of a file',
        zod: {
            params: oaFileParamsSchema,
            body: oaFileCreateBody
        },
        responses: {
            200: {
                description: 'Version created',
                schema: oaFileCreateResponse
            }
        }
    },
    POST_OA_DEPRECATED: {
        method: ApiMethod.POST,
        path: '/public/v1/file-versions/:fileUuid',
        handlerMethod: 'createFileVersion' as 'createFileVersion',
        publicApi: true,
        group: Group.Files,
        description: 'Creates a new version of a file',
        zod: {
            params: oaFileUuidSchema,
            body: oaFileCreateBody
        },
        responses: {
            200: {
                description: 'Version created',
                schema: oaFileCreateResponse
            }
        }
    },
    PUT: {
        method: ApiMethod.PUT,
        path: '/workspaces/:workspaceName/files/:fileUuid/versions/:fileVersion',
        handlerMethod: 'updateFileVersion' as 'updateFileVersion',
        zod: {
            params: fileVersionParamsSchema,
            body: fileUpdateBody
        },
        responses: {
            200: {
                description: 'The updated file version',
                schema: fileFullSchema
            }
        }
    },
    RESTORE: {
        method: ApiMethod.PATCH,
        path: '/workspaces/:workspaceName/files/:fileUuid/versions/:fileVersion',
        handlerMethod: 'restoreFileVersion' as 'restoreFileVersion',
        zod: {
            params: fileVersionParamsSchema
        }
    },
    DELETE: {
        method: ApiMethod.DELETE,
        path: '/workspaces/:workspaceName/files/:fileUuid/versions/:fileVersion',
        handlerMethod: 'deleteFileVersion' as 'deleteFileVersion',
        zod: {
            params: fileVersionParamsSchema
        },
        responses: {
            200: {
                description: 'The deleted file expiry',
                schema: z.date()
            }
        }
    }
};

export const API_FILE_SUBTITLES = {
    LIST: {
        method: ApiMethod.GET,
        path: '/workspaces/:workspaceName/files/:fileUuid/versions/:fileVersion/subtitles',
        handlerMethod: 'listFileSubtitles' as 'listFileSubtitles',
        zod: {
            params: fileVersionParamsSchema,
            query: filePasswordQuery
        },
        responses: {
            200: {
                description: 'List of subtitles for a file',
                schema: fileSubtitlesFindResponse
            }
        }
    },
    LIST_OA: {
        method: ApiMethod.GET,
        path: '/public/v1/workspaces/:workspaceName/files/:fileUuid/versions/:fileVersion/subtitles',
        handlerMethod: 'listFileSubtitles' as 'listFileSubtitles',
        publicApi: true,
        group: Group.Files,
        description: 'Get file subtitles',
        zod: {
            params: oaFileVersionParamsSchema,
            query: oaPasswordQuery
        },
        responses: {
            200: {
                description: 'List of subtitles associated with the file',
                schema: fileSubtitlesFindResponse
            }
        }
    },
    LIST_OA_DEPRECATED: {
        method: ApiMethod.GET,
        path: '/public/v1/file/:fileUuid/v/:fileVersion/subtitles',
        handlerMethod: 'listFileSubtitles' as 'listFileSubtitles',
        publicApi: true,
        group: Group.Files,
        description: 'Get file subtitles',
        zod: {
            params: oaDeprecatedFileSpecificParams,
            query: oaPasswordQuery
        },
        responses: {
            200: {
                description: 'List of subtitles associated with the file',
                schema: fileSubtitlesFindResponse
            }
        }
    }
};

export const API_FILE_FINAL_VOTES = {
    GET: {
        method: ApiMethod.GET,
        path: '/workspaces/:workspaceName/files/:fileUuid/final-votes',
        handlerMethod: 'getFileFinalVotes' as 'getFileFinalVotes',
        zod: {
            params: fileParamsSchema
        },
        responses: {
            200: {
                description: 'List of final votes for a file',
                schema: fileVotesSchema
            }
        }
    },
    POST: {
        method: ApiMethod.POST,
        path: '/workspaces/:workspaceName/files/:fileUuid/final-votes',
        handlerMethod: 'addFileFinalVote' as 'addFileFinalVote',
        zod: {
            params: fileParamsSchema,
            body: fileFinalVoteBody
        },
        responses: {
            200: {
                description: 'List of final votes for a file',
                schema: fileVotesSchema
            }
        }
    }
};

export const API_FILE_VERSION_ASSET = {
    GET_DOWNLOAD_URL: {
        method: ApiMethod.GET,
        path: '/workspaces/:workspaceName/files/:fileUuid/versions/:fileVersion/asset-download-url',
        handlerMethod: 'getFileVersionAssetDownloadUrl',
        zod: {
            params: fileVersionParamsSchema,
            query: fileAssetDownloadUrlQuerySchema
        },
        responses: {
            200: {
                description: 'The download url of the asset',
                schema: fileAssetDownloadUrlSchema
            }
        }
    },
    GET_HLS_KEY: {
        method: ApiMethod.GET,
        path: '/workspaces/:workspaceName/files/:fileUuid/versions/:fileVersion/asset-hls-key',
        handlerMethod: 'getHlsKey',
        zod: {
            params: fileVersionParamsSchema
        },
        responses: {
            200: {
                description: 'The HLS key of the asset',
                contentTypes: ['application/octet-stream'],
                schema: z.any()
            }
        }
    }
} as const;

export const API_FILE_BULK_DELETE = {
    POST: {
        method: ApiMethod.POST,
        path: '/workspaces/:workspaceName/files-delete',
        handlerMethod: 'bulkDeleteFiles' as 'bulkDeleteFiles',
        zod: {
            params: workspaceParamsSchema,
            body: fileBulkDeleteBody
        },
        responses: {
            204: {
                description: 'The files have been successfully deleted'
            }
        }
    }
};

export const API_FILE_CUSTOM_FIELD_VALUES = {
    LIST_OA: {
        method: ApiMethod.GET,
        path: '/public/v1/workspaces/:workspaceName/files/:fileUuid/custom-fields',
        handlerMethod: 'listFileCustomFields',
        publicApi: true,
        group: Group.Files,
        description: 'List all the custom field values related to a file',
        zod: {
            params: oaFileParamsSchema
        },
        responses: {
            200: {
                description: 'List of custom field values',
                schema: customFieldValuesSchema
            }
        }
    },
    PUT_OA: {
        method: ApiMethod.PUT,
        path: '/public/v1/workspaces/:workspaceName/files/:fileUuid/custom-fields',
        handlerMethod: 'setFileCustomFields',
        publicApi: true,
        description: 'Set custom field values to a file',
        group: Group.Files,
        zod: {
            params: oaFileParamsSchema,
            body: customFieldValuesBodySchema
        },
        responses: {
            204: {
                description: 'Custom field values successfully set'
            }
        }
    }
};
