import { signInLink } from '@he-novation/config/paths/herawLoginPaths';
import { AuthPasswordUpdatePayload } from '@he-novation/config/types/payloads/auth.payload';
import {
    UserInfoSchema,
    UserPreferencesSchema
} from '@he-novation/config/types/payloads/user.payload';
import { UserAppInfos } from '@he-novation/config/types/user.types';
import {
    fetchAccount as _fetchAccount,
    fetchUserAppInfos as _fetchUser,
    updatePassword as _updatePassword,
    updateProfile as _updateProfile,
    updateUserPreferences as _updateUserPreferences
} from '@he-novation/front-shared/async/user.async';
import { Dispatch } from 'redux';
import { v4 as uuidV4 } from 'uuid';
import asyncAction, { asyncActionSuccess } from '../../helpers/asyncAction';

export const USER = 'USER';

export const ADD_PLUGIN_CONFIGURATION = `${USER}/ADD_PLUGIN_CONFIGURATION`;
export const DELETE_PLUGIN_CONFIGURATION = `${USER}/DELETE_PLUGIN_CONFIGURATION`;
export const DISABLE_TOTP = `${USER}/DISABLE_TOTP`;
export const EDIT_PLUGIN_CONFIGURATION = `${USER}/EDIT_PLUGIN_CONFIGURATION`;
export const PLUGIN_MERGE_PROPERTIES = `${USER}/PLUGIN_MERGE_PROPERTIES`;
export const FETCH = `${USER}/FETCH`;
export const FETCH_ACCOUNT = `${USER}/FETCH_ACCOUNT`;
export const FETCH_STORAGE_LICENSES = `${USER}/FETCH_STORAGE_LICENSES`;
export const FETCH_STORAGE_TOTAL = `${USER}/FETCH_STORAGE_TOTAL`;
export const FETCH_STORAGE_USED = `${USER}/FETCH_STORAGE_USED`;
export const REQUEST_TOKEN = `${USER}REQUEST_TOKEN`;
export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
export const UPDATE_PREFERENCES = 'UPDATE_PREFERENCES';
export const UPDATE_PROFILE = `${USER}UPDATE_PROFILE`;
export const UPDATE_TOTP = `${USER}/UPDATE_TOTP`;

export const fetchUser =
    (cb?: (user: any) => void, onError?: (error: Error) => void) => async (dispatch) => {
        dispatch({ type: FETCH });
        try {
            const user = await _fetchUser();
            dispatch({ type: asyncActionSuccess(FETCH), user });
            if (typeof cb === 'function') cb(user);
        } catch (e) {
            onError?.(e);
        }
    };

export const updatePassword =
    (body: AuthPasswordUpdatePayload, cb: (e?: any) => void) => async (dispatch) => {
        dispatch({ type: UPDATE_PASSWORD });
        try {
            await _updatePassword(body);
            dispatch({ type: asyncActionSuccess(UPDATE_PASSWORD) });
            cb?.();
        } catch (e) {
            cb?.(e);
        }
    };

export const fetchAccount =
    (cb?: (account: any) => void, onError?: (e: Error, redirectToLogin: () => void) => void) =>
    async (dispatch) => {
        dispatch({ type: FETCH_ACCOUNT });
        try {
            const account = await _fetchAccount();
            dispatch({ type: asyncActionSuccess(FETCH_ACCOUNT), account });
            if (typeof cb === 'function') cb(account);
        } catch (e) {
            console.error(e);
            const redirectToLogin = () => {
                window.location.href = signInLink('fr', {
                    redirect: window.location.pathname.replace('?', '&')
                });
            };
            if (!onError) {
                redirectToLogin();
            } else {
                onError(e, redirectToLogin);
            }
        }
    };

export const requestToken = () =>
    asyncAction(REQUEST_TOKEN, {
        method: 'POST',
        url: `proxy/get/access/token`
    })({ request_token: uuidV4() });

export const updateProfile =
    (userUuid: string, profile: UserInfoSchema, cb?: (profile: UserInfoSchema) => void) =>
    async (dispatch) => {
        dispatch({ type: UPDATE_PROFILE });
        const { picture } = (await _updateProfile(userUuid, profile)) as { picture: string };
        dispatch({ type: asyncActionSuccess(UPDATE_PROFILE), profile, picture });
        if (typeof cb === 'function') cb(profile);
    };

export function updateUserPreferences(userUuid: string, preferences: UserPreferencesSchema) {
    return async (dispatch: Dispatch) => {
        dispatch({ type: UPDATE_PREFERENCES });
        try {
            await _updateUserPreferences(userUuid, preferences);
            dispatch({ type: asyncActionSuccess(UPDATE_PREFERENCES), preferences });
        } catch (e) {
            console.error(e);
        }
    };
}

export const SET_APP_INFOS = `${USER}/SET_APP_INFOS`;
export function setAppInfos(appInfos: UserAppInfos) {
    return {
        type: SET_APP_INFOS,
        appInfos
    };
}

export const SET_ACCOUNT = `${USER}/SET_ACCOUNT`;
export function setAccount(account: any) {
    return {
        type: SET_ACCOUNT,
        account
    };
}

export function updateTotp(active: boolean) {
    return { type: UPDATE_TOTP, active };
}
