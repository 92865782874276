import styles from './SidePanelFile.module.css';
import React from 'react';
import { useSelector } from 'react-redux';
import { Tabs } from '@he-novation/design-system/components/widgets/Tabs/Tabs';

import { SidePanelFileInfo } from '$components/SidePanel/SidePanelFile/SidePanelFileInfo/SidePanelFileInfo';
import { SidePanelNotes } from '$components/SidePanel/SidePanelFile/SidePanelNotes/SidePanelNotes';
import { SidePanelSubtitles } from '$components/SidePanel/SidePanelFile/SidePanelSubtitles/SidePanelSubtitles';
import { useTranslate } from '$hooks/useTranslate';
import { fileTypeSelector } from '$redux/content/file/fileSelectors';

type SidePanelNotesProps = {
    payload?: {
        castUid?: string;
        castFileUuid?: string;
    };
};

function SidePanelFile({ payload: { castUid, castFileUuid } = {} }: SidePanelNotesProps) {
    const { fileType } = useSelector(fileTypeSelector);

    const { t } = useTranslate();

    return (
        <div className={styles.fileSidePanel}>
            {fileType === 'video' && !castFileUuid ? (
                <Tabs
                    className={styles.tabs}
                    tabs={[
                        {
                            id: 'side-panel-tab-notes',
                            tooltip: t('player.Notes'),
                            icon: 'speech_bubbles'
                        },
                        {
                            id: 'side-panel-tab-subs',
                            tooltip: t('common.Subtitles'),
                            icon: 'subtitles'
                        },
                        {
                            id: 'side-panel-tab-infos',
                            tooltip: t('common.Information'),
                            icon: 'info'
                        }
                    ]}
                >
                    <SidePanelNotes
                        fileType={fileType}
                        castUid={castUid}
                        castFileUuid={castFileUuid}
                    />
                    <SidePanelSubtitles />
                    <SidePanelFileInfo />
                </Tabs>
            ) : (
                <Tabs
                    className={styles.tabs}
                    tabs={[
                        {
                            id: 'side-panel-tab-notes',
                            label: t('player.Notes'),
                            icon: 'speech_bubbles'
                        },
                        {
                            id: 'side-panel-tab-infos',
                            label: t('common.Information'),
                            icon: 'info'
                        }
                    ]}
                >
                    <SidePanelNotes
                        fileType={fileType}
                        castUid={castUid}
                        castFileUuid={castFileUuid}
                    />
                    <SidePanelFileInfo />
                </Tabs>
            )}
        </div>
    );
}

export default SidePanelFile;
