import { z } from 'zod';
import { ApiMethod } from '../types/api.types';
import {
    subscriptionCustomerPayloadSchema,
    subscriptionPayloadSchema
} from '../types/payloads/subscription.payload';
import { workspaceParamsSchema } from '../types/payloads/workspace.payload';
import {
    checkoutPricesResponseSchema,
    paymentMethodIdSchema,
    paymentMethodSchema,
    subscriptionCustomerResponseSchema,
    subscriptionInvoiceSchema,
    subscriptionResponseSchema,
    subscriptionSchema
} from '../types/subscription.types';

export const API_SUBSCRIPTION = {
    GET: {
        method: ApiMethod.GET,
        path: '/subscriptions/:workspaceName',
        handlerMethod: 'getSubscription',
        zod: {
            params: workspaceParamsSchema
        },
        responses: {
            200: {
                description: "The workspace's subscription plan",
                schema: subscriptionSchema
            }
        }
    },
    STRIPE_CUSTOMER_PUT: {
        method: ApiMethod.PUT,
        path: '/subscriptions/:workspaceName/stripe/customer',
        handlerMethod: 'putCustomer',
        zod: {
            params: workspaceParamsSchema,
            body: subscriptionCustomerPayloadSchema
        },
        responses: {
            200: {
                description: 'The customer ID and stripe setup intent info',
                schema: subscriptionCustomerResponseSchema
            }
        }
    },
    STRIPE_SETUP_INTENT_POST: {
        method: ApiMethod.POST,
        path: '/subscriptions/:workspaceName/stripe/setup-intent',
        handlerMethod: 'postSetupIntent',
        zod: {
            params: workspaceParamsSchema,
            body: z.object({
                customerId: z.string(),
                paymentMethodId: z.string()
            })
        },
        responses: {
            200: {
                description: 'The stripe setup intent info',
                schema: z.object({
                    id: z.string(),
                    clientSecret: z.string()
                })
            }
        }
    },
    PUT: {
        method: ApiMethod.PUT,
        path: '/subscriptions/:workspaceName',
        handlerMethod: 'putSubscription',
        zod: {
            params: workspaceParamsSchema,
            body: subscriptionPayloadSchema
        },
        responses: {
            200: {
                description: 'The requested subscription',
                schema: subscriptionResponseSchema
            }
        }
    },
    PATCH: {
        method: ApiMethod.PATCH,
        path: '/subscriptions/:workspaceName',
        handlerMethod: 'updateSubscription',
        zod: {
            params: workspaceParamsSchema,
            body: subscriptionPayloadSchema
        },
        responses: {
            200: {
                description: 'The updated subscription',
                schema: z.object({
                    status: z.string()
                })
            }
        }
    },
    DELETE: {
        method: ApiMethod.DELETE,
        path: '/subscriptions/:workspaceName',
        handlerMethod: 'deleteSubscription',
        zod: {
            params: workspaceParamsSchema
        },
        response: {
            200: {
                description: 'The new subscription status',
                schema: subscriptionSchema
            }
        }
    },
    STATUS_PATCH: {
        method: ApiMethod.PATCH,
        path: '/subscriptions/:workspaceName/status',
        handlerMethod: 'updateSubscriptionStatus',
        zod: {
            params: workspaceParamsSchema
        },
        responses: {
            200: {
                description: 'The updated subscription',
                schema: z.object({
                    status: z.string()
                })
            }
        }
    },
    PAYMENT_POST: {
        method: ApiMethod.POST,
        path: '/subscriptions/:workspaceName/payment',
        handlerMethod: 'retryPayment',
        zod: {
            params: workspaceParamsSchema
        },
        responses: {
            200: {
                description: 'The requested subscription',
                schema: subscriptionResponseSchema
            }
        }
    },

    FREE_TRIAL_GET: {
        method: ApiMethod.GET,
        path: '/subscription-free-trial',
        handlerMethod: 'canEnableFreeTrial',
        zod: {
            query: z.object({
                workspaceName: z.string().optional()
            })
        },
        responses: {
            200: {
                description: 'The free trial boolean',
                schema: z.object({
                    canEnableFreeTrial: z.boolean()
                })
            }
        }
    }
} as const;

export const API_SUBSCRIPTION_PAYMENT_METHOD = {
    GET: {
        method: ApiMethod.GET,
        path: '/subscriptions/:workspaceName/payment-method',
        handlerMethod: 'getPaymentMethod',
        zod: {
            params: workspaceParamsSchema
        },
        responses: {
            200: {
                description: 'The payment method for the workspace',
                schema: paymentMethodSchema
            }
        }
    },
    PUT: {
        method: ApiMethod.PUT,
        path: '/subscriptions/:workspaceName/payment-method',
        handlerMethod: 'replacePaymentMethod',
        zod: {
            params: workspaceParamsSchema,
            body: paymentMethodIdSchema
        },
        responses: {
            204: {
                description: 'The payment method was successfully replaced'
            }
        }
    }
};

export const API_SUBSCRIPTION_COUNTRY = {
    LIST: {
        method: ApiMethod.GET,
        path: '/subscription-countries/:locale',
        handlerMethod: 'listCountries',
        zod: {
            params: z.object({
                locale: z.string()
            })
        },
        responses: {
            200: {
                description: 'List of supported countries and codes by stripe',
                schema: z.array(
                    z.object({
                        code: z.string(),
                        name: z.string()
                    })
                )
            }
        }
    }
} as const;

export const API_SUBSCRIPTION_PRICE = {
    LIST: {
        method: ApiMethod.GET,
        path: '/subscription-prices',
        handlerMethod: 'listPrices',
        responses: {
            200: {
                description: 'Current prices',
                schema: checkoutPricesResponseSchema
            }
        }
    }
} as const;

export const API_CHECKOUT_TAX_CALCULATION = {
    POST: {
        method: ApiMethod.POST,
        path: '/subscription-prices/taxes',
        handlerMethod: 'postTaskCalculation',
        zod: {
            body: z.object({
                countryCode: z.string(),
                items: z.array(
                    z.object({
                        amount: z.number(),
                        quantity: z.number()
                    })
                )
            })
        },
        responses: {
            200: {
                description: 'Tax calculation',
                schema: z.object({
                    total: z.number(),
                    subtotal: z.number(),
                    taxBreakdown: z.object({
                        exclusive: z.number(),
                        inclusive: z.number()
                    }),
                    taxes: z.number()
                })
            }
        }
    }
} as const;

export const API_SUBSCRIPTION_INVOICE = {
    LIST: {
        method: ApiMethod.GET,
        path: '/subscriptions/:workspaceName/invoices',
        handlerMethod: 'listInvoices',
        zod: {
            params: workspaceParamsSchema
        },
        responses: {
            200: {
                description: 'List of invoices',
                schema: subscriptionInvoiceSchema.array()
            }
        }
    }
} as const;
