import { sanitizedString } from '@he-novation/utils/zod.utils';
import { z } from 'zod';

export const clientLabelParamsSchema = z.object({
    workspaceName: sanitizedString({ min: 3, max: 255 }),
    uid: sanitizedString({ min: 6, max: 6 })
});

export const clientLabelQuerySchema = z.object({
    type: z.enum(['cast', 'contact', 'event', 'item'])
});

export const clientLabelBodySchema = z.object({
    name: z.string(),
    position: z.number().optional(),
    color: z.string().optional()
});

export type ClientLabelBody = z.infer<typeof clientLabelBodySchema>;

export const clientLabelUpdateBody = clientLabelBodySchema.partial();

export type ClientLabelUpdateBody = z.infer<typeof clientLabelUpdateBody>;

export const clientLabelAssociationParamsSchema = z.object({
    workspaceName: sanitizedString({ min: 3, max: 255 }),
    uid: sanitizedString({ min: 6, max: 6 }),
    associationUuid: z.string().uuid()
});
