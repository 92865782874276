import { z } from 'zod';
import { TaskStatus } from './db/enums';
import { assetSchema } from './asset.types';
import { clientContactLabelSchema } from './clientLabel.types';
import { basicEntitySchema } from './entity.types';
import { noteMetadataSchema } from './note.medatata.types';
import { teamSchema } from './team.types';
import { contentUserSchema } from './user.types';

export { TaskStatus };

export enum TaskColumn {
    ToDo = 'toDo',
    InProgress = 'inProgress',
    ToValidate = 'toValidate',
    Done = 'done'
}

const taskAssignedUserSchema = contentUserSchema.extend({
    labels: z.array(clientContactLabelSchema)
});
export type TaskAssignedUser = z.infer<typeof taskAssignedUserSchema>;

const taskNoteSchema = z.object({
    uuid: z.string().uuid(),
    assets: z.array(assetSchema),
    type: z.string(),
    content: z.string().nullable().optional(),
    file: z
        .object({
            uuid: z.string().uuid(),
            name: z.string(),
            version: z.number(),
            assets: z.array(assetSchema)
        })
        .nullable(),
    metadata: noteMetadataSchema.nullable().optional()
});
export const taskSchema = z.object({
    uuid: z.string().uuid(),
    description: z.string(),
    status: z.nativeEnum(TaskStatus),
    number: z.number().nullable(),
    ordering: z.number().nullable(),
    estimatedEndDate: z.date().nullable(),
    endDate: z.date().nullable(),
    created: z.date(),
    updated: z.date().nullable(),
    assets: z.array(assetSchema),
    note: taskNoteSchema.nullable(),
    team: teamSchema,
    assignedUsers: z.array(taskAssignedUserSchema),
    user: contentUserSchema
        .omit({ uuid: true })
        .extend({ email: z.string().email(), uuid: z.string().uuid().optional().nullable() }),
    workspace: z.object({
        name: z.string(),
        uuid: z.string().uuid()
    }),
    project: z.object({
        uuid: z.string().uuid(),
        name: z.string(),
        isFavorite: z.boolean().optional()
    }),
    company: basicEntitySchema.optional().nullable()
});

export type Task = z.infer<typeof taskSchema>;

export interface TaskNoteUuid extends Omit<Task, 'note' | 'client'> {
    noteUuid: string;
}

export type TaskChange = {
    taskUuid: string;
    status: TaskStatus;
    ordering: number;
};

export enum TaskFilterType {
    TEAM = 'team',
    ASSIGNEE = 'assignee',
    CREATOR = 'creator',
    LABEL = 'label'
}

export type TaskFilterValue = {
    identifier: string;
    label: string;
};

export type TaskFilter = {
    type: TaskFilterType;
    values: TaskFilterValue[];
};
