export class AsyncThrottler {
    private last5secondCalls: number[] = [];

    constructor(private maxCallsPer5Seconds: number) {}

    public async call<T>(cb: () => T): Promise<T> {
        this.last5secondCalls = this.last5secondCalls.filter((call) => call > Date.now() - 5000);

        if (this.last5secondCalls.length >= this.maxCallsPer5Seconds) {
            return new Promise((resolve) => {
                setTimeout(() => {
                    resolve(this.call(cb));
                }, 1000);
            });
        }

        this.last5secondCalls.push(Date.now());
        return cb();
    }
}
