import { ApiMethod } from '../types/api.types';
import { logSearchBody } from '../types/payloads/logs/log.payload';

export const API_LOG = {
    SEARCH_POST: {
        path: '/logs',
        method: ApiMethod.POST,
        handlerMethod: 'search',
        zod: {
            body: logSearchBody
        }
    },
    DELETE_OLD: {
        path: '/logs/old',
        method: ApiMethod.DELETE,
        handlerMethod: 'deleteOldLogs'
    }
} as const;
