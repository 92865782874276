import { WSCastEventType } from '../../types/websockets/cast.ws.types';
import { WSEntityEventType } from '../../types/websockets/entity.ws.types';
import { WSFolderEventType } from '../../types/websockets/folder.ws.types';
import { WSItemEventType, WSItemKitEventType } from '../../types/websockets/item.ws.types';
import { WSNoteEventType } from '../../types/websockets/note.ws.types';
import { WSProjectEventType } from '../../types/websockets/project.ws.types';
import {
    WSWorkspaceAdminUserEventType,
    WSWorkspaceEventType,
    WSWorkspaceTeamEventType,
    WSWorkspaceUserEventType
} from '../../types/websockets/workspace.ws.types';

export type SocketAction = {
    name: string;
    client: string;
    server: boolean | string;
};

export const SOCKETS_CLIENT = {
    id: '/client',
    channel: 'sio#/client',
    actions: [
        {
            name: 'style/updated',
            client: 'sioClientStyleUpdate',
            server: false
        }
    ]
};

export const SOCKETS_CUSTOM_FIELDS = {
    id: '/custom_fields',
    channel: 'sio#/custom_fields',
    actions: [
        {
            name: 'customField/created',
            client: 'sioCustomFieldCreated',
            server: false
        },
        {
            name: 'customField/updated',
            client: 'sioCustomFieldUpdated',
            server: false
        },
        {
            name: 'customField/deleted',
            client: 'sioCustomFieldDeleted',
            server: false
        }
    ]
};

export const SOCKETS_EVENTS = {
    id: '/events',
    channel: 'sio#events',
    actions: [
        {
            name: 'EVENTS/UPSERT',
            client: 'sioEventUpsert',
            server: false
        },
        {
            name: 'EVENTS/DELETE',
            client: 'sioEventDelete',
            server: false
        },
        {
            name: 'EVENTS/DELETE_USER',
            client: 'sioEventDeleteUser',
            server: false
        }
    ]
};

export const SOCKETS_FOLDER = {
    id: '/folder',
    channel: 'sio#/folder',
    actions: [
        {
            name: 'file/add',
            client: 'sioFileAdd',
            server: false
        },
        {
            name: 'file/delete',
            client: 'sioFileDelete',
            server: false
        },
        {
            name: 'files/delete',
            client: 'sioFilesDelete',
            server: false
        },
        {
            name: 'file/export/progress',
            client: 'sioFileExportProgress',
            server: false
        },
        {
            name: 'file/export/finished',
            client: 'sioFileExportFinished',
            server: false
        },
        {
            name: 'file/set',
            client: 'sioFileSet',
            server: 'broadcastRoom'
        },
        {
            name: 'file/upload/add',
            client: 'sioFileUploadAdd',
            server: 'broadcastRoom'
        },
        {
            name: 'file/upload/asset/started',
            client: 'sioFileUploadAssetStarted',
            server: false
        },
        {
            name: 'file/upload/progress',
            client: 'sioFileUploadProgress',
            server: false
        },
        {
            name: 'file/upload/abort',
            client: 'sioFileUploadAbort',
            server: false
        },
        {
            name: 'folder/add',
            client: 'sioFolderAdd',
            server: 'broadcastRoom'
        },
        {
            name: 'folder/delete',
            client: 'sioFolderDelete',
            server: 'broadcastRoom'
        },
        {
            name: 'folders/delete',
            client: 'sioFoldersDelete',
            server: 'broadcastRoom'
        },
        {
            name: 'folder/set',
            client: 'sioFolderSet',
            server: 'broadcastRoom'
        },
        {
            name: 'folder/cast',
            client: 'sioFolderCast',
            server: 'broadcastRoom'
        },
        {
            name: 'folder/members/add',
            client: 'sioFolderMembersAdd',
            server: 'broadcastRoom'
        },
        {
            name: 'folder/members/remove',
            client: 'sioFolderMembersRemove',
            server: 'broadcastRoom'
        },
        {
            name: 'folder/castDelete',
            client: 'sioFolderCastDelete',
            server: 'broadcastRoom'
        },
        {
            name: 'file/cast',
            client: 'sioFileCast',
            server: 'broadcastRoom'
        },
        {
            name: 'file/castDelete',
            client: 'sioFileCastDelete',
            server: 'broadcastRoom'
        },
        {
            name: 'file/version/delete',
            client: 'sioFileVersionDelete',
            server: false
        },
        {
            name: 'file/version/restore',
            client: 'sioFileVersionRestore',
            server: false
        }
    ]
};

export const SOCKETS_ACTIVITY = {
    id: '/activity',
    channel: 'sio#/activity',
    actions: [
        {
            name: 'activity/add',
            client: 'sioActivityAdd',
            server: false
        }
    ]
};

export const SOCKETS_ARCHIVES = {
    id: '/archives',
    channel: 'sio#/archives',
    actions: [
        {
            name: 'archives/add',
            client: 'sioArchivesAdd',
            server: false
        }
    ]
};

export const SOCKETS_NOTE = {
    id: '/note',
    channel: 'sio#/note',
    isPublic: true,
    actions: [
        {
            name: WSNoteEventType.NoteAttachment,
            client: 'sioNoteAttachment',
            server: false
        },
        {
            name: 'note/create',
            client: 'sioNoteCreate',
            server: false
        },
        {
            name: 'note/delete',
            client: 'sioNoteDelete',
            server: false
        },
        {
            name: 'note/update',
            client: 'sioNoteUpdate',
            server: false
        },
        {
            name: 'comment/create',
            client: 'sioCommentCreate',
            server: false
        },
        {
            name: 'comment/update',
            client: 'sioCommentUpdate',
            server: false
        },
        {
            name: 'comment/delete',
            client: 'sioCommentDelete',
            server: false
        }
    ]
};

export const SOCKETS_MISC = {
    id: '/misc',
    channel: 'sio#/misc',
    actions: [
        {
            name: 'misc/reload',
            client: 'sioMiscReload',
            server: false
        },
        {
            name: 'misc/newVersion',
            client: 'sioMiscNewVersion',
            server: false
        }
    ]
};

export const SOCKETS_PROJECTS = {
    id: '/projects',
    channel: 'sio#/projects',
    scopedToWorkspace: true,
    actions: [
        {
            name: 'PROJECTS/UPDATE_CURRENT',
            client: 'sioProjectUpdate',
            server: false
        },
        {
            name: 'PROJECTS/UPDATE',
            client: 'sioProjectsUpdate',
            server: false
        },
        {
            name: 'PROJECTS/ARCHIVE_PROJECT',
            client: 'sioProjectArchive',
            server: false
        },
        {
            name: 'PROJECTS/DELETE_PROJECT',
            client: 'sioProjectDelete',
            server: false
        },
        {
            name: 'PROJECTS/RESTORE_PROJECT',
            client: 'sioProjectRestore',
            server: false
        },
        {
            name: 'PROJECTS/CREATE',
            client: 'sioProjectCreate',
            server: false
        },
        {
            name: 'PROJECTS/UPDATE_MEMBER',
            client: 'sioProjectUpdateMember',
            server: false
        },
        {
            name: 'PROJECTS/DELETE_MEMBER',
            client: 'sioProjectDeleteMember',
            server: false
        },
        {
            name: 'PROJECTS/DELETE_TEAM',
            client: 'sioProjectDeleteTeam',
            server: false
        },
        {
            name: 'PROJECTS/UPDATE_TEAM',
            client: 'sioProjectUpdateTeam',
            server: false
        },
        {
            name: WSProjectEventType.TaskCreate,
            client: 'sioTaskCreate',
            server: false
        },
        {
            name: WSProjectEventType.TaskUpdate,
            client: 'sioTaskUpdate',
            server: false
        },
        {
            name: 'TASKS/DELETE',
            client: 'sioTaskDelete',
            server: false
        },
        {
            name: 'PROJECTS/TEAM_CREATE',
            client: 'sioProjectTeamCreate',
            server: false
        },
        {
            name: WSProjectEventType.AttachmentUpload,
            client: 'sioTaskAttachmentUpload',
            server: false
        }
    ]
} as const;

export const SOCKETS_ENTITIES = {
    id: '/entities',
    channel: 'sio#/entities',
    actions: [
        {
            name: WSEntityEventType.EntityCreated,
            client: 'sioEntityCreate',
            server: false
        },
        {
            name: WSEntityEventType.EntityUpdated,
            client: 'sioEntityUpdate',
            server: false
        },
        {
            name: WSEntityEventType.EntityDeleted,
            client: 'sioEntityDelete',
            server: false
        }
    ]
};

export const SOCKETS_ITEMS = {
    id: '/items',
    channel: 'sio#/items',
    actions: [
        {
            name: WSItemEventType.ItemCreated,
            client: 'sioItemCreate',
            server: false
        },
        {
            name: WSItemEventType.ItemUpdated,
            client: 'sioItemUpdate',
            server: false
        },
        {
            name: WSItemEventType.ItemDeleted,
            client: 'sioItemDelete',
            server: false
        },
        {
            name: WSItemKitEventType.ItemKitCreated,
            client: 'sioItemKitCreate',
            server: false
        },
        {
            name: WSItemKitEventType.ItemKitUpdated,
            client: 'sioItemKitUpdate',
            server: false
        },
        {
            name: WSItemKitEventType.ItemKitDeleted,
            client: 'sioItemKitDelete',
            server: false
        },
        {
            name: WSItemKitEventType.ItemKitItemAdded,
            client: 'sioItemKitAddItem',
            server: false
        },
        {
            name: WSItemKitEventType.ItemKitItemRemoved,
            client: 'sioItemKitRemoveItem',
            server: false
        }
    ]
};

export const SOCKETS_SUBTITLES = {
    id: '/subtitles',
    channel: 'sio#/subtitles',
    actions: [
        {
            name: 'SUBTITLES/CREATE',
            client: 'sioSubtitlesCreate',
            server: false
        },
        {
            name: 'SUBTITLES/UPDATE',
            client: 'sioSubtitlesUpdate',
            server: false
        },
        {
            name: 'SUBTITLES/READY',
            client: 'sioSubtitlesReady',
            server: false
        },
        {
            name: 'BURN_SUBTITLES/READY',
            client: 'sioBurnSubtitlesReady',
            server: false
        }
    ]
};

export const SOCKETS_USER = {
    id: '/user',
    channel: 'sio#/user',
    actions: [
        {
            name: 'archive/ready',
            client: 'sioArchiveReady',
            server: false
        },
        {
            name: 'contact/created',
            client: 'sioContactCreated',
            server: false
        }
    ]
};
export const SOCKETS_FOLDERS = {
    id: '/folders',
    channel: 'sio#/folders',
    scopedToWorkspace: true,
    actions: [
        {
            name: WSFolderEventType.AssetUploadProgress,
            client: 'sioAssetUploadProgress',
            server: false
        },
        {
            name: WSFolderEventType.ConvertingFileToVersion,
            client: 'sioFileConvertToVersion',
            server: false
        },
        {
            name: WSFolderEventType.SourceAssetType,
            client: 'onSourceAssetType',
            server: false
        },
        {
            name: WSFolderEventType.AssetReady,
            client: 'onAssetReady',
            server: false
        },
        {
            name: WSFolderEventType.TranscodingPending,
            client: 'onTranscodingPending',
            server: false
        },
        {
            name: WSFolderEventType.TranscodingProgress,
            client: 'onTranscodingProgress',
            server: false
        },
        {
            name: WSFolderEventType.TranscodingFinished,
            client: 'onTranscodingFinished',
            server: false
        }
    ]
};

export const SOCKETS_CAST = {
    id: '/casts',
    channel: 'sio#/casts',
    actions: [
        {
            name: WSCastEventType.FolderCreated,
            client: 'onFolderCreated',
            server: false
        },
        {
            name: WSCastEventType.FileCreated,
            client: 'onFileCreated',
            server: false
        }
    ]
};

export const SOCKETS_WORKSPACE = {
    id: '/workspace',
    channel: 'sio#/workspace',
    actions: [
        {
            name: WSWorkspaceEventType.WorkspaceUpdated,
            client: 'sioWorkspaceUpdated',
            server: false
        }
    ]
};

export const SOCKETS_WORKSPACE_TEAMS = {
    id: '/workspace_teams',
    channel: 'sio#/workspace_teams',
    actions: [
        {
            name: WSWorkspaceTeamEventType.TeamCreated,
            client: 'sioWorkspaceTeamCreated',
            server: false
        },
        {
            name: WSWorkspaceTeamEventType.TeamDeleted,
            client: 'sioWorkspaceTeamDeleted',
            server: false
        },
        {
            name: WSWorkspaceTeamEventType.TeamUpdated,
            client: 'sioWorkspaceTeamUpdated',
            server: false
        }
    ]
};

export const SOCKETS_WORKSPACE_ADMIN_USERS = {
    id: '/workspace_admin_users',
    channel: 'sio#/workspace_admin_users',
    actions: [
        {
            name: WSWorkspaceAdminUserEventType.UserAdded,
            client: 'sioWorkspaceAdminMemberAdded',
            server: false
        },
        {
            name: WSWorkspaceAdminUserEventType.UserRemoved,
            client: 'sioWorkspaceAdminMemberRemoved',
            server: false
        },
        {
            name: WSWorkspaceAdminUserEventType.UserUpdated,
            client: 'sioWorkspaceAdminMemberUpdated',
            server: false
        }
    ]
};

export const SOCKETS_WORKSPACE_USERS = {
    id: '/workspace_users',
    channel: 'sio#/workspace_users',
    actions: [
        {
            name: WSWorkspaceUserEventType.UserAdded,
            client: 'sioWorkspaceMemberAdded',
            server: false
        },
        {
            name: WSWorkspaceUserEventType.UserRemoved,
            client: 'sioWorkspaceMemberRemoved',
            server: false
        },
        {
            name: WSWorkspaceUserEventType.UserUpdated,
            client: 'sioWorkspaceMemberUpdated',
            server: false
        }
    ]
};
