import z from 'zod';
import { subtitleSchema } from '../subtitle.types';
import { uploadCreateResponse } from './upload.responses';

export const subtitleUploadResponse = subtitleSchema;
export type SubtitlesUploadResponse = z.infer<typeof subtitleUploadResponse>;

export const subtitleUploadUrlResponse = uploadCreateResponse.extend({
    subtitleUuid: z.string()
});
export type SubtitleUploadUrlResponse = z.infer<typeof subtitleUploadUrlResponse>;
