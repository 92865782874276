import { UserAppInfos, UserState } from '@he-novation/config/types/user.types';
import update, { Spec } from 'immutability-helper';
import type { Action } from 'redux';
import { FETCH_SETTINGS_VIEW } from '../content/contentActions';
import {
    DISABLE_TOTP,
    FETCH,
    FETCH_ACCOUNT,
    FETCH_STORAGE_LICENSES,
    FETCH_STORAGE_TOTAL,
    FETCH_STORAGE_USED,
    REQUEST_TOKEN,
    SET_ACCOUNT,
    SET_APP_INFOS,
    UPDATE_PASSWORD,
    UPDATE_PREFERENCES,
    UPDATE_PROFILE,
    UPDATE_TOTP
} from './userActions';

import { asyncActionError, asyncActionSuccess } from '$helpers/asyncAction';

const initialState: UserState = {
    clientName: '',
    email: '',
    uuid: '',
    fetchedAccount: false,
    fetchingAccount: false,
    storageTotal: 0,
    storageUsed: 0,
    clientRights: {},
    totp_enabled: false,
    locale: 'en'
};

export default (state: UserState = initialState, action: Action & any) => {
    switch (action.type) {
        case asyncActionSuccess(DISABLE_TOTP): {
            return {
                ...state,
                totp_enabled: false
            };
        }
        case UPDATE_TOTP:
        case asyncActionSuccess(UPDATE_TOTP): {
            const active = action.active;
            return {
                ...state,
                totp_enabled: active
            };
        }

        case asyncActionSuccess(UPDATE_PASSWORD): {
            return {
                ...state,
                passwordReset: new Date()
            };
        }

        case asyncActionSuccess(FETCH): {
            const { profile, ...data } = action.user;
            return update(state, {
                $merge: {
                    ...data,
                    ...profile,
                    storage: {
                        total: action.user.storage
                    },
                    created: new Date(data.created)
                }
            });
        }

        case SET_ACCOUNT:
            return update(state, {
                $merge: {
                    ipAddress: action.account.ipAddress,
                    clientName: action.account.client_name,
                    clientUuid: action.account.client_uuid,
                    clientRights: action.account.clientRights || {},
                    isClient: action.account.isClient,
                    preferences: action.account.preferences,
                    totp_enabled: !!action.account.totp_enabled,
                    fetchedAccount: true,
                    fetchingAccount: false,
                    locale: action.account.locale
                }
            });

        case SET_APP_INFOS: {
            const { profile, ...data }: UserAppInfos = action.appInfos;
            return update(state, {
                $merge: {
                    ...data,
                    ...profile
                }
            });
        }

        case asyncActionSuccess(FETCH_SETTINGS_VIEW): {
            return update(state, {
                $merge: action.userState
            });
        }

        case asyncActionSuccess(FETCH_STORAGE_USED):
            return {
                ...state,
                storageUsed: action.response.storage_used
            };

        case asyncActionError(FETCH_STORAGE_USED):
            return {
                ...state,
                storageUsed: 0
            };

        case asyncActionSuccess(FETCH_STORAGE_TOTAL):
            return {
                ...state,
                storageTotal: action.response.storage_available
            };

        case asyncActionError(FETCH_STORAGE_TOTAL):
            return {
                ...state,
                storageTotal: 0
            };

        case asyncActionSuccess(FETCH_STORAGE_LICENSES):
            return {
                ...state,
                storage: action.storage
            };

        case FETCH_ACCOUNT:
            return {
                ...state,
                fetchedAccount: false,
                fetchingAccount: true
            };

        case asyncActionSuccess(FETCH_ACCOUNT):
            return update(state, {
                $merge: {
                    ipAddress: action.account.ipAddress,
                    clientName: action.account.client_name,
                    clientUuid: action.account.client_uuid,
                    clientRights: action.account.clientRights || {},
                    isClient: action.account.isClient,
                    preferences: action.account.preferences,
                    totp_enabled: !!action.account.totp_enabled,
                    fetchedAccount: true,
                    fetchingAccount: false
                }
            });

        case asyncActionError(FETCH_ACCOUNT):
            return {
                ...state,
                clientName: '',
                fetchedAccount: true,
                fetchingAccount: false
            };

        case asyncActionSuccess(UPDATE_PROFILE): {
            const _update: Spec<UserState, never> = {
                firstname: { $set: action.profile.firstname },
                lastname: { $set: action.profile.lastname },
                phone: { $set: action.profile.phone },
                firm: { $set: action.profile.firm },
                role: { $set: action.profile.role },
                city: { $set: action.profile.city },
                country: { $set: action.profile.country },
                locale: { $set: action.profile.locale }
            };
            if (action.picture) _update.picture = { $set: action.picture };
            return update(state, _update);
        }

        case asyncActionSuccess(UPDATE_PREFERENCES): {
            action.preferences.tags = Array.isArray(action.preferences.tags)
                ? action.preferences.tags
                : action.preferences.tags?.split(',') || [];
            action.preferences.labels = Array.isArray(action.preferences.labels)
                ? action.preferences.labels
                : action.preferences.labels?.split(',') || [];

            return update(state, {
                preferences: { $merge: action.preferences },
                tags: {
                    $set: action.preferences.tags
                },
                labels: {
                    $set: action.preferences.labels
                }
            });
        }

        case asyncActionSuccess(REQUEST_TOKEN):
            return update(state, {
                requestToken: { $set: action.body.request_token },
                accessToken: { $set: action.response.access_token }
            });
    }

    return state;
};
