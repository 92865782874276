import z from 'zod';
import { SpotlStatus, SubtitleType } from '../db/enums';
import { oaWorkspaceParamsSchema } from '../payloads/workspace.payload';
import { oaUploadCreateResponse } from './upload.open-api.types';

export const oaSubtitleParamsSchema = oaWorkspaceParamsSchema.extend({
    subtitleUuid: z.string().uuid()
});

export const oaSubtitleQuerySchema = z.object({
    format: z.enum(['srt', 'stl-ebu', 'stl-spruce', 'vtt']),
    timestamp: z.coerce.number().optional()
});

export const oaSubtitleEntrySchema = z.object({
    uuid: z.string().uuid(),
    timecodeIn: z.string(),
    timecodeOut: z.string(),
    start: z.number(),
    end: z.number(),
    content: z.string(),
    teletext: z.unknown().nullable(),
    metadata: z.record(z.unknown()).nullable().optional()
});

export type OASubtitleEntry = z.infer<typeof oaSubtitleEntrySchema>;
export const oaSubtitleSchema = z.object({
    uuid: z.string().uuid(),
    assetUrl: z.string(),
    assetUrlExpires: z.date(),
    assetUuid: z.string().uuid(),
    created: z.date(),
    entries: z.array(oaSubtitleEntrySchema),
    fileName: z.string(),
    fileUuid: z.string().uuid(),
    fileVersion: z.number(),
    locale: z.string(),
    name: z.string(),
    spotlTranslation: z
        .object({
            spotlProjectId: z.string(),
            status: z.enum([SpotlStatus.processing, SpotlStatus.done]),
            verified: z.boolean(),
            sourceLocale: z.string()
        })
        .nullable(),
    type: z.nativeEnum(SubtitleType),
    updated: z.date().nullable()
});

export type OASubtitle = z.infer<typeof oaSubtitleSchema>;

export const oaSubtitleUploadResponse = oaSubtitleSchema;
export type OASubtitlesUploadResponse = z.infer<typeof oaSubtitleUploadResponse>;

export const subtitleUploadUrlResponse = oaUploadCreateResponse.extend({
    subtitleUuid: z.string()
});
export type SubtitleUploadUrlResponse = z.infer<typeof subtitleUploadUrlResponse>;

export const oaSubtitleUploadBody = z.object({
    fileUuid: z.string().uuid(),
    fileVersion: z.coerce.number(),
    locale: z.string(),
    file: z.any()
});

export const deprecatedSubtitleParamsSchema = z.object({
    subtitleUuid: z.string().uuid()
});

export type DeprecatedSubtitleParamsSchema = z.infer<typeof deprecatedSubtitleParamsSchema>;
