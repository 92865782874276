import { useCallback } from 'react';
import { PAYMENT_INCIDENT } from '@he-novation/config/paths/modals.constants';
import { ClientStatus } from '@he-novation/config/types/db/enums';
import { useAtomValue } from 'jotai/index';
import { useModal } from './useModal';

import { workspaceAtom } from '$atoms/workspace-atoms';

export function useWorkspaceStatus() {
    const workspace = useAtomValue(workspaceAtom);
    const { openModal } = useModal();

    const canAddContent = useCallback(() => {
        return workspace?.status !== ClientStatus.PAYMENT_INCIDENT;
    }, [workspace]);

    const openModalPaymentIncident = useCallback(() => {
        openModal(PAYMENT_INCIDENT);
    }, []);

    return { canAddContent, openModalPaymentIncident };
}
