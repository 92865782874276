import styles from './DonutChart.module.css';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import cn from 'classnames';

export type DonutChartProps = {
    data: {
        label: string;
        value: number;
        valueString?: string;
        color?: string;
    }[];
    primaryColor?: string;
    hueShift?: number;
    strokeWidth: number;
    padding: number;
    gap: number;
    segmentBorderRadius: number;
};

export enum ColorRangeColor {
    Green = '#49f281',
    Yellow = '#ffc52a',
    Red = '#ff5050',
    White = '#fff'
}

const pixelsToDegrees = (pixels: number, radius: number) =>
    Math.asin(pixels / radius) * (180 / Math.PI);

const arcradius = (cx: number, cy: number, radius: number, degrees: number) => {
    const radians = ((degrees - 90) * Math.PI) / 180.0;
    return { x: cx + radius * Math.cos(radians), y: cy + radius * Math.sin(radians) };
};

export const DonutChart: React.FC<DonutChartProps> = ({
    data,
    primaryColor,
    hueShift,
    strokeWidth,
    padding,
    gap,
    segmentBorderRadius
}) => {
    const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
    const [pathes, setPathes] = useState<string[]>([]);

    const width = 1000;
    const height = 1000;

    const handleMouseEnter = (index: number) => {
        setHoveredIndex(index);
    };

    const handleMouseLeave = () => {
        setHoveredIndex(null);
    };

    const getColor = useCallback(
        (index: number) => {
            if (primaryColor) {
                return primaryColor;
            } else {
                return data[index].color;
            }
        },
        [primaryColor, data]
    );

    const getStyle = useCallback(
        (index: number) => {
            if (!primaryColor) {
                return { filter: `hue-rotate(-${0}turn)` };
            } else {
                return { filter: `hue-rotate(-${(hueShift || 10 / 360) * index}turn)` };
            }
        },
        [primaryColor, hueShift]
    );

    const donutGeom = useMemo(
        (): {
            cx: number;
            cy: number;
            outerRadius: number;
            innerRadius: number;
            gapInDegrees: number;
        } => ({
            cx: width / 2,
            cy: height / 2,
            outerRadius: (width - padding) / 2,
            innerRadius: (width - padding - strokeWidth) / 2,
            gapInDegrees: pixelsToDegrees(gap / 2, (width - padding - strokeWidth) / 2)
        }),
        [strokeWidth, padding, gap]
    );

    useEffect(() => {
        const pathes: string[] = [];
        let beg = 0;
        let end = 0;
        let count = 0;

        const total = data.reduce((acc, { value }) => acc + value, 0);
        if (total === 0) {
            setPathes([]);
            return;
        }

        const cx = width / 2;
        const cy = height / 2;

        for (let i = 0; i < data.length; i++) {
            const augmentedRadius = i === hoveredIndex ? 20 : 0;
            const outerRadius = donutGeom.outerRadius + augmentedRadius;
            const innerRadius = donutGeom.innerRadius;
            const item = data[i];

            let p = (item.value / total) * 100;

            count += p;

            if (i === data.length - 1 && count < 100) p = p + (100 - count);

            end = beg + (360 / 100) * p;

            if (Math.abs(end) - Math.abs(beg) < donutGeom.gapInDegrees) {
                pathes.push('');
                continue;
            }

            const begOut = arcradius(
                cx,
                cy,
                outerRadius,
                end - donutGeom.gapInDegrees - pixelsToDegrees(segmentBorderRadius, outerRadius)
            );

            const endOut = arcradius(
                cx,
                cy,
                outerRadius,
                beg + donutGeom.gapInDegrees + pixelsToDegrees(segmentBorderRadius, outerRadius)
            );

            const borderEndOut = arcradius(
                cx,
                cy,
                outerRadius - segmentBorderRadius,
                beg + donutGeom.gapInDegrees
            );

            const borderEndIn = arcradius(
                cx,
                cy,
                innerRadius + segmentBorderRadius,
                beg + donutGeom.gapInDegrees
            );

            const begIn = arcradius(
                cx,
                cy,
                innerRadius,
                end - donutGeom.gapInDegrees - pixelsToDegrees(segmentBorderRadius, outerRadius) // outerRadius to have the same border radius
            );
            const endIn = arcradius(
                cx,
                cy,
                innerRadius,
                beg + donutGeom.gapInDegrees + pixelsToDegrees(segmentBorderRadius, outerRadius)
            );

            const borderBegIn = arcradius(
                cx,
                cy,
                innerRadius + segmentBorderRadius,
                end - donutGeom.gapInDegrees
            );

            const borderBegOut = arcradius(
                cx,
                cy,
                outerRadius - segmentBorderRadius,
                end - donutGeom.gapInDegrees
            );

            const la = end - beg <= 180 ? 0 : 1;

            pathes.push(
                `M ${begOut.x} ${begOut.y} 
                A ${outerRadius} ${outerRadius} 0 ${la} 0 ${endOut.x} ${endOut.y}
                A ${segmentBorderRadius} ${segmentBorderRadius} 0 0 0 ${borderEndOut.x} ${borderEndOut.y}
                L ${borderEndIn.x} ${borderEndIn.y} 
                A ${segmentBorderRadius} ${segmentBorderRadius} 0 0 0  ${endIn.x} ${endIn.y}
                A ${innerRadius} ${innerRadius} 0 ${la} 1 ${begIn.x} ${begIn.y}
                A ${segmentBorderRadius} ${segmentBorderRadius} 0 0 0 ${borderBegIn.x} ${borderBegIn.y}
                L ${borderBegOut.x} ${borderBegOut.y}
                A ${segmentBorderRadius} ${segmentBorderRadius} 0 0 0 ${begOut.x} ${begOut.y}
                 Z`
            );
            beg = end;
        }

        setPathes(pathes);
    }, [donutGeom, hoveredIndex, data]);

    return (
        <div className={styles.container}>
            <div className={styles.chart}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="100%"
                    height="100%"
                    viewBox={`0 0 ${width} ${height}`}
                >
                    {pathes.length > 0 ? (
                        pathes.map((d, i) => {
                            return (
                                <path
                                    key={i}
                                    stroke="none"
                                    fill={getColor(i)}
                                    style={getStyle(i)}
                                    d={d}
                                    onMouseEnter={() => handleMouseEnter(i)}
                                    onMouseLeave={handleMouseLeave}
                                />
                            );
                        })
                    ) : (
                        <circle
                            stroke="var(--color-bg-3)"
                            strokeWidth={donutGeom.outerRadius - donutGeom.innerRadius}
                            fill="none"
                            cx={width / 2}
                            cy={height / 2}
                            r={
                                donutGeom.innerRadius +
                                (donutGeom.outerRadius - donutGeom.innerRadius) / 2
                            }
                        />
                    )}
                </svg>
            </div>
            <div className={styles.legend}>
                <ul>
                    {data.map((item, i) => (
                        <li key={i}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="12px" height="12px">
                                <circle
                                    cx="6"
                                    cy="6"
                                    r="6"
                                    fill={getColor(i)}
                                    style={getStyle(i)}
                                />
                            </svg>
                            <span
                                className={cn(
                                    styles['legend-text'],
                                    hoveredIndex === i && styles.selected
                                )}
                            >
                                {`${item.label} `}
                                <span className={styles['legend-text-value']}>
                                    ({item.valueString || item.value})
                                </span>
                            </span>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};
