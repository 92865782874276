import { useEffect } from 'react';
import { UserFileType } from '@he-novation/config/types/user-file.types';
import { fetchClientStyles } from '@he-novation/front-shared/async/client.async';
import { asyncWorkspaceUserFilesFetch } from '@he-novation/front-shared/async/workspaceUserFile.async';
import { useAtom, useAtomValue } from 'jotai/index';
import { ClientStyle, clientStyleAtom } from '../atoms/client-atoms';
import { workspaceNameAtom } from '../atoms/workspace-atoms';

type ClientStyleHook = {
    clientStyle: ClientStyle | null;
    setClientStyle: (clientStyle: ClientStyle | null) => void;
};
let isFetching = false;

export function useClientStyle(): ClientStyleHook {
    const [clientStyle, setClientStyle] = useAtom(clientStyleAtom);
    const workspaceName = useAtomValue(workspaceNameAtom);

    useEffect(() => {
        if (!clientStyle && !isFetching) {
            isFetching = true;
            const clientLogoFetch = asyncWorkspaceUserFilesFetch(
                workspaceName,
                UserFileType.CLIENT_LOGO
            ).then((userFiles) => userFiles[0]);

            const clientBackgroundFetch = asyncWorkspaceUserFilesFetch(
                workspaceName,
                UserFileType.CLIENT_BACKGROUND
            ).then((userFiles) => userFiles[0]);

            const clientColorFetch = fetchClientStyles(workspaceName).then(
                (styles) => styles.primary
            );

            Promise.all([clientLogoFetch, clientBackgroundFetch, clientColorFetch]).then(
                ([logo, background, primary]) => {
                    setClientStyle({
                        logo,
                        background,
                        primary
                    });
                }
            );
        }
    }, [clientStyle]);

    return { clientStyle, setClientStyle };
}
