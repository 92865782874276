import { buildApiCall } from '@he-novation/config/apis/api-fetch';
import {
    API_CHECKOUT_TAX_CALCULATION,
    API_SUBSCRIPTION,
    API_SUBSCRIPTION_COUNTRY,
    API_SUBSCRIPTION_INVOICE,
    API_SUBSCRIPTION_PAYMENT_METHOD,
    API_SUBSCRIPTION_PRICE
} from '@he-novation/config/apis/subscription-api';
import type {
    SubscriptionCustomerPayload,
    SubscriptionPayload
} from '@he-novation/config/types/payloads/subscription.payload';
import type { SubscriptionResponse } from '@he-novation/config/types/subscription.types';
import type { Stripe } from '@stripe/stripe-js';

export function asyncListCountries(locale: string) {
    return buildApiCall(API_SUBSCRIPTION_COUNTRY.LIST)({ params: { locale } });
}

export function asyncPaymentMethodFetch(workspaceName: string) {
    return buildApiCall(API_SUBSCRIPTION_PAYMENT_METHOD.GET)({ params: { workspaceName } }).then(
        (r) => ({
            ...r,
            details: r.details
                ? {
                      ...r.details,
                      expiry: new Date(r.details.expiry)
                  }
                : null
        })
    );
}

export function asyncPaymentMethodReplace(workspaceName: string, paymentMethodId: string) {
    return buildApiCall(API_SUBSCRIPTION_PAYMENT_METHOD.PUT)({
        params: { workspaceName },
        body: { paymentMethodId }
    });
}

export function asyncListPrices() {
    return buildApiCall(API_SUBSCRIPTION_PRICE.LIST)({});
}
export function asyncFetchSubscription(workspaceName: string) {
    return buildApiCall(API_SUBSCRIPTION.GET)({ params: { workspaceName } }).then((r) => ({
        ...r,
        expires: r.expires ? new Date(r.expires) : null,
        stripe: r.stripe
            ? {
                  ...r.stripe,
                  schedule: r.stripe.schedule
                      ? {
                            ...r.stripe.schedule,
                            endDate: new Date(r.stripe.schedule.endDate)
                        }
                      : null
              }
            : null
    }));
}

export function asyncSubscriptionCustomerPut(
    workspaceName: string,
    body: SubscriptionCustomerPayload
) {
    return buildApiCall(API_SUBSCRIPTION.STRIPE_CUSTOMER_PUT)({
        params: { workspaceName },
        body
    });
}

export function asyncSubscriptionSetupIntentPost(
    workspaceName: string,
    customerId: string,
    paymentMethodId: string
) {
    return buildApiCall(API_SUBSCRIPTION.STRIPE_SETUP_INTENT_POST)({
        params: { workspaceName },
        body: { customerId, paymentMethodId }
    });
}

export function asyncSubscriptionPut(workspaceName: string, body: SubscriptionPayload) {
    return buildApiCall(API_SUBSCRIPTION.PUT)({ params: { workspaceName }, body });
}

export function asyncPatchSubscriptionPayment(workspaceName: string) {
    return buildApiCall(API_SUBSCRIPTION.STATUS_PATCH)({ params: { workspaceName } });
}

export function asyncCancelSubscription(workspaceName: string) {
    return buildApiCall(API_SUBSCRIPTION.DELETE)({ params: { workspaceName } });
}

export function asyncTaxCalculate(
    items: { amount: number; quantity: number }[],
    countryCode: string
) {
    return buildApiCall(API_CHECKOUT_TAX_CALCULATION.POST)({
        body: { items, countryCode }
    });
}

export function asyncRetryPayment(workspaceName: string) {
    return buildApiCall(API_SUBSCRIPTION.PAYMENT_POST)({ params: { workspaceName } });
}

export async function paymentResponseHandler(
    workspaceName: string,
    r: SubscriptionResponse,
    {
        onSuccess,
        onError,
        onNeedsConfirmation
    }: {
        onSuccess: () => void;
        onError: (message?: string) => void;
        onNeedsConfirmation: () => void;
    },
    stripePromise: Promise<Stripe | null>
) {
    if (r.status === 'succeeded') {
        return onSuccess();
    }
    if (!r.paymentIntent?.clientSecret) {
        return onError();
    }

    const stripe = await stripePromise;
    if (r.paymentIntent && r.status === 'requires_action' && r.paymentIntent.clientSecret) {
        onNeedsConfirmation();

        const { error } = await stripe!.confirmCardPayment(r.paymentIntent.clientSecret);
        if (error && error.message) {
            onError(error.message);
        } else {
            const { status } = await asyncPatchSubscriptionPayment(workspaceName);
            if (status !== 'succeeded') {
                onError(status);
                return;
            }
            onSuccess();
        }
    } else {
        console.error('error', r);
        onError(r.status);
    }
}

export function asyncSubscriptionStatusRefresh(workspaceName: string) {
    return buildApiCall(API_SUBSCRIPTION.STATUS_PATCH)({ params: { workspaceName } });
}

export function asyncCanEnableFreeTrial(workspaceName) {
    return buildApiCall(API_SUBSCRIPTION.FREE_TRIAL_GET)({ query: { workspaceName } });
}

export function asyncSubscriptInvoicesList(workspaceName: string) {
    return buildApiCall(API_SUBSCRIPTION_INVOICE.LIST)({ params: { workspaceName } }).then((r) => {
        return r.map((invoice) => ({
            ...invoice,
            start: new Date(invoice.start),
            end: new Date(invoice.end)
        }));
    });
}
