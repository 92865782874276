import { z } from 'zod';
import { FileStatus } from './db/enums';
import { Asset, assetSchema } from './asset.types';
import { BreadCrumb } from './folder.types';
import { Subtitle } from './subtitle.types';

export type BaseFileType = {
    /**
     * @TJS-format uuid
     */
    uuid: string;
    name: string;
    version: number;
    mimeType?: string | null;
};

export enum FileEnum {
    Audio = 'audio',
    Document = 'document',
    Video = 'video',
    HTML = 'html',
    Image = 'image',
    PDF = 'pdf',
    Office = 'office',
    ThreeDimensionsModel = '3d'
}

export const fileSchema = z.object({
    uuid: z.string(),
    name: z.string(),
    created: z.date().nullable(),
    updated: z.date().nullable(),
    version: z.number(),
    metadata: z.any().nullable(),
    site: z.string(),
    tags: z.string().array(),
    status: z.nativeEnum(FileStatus),
    folder: z.object({
        uuid: z.string(),
        name: z.string()
    })
});
export type File = z.infer<typeof fileSchema>;

export const versionAssetRecapSchema = z.object({
    name: z.string(),
    ffprobeResult: z.any(),
    exifResult: z.any(),
    version: z.number(),
    size: z.number().nullable(),
    notes: z.number(),
    created: z.date(),
    expires: z.date().nullable(),
    mimeType: z.string().nullable(),
    thumbnail: z.string().nullable(),
    thumbnailExpires: z.date().nullable(),
    updating: z.boolean().optional()
});

export type VersionAssetRecap = z.infer<typeof versionAssetRecapSchema>;

export const contentFileSchema = z
    .object({
        folder: z.object({
            uuid: z.string(),
            name: z.string()
        }),
        project: z
            .object({
                uuid: z.string(),
                name: z.string()
            })
            .nullable()
            .optional(),
        assets: z.array(assetSchema),
        creator: z.object({
            uuid: z.string(),
            email: z.string(),
            firstname: z.string().optional().nullable(),
            lastname: z.string().optional().nullable()
        }),
        workspace: z.object({
            uuid: z.string(),
            name: z.string()
        }),
        frameRate: z.number().optional(),
        site: z.string(),
        finals: z.array(
            z.object({
                user: z.object({
                    uuid: z.string(),
                    email: z.string()
                })
            })
        ),
        resolution: z.tuple([z.number(), z.number()]).optional(),
        timecodestart: z.string().optional(),
        metadata: z.any().optional(),
        metadataAle: z.any().optional(),
        duration: z.number().optional(),
        castFiles: z.array(
            z.object({
                uuid: z.string(),
                castUid: z.string(),
                castName: z.string(),
                castFolderUuid: z.string().nullable().optional()
            })
        ),
        notes: z.number(),
        expires: z.date().nullable()
    })
    .merge(fileSchema.omit({ folder: true }));
export type ContentFile = z.infer<typeof contentFileSchema>;

export type FileState = {
    breadcrumb: (BreadCrumb | { name: string })[];
    parent?: string;
    activeAsset?: Asset;
    activeSubtitles: string[];
    isLoaded: boolean;
    comparison: any | null;
    comparisonAsset?: Asset;
    isPlaying?: boolean;
    type?: FileEnum;
    name?: string;
    subtitlesTimeStamp: number;
    error: any | null;
    iframeCapture?: any;
    versionLocked?: boolean;
    timecodestart?: string;
    subtitles: Subtitle[];
    page?: number;
    highlightedVersion?: number;
    width?: number;
    height?: number;
    deletedVersions?: number[];
    version?: number;
    source?: string;
    encryptionRequestToken?: string;
    encryptionAccessToken?: string;
    comparisonSubtitles?: Subtitle[];
    clientName?: string;
    project?: {
        uuid: string;
        name: string;
    } | null;
    description?: string | null;
} & ({ uuid?: undefined; assets?: undefined } | ContentFile);

export const fileFullSchema = contentFileSchema.omit({ folder: true }).extend({
    folder: z.object({
        uuid: z.string(),
        name: z.string(),
        hasWatermark: z.boolean().optional()
    }),
    description: z.string().nullable()
});

export type FileFull = z.infer<typeof fileFullSchema>;

export const fileVotesSchema = z.object({
    voters: z.array(
        z.object({
            uuid: z.string().uuid(),
            email: z.string(),
            created: z.date(),
            requestUser: z
                .object({
                    uuid: z.string().uuid(),
                    email: z.string()
                })
                .nullable()
        })
    ),
    reporters: z.array(
        z.object({
            uuid: z.string().uuid(),
            email: z.string()
        })
    )
});

export type FileVotes = z.infer<typeof fileVotesSchema>;

export type BasicFile = {
    uuid: string;
    name: string;
    created: Date;
    updated: Date | null;
    version: number;
    thumbnail: string | null;
    mimeType: string;
    type: 'basic-file';
    status: FileStatus;
    isLoading: boolean;
    isPlayable: boolean;
    size: number;
    workspaceName: string;
};
