import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PlaylistFile } from '@he-novation/front-shared/types/playlist.front.types';

import combineSelectors from '$helpers/combineSelectors';
import { usePlaylist } from '$hooks/usePlaylist';
import { PubSubEvent, useSubscribe } from '$hooks/useSubscribe';
import { setPage } from '$redux/content/file/fileActions';
import {
    activeAssetSelector,
    fileUuidSelector,
    pageAndPageNumberSelector
} from '$redux/content/file/fileSelectors';

type PlaylistTrackerProps = {
    openItemInPlayer: (file: PlaylistFile) => void;
};

export default function PlaylistTracker({ openItemInPlayer }: PlaylistTrackerProps) {
    const dispatch = useDispatch();

    const { fileUuid, page } = useSelector(
        combineSelectors(fileUuidSelector, pageAndPageNumberSelector)
    );

    const activeAsset = useSelector(activeAssetSelector);

    const { isPlaylistPlaying, playlistContent, setPlaylistCurrentFileUuid, setPlaylistIsPlaying } =
        usePlaylist();

    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
        setActiveIndex(playlistContent.findIndex(({ uuid }) => uuid === fileUuid));
        setPlaylistCurrentFileUuid(fileUuid);
    }, [fileUuid, playlistContent]);

    const playNext = useCallback(
        (index: number) => {
            const currentFile = playlistContent[index];
            const pages = activeAsset?.metadata?.pages || [];

            if (index < playlistContent.length - 1) {
                if (currentFile.mimeType === 'application/pdf' && page < pages.length - 1) {
                    dispatch(setPage(page + 1));
                } else {
                    const target = playlistContent[index + 1];
                    if (target.isPlayable) {
                        openItemInPlayer(target);
                    } else {
                        playNext(index + 1);
                    }
                }
            } else if (currentFile.mimeType === 'application/pdf' && page < pages.length - 1) {
                dispatch(setPage(page + 1));
            } else {
                setPlaylistIsPlaying(false);
            }
        },
        [playlistContent, page]
    );

    useSubscribe(
        PubSubEvent.PlayNextInPlaylist,
        () => {
            if (isPlaylistPlaying) {
                playNext(activeIndex);
            }
        },
        [isPlaylistPlaying, activeIndex]
    );

    return null;
}
