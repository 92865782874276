import { ROLES } from '../constants/projects.constants';
import { ClientUserRole, FolderRole } from '../types/db/enums';
import { WorkspaceFeature } from '../types/workspace.types';

export const ROLE_MANAGER = FolderRole.ROLE_MANAGER;
export const ROLE_REPORTER = FolderRole.ROLE_REPORTER;
export const ROLE_PROVIDER = FolderRole.ROLE_PROVIDER;
export const ROLE_SPECTATOR = FolderRole.ROLE_SPECTATOR;

function isRoleIn(role: FolderRole, subset: readonly FolderRole[]): boolean {
    return subset.includes(role);
}

export const acl = {
    player: {
        show: (role?: FolderRole | null) =>
            !!role &&
            [ROLE_MANAGER, ROLE_PROVIDER, ROLE_REPORTER, ROLE_SPECTATOR].indexOf(role) !== -1
    },

    content: {
        showRoles: () => [ROLE_MANAGER, ROLE_PROVIDER, ROLE_REPORTER, ROLE_SPECTATOR]
    },

    subtitles: {
        manageAll: (role?: FolderRole | null) =>
            !!role && isRoleIn(role, [ROLE_MANAGER, ROLE_PROVIDER, ROLE_REPORTER]),
        manageOwn: (role?: FolderRole | null) =>
            !!role && isRoleIn(role, [ROLE_MANAGER, ROLE_PROVIDER, ROLE_REPORTER]),
        deleteAll: (role?: FolderRole | null) => !!role && isRoleIn(role, [ROLE_MANAGER]),
        deleteOwn: (role?: FolderRole | null) =>
            !!role && isRoleIn(role, [ROLE_MANAGER, ROLE_PROVIDER]),
        uploadExternal: (role?: FolderRole | null) =>
            !!role && isRoleIn(role, [ROLE_MANAGER, ROLE_PROVIDER]),
        generate: (role?: FolderRole | null) =>
            !!role && isRoleIn(role, [ROLE_MANAGER, ROLE_PROVIDER])
    },

    comments: {
        show: (role?: FolderRole) =>
            !!role && isRoleIn(role, [ROLE_MANAGER, ROLE_PROVIDER, ROLE_REPORTER]),

        add: (role?: FolderRole) =>
            !!role && isRoleIn(role, [ROLE_MANAGER, ROLE_PROVIDER, ROLE_REPORTER]),

        modify: (role: FolderRole) => isRoleIn(role, [ROLE_MANAGER, ROLE_PROVIDER, ROLE_REPORTER]),

        delete: (userUuid: string, authorUuid: string | undefined, userRole: FolderRole) =>
            userUuid === authorUuid || isRoleIn(userRole, [ROLE_MANAGER])
    },

    members: {
        show: (role: FolderRole) => isRoleIn(role, [ROLE_MANAGER, ROLE_PROVIDER, ROLE_REPORTER]),

        add: (role: FolderRole) => isRoleIn(role, [ROLE_MANAGER]),

        modify: (role: FolderRole) => isRoleIn(role, [ROLE_MANAGER]),

        del: (role: FolderRole) => isRoleIn(role, [ROLE_MANAGER])
    },

    files: {
        add: (role?: FolderRole | null) => !!role && isRoleIn(role, [ROLE_MANAGER, ROLE_PROVIDER]),

        modify: (role: FolderRole | null) =>
            !!role && isRoleIn(role, [ROLE_MANAGER, ROLE_PROVIDER]),

        setFinal: (role?: FolderRole | null) => !!role && isRoleIn(role, [ROLE_MANAGER]),

        setStatus: (role?: FolderRole | null) =>
            role && isRoleIn(role, [ROLE_MANAGER, ROLE_REPORTER, ROLE_PROVIDER]),

        voteFinal: (role?: FolderRole | null) =>
            role && isRoleIn(role, [ROLE_PROVIDER, ROLE_REPORTER]),

        move: (role: FolderRole) => isRoleIn(role, [ROLE_MANAGER, ROLE_PROVIDER]),

        setInfos: (role?: FolderRole) => role && isRoleIn(role, [ROLE_MANAGER, ROLE_PROVIDER]),
        del: (role?: FolderRole | null) => !!role && isRoleIn(role, [ROLE_MANAGER]),

        sendEmail: (role: FolderRole) => isRoleIn(role, [ROLE_MANAGER]),

        viewVersions: (role?: FolderRole) =>
            !!role && isRoleIn(role, [ROLE_MANAGER, ROLE_PROVIDER, ROLE_REPORTER])
    },

    folders: {
        add: (role?: FolderRole | null) => !!role && isRoleIn(role, [ROLE_MANAGER, ROLE_PROVIDER]),

        cast: (role?: FolderRole | null) => !!role && isRoleIn(role, [ROLE_MANAGER]),

        modify: (role?: FolderRole | null) =>
            !!role && isRoleIn(role, [ROLE_MANAGER, ROLE_PROVIDER]),

        modifyRoles: (role: FolderRole) => isRoleIn(role, [ROLE_MANAGER]),

        move: (role: FolderRole) => isRoleIn(role, [ROLE_MANAGER, ROLE_PROVIDER]),

        moveRoles: () => [ROLE_MANAGER, ROLE_PROVIDER],

        copyTree: (role: FolderRole) => isRoleIn(role, [ROLE_MANAGER]),

        del: (role?: FolderRole | null) => !!role && isRoleIn(role, [ROLE_MANAGER]),

        rename: (role?: FolderRole | null) =>
            !!role && isRoleIn(role, [ROLE_MANAGER, ROLE_PROVIDER]),

        castSync: (role: FolderRole) => isRoleIn(role, [ROLE_MANAGER]),

        sendEmail: (role: FolderRole) => isRoleIn(role, [ROLE_MANAGER]),
        share: (role: FolderRole) => isRoleIn(role, [ROLE_MANAGER])
    },

    logs: {
        show: (role: FolderRole) => isRoleIn(role, [ROLE_MANAGER, ROLE_PROVIDER, ROLE_REPORTER])
    },

    rights: {
        all: (role: FolderRole) => isRoleIn(role, [ROLE_MANAGER])
    },

    projects: {
        addEvent: (role?: ROLES | null) => role && [ROLES.MANAGER, ROLES.PROVIDER].includes(role),

        statistics: (role: ROLES) => ROLES.MANAGER === role,

        members: {
            show: (role: ROLES) => [ROLES.MANAGER, ROLES.PROVIDER, ROLES.REPORTER].includes(role)
        }
    },

    plugin: {
        free: (): FolderRole[] => [],

        basic: () => [ROLE_REPORTER],

        basicDefault: () => ROLE_REPORTER,

        premium: () => [ROLE_MANAGER, ROLE_PROVIDER, ROLE_REPORTER, ROLE_SPECTATOR],

        premiumDefault: () => ROLE_REPORTER
    }
};

export const featureACL = {
    api: (features: WorkspaceFeature[]) => features.includes(WorkspaceFeature.API),

    avid: (features: WorkspaceFeature[]) =>
        features.includes(WorkspaceFeature.Projects) || features.includes(WorkspaceFeature.DAM),

    calendar: (features: WorkspaceFeature[]) => features.includes(WorkspaceFeature.Calendar),

    customFields: (features: WorkspaceFeature[]) =>
        features.includes(WorkspaceFeature.CustomFields),

    files: (features: WorkspaceFeature[]) => features.includes(WorkspaceFeature.DAM),

    folders: (features: WorkspaceFeature[]) => features.includes(WorkspaceFeature.DAM),

    projects: (features: WorkspaceFeature[]) =>
        features.includes(WorkspaceFeature.Projects) ||
        features.includes(WorkspaceFeature.Calendar),

    projectFolders: (features: WorkspaceFeature[]) => features.includes(WorkspaceFeature.Projects),

    tasks: (features: WorkspaceFeature[]) => features.includes(WorkspaceFeature.Projects),

    entities: (features: WorkspaceFeature[]) =>
        features.includes(WorkspaceFeature.Projects) ||
        features.includes(WorkspaceFeature.Calendar),

    items: (features: WorkspaceFeature[]) => features.includes(WorkspaceFeature.Calendar),

    subtitles: {
        edit: (features: WorkspaceFeature[]) => features.includes(WorkspaceFeature.Projects)
    },

    admittance: (features: WorkspaceFeature[]) =>
        features.includes(WorkspaceFeature.DAM) && features.includes(WorkspaceFeature.Security),

    settings: {
        passwordConstraints: (features: WorkspaceFeature[]) =>
            features.includes(WorkspaceFeature.Security),
        sso: (features: WorkspaceFeature[]) => features.includes(WorkspaceFeature.Security)
    },

    watermark: (features: WorkspaceFeature[]) => features.includes(WorkspaceFeature.Security),

    casts: {
        design: (features: WorkspaceFeature[]) => features.includes(WorkspaceFeature.Customization),
        password: (features: WorkspaceFeature[]) => features.includes(WorkspaceFeature.Security)
    }
};

export const workspaceAcl = {
    labels: {
        manage: [ClientUserRole.ADMINISTRATOR, ClientUserRole.USER] as readonly ClientUserRole[]
    },
    workspaces: {
        manage: [ClientUserRole.ADMINISTRATOR] as readonly ClientUserRole[],
        memberAdd: [ClientUserRole.ADMINISTRATOR, ClientUserRole.USER] as readonly ClientUserRole[]
    }
} as const;
