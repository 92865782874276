import { sanitizedString } from '@he-novation/utils/zod.utils';
import { z } from 'zod';
import { TaskStatus } from '../db/enums';
import { oaAssetSchema } from './asset.open-api.types';

const oaCommentSchema = z.object({
    uuid: z.string().uuid(),
    content: z.string(),
    draft: z.boolean(),
    isImportant: z.boolean(),
    created: z.date(),
    updated: z.date().nullable(),
    tags: z.array(z.string()),
    postedAs: z.string().email().nullable().optional(),
    user: z.object({
        email: z.string().email(),
        uuid: z.string().uuid().optional()
    }),
    assets: z.array(oaAssetSchema),
    note: z.object({
        uuid: z.string().uuid()
    }),
    file: z.object({
        uuid: z.string().uuid(),
        name: z.string(),
        version: z.number()
    })
});

export type OAComment = z.infer<typeof oaCommentSchema>;

export const oaNoteSchema = z.object({
    uuid: z.string().uuid(),
    created: z.date(),
    updated: z.date().nullable(),
    type: z.string(),
    metadata: z.any(),
    assets: z.array(oaAssetSchema),
    user: z
        .object({
            email: z.string().email(),
            uuid: z.string().uuid().optional()
        })
        .nullable(),
    task: z
        .object({
            uuid: z.string().uuid(),
            description: z.string(),
            status: z.nativeEnum(TaskStatus),
            number: z.number().nullable(),
            ordering: z.number().nullable()
        })
        .nullable()
        .optional(),
    team: z
        .object({
            name: z.string(),
            uuid: z.string().uuid()
        })
        .nullable(),
    comments: z.array(oaCommentSchema.omit({ note: true, file: true })),
    file: z.object({
        uuid: z.string().uuid(),
        name: z.string(),
        version: z.number()
    }),
    cast: z
        .object({
            uuid: z.string().uuid(),
            name: z.string()
        })
        .nullable()
        .optional()
});

export type OANote = z.infer<typeof oaNoteSchema>;

export const oaNoteCreateBody = z.object({
    type: z.enum(['areaselection', 'timecode', 'global', 'sequence', 'rectangle', 'page']),
    tags: z.array(z.string()).optional(),
    content: sanitizedString({ html: true })
});
export type OANoteCreateBody = z.infer<typeof oaNoteCreateBody>;

export const oaNoteSpecificParams = z.object({
    noteUuid: z.string().uuid()
});

export type OANoteSpecificParams = z.infer<typeof oaNoteSpecificParams>;
export const oaCommentCreateBody = z.object({
    content: sanitizedString({ html: true }),
    tags: z.array(z.string()).nullable().optional(),
    castFileUuid: z.string().uuid().optional()
});

export type OACommentCreateBody = z.infer<typeof oaCommentCreateBody>;
