import { ApiMethod } from '../types/api.types';
import {
    transcoderSpecificSchema,
    transcoderStartBody
} from '../types/payloads/transcoder.payloads';

export const API_TRANSCODER = {
    LIST: {
        method: ApiMethod.GET,
        path: '/transcoders',
        handlerMethod: 'findTranscoders' as 'findTranscoders',
        superAdminOnly: true
    },
    POST: {
        method: ApiMethod.POST,
        path: '/transcoders',
        handlerMethod: 'startTranscoderInstance' as 'startTranscoderInstance',
        superAdminOnly: true,
        zod: {
            body: transcoderStartBody
        }
    },
    DELETE: {
        method: ApiMethod.DELETE,
        path: '/transcoders/:transcoderUuid',
        handlerMethod: 'stopTranscoderInstance' as 'stopTranscoderInstance',
        superAdminOnly: true,
        zod: {
            params: transcoderSpecificSchema
        }
    },
    PID: {
        method: ApiMethod.GET,
        path: '/transcoder-pid',
        handlerMethod: 'findPIDData' as 'findPIDData',
        superAdminOnly: true
    }
};

export const API_TRANSCODER_UNASSIGNED_TASKS = {
    LIST: {
        method: ApiMethod.GET,
        path: '/transcoder-unassigned-tasks',
        handlerMethod: 'findUnassignedTasks' as 'findUnassignedTasks',
        superAdminOnly: true
    }
};

export const API_TRANSCODER_AVAILABLE_CLASSES = {
    LIST: {
        method: ApiMethod.GET,
        path: '/transcoder-available-classes',
        handlerMethod: 'findAvailableTranscoderClasses' as 'findAvailableTranscoderClasses',
        superAdminOnly: true
    }
};
