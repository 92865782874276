export enum AreaSelectionType {
    Freehand = 'freehand',
    Rectangle = 'rectangle',
    Circle = 'circle'
}
export type AreaSelectionCircle = {
    x: number;
    y: number;
    radiusToWidth: number;
    radiusToHeight: number;
};

export type AreaSelectionRectangle = { x: number; y: number; width: number; height: number };

export type AreaSelectionFreehand = { x: number; y: number }[];
export type AreaSelectionData = {
    rectangle: AreaSelectionRectangle;
    freehand?: AreaSelectionFreehand;
    circle?: AreaSelectionCircle;
};
export type AreaSelectionOutputData = {
    rectangle: AreaSelectionRectangle;
    freehand?: AreaSelectionFreehand[];
    circle?: AreaSelectionCircle[];
};
