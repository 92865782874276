import { Group } from '../../paths/herawApiPathGroups';
import { ApiMethod } from '../../types/api.types';
import { oaUserSchema } from '../../types/open-api/user.open-api.types';
import {
    authLoginPayload,
    authPasswordContraintsPayload,
    authPasswordResetPayload
} from '../../types/payloads/auth.payload';
import { emailSchema } from '../../types/payloads/user.payload';
import { passwordConstraintsSchema } from '../../types/payloads/workspace.payload';

export const API_AUTH_PUBLIC = {
    GET: {
        method: ApiMethod.GET,
        path: '/auth/session',
        handlerMethod: 'refreshAccessToken',
        passResAndNextToHandler: true,
        isPublic: true
    },
    POST: {
        method: ApiMethod.POST,
        isPublic: true,
        path: '/auth/session',
        handlerMethod: 'login',
        passResAndNextToHandler: true,
        zod: {
            body: authLoginPayload
        }
    }
} as const;

export const API_AUTH_PUBLIC_PASSWORD = {
    RESET: {
        method: ApiMethod.PUT,
        path: '/auth/password/reset',
        passResAndNextToHandler: true,
        handlerMethod: 'resetPassword',
        isPublic: true,
        zod: {
            body: authPasswordResetPayload
        }
    },
    TOKEN_POST: {
        method: ApiMethod.POST,
        path: '/auth/password/token',
        handlerMethod: 'passwordTokenCreate',
        isPublic: true,
        passResAndNextToHandler: true,
        zod: {
            body: emailSchema
        }
    },
    CONSTRAINTS_GET: {
        method: ApiMethod.GET,
        path: '/auth/password/constraints',
        handlerMethod: 'getPasswordConstraints',
        isPublic: true,
        zod: {
            query: authPasswordContraintsPayload
        },
        responses: {
            200: {
                description: 'Password constraints',
                schema: passwordConstraintsSchema
            }
        }
    }
} as const;

export const API_AUTH_PUBLIC_USER = {
    // uses a POST request to avoid logging sensitive data in the URL
    //@deprecated should be a client_user route
    GET_POST_OA_DEPRECATED: {
        method: ApiMethod.POST,
        publicApi: 'deprecated',
        path: '/public/v1/auth/user',
        handlerMethod: 'getOpenApiUser',
        passResAndNextToHandler: true,
        description: 'Fetches user from email and password',
        zod: {
            body: authLoginPayload
        },
        isPublic: true,
        group: Group.Users,
        responses: {
            200: {
                description: 'User infos',
                schema: oaUserSchema
            }
        }
    }
} as const;
