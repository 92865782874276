import styles from './ClientStyleForm.module.css';
import React, { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { UserFileType } from '@he-novation/config/types/user-file.types';
import { Button, ButtonTone } from '@he-novation/design-system/components/buttons/Button/Button';
import { FormField } from '@he-novation/design-system/components/form/FormField/FormField';
import { updateClientStyles } from '@he-novation/front-shared/async/client.async';
import {
    asyncWorkspaceUserFileCreate,
    asyncWorkspaceUserFileDelete
} from '@he-novation/front-shared/async/workspaceUserFile.async';
import { useAtomValue } from 'jotai';
import { workspaceNameAtom } from '../../../../atoms/workspace-atoms';
import { ClientLoginPreview } from './ClientLoginPreview';

import { useClientStyle } from '$hooks/useClientStyle';
import { useTranslate } from '$hooks/useTranslate';

function getBase64Image(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            if (typeof reader.result === 'string') {
                return resolve(reader.result);
            } else {
                reject('Invalid format');
            }
        };
        reader.onerror = function (error) {
            reject(error);
        };
    });
}

export function ClientStyleForm() {
    const { t } = useTranslate();
    const { clientStyle, setClientStyle } = useClientStyle();

    const [submitting, setSubmitting] = useState<boolean>(false);

    const [color, setColor] = useState<string | undefined>(undefined);
    const [logo, setLogo] = useState<string | null>(null);
    const [background, setBackground] = useState<string | null>(null);

    const [logoFile, setLogoFile] = useState<File | null>(null);
    const [backgroundFile, setBackgroundFile] = useState<File | null>(null);
    const workspaceName = useAtomValue(workspaceNameAtom);

    const onLogoAdd = useCallback(
        async (file: File) => {
            const base64Image = await getBase64Image(file);
            setLogo(base64Image);
            setLogoFile(file);
        },
        [clientStyle]
    );

    const onBackgroundAdd = useCallback(
        async (file: File) => {
            const base64Image = await getBase64Image(file);
            setBackground(base64Image);
            setBackgroundFile(file);
        },
        [clientStyle]
    );

    const onColorChange = useCallback(
        (e: SyntheticEvent<HTMLInputElement>) => {
            setColor(e.currentTarget.value);
        },
        [clientStyle]
    );

    useEffect(() => {
        if (clientStyle) {
            if (clientStyle.primary) {
                setColor(clientStyle.primary);
            }

            if (clientStyle.logo) {
                setLogo(clientStyle.logo.url);
            }

            if (clientStyle.background) {
                setBackground(clientStyle.background.url);
            }
        }
    }, [clientStyle]);

    const onSave = useCallback(
        async (e) => {
            e.preventDefault();
            setSubmitting(true);

            let clientLogo = clientStyle?.logo;
            let clientBackground = clientStyle?.background;
            let clientPrimary = clientStyle?.primary;

            if (logoFile) {
                if (clientStyle?.logo) {
                    await asyncWorkspaceUserFileDelete(workspaceName, clientStyle.logo.uid);
                }

                clientLogo = await asyncWorkspaceUserFileCreate(
                    workspaceName,
                    UserFileType.CLIENT_LOGO,
                    logoFile
                );
            }

            if (backgroundFile) {
                if (clientStyle?.background) {
                    await asyncWorkspaceUserFileDelete(workspaceName, clientStyle.background.uid);
                }

                clientBackground = await asyncWorkspaceUserFileCreate(
                    workspaceName,
                    UserFileType.CLIENT_BACKGROUND,
                    backgroundFile
                );
            }

            if (color) {
                await updateClientStyles(workspaceName, { primary: color });
                clientPrimary = color;
            }

            setClientStyle({
                background: clientBackground,
                logo: clientLogo,
                primary: clientPrimary
            });

            setSubmitting(false);
        },
        [clientStyle, logoFile, backgroundFile, color]
    );

    const fieldId = (field: string) => 'settings-client-ui-' + field;

    return (
        <>
            <ClientLoginPreview
                className={styles.loginPreview}
                backgroundColor={color}
                background={background}
                logo={logo}
            />

            <div className={styles.form}>
                <FormField
                    className={styles.logo}
                    name="client-logo"
                    id={fieldId('client-logo')}
                    label={t('misc.Logo')}
                    type={'graphic-picker'}
                    value={{ uid: 'client-logo', url: logo }}
                    values={[]}
                    disabled={submitting}
                    onAdd={onLogoAdd}
                />

                <FormField
                    className={styles.background}
                    name="client-background"
                    id={fieldId('client-background')}
                    label={t('misc.Background')}
                    type={'graphic-picker'}
                    value={{ uid: 'client-background', url: background }}
                    values={[]}
                    onAdd={onBackgroundAdd}
                    disabled={submitting}
                    noTopMargin={true}
                />

                <FormField
                    className={styles.color}
                    name="client-color"
                    id={fieldId('client-color')}
                    label={t('common.Color')}
                    type="color-picker"
                    value={color}
                    onChange={onColorChange}
                    disabled={submitting}
                    noTopMargin={true}
                />
            </div>

            <Button
                tone={ButtonTone.Primary}
                isLoading={submitting}
                className={styles.save}
                onClick={onSave}
            >
                {t('common.Save')}
            </Button>
        </>
    );
}
