import styles from './HeaderWrapper.module.css';
import React from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import { usePanel } from '$hooks/usePanel';
import { routeSelector } from '$redux/route/routeSelectors';

export function HeaderWrapper() {
    const { panel } = usePanel();

    const { route } = useSelector(routeSelector);

    const isPlayer = (route as string).includes('/player/');
    const isProject = (route as string).includes('/project/');

    return (
        <header
            id="header-wrapper"
            className={cn(styles.headerWrapper, isPlayer && styles.isPlayer)}
        >
            <div id="header" className={styles.header} />
            <div
                id="sub-header"
                className={cn(styles.subHeader, {
                    [styles.hasOpenPanel]: panel,
                    ['has-open-panel']: panel,
                    [styles.subProject]: !isPlayer && isProject
                })}
            />
        </header>
    );
}
