import { Asset } from '@he-novation/config/types/asset.types';
import { FileStatus } from '@he-novation/config/types/db/enums';
import { FileEnum, FileFull } from '@he-novation/config/types/file.types';
import { getOfficeType } from '@he-novation/utils/mimeType.utils';
import mapTimeUpdate from '../../../content/file/maps/mapTimeUpdate';

function findAssetData({ assets, version }: { assets: Asset[]; version: number }) {
    const sourceAsset = assets.find((a) => a.quality === 'source' && a.version === version)!;
    return {
        exifResult: sourceAsset.exifResult,
        ffprobeResult: sourceAsset.ffprobeResult
    };
}
const mapFetchResponseToPDF = ({ assets, version }: { assets: Asset[]; version: number }) => {
    const { exifResult: { ImageHeight, ImageWidth, PageCount } = {} } = findAssetData({
        assets,
        version
    });
    return {
        type: FileEnum.PDF,
        pageNumber: PageCount,
        height: ImageHeight,
        width: ImageWidth
    };
};

const mapFetchResponseToVideo = ({ metadata, assets, version, duration }: FileFull) => {
    const {
        exifResult: { ImageHeight, ImageWidth },
        ffprobeResult
    } = findAssetData({
        assets,
        version
    });
    const frameRate = ffprobeResult?.FrameRate ? parseFloat(ffprobeResult.FrameRate) : 25;
    const timecodestart = ffprobeResult?.timecodestart;
    // height and width will be temporarily set and overriden by fetch assets
    const v = ffprobeResult?.VideoSize
        ? ffprobeResult.VideoSize.split('x').map((v) => parseInt(v))
        : [ImageWidth, ImageHeight];
    return {
        type: FileEnum.Video,
        frameRate,
        height: v[1],
        width: v[0],
        timecodestart: metadata?.timecodestart || timecodestart,
        duration,
        ...mapTimeUpdate(0, frameRate, metadata?.timecodestart || timecodestart)
    };
};

const mapFetchResponseToAudio = ({ assets, version }: FileFull) => {
    const { exifResult: { Duration } = {} } = findAssetData({ assets, version });
    return {
        type: FileEnum.Audio,
        duration: Duration
    };
};

const mapFetchResponseToImage = ({ assets, version }: FileFull) => {
    const { exifResult: { ImageHeight, ImageWidth } = {} } = findAssetData({ assets, version });
    return {
        type: FileEnum.Image,
        height: ImageHeight,
        width: ImageWidth
    };
};

const mapFetchResponseToOffice = () => ({
    type: FileEnum.Office
});

const mapFetchResponseTo3dModel = () => ({
    type: FileEnum.ThreeDimensionsModel
});

const mapFetchResponseToHtmlBundle = ({ uuid, version }: FileFull) => ({
    type: FileEnum.HTML,
    source: `/proxy/bundles/html/${uuid}/${version}/index.html`
});

export const mapFetchFileToUpdate = (response: FileFull) => {
    const file = {
        versionLocked: response.status === FileStatus.final,
        name: response.name,
        folderUuid: response.folder.uuid,
        uuid: response.uuid,
        version: response.version,
        assets: response.assets,
        highlightedVersion: response.version,
        page: 0,
        metadata: response.metadata,
        parent: response.folder.uuid,
        project: response.project,
        clientName: response.workspace.name,
        finals: response.finals,
        status: response.status,
        thumbnail: response.assets.find(
            (a) => a.type === 'thumbnail' && a.url && a.version === response.version
        )?.url,
        description: response.description,
        tags: response.tags,
        created: response.created,
        updated: response.updated
    };

    if (!response.metadata) response.metadata = null;
    else if (!response.metadata.timecodestart) response.metadata.timecodestart = null;

    const sourceAsset = response.assets.find(
        (a) => a.quality === 'source' && a.version === response.version
    )!;
    if (!sourceAsset.mimeType) sourceAsset.mimeType = '';

    if (
        sourceAsset.mimeType.indexOf('pdf') > -1 ||
        sourceAsset.mimeType === 'application/vnd.adobe.illustrator'
    )
        return { ...file, ...mapFetchResponseToPDF(response) };

    if (sourceAsset.mimeType === 'image/gif') {
        // if no metadata type, it's a legacy asset that's been trancoded to a video
        if (!sourceAsset.metadata?.type || sourceAsset.metadata.type === 'video') {
            return { ...file, ...mapFetchResponseToVideo(response) };
        } else {
            return { ...file, ...mapFetchResponseToImage(response) };
        }
    }
    if (
        sourceAsset.mimeType.indexOf('video') > -1 ||
        sourceAsset.mimeType.indexOf('application/mxf') > -1
    )
        return { ...file, ...mapFetchResponseToVideo(response) };

    if (sourceAsset.mimeType.indexOf('audio') > -1) {
        return { ...file, ...mapFetchResponseToAudio(response) };
    }

    if (
        sourceAsset.mimeType.indexOf('image') > -1 ||
        sourceAsset.mimeType === 'application/vnd.adobe.photoshop' ||
        (sourceAsset.mimeType === 'application/postscript' && file.name.endsWith('.ai'))
    )
        return { ...file, ...mapFetchResponseToImage(response) };

    const playerAsset = response.assets.find(
        (a) => a.type === 'player' && a.version === response.version
    );

    if (
        /^bundle\/html/.test(sourceAsset.mimeType) ||
        (playerAsset && playerAsset.mimeType === 'text/html' && playerAsset.metadata?.bundle)
    )
        return { ...file, ...mapFetchResponseToHtmlBundle(response) };

    if (getOfficeType(response.name)) return { ...file, ...mapFetchResponseToOffice() };

    if (sourceAsset.mimeType.indexOf('model') > -1) {
        return { ...file, ...mapFetchResponseTo3dModel() };
    }

    throw new Error(`Unknown file type: ${sourceAsset.mimeType}`);
};
