import { z } from 'zod';
import { pictureAssetSchema } from './asset.types';

export const basicEntitySchema = z.object({
    uuid: z.string().uuid(),
    name: z.string(),
    color: z.string().nullable()
});

export type BasicEntity = z.infer<typeof basicEntitySchema>;

export const entitySchema = basicEntitySchema.extend({
    phone: z.string().nullable().optional(),
    address: z.object({
        street: z.string().nullable().optional(),
        city: z.string().nullable().optional(),
        country: z.string().nullable().optional(),
        zipCode: z.string().nullable().optional()
    }),
    pictureAsset: pictureAssetSchema.nullable().optional()
});

export type Entity = z.infer<typeof entitySchema>;
