import {
    API_FOLDER,
    API_FOLDER_CONTENT,
    API_FOLDER_TEAMS,
    API_FOLDER_USER,
    API_TRASH
} from '@he-novation/config/apis/folder-api';
import type { BasicFile } from '@he-novation/config/types/file.types';
import {
    type BasicFolder,
    BreadCrumb,
    FolderMember,
    FolderSizeData,
    FullFolder
} from '@he-novation/config/types/folder.types';
import {
    FolderMemberPayload,
    FolderModifyAccessBody,
    FolderUpdateBody
} from '@he-novation/config/types/payloads/folder.payload';
import { buildApiCall } from '../../config/apis/api-fetch';
import { apiFolderContentToFrontFolderContent } from '../mappers/folder.mappers';
import { FrontFolderContent } from '../types/folder.front-types';

export const createFolder = (
    workspaceName: string,
    {
        folderUuid,
        name,
        copyParentProperties,
        teamUuids
    }: {
        folderUuid?: string;
        name: string;
        copyParentProperties?: boolean;
        teamUuids?: string[];
    }
): Promise<FullFolder> =>
    buildApiCall(API_FOLDER.POST)({
        params: {
            workspaceName
        },
        body: {
            name,
            parentFolderUuid: folderUuid,
            copyParentProperties,
            copyParentMembers: true,
            copyParentMembersPending: true,
            teamUuids
        }
    });

export const fetchFolder = (
    workspaceName: string,
    {
        uuid
    }: {
        uuid: string;
    },
    options?: {
        signal: AbortSignal;
    }
): Promise<FullFolder> =>
    buildApiCall(API_FOLDER.GET)({
        params: {
            workspaceName,
            folderUuid: uuid
        },
        options
    });

export const fetchFolderContent = (
    workspaceName: string,
    folderUuid: string | null,
    options?: { signal: AbortSignal; basic?: boolean }
): Promise<FrontFolderContent> =>
    buildApiCall(API_FOLDER_CONTENT.GET)({
        params: { workspaceName, folderUuid: folderUuid ? folderUuid : undefined },
        query: { basic: options?.basic },
        options
    }).then(apiFolderContentToFrontFolderContent);

export function fetchFolderBasicContent(
    workspaceName: string,
    uuid: string | null,
    options?: { signal: AbortSignal }
) {
    return buildApiCall(API_FOLDER_CONTENT.GET)({
        params: { workspaceName, folderUuid: uuid ? uuid : undefined },
        query: { basic: true },
        options
    }) as Promise<(BasicFile | BasicFolder)[]>;
}

export const asyncFolderUpdate = (
    workspaceName: string,
    folderUuid: string,
    body: FolderUpdateBody
) =>
    buildApiCall(API_FOLDER.PATCH)({
        params: { workspaceName, folderUuid },
        body
    });

export const asyncFolderMembersFetch = (
    workspaceName: string,
    folderUuid: string,
    pending?: boolean
): Promise<FolderMember[]> =>
    buildApiCall(API_FOLDER_USER.LIST)({
        params: { workspaceName, folderUuid },
        query: { pending }
    }).then((r) =>
        r.map((m) => ({
            ...m,
            download: m.canDownload,
            export: m.canExport,
            created: new Date(m.created)
        }))
    );

export const fetchFolderSize = (
    workspaceName: string,
    folderUuid: string,
    {
        password,
        flat
    }: {
        password?: string;
        flat?: boolean;
    } = {}
): Promise<FolderSizeData> =>
    buildApiCall(API_FOLDER.SIZE_GET)({
        params: { workspaceName, folderUuid },
        query: { password, flat }
    });

export const grantAccess = (
    workspaceName: string,
    folderUuid: string,
    members: FolderMemberPayload[],
    message?: string | null
) =>
    buildApiCall(API_FOLDER_USER.POST)({
        params: { workspaceName, folderUuid },
        body: {
            members,
            message
        }
    });

export const revokeAccess = (workspaceName: string, folderUuid: string, userUuid: string) =>
    buildApiCall(API_FOLDER_USER.DELETE)({
        params: { workspaceName, folderUuid, userUuid }
    });

export const asyncFolderTeamsFetch = (workspaceName: string, folderUuid: string) =>
    buildApiCall(API_FOLDER_TEAMS.LIST)({
        params: { workspaceName, folderUuid }
    });

export const fetchFolderPath = (workspaceName: string, folderUuid: string): Promise<BreadCrumb[]> =>
    buildApiCall(API_FOLDER.PATH_GET)({
        params: { workspaceName, folderUuid }
    });

export const asyncSetFolderTeams = (
    workspaceName: string,
    folderUuid: string,
    teamUuids: string[]
) =>
    buildApiCall(API_FOLDER_TEAMS.PUT)({
        params: { workspaceName, folderUuid },
        body: { teamUuids }
    });

export const modifyAccess = (
    workspaceName: string,
    folderUuid: string,
    userUuid: string,
    { role, canDownload }: FolderModifyAccessBody
) =>
    buildApiCall(API_FOLDER_USER.PATCH)({
        params: { workspaceName, folderUuid, userUuid },
        body: { role, canDownload }
    });

export const folderRecursiveSettingsSet = (
    workspaceName: string,
    {
        public_download,
        public_password,
        export_mode,
        default_presets,
        uuid,
        tags,
        labels,
        ...folder
    }
) => {
    return buildApiCall(API_FOLDER.RECURSIVE_PATCH)({
        params: { workspaceName, folderUuid: uuid },
        body: {
            folder: {
                ...folder,
                tags: tags ? (Array.isArray(tags) ? tags : tags.split(',').filter((v) => v)) : [],
                labels: labels
                    ? Array.isArray(labels)
                        ? labels
                        : labels.split(',').filter((v) => v)
                    : [],
                default_presets,
                public_download,
                public_password,
                export_mode
            }
        }
    });
};

export const asyncFolderCopyTree = (
    workspaceName: string,
    sourceFolderUuid: string,
    targetFolderUuid: string,
    { name, renameIfExists }: { name?: string; renameIfExists?: boolean } = {}
) =>
    buildApiCall(API_FOLDER.COPY_TREE)({
        params: { workspaceName },
        body: {
            sourceFolderUuid,
            targetFolderUuid,
            name,
            renameIfExists
        }
    });

export const folderMove = (workspaceName: string, sourceUuid: string, destinationUuid: string) =>
    buildApiCall(API_FOLDER.MOVE)({
        params: {
            workspaceName,
            sourceFolderUuid: sourceUuid,
            targetFolderUuid: destinationUuid
        }
    });

export const asyncFolderBulkDelete = (
    workspaceName: string,
    parentUuid: string | null,
    uuids: string[]
) =>
    buildApiCall(API_FOLDER.BULK_DELETE)({
        params: {
            workspaceName
        },
        body: {
            parentUuid,
            uuids
        }
    });

export const folderTrashFetch = (workspaceName: string) =>
    buildApiCall(API_TRASH.GET)({
        params: {
            workspaceName
        }
    });

export const asyncFolderRestore = (workspaceName: string, folderUuid: string) =>
    buildApiCall(API_FOLDER.RESTORE)({
        params: {
            workspaceName,
            folderUuid
        }
    });
