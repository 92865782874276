import { CalendarZoom } from '@he-novation/config/types/calendar.types';
import { ProjectsStateCurrentProject } from '@he-novation/config/types/project.types';
import { atom } from 'jotai';

import { FilterName, FilterValueId } from '$components/Filters/FilterCheckboxes';
import {
    CalendarFilterEnum,
    CalendarItemColorType,
    GlobalRowsType,
    HerawEventWithOptionalUuid,
    type QuickEventPayload,
    ScrollCalendarGrouper,
    ScrollCalendarItemType
} from '$components/ScrollCalendar/ScrollCalendar.types';

export const calendarZoomAtom = atom<CalendarZoom>(CalendarZoom.Weeks);
export const calendarGrouperAtom = atom<ScrollCalendarGrouper>(ScrollCalendarGrouper.Users);
export const calendarActiveFiltersAtom = atom<Record<FilterName, FilterValueId[]>>({
    [CalendarFilterEnum.Teams]: [],
    [CalendarFilterEnum.Labels]: [],
    [CalendarFilterEnum.Authors]: []
});
export const calendarActiveGrouperFilters = atom<string[]>([]);
export const calendarTasksAtom = atom<ScrollCalendarItemType[]>([]);

export const calendarItemsAtom = atom<ScrollCalendarItemType[]>([]);
export const calendarItemColorTypeAtom = atom<CalendarItemColorType>(CalendarItemColorType.Label);
export const calendarPreviewItemsAtom = atom<ScrollCalendarItemType[] | null>(null);
export const calendarProjectAtom = atom<ProjectsStateCurrentProject | undefined>(undefined);
export const calendarGlobalRowsAtom = atom<GlobalRowsType | undefined>(undefined);
export const calendarSearchTermAtom = atom<string>('');

export const contextModalDataAtom = atom<{
    event: QuickEventPayload;
    occurence: ScrollCalendarItemType;
} | null>(null);

export const contextModalTriggerPositionAtom = atom<[number, number]>([0, 0]);
export const diffModalItemAtom = atom<ScrollCalendarItemType | null>(null);
export const isCalendarModalOpenAtom = atom((get) => {
    const contextModalData = get(contextModalDataAtom);
    const diffModalItem = get(diffModalItemAtom);
    return Boolean(contextModalData || diffModalItem);
});
