import { z } from 'zod';
import { Group } from '../paths/herawApiPathGroups';
import { ApiMethod } from '../types/api.types';
import {
    oaProjectParamsSchema,
    oaProjectTeamMemberParamsSchema,
    oaProjectUuidAndUserUuidSchema,
    oaProjectUuidSchema,
    oaTeamSchema,
    oaTeamWithMembersSchema
} from '../types/open-api/project.open-api.types';
import {
    oaTeamParamsSchema,
    oaTeamPostBody,
    oaTeamUsersPostBody
} from '../types/open-api/team.open-api.types';
import { projectParamsSchema } from '../types/payloads/project.payload';
import {
    teamParamsSchema,
    teamPostBody,
    teamUpdateBody,
    teamUserParamsSchema,
    teamUserPatchBody,
    teamUsersPostBody
} from '../types/payloads/team.payload';
import { teamMemberSchema, teamSchema, teamWithMembersSchema } from '../types/team.types';

export const API_TEAM = {
    LIST: {
        method: ApiMethod.GET,
        path: '/workspaces/:workspaceName/projects/:projectUuid/teams',
        handlerMethod: 'getTeams',
        zod: {
            params: projectParamsSchema
        },
        responses: {
            200: {
                description: 'List of project teams',
                schema: z.array(teamWithMembersSchema)
            }
        }
    },
    LIST_OA: {
        method: ApiMethod.GET,
        path: '/public/v1/workspaces/:workspaceName/projects/:projectUuid/teams',
        handlerMethod: 'getTeams',
        publicApi: true,
        description: 'Gets all teams and members for a given project',
        group: Group.Projects,
        zod: {
            params: oaProjectParamsSchema
        },
        responses: {
            200: {
                description: 'List of project teams',
                schema: z.array(oaTeamSchema)
            }
        }
    },
    LIST_OA_DEPRECATED: {
        method: ApiMethod.GET,
        path: '/public/v1/projects/:projectUuid/teams',
        handlerMethod: 'getTeams',
        publicApi: 'deprecated',
        description: 'Gets all teams and members for a given project',
        group: Group.Projects,
        zod: {
            params: oaProjectUuidSchema
        },
        responses: {
            200: {
                schema: z.array(oaTeamWithMembersSchema),
                description: 'Teams list'
            }
        }
    },
    POST: {
        method: ApiMethod.POST,
        path: '/workspaces/:workspaceName/projects/:projectUuid/teams',
        handlerMethod: 'postTeam',
        zod: {
            params: projectParamsSchema,
            body: teamPostBody
        },
        responses: {
            200: {
                description: 'Team created',
                schema: teamSchema
            }
        }
    },
    POST_OA: {
        method: ApiMethod.POST,
        path: '/public/v1/workspaces/:workspaceName/projects/:projectUuid/teams',
        handlerMethod: 'postTeam',
        publicApi: true,
        description: 'Create a team for a given project',
        group: Group.Projects,
        zod: {
            params: oaProjectParamsSchema,
            body: oaTeamPostBody
        },
        responses: {
            200: {
                description: 'Team created',
                schema: oaTeamSchema
            }
        }
    },
    PATCH: {
        method: ApiMethod.PATCH,
        path: '/workspaces/:workspaceName/projects/:projectUuid/teams/:teamUuid',
        handlerMethod: 'patchTeam',
        zod: {
            params: teamParamsSchema,
            body: teamUpdateBody
        },
        responses: {
            200: {
                description: 'Team updated',
                schema: teamSchema
            }
        }
    },
    DELETE: {
        method: ApiMethod.DELETE,
        path: '/workspaces/:workspaceName/projects/:projectUuid/teams/:teamUuid',
        handlerMethod: 'deleteTeam',
        zod: {
            params: teamParamsSchema
        },
        response: {
            204: {
                description: 'Team successfully deleted'
            }
        }
    }
} as const;

export const API_TEAM_USER = {
    POST: {
        method: ApiMethod.POST,
        path: '/workspaces/:workspaceName/projects/:projectUuid/teams/:teamUuid/users',
        handlerMethod: 'postTeamUsers',
        zod: {
            params: teamParamsSchema,
            body: teamUsersPostBody
        },
        responses: {
            200: {
                description: 'User added',
                schema: teamWithMembersSchema
            }
        }
    },
    POST_OA: {
        method: ApiMethod.POST,
        path: '/public/v1/workspaces/:workspaceName/projects/:projectUuid/teams/:teamUuid/users',
        handlerMethod: 'postTeamUsers',
        publicApi: true,
        description: 'Adds a member to a project',
        group: Group.Projects,
        zod: {
            params: oaTeamParamsSchema,
            body: oaTeamUsersPostBody
        },
        responses: {
            200: {
                description: 'User added',
                schema: oaTeamWithMembersSchema
            }
        }
    },
    PATCH: {
        method: ApiMethod.PATCH,
        path: '/workspaces/:workspaceName/projects/:projectUuid/teams/:teamUuid/users/:userUuid',
        handlerMethod: 'patchTeamUser',
        zod: {
            params: teamUserParamsSchema,
            body: teamUserPatchBody
        }
    },
    DELETE: {
        method: ApiMethod.DELETE,
        path: '/workspaces/:workspaceName/projects/:projectUuid/teams/:teamUuid/users/:userUuid',
        handlerMethod: 'deleteTeamUser',
        zod: {
            params: teamUserParamsSchema
        }
    },
    DELETE_OA: {
        method: ApiMethod.DELETE,
        path: '/public/v1/workspaces/:workspaceName/projects/:projectUuid/teams/:teamUuid/users/:userUuid',
        handlerMethod: 'deleteTeamUser',
        publicApi: true,
        description: 'Removes a member from a project',
        group: Group.Projects,
        zod: {
            params: oaProjectTeamMemberParamsSchema
        },
        responses: {
            204: {
                description: 'User successfully removed from project'
            }
        }
    },
    DELETE_OA_DEPRECATED: {
        method: ApiMethod.DELETE,
        path: '/public/v1/projects/:projectUuid/users/:userUuid',
        handlerMethod: 'deleteTeamUserDeprecated',
        publicApi: 'deprecated',
        description: 'Removes a member from a project',
        group: Group.Projects,
        zod: {
            params: oaProjectUuidAndUserUuidSchema
        },
        responses: {
            204: {
                description: 'User successfully removed from project'
            }
        }
    }
} as const;
