import { buildApiCall } from '@he-novation/config/apis/api-fetch';
import {
    API_FILE,
    API_FILE_BULK_DELETE,
    API_FILE_FINAL_VOTES,
    API_FILE_SUBTITLES,
    API_FILE_VERSION,
    API_FILE_VERSION_ASSET
} from '@he-novation/config/apis/file-api';
import { API_MIME_TYPE } from '@he-novation/config/apis/public/misc-public-api';
import type { FileFull, VersionAssetRecap } from '@he-novation/config/types/file.types';
import { FileVotes } from '@he-novation/config/types/file.types';
import {
    FileCreateBody,
    FileFinalVoteBody,
    FileStatusUpdateBody,
    FileUpdateBody
} from '@he-novation/config/types/payloads/file.payload';
import { FileCreateResponse } from '@he-novation/config/types/responses/file.responses';
import type { Subtitle } from '@he-novation/config/types/subtitle.types';
import { apiFetch } from './apiFetch';

export function openLink(url: string) {
    const a = document.createElement('a');
    a.href = url;
    a.target = '_blank';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
}

export function download(url: string, name?: string) {
    const a = document.createElement('a');
    a.href = url;
    a.download = name || (url.split('/').pop() as string);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
}

export const asyncFileCreate = (
    workspaceName: string,
    body: FileCreateBody
): Promise<FileCreateResponse> => buildApiCall(API_FILE.POST)({ params: { workspaceName }, body });

export const asyncFileConvertToVersion = (
    workspaceName: string,
    targetFileUuid: string,
    sourceFileUuid: string
) =>
    buildApiCall(API_FILE.CONVERT_TO_VERSION)({
        params: {
            workspaceName,
            fileUuid: sourceFileUuid
        },
        body: {
            targetFileUuid: targetFileUuid
        }
    });

export const asyncFileCopy = (
    workspaceName: string,
    fileUuid: string,
    targetFolderUuid: string,
    newUuid: string
) =>
    buildApiCall(API_FILE.COPY)({
        params: {
            workspaceName,
            fileUuid
        },
        body: {
            targetFolderUuid,
            newUuid
        }
    });

export const asyncFileMove = (workspaceName: string, fileUuid: string, targetFolderUuid: string) =>
    buildApiCall(API_FILE.MOVE)({
        params: { workspaceName, fileUuid },
        body: { targetFolderUuid }
    });

export const asyncFileRestore = (workspaceName: string, fileUuid: string) =>
    buildApiCall(API_FILE.RESTORE)({ params: { workspaceName, fileUuid } });

export const asyncFileStatusUpdate = (
    workspaceName: string,
    fileUuid: string,
    body: FileStatusUpdateBody
) =>
    buildApiCall(API_FILE.UPDATE_STATUS)({
        params: { workspaceName, fileUuid },
        body
    });

export const asyncFileEncode = (workspaceName: string, fileUuid: string) =>
    buildApiCall(API_FILE.ENCODE)({
        params: {
            workspaceName,
            fileUuid
        }
    });

export const asyncFileAddFinalVote = (
    workspaceName: string,
    fileUuid: string,
    body: FileFinalVoteBody
): Promise<FileVotes> =>
    buildApiCall(API_FILE_FINAL_VOTES.POST)({
        params: {
            workspaceName,
            fileUuid
        },
        body
    }).then((r: FileVotes) => ({
        ...r,
        voters: r.voters.map((v) => ({ ...v, created: new Date(v.created) }))
    }));

export const asyncFileFetchFinalVotes = (
    workspaceName: string,
    fileUuid: string
): Promise<FileVotes> =>
    buildApiCall(API_FILE_FINAL_VOTES.GET)({
        params: {
            workspaceName,
            fileUuid
        }
    }).then((r: FileVotes) => ({
        ...r,
        voters: r.voters.map((v) => ({ ...v, created: new Date(v.created) }))
    }));

export const asyncFileVersionFetch = ({
    workspaceName,
    uuid,
    version,
    public_password
}: {
    workspaceName: string;
    uuid: string;
    version: number;
    public_password?: string | null;
}): Promise<FileFull> =>
    buildApiCall(API_FILE_VERSION.GET)({
        params: { workspaceName, fileUuid: uuid, fileVersion: version },
        query: { p: public_password || undefined }
    }).then((file) => ({
        ...file,
        created: file.created ? new Date(file.created) : new Date(),
        updated: file.updated ? new Date(file.updated) : null
    }));

export const asyncFileVersionsFetch = (
    workspaceName: string,
    fileUuid: string
): Promise<VersionAssetRecap[]> =>
    buildApiCall(API_FILE_VERSION.LIST)({ params: { workspaceName, fileUuid } });

export const asyncFileVersionCreate = (
    workspaceName: string,
    fileUuid: string,
    body: FileCreateBody
): Promise<FileCreateResponse> => {
    return buildApiCall(API_FILE_VERSION.POST)({
        params: { workspaceName, fileUuid },
        body
    });
};

export const asyncFileVersionUpdate = (
    workspaceName: string,
    fileUuid: string,
    fileVersion: number,
    body: FileUpdateBody
) => buildApiCall(API_FILE_VERSION.PUT)({ params: { workspaceName, fileUuid, fileVersion }, body });

export const asyncFileVersionDelete = (
    workspaceName: string,
    fileUuid: string,
    fileVersion: number
) =>
    buildApiCall(API_FILE_VERSION.DELETE)({
        params: { workspaceName, fileUuid, fileVersion }
    });

export const asyncFileVersionRestore = (
    workspaceName: string,
    fileUuid: string,
    fileVersion: number
) =>
    buildApiCall(API_FILE_VERSION.RESTORE)({
        params: { workspaceName, fileUuid, fileVersion }
    });

export const asyncFileVersionSubtitlesFetch = (
    workspaceName: string,
    fileUuid: string,
    fileVersion: number,
    password?: string | null
): Promise<Subtitle[]> =>
    buildApiCall(API_FILE_SUBTITLES.LIST)({
        params: { workspaceName, fileUuid, fileVersion },
        query: { p: password || undefined }
    });

export const asyncFileAssetDownloadUrlFetch = ({
    workspaceName,
    uuid,
    version,
    quality,
    password
}: {
    workspaceName: string;
    uuid: string;
    version: number;
    quality: string;
    password?: string;
}) =>
    buildApiCall(API_FILE_VERSION_ASSET.GET_DOWNLOAD_URL)({
        params: { workspaceName, fileUuid: uuid, fileVersion: version },
        query: { quality, p: password }
    }).then(({ url }) => url);

export const asyncFileDownload = async (
    workspaceName: string,
    uuid: string,
    version: number,
    quality: string,
    password?: string,
    name?: string
) => {
    const link = await asyncFileAssetDownloadUrlFetch({
        workspaceName,
        uuid,
        version,
        quality,
        password
    });
    download(link, name);
};
export const asyncFileBulkDelete = (
    workspaceName: string,
    folderUuid: string,
    fileUuids: string[]
) =>
    buildApiCall(API_FILE_BULK_DELETE.POST)({
        params: { workspaceName },
        body: { parentUuid: folderUuid, uuids: fileUuids }
    });

export const asyncOpenApiFileFetch = (
    workspaceName: string,
    apiKey: string,
    fileUuid: string,
    fileVersion: number
) =>
    buildApiCall(API_FILE_VERSION.GET_OA)({
        options: {
            headers: {
                Authorization: 'Bearer ' + apiKey
            }
        },
        params: { workspaceName, fileUuid, fileVersion },
        query: {}
    });

export const asyncReadMagicBytes = async (file: File): Promise<string | null> => {
    try {
        const input = file.slice(0, Math.min(file.size, 1024 * 10));
        const buffer = new Uint8Array(await input.arrayBuffer());
        const extension = file.name.split('.').pop() || '';

        const data: {
            mimeType: string | null;
        } = await buildApiCall(API_MIME_TYPE.INFER)({
            options: {
                headers: {
                    'Content-Type': API_MIME_TYPE.INFER.contentType
                }
            },
            query: { extension, mimeType: file.type },
            body: buffer
        });
        return data.mimeType || null;
    } catch (e) {
        return null;
    }
};

export const fileEncryptedAccessTokenRequest = (request_token: string) =>
    apiFetch('/proxy/get/access/token', {
        method: 'POST',
        body: JSON.stringify({
            request_token,
            delay: 0
        })
    });
