import { z } from 'zod';
import { ApiMethod } from '../../types/api.types';
import { folderParamsSchema } from '../../types/payloads/folder.payload';

export const API_ADMITTANCE_PUBLIC = {
    POST: {
        method: ApiMethod.POST,
        path: '/admittance/:workspaceName/folders/:folderUuid',
        handlerMethod: 'requestFolderAccess',
        passResAndNextToHandler: true as true,
        isPublic: true,
        zod: {
            params: folderParamsSchema,
            body: z.object({
                signupData: z
                    .object({
                        email: z.string().email(),
                        firstname: z.string(),
                        lastname: z.string(),
                        firm: z.string(),
                        phone: z.string(),
                        role: z.string(),
                        locale: z.string(),
                        country: z.string(),
                        accept: z.boolean(),
                        address: z.string()
                    })
                    .nullable()
                    .optional()
            })
        }
    }
} as const;
