export enum Group {
    Casts = 'Casts',
    Contacts = 'Contacts',
    ContactGroups = 'ContactGroups',
    CustomFields = 'Custom Fields',
    Entity = 'Entity',
    Events = 'Events',
    Files = 'Files',
    Folders = 'Folders',
    Forms = 'Forms',
    Notes = 'Notes',
    Projects = 'Projects',
    Search = 'Search',
    Subtitles = 'Subtitles',
    Tasks = 'Tasks',
    Teams = 'Teams',
    Users = 'Users',
    Versions = 'Versions',
    Workspace = 'Workspace'
}
