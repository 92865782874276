import { z } from 'zod';
import { ClientUserRole } from '../db/enums';

export const oaWorkspaceUserSchema = z.object({
    uuid: z.string().uuid(),
    firstname: z.string().nullable(),
    lastname: z.string().nullable(),
    email: z.string().email(),
    picture: z.string().nullable(),
    created: z.date(),
    updated: z.date().nullable(),
    role: z.nativeEnum(ClientUserRole)
});

export const oaWorkspaceTeamSchema = z.object({
    uuid: z.string().uuid(),
    name: z.string()
});

export type OAWorkspaceTeam = z.infer<typeof oaWorkspaceTeamSchema>;

export const oaWorkspaceTeamWithMembersSchema = oaWorkspaceTeamSchema.extend({
    members: z.array(oaWorkspaceUserSchema)
});

export type OAWorkspaceTeamWithMembers = z.infer<typeof oaWorkspaceTeamWithMembersSchema>;
