import formStyles from '@he-novation/design-system/styles/form-styles.module.css';
import React, { FormEvent, useCallback } from 'react';
import { ButtonTone } from '@he-novation/design-system/components/buttons/BottomButtons/BottomButtons';
import {
    asyncCastFolderCreate,
    asyncCastFolderUpdate
} from '@he-novation/front-shared/async/cast.async';
import { useAtomValue } from 'jotai';

import { workspaceNameAtom } from '$atoms/workspace-atoms';
import { FormCastFolder } from '$components/form/FormCastFolder';
import ModalBottomButtons from '$components/modal/ModalBottomButtons/ModalBottomButtons';
import { ModalContent } from '$components/modal/ModalContent/ModalContent';
import ModalHeader from '$components/modal/ModalHeader/ModalHeader';
import { useTranslate } from '$hooks/useTranslate';

type ModalFormCastFolderProps = {
    payload: {
        castUid: string;
        castFolderUuid?: string;
        castParentFolderUuid?: string;
        cb?: (data: any) => void;
        data?: { uuid: string };
    };
    closeModal: () => void;
};

export function ModalFormCastFolder({
    payload: { data, castUid, castFolderUuid },
    closeModal
}: ModalFormCastFolderProps) {
    const workspaceName = useAtomValue(workspaceNameAtom);
    const submit = useCallback(
        async (e: FormEvent, payload: { name: string }) => {
            e.preventDefault();
            if (data?.uuid) {
                await asyncCastFolderUpdate(workspaceName, castUid, data.uuid, payload.name);
            } else {
                await asyncCastFolderCreate(workspaceName, castUid, castFolderUuid, payload.name);
                closeModal();
            }
        },
        [workspaceName]
    );

    const { t } = useTranslate();

    const formId = 'form-cast-folder';

    return (
        <>
            <ModalContent className={formStyles.light}>
                <ModalHeader
                    title={t(data?.uuid ? 'cast.Edit cast folder' : 'cast.Create cast folder')}
                />
                <FormCastFolder formId={formId} submit={submit} />
            </ModalContent>
            <ModalBottomButtons
                buttons={[
                    {
                        children: t('common.Cancel'),
                        onClick: () => closeModal(),
                        tone: ButtonTone.Outlined
                    },
                    {
                        type: 'submit',
                        form: formId,
                        children: t('common.Save'),
                        tone: ButtonTone.Primary
                    }
                ]}
            />
        </>
    );
}

export default ModalFormCastFolder;
