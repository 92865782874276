import { WSWorkspaceEventUpdated } from '@he-novation/config/types/websockets/workspace.ws.types';
import { useSetAtom } from 'jotai';
import { useAtom } from 'jotai/index';

import { workspaceAtom, workspacesAtom } from '$atoms/workspace-atoms';
import { useSocketIO } from '$hooks/useSocketIO';

export function useWorkspaceSocket() {
    const [workspace, setWorkspace] = useAtom(workspaceAtom);
    const setWorkspaces = useSetAtom(workspacesAtom);

    const { subscribe, unsubscribe } = useSocketIO();

    const subscribeWorkspaceSocket = () => {
        if (workspace && subscribe) {
            subscribe({
                room: workspace.name,
                actions: {
                    sioWorkspaceUpdated: ({
                        type,
                        ...updatedWorkspace
                    }: WSWorkspaceEventUpdated) => {
                        setWorkspace((w) => {
                            if (!w) return null;
                            return { ...w, ...updatedWorkspace };
                        });
                        setWorkspaces((ws) => {
                            if (!ws) return null;
                            return ws.map((w) =>
                                workspace.uuid === w.uuid ? { ...w, ...updatedWorkspace } : w
                            );
                        });
                    }
                },
                socket: 'workspace'
            });
        }
    };

    return {
        subscribeWorkspaceSocket,
        unsubscribeWorkspaceSocket: unsubscribe
    };
}
