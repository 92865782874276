import { buildApiCall } from '@he-novation/config/apis/api-fetch';
import type {
    PIDData,
    Transcoder,
    TranscoderInstanceClass,
    TranscodingTask
} from '@he-novation/config/types/transcoding';
import {
    API_TRANSCODER,
    API_TRANSCODER_AVAILABLE_CLASSES,
    API_TRANSCODER_UNASSIGNED_TASKS
} from '../../config/apis/transcoder-api';
import { TranscoderStartBody } from '../../config/types/payloads/transcoder.payloads';

export async function asyncTranscodersFetch() {
    return buildApiCall(API_TRANSCODER.LIST)({}) as Promise<Transcoder[]>;
}

export async function asyncTranscoderAvailableClassesFetch() {
    return buildApiCall(API_TRANSCODER_AVAILABLE_CLASSES.LIST)({}) as Promise<
        TranscoderInstanceClass[]
    >;
}

export async function asyncTranscoderUnassignedTasksFetch() {
    return buildApiCall(API_TRANSCODER_UNASSIGNED_TASKS.LIST)({}) as Promise<
        Omit<TranscodingTask, 'transcoderUuid'>[]
    >;
}

export async function asyncTranscoderStart(body: TranscoderStartBody) {
    return buildApiCall(API_TRANSCODER.POST)({ body });
}

export async function asyncTranscoderStop(transcoderUuid: string) {
    return buildApiCall(API_TRANSCODER.DELETE)({ params: { transcoderUuid } });
}

export async function asyncTranscoderPIDDataFetch() {
    return buildApiCall(API_TRANSCODER.PID)({}) as Promise<PIDData | null>;
}
