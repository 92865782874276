import { sanitizedString } from '@he-novation/utils/zod.utils';
import { z } from 'zod';
import { FileStatus } from '../db/enums';
import { baseUploadPayload } from './asset.payload';
import { workspaceParamsSchema } from './workspace.payload';
import { customFieldValuesBodySchema } from './custom-field.payload';

export const fileParamsSchema = workspaceParamsSchema.extend({
    fileUuid: z.string().uuid(),
    fileVersion: z.coerce.number().optional()
});
export type FileParams = z.infer<typeof fileParamsSchema>;
export const fileVersionParamsSchema = workspaceParamsSchema.extend({
    fileUuid: z.string().uuid(),
    fileVersion: z.coerce.number()
});
export type FileVersionParams = z.infer<typeof fileVersionParamsSchema>;

export const fileUuidSchema = z.object({
    fileUuid: z.string().uuid()
});

export type FileUuidSchema = z.infer<typeof fileUuidSchema>;

export const fileSpecificParams = z.object({
    fileUuid: z.string().uuid(),
    fileVersion: z.coerce.number()
});

export type FileSpecificParams = z.infer<typeof fileSpecificParams>;

export const fileUpdateBody = z.object({
    name: sanitizedString({ optional: true }),
    tags: z.array(z.string()).optional(),
    metadata: z.any().optional(),
    final: z.boolean().optional(),
    description: sanitizedString({ optional: true, nullable: true }),
    status: z.nativeEnum(FileStatus).optional()
});

export type FileUpdateBody = z.infer<typeof fileUpdateBody>;

export const fileCopyOrMoveBodySchema = z.object({
    targetFolderUuid: z.string().uuid(),
    newUuid: z.string().uuid().optional()
});

export type FileCopyOrMoveBody = z.infer<typeof fileCopyOrMoveBodySchema>;

export const filePasswordQuery = z.object({
    p: z.string().optional()
});

export type FilePasswordQuery = z.infer<typeof filePasswordQuery>;

export const fileFinalVoteBody = z.object({
    voteForUserUuid: z.string().uuid().optional().nullable(),
    final: z.boolean()
});

export type FileFinalVoteBody = z.infer<typeof fileFinalVoteBody>;

export const fileStatusUpdateBody = z.object({
    status: z.nativeEnum(FileStatus)
});

export type FileStatusUpdateBody = z.infer<typeof fileStatusUpdateBody>;

export const fileVersionAddBody = z.object({
    targetFileUuid: z.string().uuid()
});

export type FileVersionAddBody = z.infer<typeof fileVersionAddBody>;

export const fileCreateBody = baseUploadPayload.extend({
    folderUuid: z.string().uuid(),
    version: z.number(),
    uploadGroup: z.string(),
    customFields: customFieldValuesBodySchema.optional()
});

export type FileCreateBody = z.infer<typeof fileCreateBody>;

export const fileMimeTypeQuery = z.object({
    extension: sanitizedString(),
    mimeType: z.string()
});

export type FileMimeTypeQuery = z.infer<typeof fileMimeTypeQuery>;

export const fileBulkDeleteBody = z.object({
    parentUuid: z.string().uuid(),
    uuids: z.array(z.string().uuid())
});

export type FileBulkDeleteBody = z.infer<typeof fileBulkDeleteBody>;

export const fileAssetDownloadUrlSchema = z.object({
    url: z.string(),
    urlExpires: z.date().nullable()
});

export const fileAssetDownloadUrlQuerySchema = filePasswordQuery.extend({
    quality: sanitizedString({ min: 2 })
});

export type FileAssetDownloadUrlQuery = z.infer<typeof fileAssetDownloadUrlQuerySchema>;
