import styles from './WorkspaceIcon.module.css';
import React from 'react';
import { colorAverage } from '@he-novation/config/utils/colors';
import { Icon, IconProp } from '@he-novation/design-system/components/graphics/Icon/Icon';
import { initialsColor } from '@he-novation/utils/colors';
import cn from 'classnames';

export enum WorkspaceIconSize {
    Small = 'small',
    Medium = 'medium'
}

type WorkspaceIconProps = {
    name: string;
    primary?: string | null;
    logoUrl?: string | null;
    size: WorkspaceIconSize;
    icon?: IconProp;
    className?: string;
};

export function WorkspaceIcon({
    name,
    primary,
    logoUrl,
    size,
    icon,
    className
}: WorkspaceIconProps) {
    const backgroundColor = primary || (icon ? undefined : initialsColor(name[0]));
    const initialColor = backgroundColor
        ? colorAverage(backgroundColor) > 127
            ? 'black'
            : 'white'
        : undefined;

    return (
        <div
            className={cn(styles.workspaceIcon, size, className)}
            style={{
                backgroundColor: backgroundColor
            }}
        >
            {logoUrl ? (
                <img src={logoUrl} alt="" />
            ) : icon ? (
                <Icon icon={icon} />
            ) : (
                <div className={styles.workspaceInitial} style={{ color: initialColor }}>
                    {name[0]}
                </div>
            )}
        </div>
    );
}
