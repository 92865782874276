import React from 'react';
import { DonutChart } from '@he-novation/design-system/components/graphs/DonutChart/DonutChart';

import { useTranslate } from '$hooks/useTranslate';

type ClientDashboardChartProps = {
    value: number;
    valueLabel?: string;
    valueToString?: (value: number) => string;
    total: number;
    className?: string;
};

export function ClientDashboardChart({
    value,
    valueLabel,
    valueToString,
    total,
    className
}: ClientDashboardChartProps) {
    const exceeding = Math.abs(Math.min(total - value, 0));
    const { t } = useTranslate();

    const ratio = total > 0 ? value / total : 0;

    let color = 'var(--color-success)';
    if (ratio >= 0.9) {
        color = 'var(--color-alert)';
    } else if (ratio >= 0.75) {
        color = 'var(--color-gold)';
    }

    const data = [
        {
            label: valueLabel || t('common.Enabled'),
            value: value,
            valueString: valueToString?.(value),
            color: color
        }
    ];

    if (exceeding > 0) {
        data.push({
            label: `${t('settings.Exceeding')}`,
            value: exceeding,
            valueString: valueToString?.(exceeding),
            color: 'red'
        });
    } else {
        data.push({
            label: `${t('common.Available')}`,
            value: total - value,
            valueString: valueToString?.(total - value),
            color: 'var(--color-bg-1)'
        });
    }

    return (
        <div className={className}>
            {total > 0 && (
                <DonutChart
                    data={data}
                    strokeWidth={140}
                    padding={40}
                    gap={20}
                    segmentBorderRadius={10}
                    hueShift={30 / 360}
                ></DonutChart>
            )}
        </div>
    );
}
