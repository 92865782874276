import { z } from 'zod';

export const coordinatesSchema = z.object({
    x: z.number(),
    y: z.number(),
    z: z.number()
});

export type Coordinates = z.infer<typeof coordinatesSchema>;

export const cameraCoordinatesSchema = z.object({
    position: coordinatesSchema,
    target: coordinatesSchema
});

export type CameraCoordinates = z.infer<typeof cameraCoordinatesSchema>;

export const sceneLightningSchema = z.object({
    environmentIntensity: z.number(),
    skyLightness: z.number(),
    groundLightness: z.number(),
    lightIntensity: z.number()
});

export type SceneLightning = z.infer<typeof sceneLightningSchema>;
