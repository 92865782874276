import styles from './ReactSelect.module.scss';
import React, { ReactNode, RefObject, useCallback, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { FieldComponentProps } from 'react-modular-forms';
import Select, { createFilter } from 'react-select';
import { components as defaultComponent } from 'react-select';
import Async from 'react-select/async';
import Creatable from 'react-select/creatable';
import cn from 'classnames';
import useWindowSize from '../../../../../hooks/useWindowSize';
import { offset } from '../../../../../utils/dom/offset';
import { parentHasClass } from '../../../../../utils/dom/parentHasClass';

export type Option = {
    label: ReactNode;
    searchValue?: any;
    value: any;
};

export type Group = Option & {
    items: Option[];
};

type ReactSelectProps = FieldComponentProps & {
    onMount?: (selectRef: any, value: any, options: { label: ReactNode; value: any }[]) => {};
    onChange?: (e: null, option: any) => void;
    alterSelected?: (options: any) => any;
    options: Option[];
    groups?: Group[];
    menuClassName?: string;
    getMenuWidth?: (parentWidth: any) => string | number;
    filterBy?: string;
    creatable?: boolean;
    selectRef?: RefObject<any>;
    disabled?: boolean;
    formatCreateLabel?: (inputValue: string) => string;
    menuPlacement: 'top' | 'bottom' | 'auto';
    readOnly?: boolean;
    loadOptions?: (inputValue: string, callback: (options: Option[]) => void) => void;
};

export function ReactSelect({
    name,
    value: _value,
    onChange,
    componentRef,
    isSearchable,
    isClearable,
    theme,
    after,
    options,
    groups = [],
    onMount,
    menuClassName,
    alterSelected = (o) => o,
    getMenuWidth,
    menuPortalTarget,
    multiContainerRef,
    isMulti,
    filterBy,
    components,
    creatable,
    selectRef,
    disabled,
    formatCreateLabel,
    menuPlacement: _menuPlacement,
    ...props
}: ReactSelectProps) {
    const [value, setValue] = useState<any>(isMulti ? [] : undefined);
    const ref = selectRef || useRef<any>();

    useEffect(() => {
        componentRef.current = value;
        onMount?.(ref, value, options);
    }, []);

    useEffect(() => {
        componentRef.current = _value;
        setValue(_value);
    }, [_value]);

    let filterOption;
    if (filterBy) {
        filterOption = createFilter({
            stringify: (option) => (option.data as any)[filterBy] as string
        });
    }

    const updateValue = (option: Option | Option[] | null) => {
        let newValue = alterSelected(option);
        if (Array.isArray(newValue) && groups) {
            const selectedGroups = newValue
                .map((o) => groups.find((g) => g.value === o.value))
                .filter((g) => g);
            newValue = newValue.filter((o) => !groups.find((g) => g.value === o.value));
            selectedGroups.forEach((g) => {
                g?.items.forEach((i) => {
                    if (!newValue.find((o: Option) => o.value === i.value)) newValue.push(i);
                });
            });
        }
        componentRef.current = Array.isArray(newValue)
            ? newValue.map((o) => o.value)
            : newValue && newValue.value;
        setValue(componentRef.current);
        return newValue;
    };

    const reactSelectValue = value
        ? Array.isArray(value)
            ? options.filter((o: Option) => value.includes(o.value))
            : options.find((o: any) => o.value === value)
        : value;

    const combinedOptions = [...options];
    const filteredGroups: Group[] = [];
    for (const group of groups) {
        if (!group.items.find((i) => !value?.includes(i.value))) continue;
        filteredGroups.push(group);
        for (const item of group.items) {
            if (!options.find((o) => o.value === item.value)) combinedOptions.push(item);
        }
    }
    combinedOptions.push(...filteredGroups);

    const Tag = creatable ? Creatable : props.loadOptions ? Async : Select;
    const { onMenuOpen, menuPlacement } = useReactSelectMenuPlacement(_menuPlacement, ref, options);

    return (
        <>
            <div
                onClick={(e) => {
                    if (
                        !ref.current.commonProps.selectProps.menuIsOpen &&
                        !parentHasClass(e.target as HTMLElement, 'multi-value') &&
                        !disabled
                    ) {
                        if (isSearchable) ref.current.onMenuOpen();
                    }
                }}
                className={styles.reactSelect}
            >
                <Tag
                    unstyled
                    openMenuOnClick={false}
                    ref={ref}
                    menuPortalTarget={
                        typeof menuPortalTarget === 'undefined' ? document.body : menuPortalTarget
                    }
                    placeholder={null}
                    isClearable={isClearable}
                    closeMenuOnSelect
                    menuPlacement={menuPlacement}
                    isDisabled={disabled}
                    isMulti={isMulti}
                    formatCreateLabel={formatCreateLabel}
                    filterOption={filterOption}
                    styles={{
                        menuPortal: (s) =>
                            ({
                                ...s,
                                width: getMenuWidth ? getMenuWidth(s.width) : s.width,
                                zIndex: '12'
                            }) as any,
                        valueContainer: () => ({ overflow: 'hidden' }),
                        multiValue: ({ minWidth, ...s }) => s,
                        menu: (s) => ({ ...s, zIndex: 'auto' }) as any,
                        input: ({ width, ...s }) => s,
                        control: ({ minHeight, ...s }) => ({ ...s, minHeight: 32 })
                    }}
                    options={combinedOptions}
                    classNames={{
                        control: (r) => cn('control', isClearable && 'is-clearable'),
                        valueContainer: () => 'value-container',
                        input: () => 'input',
                        indicatorsContainer: () => 'indicators-container',
                        placeholder: () => 'placeholder',
                        menu: () =>
                            cn('react-select-menu', styles.reactSelectMenu, theme, menuClassName),
                        option: ({ isFocused }) => cn('option', isFocused && 'is-focused'),
                        multiValue: () => 'multi-value',
                        multiValueRemove: (s: any) =>
                            cn('multi-value-remove', s.data?.noDelete && 'undeletable'),
                        multiValueLabel: () => 'multi-value-label'
                    }}
                    name={Array.isArray(name) ? name.join('.') : name}
                    value={reactSelectValue}
                    isSearchable={isSearchable ?? false}
                    onChange={(option: Option, actionMeta: any) => {
                        updateValue(option);
                        onChange?.(null, componentRef.current);
                    }}
                    onMenuOpen={onMenuOpen}
                    components={
                        multiContainerRef
                            ? {
                                  ...components,
                                  MultiValue: (props) => {
                                      const MultiValueComponent =
                                          components?.MultiValue || defaultComponent.MultiValue;
                                      return ReactDOM.createPortal(
                                          <MultiValueComponent {...props} />,
                                          multiContainerRef.current
                                      );
                                  }
                              }
                            : components
                    }
                    {...props}
                />
            </div>
            {after}
        </>
    );
}

function useReactSelectMenuPlacement(
    _menuPlacement: 'auto' | 'bottom' | 'top',
    ref: React.RefObject<any>,
    options: Option[]
) {
    const [menuHeight, setMenuHeight] = useState<number | null>(null);
    const [menuPlacement, setMenuPlacement] = useState<'auto' | 'bottom' | 'top'>(
        _menuPlacement || 'auto'
    );

    const onMenuOpen = useCallback(() => {
        if (!ref.current) return;

        const options = ref.current.props.options;
        const selectedOption = ref.current.props.value;

        setTimeout(() => {
            const el = document.getElementsByClassName(styles.reactSelectMenu)[0] as HTMLDivElement;
            setMenuHeight(el.offsetHeight);

            if (!selectedOption) return;

            const optionSelectedIndex = options.findIndex(
                (o: { value: string }) => o.value === selectedOption.value
            );
            if (optionSelectedIndex === -1) return;

            const optionEl = el?.getElementsByClassName('option')?.[
                optionSelectedIndex
            ] as HTMLDivElement;
            if (!optionEl) return;

            optionEl?.scrollIntoView({
                behavior: 'instant',
                block: 'center',
                inline: 'center'
            });
        }, 0);
    }, [ref, options]);

    const [_windowWidth, windowHeight] = useWindowSize();

    useEffect(() => {
        if (!_menuPlacement) {
            if (menuHeight && offset(ref.current.inputRef).top + menuHeight > windowHeight) {
                setMenuPlacement('top');
            } else {
                setMenuPlacement('bottom');
            }
        } else {
            setMenuPlacement(_menuPlacement);
        }
    }, [menuHeight, _menuPlacement, windowHeight]);
    return { onMenuOpen, menuPlacement };
}
