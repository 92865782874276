import { z } from 'zod';
import { ClientUserRole } from '../db/enums';

export const oaClientLabelSchema = z.object({
    uid: z.string().uuid(),
    name: z.string().nullable(),
    position: z.number()
});

export const oaClientUserSchema = z.object({
    apiKey: z.string().optional(),
    workspaceRole: z.nativeEnum(ClientUserRole),
    created: z.date(),
    deleteRequested: z.date().optional().nullable(),
    email: z.string(),
    expires: z.date().optional().nullable(),
    firm: z.string().optional(),
    firstname: z.string().optional().nullable(),
    lastname: z.string().optional().nullable(),
    phone: z.string().optional(),
    picture: z.string().optional().nullable(),
    role: z.nativeEnum(ClientUserRole),
    storage: z.object({
        total: z.number(),
        used: z.number()
    }),
    locale: z.union([z.literal('de'), z.literal('en'), z.literal('fr')]),
    translationCredits: z.number(),
    translationCreditsTotal: z.number(),
    updated: z.date().nullable(),
    lastUsed: z.date().nullable(),
    uuid: z.string()
});
