import { z } from 'zod';
import { ClientStatus, ClientTier } from './db/enums';
import { WorkspaceFeature } from './workspace.types';

export enum SubscriptionType {
    Freemium = 'freemium',
    Projects = 'projects',
    OneStudio = 'oneStudio'
}
export enum TeamsSubscriptionType {
    Projects = SubscriptionType.Projects,
    OneStudio = SubscriptionType.OneStudio
}

export const productOptionsSchema = z.object({
    maxProjects: z.number(),
    maxLicences: z.number(),
    features: z.array(z.nativeEnum(WorkspaceFeature)),
    storage: z.number(),
    tier: z.nativeEnum(ClientTier),
    type: z.nativeEnum(TeamsSubscriptionType).optional().nullable()
});

export type ProductOptions = z.infer<typeof productOptionsSchema>;

export type WorkspaceOptions = ProductOptions & {
    licenses: number;
    basicLicenses: number;
};

export const checkoutPriceProductSchema = z.object({
    name: z.string(),
    options: productOptionsSchema,
    yearlyPrice: z
        .object({
            amount: z.number(),
            id: z.string()
        })
        .nullable(),
    monthlyPrice: z
        .object({
            amount: z.number(),
            id: z.string()
        })
        .nullable()
});

export type CheckoutPriceProduct = z.infer<typeof checkoutPriceProductSchema>;

export const checkoutPricesResponseSchema = z.object({
    currency: z.string(),
    products: checkoutPriceProductSchema.array()
});

export type SubscriptionPricesResponse = z.infer<typeof checkoutPricesResponseSchema>;

export const subscriptionSchema = z.object({
    stripe: z
        .object({
            status: z.string(),
            paymentStatus: z.string(),
            subscriptionId: z.string(),
            priceId: z.string(),
            quantity: z.number(),
            total: z.number(),
            periodicity: z.union([z.literal('monthly'), z.literal('yearly')]),
            currentPeriodEnd: z.date(),
            cancelAtPeriodEnd: z.boolean(),
            schedule: z
                .object({
                    endDate: z.date(),
                    priceId: z.string(),
                    options: productOptionsSchema.extend({
                        licenses: z.number(),
                        basicLicenses: z.number()
                    })
                })
                .nullable(),
            paymentIntent: z
                .object({
                    id: z.string(),
                    clientSecret: z.string(),
                    status: z.string()
                })
                .nullable()
        })
        .optional()
        .nullable(),
    subscriptionType: z.nativeEnum(TeamsSubscriptionType).optional().nullable(),
    maxProjects: z.number(),
    licenses: z.number(),
    status: z.nativeEnum(ClientStatus),
    tier: z.nativeEnum(ClientTier),
    storage: z.number(),
    expires: z.date().nullable(),
    customer: z
        .object({
            id: z.string(),
            email: z.string().nullable(),
            firstname: z.string().nullable().optional(),
            lastname: z.string().nullable().optional(),
            company: z
                .object({
                    name: z.string().nullable().optional(),
                    vatNumber: z.string().nullable().optional()
                })
                .nullable()
                .optional(),
            address: z
                .object({
                    city: z.string().nullable().optional(),
                    country: z.string().nullable().optional(),
                    state: z.string().nullable().optional(),
                    street1: z.string().nullable().optional(),
                    zipcode: z.string().nullable().optional()
                })
                .nullable()
                .optional()
        })
        .nullable()
});

export type Subscription = z.infer<typeof subscriptionSchema>;

export const subscriptionCustomerResponseSchema = z.object({
    customerId: z.string()
});

export type SubscriptionCustomerResponse = z.infer<typeof subscriptionCustomerResponseSchema>;

export const subscriptionResponseSchema = z.object({
    status: z.string(),
    paymentIntent: z
        .object({
            id: z.string(),
            clientSecret: z.string()
        })
        .nullable()
});

export type SubscriptionResponse = z.infer<typeof subscriptionResponseSchema>;

export const customerAddressSchema = z.object({
    email: z.string().email(),
    firstname: z.string(),
    lastname: z.string(),
    address: z.object({
        city: z.string(),
        country: z.string(),
        zipcode: z.string(),
        street1: z.string(),
        state: z.string()
    }),
    companyName: z.string().optional(),
    companyVatNumber: z.string().optional()
});

export type CustomerAddress = z.infer<typeof customerAddressSchema>;

export const paymentMethodSchema = z.object({
    type: z.string().nullable(),
    id: z.string().nullable(),
    details: z
        .object({
            brand: z.string(),
            last4: z.string(),
            expiry: z.date()
        })
        .optional()
        .nullable()
});

export type PaymentMethod = z.infer<typeof paymentMethodSchema>;

export const paymentMethodIdSchema = z.object({
    paymentMethodId: z.string()
});

export type PaymentMethodId = z.infer<typeof paymentMethodIdSchema>;

export type SubscriptionData = {
    newWorkspaceName?: string;
    licenses: number;
    priceId: string | null;
    unitPrice: number;
    customer?: Subscription['customer'];
    periodicity: 'monthly' | 'yearly';
    selectedProduct: CheckoutPriceProduct;
};

export const subscriptionInvoiceSchema = z.object({
    start: z.date(),
    end: z.date(),
    paid: z.boolean(),
    total: z.number(),
    status: z.string().nullable(),
    pdf: z.string().nullable().optional(),
    dueDate: z.date().nullable()
});

export type SubscriptionInvoice = z.infer<typeof subscriptionInvoiceSchema>;
