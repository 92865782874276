import './Empty.scss';
import React, { forwardRef } from 'react';

import { useTranslate } from '$hooks/useTranslate';

type EmptyProps = {
    text?: string;
};
function Empty({ text }: EmptyProps, ref?: any) {
    const { t } = useTranslate();
    return (
        <div ref={ref} className="c-empty">
            {text || t('common.No content')}
        </div>
    );
}

export default forwardRef(Empty);
