import { FileState } from '@he-novation/config/types/file.types';
import { ProjectsState } from '@he-novation/config/types/project.types';
import { UserState } from '@he-novation/config/types/user.types';
import { FolderState } from '@he-novation/front-shared/types/folder.front-types';
// use deprecated createStore to avoid Date errors (mutability)
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { CONFIG } from './config/configActions';
import configReducer from './config/configReducer';
import { CONTENT } from './content/contentActions';
import contentReducer from './content/contentReducer';
import { ROUTE, setRoutes } from './route/routeActions';
import routeReducer from './route/routeReducer';
import { UI } from './ui/uiActions';
import uiReducer from './ui/uiReducer';
import { USER } from './user/userActions';
import userReducer from './user/userReducer';

import { FILE } from '$redux/content/file/fileActions';
import { fileInitialState } from '$redux/content/file/fileReducer';
import { folderInitialState } from '$redux/content/folder/folderReducer';
import { PROJECTS } from '$redux/content/projects/projectsActions';
import { projectsInitialState } from '$redux/content/projects/projectsReducer';
import { i18nInitialState, i18nReducer, i18nReducerName } from '$redux/i18n/i18nReducer';
import { I18nState } from '$redux/i18n/i18nTypes';
import { FOLDER } from '$redux/storeNames';

export type ActionType = {
    type: string;
    [key: string]: unknown;
};
export let lastAction: ActionType;

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const middlewares = [
    thunk,
    () => (next) => (action: ActionType) => {
        lastAction = action;
        next(action);
    }
];

const store = createStore(
    combineReducers({
        [CONFIG]: configReducer,
        [CONTENT]: contentReducer,
        [i18nReducerName]: i18nReducer,
        [ROUTE]: routeReducer,
        [USER]: userReducer,
        [UI]: uiReducer
    }),
    {
        [CONTENT]: {
            [FILE]: fileInitialState,
            [FOLDER]: folderInitialState,
            [PROJECTS]: projectsInitialState
        },
        [i18nReducerName]: i18nInitialState
    },
    composeEnhancers(applyMiddleware(...middlewares))
);
window.addEventListener('DOMContentLoaded', () => {
    store.dispatch(setRoutes(window.location.href));
});

export type ContentState = {
    FILE: FileState;
    FOLDER: FolderState;
    PROJECTS: ProjectsState;
    USER: UserState;
};
export type ReduxState = {
    CONTENT: ContentState;
    i18n: I18nState;
};

export default store;
