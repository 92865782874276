import React, { SyntheticEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FolderRole } from '@he-novation/config/types/folder.types';
import { UserPrefs } from '@he-novation/config/types/user.types';
import {
    ROLE_MANAGER,
    ROLE_PROVIDER,
    ROLE_REPORTER,
    ROLE_SPECTATOR
} from '@he-novation/config/utils/acl';
import { FormFields } from '@he-novation/design-system/components/form/FormField/FormField';
import { currentUserUuidSelector } from '../../../redux/user/userSelectors';

import Accordion from '$components/Accordion/Accordion';
import { MemberRole } from '$components/form/fields/MemberRole/MemberRole';
import { SubHeader } from '$components/layout/Header/SubHeader/SubHeader';
import { useTranslate } from '$hooks/useTranslate';
import { updateUserPreferences } from '$redux/user/userActions';

type SettingsUserRightsProps = {
    preferences: UserPrefs;
};

export function SettingsUserRights({ preferences }: SettingsUserRightsProps) {
    const dispatch = useDispatch();

    const { t } = useTranslate();

    const fieldId = (field: string) => 'settings-users-rights-' + field;

    const [triggerBundles, setTriggerBundles] = useState<boolean>();
    const [rights, setRight] = useState<UserPrefs['rights']>();
    const { currentUserUuid } = useSelector(currentUserUuidSelector);

    useEffect(() => {
        if (preferences && !rights) {
            setRight(preferences.rights);
            setTriggerBundles(preferences.trigger_bundles);
        }
    }, [preferences]);

    useEffect(() => {
        if (rights && rights !== preferences.rights) {
            dispatch(updateUserPreferences(currentUserUuid, { rights: rights }));
        }
    }, [rights, preferences]);

    useEffect(() => {
        if (rights && triggerBundles !== preferences.trigger_bundles) {
            dispatch(updateUserPreferences(currentUserUuid, { trigger_bundles: triggerBundles }));
        }
    }, [triggerBundles, preferences]);

    return (
        <>
            <SubHeader
                subClassName={'settings-sub-header'}
                title={t('settings.Default folder rights')}
            />

            <Accordion title={t('settings.General')} isOpen={true}>
                <FormFields
                    formId="settings-preferences"
                    fields={[
                        {
                            type: 'react-select',
                            id: fieldId('role'),
                            name: 'rights.role',
                            label: t('settings.Role name'),
                            value: rights?.role || ROLE_REPORTER,
                            onChange: (_e, value: FolderRole) =>
                                setRight({ ...rights, role: value }),
                            options: [
                                ROLE_MANAGER,
                                ROLE_PROVIDER,
                                ROLE_REPORTER,
                                ROLE_SPECTATOR
                            ].map((role) => ({
                                label: <MemberRole role={role} />,
                                value: role
                            }))
                        },
                        {
                            type: 'checkbox',
                            id: fieldId('download'),
                            name: 'rights.download',
                            label: t('common.Download'),
                            onChange: (e: SyntheticEvent<HTMLInputElement>) =>
                                setRight({ ...rights, download: e.currentTarget.checked }),
                            checked: rights?.download,
                            className: 'is-switch'
                        },
                        {
                            type: 'checkbox',
                            id: fieldId('export'),
                            name: 'rights.export',
                            label: t('folder.Allow exports'),
                            onChange: (e: SyntheticEvent<HTMLInputElement>) =>
                                setRight({ ...rights, export: e.currentTarget.checked }),
                            checked: rights?.export,
                            className: 'is-switch'
                        },
                        {
                            type: 'checkbox',
                            id: fieldId('trigger_bundles'),
                            name: 'trigger_bundles',
                            label: t('folder.Handle HTML bundles'),
                            onChange: (e: SyntheticEvent<HTMLInputElement>) =>
                                setTriggerBundles(e.currentTarget.checked),
                            checked: triggerBundles,
                            className: 'is-switch'
                        }
                    ]}
                />
            </Accordion>
        </>
    );
}
