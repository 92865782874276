import styles from './CheckboxList.module.scss';
import React, { useCallback, useEffect, useState } from 'react';
import cn from 'classnames';
import { Checkbox } from '../../../types';
import { FormField } from '../FormField/FormField';

export type CheckboxListProps = {
    checkboxes: Checkbox[];
    formId?: string;
    className?: string;
    fieldClassName?: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>, checkboxes: Checkbox[]) => void;
    search?: string | null;
    onSearch?: (found: Checkbox[]) => void;
    nbOfDisplayedItems?: number;
};

const CHECKBOX_HEIGHT: number = 32;

export function CheckboxList({
    checkboxes,
    formId,
    className,
    fieldClassName,
    onChange,
    search,
    onSearch,
    nbOfDisplayedItems
}: CheckboxListProps) {
    const [list, setList] = useState<Checkbox[]>(checkboxes);
    const [checked, setChecked] = useState<Checkbox[]>(checkboxes);

    useEffect(() => {
        setChecked(checkboxes);
    }, [checkboxes]);

    useEffect(() => {
        if (!search) {
            setList(checkboxes);
        } else {
            setList(
                checkboxes.filter(
                    ({ label }) =>
                        typeof label === 'string' &&
                        label.toLowerCase().indexOf(search.toLowerCase()) > -1
                )
            );
        }
    }, [checkboxes, search]);

    useEffect(() => {
        onSearch?.(list);
    }, [list, onSearch]);

    const onChecked = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const id = e.target.value;
            const newCheckboxes = checked.map((checkbox) =>
                checkbox.value === id ? { ...checkbox, checked: e.target.checked } : checkbox
            );
            onChange(e, newCheckboxes);
            setChecked(newCheckboxes);
        },
        [checked, onChange]
    );

    const listStyle = nbOfDisplayedItems
        ? { maxHeight: nbOfDisplayedItems * CHECKBOX_HEIGHT }
        : undefined;

    return (
        <ul className={cn(className, styles.list)} style={listStyle}>
            {list.map((checkbox) => (
                <li key={checkbox.id}>
                    <FormField
                        formId={formId}
                        className={fieldClassName}
                        type={'checkbox'}
                        {...checkbox}
                        search={search}
                        checked={checkbox.checked}
                        onChange={onChecked}
                    />
                </li>
            ))}
        </ul>
    );
}
