import { Item, ItemKit } from '@he-novation/config/types/item.types';
import {
    WSItemEventCreated,
    WSItemEventDeleted,
    WSItemEventUpdated,
    WSItemKitEventCreated,
    WSItemKitEventDeleted,
    WSItemKitEventItemAdded,
    WSItemKitEventItemRemoved,
    WSItemKitEventUpdated
} from '@he-novation/config/types/websockets/item.ws.types';
import { asyncItemKitsFetch, asyncItemsFetch } from '@he-novation/front-shared/async/item.async';
import update from 'immutability-helper';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';

import { itemKitsAtom, itemsAtom } from '$atoms/item-atoms';
import { workspaceNameAtom, workspaceUuidAtom } from '$atoms/workspace-atoms';
import { useSocketIO } from '$hooks/useSocketIO';

export function useItems() {
    const [items, setItems] = useAtom(itemsAtom);

    const workspaceName = useAtomValue(workspaceNameAtom);

    const fetchItems = () => asyncItemsFetch(workspaceName).then(setItems);

    return { items, fetchItems, setItems };
}

export function useItemKits() {
    const [itemKits, setItemKits] = useAtom(itemKitsAtom);

    const workspaceName = useAtomValue(workspaceNameAtom);

    const fetchItemKits = () => asyncItemKitsFetch(workspaceName).then(setItemKits);

    return { itemKits, fetchItemKits };
}

export function useItemsSocket() {
    const workspaceUuid = useAtomValue(workspaceUuidAtom);

    const setItems = useSetAtom(itemsAtom);

    const handleItemCreated = (item: Item) =>
        setItems((prevItems) => {
            if (!prevItems) return null;
            return [...prevItems, item];
        });

    const handleItemUpdated = (item: Item) =>
        setItems((prevItems) => {
            if (!prevItems) return null;
            const index = prevItems?.findIndex((i) => i.uuid === item.uuid);
            if (index === -1) return prevItems;
            return update(prevItems, { [index]: { $set: item } });
        });

    const handleItemDeleted = (uuid: string) =>
        setItems((prevItems) => {
            if (!prevItems) return null;
            return prevItems.filter((i) => i.uuid !== uuid);
        });

    useSocketIO({
        socket: 'items',
        room: workspaceUuid,
        actions: {
            sioItemCreate: ({ item }: WSItemEventCreated) => handleItemCreated(item),
            sioItemUpdate: ({ item }: WSItemEventUpdated) => handleItemUpdated(item),
            sioItemDelete: ({ uuid }: WSItemEventDeleted) => handleItemDeleted(uuid)
        }
    });
}

export function useItemKitsSocket(room?: string) {
    const items = useAtomValue(itemsAtom);
    const setItemKits = useSetAtom(itemKitsAtom);
    const workspaceUuid = useAtomValue(workspaceUuidAtom);

    const handleItemDeletedInKits = (uuid: string) =>
        setItemKits((prevItemKits) => {
            if (!prevItemKits) return null;

            return prevItemKits.map((kit) => ({
                ...kit,
                items: kit.items.filter((item) => item.uuid !== uuid)
            }));
        });

    const handleItemKitCreated = (itemKit: ItemKit) =>
        setItemKits((prevItemKits) => {
            if (!prevItemKits) return null;
            return [...prevItemKits, itemKit];
        });

    const handleItemKitUpdated = (itemKit: ItemKit) =>
        setItemKits((prevItemKits) => {
            if (!prevItemKits) return null;
            const index = prevItemKits.findIndex((i) => i.uuid === itemKit.uuid);
            if (index === -1) return prevItemKits;
            return update(prevItemKits, { [index]: { $set: itemKit } });
        });

    const handleItemKitDeleted = (uuid: string) =>
        setItemKits((prevItemKits) => {
            if (!prevItemKits) return null;
            return prevItemKits.filter((i) => i.uuid !== uuid);
        });

    const handleItemKitItemAdded = (itemKitUuid: string, itemUuid: string) => {
        const item = items?.find((i) => i.uuid === itemUuid);
        if (!item) return;

        setItemKits((prevItemKits) => {
            if (!prevItemKits) return null;
            const kitIndex = prevItemKits.findIndex((i) => i.uuid === itemKitUuid);
            if (kitIndex === -1) return prevItemKits;
            return update(prevItemKits, { [kitIndex]: { items: { $push: [item] } } });
        });
    };

    const handleItemKitItemRemoved = (itemKitUuid: string, itemUuid: string) =>
        setItemKits((prevItemKits) => {
            if (!prevItemKits) return null;
            const kitIndex = prevItemKits.findIndex((i) => i.uuid === itemKitUuid);
            if (kitIndex === -1) return prevItemKits;
            return update(prevItemKits, {
                [kitIndex]: {
                    items: {
                        $apply: (items: Item[]) => items?.filter((i) => i.uuid !== itemUuid)
                    }
                }
            });
        });

    useSocketIO({
        socket: 'items',
        room: room || workspaceUuid,
        actions: {
            sioItemDelete: ({ uuid }: WSItemEventDeleted) => handleItemDeletedInKits(uuid),
            sioItemKitCreate: ({ itemKit }: WSItemKitEventCreated) => handleItemKitCreated(itemKit),
            sioItemKitUpdate: ({ itemKit }: WSItemKitEventUpdated) => handleItemKitUpdated(itemKit),
            sioItemKitDelete: ({ uuid }: WSItemKitEventDeleted) => handleItemKitDeleted(uuid),
            sioItemKitAddItem: ({ itemKitUuid, itemUuid }: WSItemKitEventItemAdded) =>
                handleItemKitItemAdded(itemKitUuid, itemUuid),
            sioItemKitRemoveItem: ({ itemKitUuid, itemUuid }: WSItemKitEventItemRemoved) =>
                handleItemKitItemRemoved(itemKitUuid, itemUuid)
        }
    });
}
