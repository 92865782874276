import { z } from 'zod';
import { cameraCoordinatesSchema, sceneLightningSchema } from './threeDimensions.types';

export const noteMetadataSchema = z.object({
    freehand: z.array(z.array(z.object({ x: z.number(), y: z.number() }))).optional(),
    activeAudioTrack: z.number().optional(),
    activeSubtitles: z.array(z.string()).optional(),
    circle: z
        .array(
            z.object({
                x: z.number(),
                y: z.number(),
                radiusToWidth: z.number(),
                radiusToHeight: z.number()
            })
        )
        .optional(),
    original: z
        .object({
            ImageHeight: z.union([z.string(), z.number()]),
            ImageWidth: z.union([z.string(), z.number()]),
            height: z.number().optional(),
            width: z.number().optional()
        })
        .optional(),
    page: z.number().optional(),
    rectangle: z
        .object({
            x: z.number(),
            y: z.number(),
            width: z.number(),
            height: z.number()
        })
        .optional(),
    camera: cameraCoordinatesSchema.optional(),
    lightning: sceneLightningSchema.optional(),
    tcIn: z.string().optional(),
    tcOut: z.string().optional(),
    frame: z.number().optional()
});

export type NoteMetadata = z.infer<typeof noteMetadataSchema>;
