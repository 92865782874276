import styles from './Header.module.css';
import React, { ReactNode } from 'react';
import { createPortal } from 'react-dom';
import cn from 'classnames';

import {
    HeaderActions,
    HeaderActionsProps
} from '$components/layout/Header/HeaderActions/HeaderActions';
import {
    HeaderBackLink,
    HeaderBackLinkProps
} from '$components/layout/Header/HeaderBackLink/HeaderBackLink';
import {
    HeaderNav,
    HeaderNavigation
} from '$components/layout/Header/HeaderNavigation/HeaderNavigation';

type HeaderProps = {
    backLink?: HeaderBackLinkProps;
    title?: ReactNode;
    children?: React.ReactNode;
    className?: string;
    nav?: HeaderNav;
} & HeaderActionsProps;

export function Header({
    backLink,
    children,
    className,
    nav,
    title,
    search,
    filters,
    groupers,
    displayMode,
    actions
}: HeaderProps) {
    const headerElement = document.getElementById('header');
    if (!headerElement) return null;

    return createPortal(
        <div className={cn(styles.header, className)}>
            <div className={styles.content}>
                {backLink && <HeaderBackLink {...backLink} />}
                {title && <h1 className={styles.title}>{title}</h1>}
                {children}
                {actions || search || filters ? (
                    <HeaderActions
                        className={styles.actions}
                        actions={actions}
                        filters={filters}
                        groupers={groupers}
                        displayMode={displayMode}
                        search={search}
                    />
                ) : undefined}
            </div>
            {nav && <HeaderNavigation className={styles.nav} nav={nav} />}
        </div>,
        headerElement
    );
}
