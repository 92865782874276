import lookup, { Socket as ClientSocket } from 'socket.io-client';
import { SimpleRoom, WorkspaceRoom } from '../../types/socket.types';
import {
    SocketAction,
    SOCKETS_ACTIVITY,
    SOCKETS_ARCHIVES,
    SOCKETS_CAST,
    SOCKETS_CLIENT,
    SOCKETS_CUSTOM_FIELDS,
    SOCKETS_ENTITIES,
    SOCKETS_EVENTS,
    SOCKETS_FOLDER,
    SOCKETS_FOLDERS,
    SOCKETS_ITEMS,
    SOCKETS_MISC,
    SOCKETS_NOTE,
    SOCKETS_PROJECTS,
    SOCKETS_SUBTITLES,
    SOCKETS_USER,
    SOCKETS_WORKSPACE,
    SOCKETS_WORKSPACE_ADMIN_USERS,
    SOCKETS_WORKSPACE_TEAMS,
    SOCKETS_WORKSPACE_USERS
} from './sockets.constants';

type RegisteredSocket = {
    room: SimpleRoom | WorkspaceRoom;
    properties: string[];
    listeners: number;
};
type RegisteredAction = { actionName: string; actionFunction: (data: any) => void };

export class ClientSocketHandler<T extends boolean = false> {
    id: string;
    channel: string;
    actions: readonly SocketAction[];
    sio: ClientSocket;

    registeredSockets: RegisteredSocket[] = [];

    constructor(options: {
        id: string;
        channel: string;
        actions: readonly SocketAction[];
        scopedToWorkspace?: T;
    }) {
        this.id = options.id;
        this.channel = options.channel;
        this.actions = options.actions;
    }

    clientInit(sioLib: typeof lookup, host: string) {
        this.sio = sioLib(host + this.id, {
            withCredentials: true,
            transports: ['websocket']
        });
    }

    private clientSetEventHandlers(room: T extends true ? WorkspaceRoom : SimpleRoom, view: any) {
        const properties = Object.keys(view);
        let registeredSocket = this.registeredSockets.find((socket) => {
            return socket.room === room && socket.properties.every((p) => properties.includes(p));
        });
        if (registeredSocket) {
            registeredSocket.listeners++;
        } else {
            registeredSocket =
                this.registeredSockets[
                    this.registeredSockets.push({
                        room: room,
                        properties,
                        listeners: 1
                    }) - 1
                ];

            this.sio.on('reconnect', () => {
                this.sio.emit('join', room);
            });
            this.sio.emit('join', room);
        }

        const registeredActions: RegisteredAction[] = [];

        this.actions.forEach((action) => {
            const actionFunction = function (data: {
                room: T extends true ? WorkspaceRoom : SimpleRoom;
                [key: string]: unknown;
            }) {
                if (
                    typeof view[action.client] !== 'function' ||
                    (data.room && data.room !== room)
                ) {
                    return false;
                }
                view[action.client](data);
            };

            registeredActions.push({
                actionName: action.name,
                actionFunction: actionFunction
            });

            this.sio.on(action.name, actionFunction);
        });

        return () => {
            if (!registeredSocket) return false;
            for (const { actionName, actionFunction } of registeredActions) {
                this.sio.off(actionName, actionFunction);
            }
            registeredSocket.listeners--;
            if (registeredSocket.listeners > 0) return;
            this.registeredSockets.splice(this.registeredSockets.indexOf(registeredSocket), 1);
            this.sio.emit('leave', room);
        };
    }

    clientActionsInit(room: T extends true ? WorkspaceRoom : SimpleRoom, view: any) {
        return this.clientSetEventHandlers(room, view);
    }

    emit<T = any>(roomUuid: string, event: string, data: T) {
        this.sio.emit(event, roomUuid, data);
    }
}

export const client = new ClientSocketHandler(SOCKETS_CLIENT);

export const customFields = new ClientSocketHandler(SOCKETS_CUSTOM_FIELDS);

export const events = new ClientSocketHandler(SOCKETS_EVENTS);

export const folder = new ClientSocketHandler(SOCKETS_FOLDER);
export const folders = new ClientSocketHandler(SOCKETS_FOLDERS);

export const activity = new ClientSocketHandler(SOCKETS_ACTIVITY);

export const archives = new ClientSocketHandler(SOCKETS_ARCHIVES);

export const note = new ClientSocketHandler(SOCKETS_NOTE);

export const misc = new ClientSocketHandler(SOCKETS_MISC);

export const projects = new ClientSocketHandler(SOCKETS_PROJECTS);

export const entities = new ClientSocketHandler(SOCKETS_ENTITIES);

export const items = new ClientSocketHandler(SOCKETS_ITEMS);

export const subtitles = new ClientSocketHandler(SOCKETS_SUBTITLES);

export const user = new ClientSocketHandler(SOCKETS_USER);

export const casts = new ClientSocketHandler(SOCKETS_CAST);

export const workspace = new ClientSocketHandler(SOCKETS_WORKSPACE);

export const workspaceAdminUsers = new ClientSocketHandler(SOCKETS_WORKSPACE_ADMIN_USERS);

export const workspaceTeams = new ClientSocketHandler(SOCKETS_WORKSPACE_TEAMS);

export const workspaceUsers = new ClientSocketHandler(SOCKETS_WORKSPACE_USERS);
