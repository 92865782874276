import React, { SyntheticEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UserPrefs } from '@he-novation/config/types/user.types';
import { FormFields } from '@he-novation/design-system/components/form/FormField/FormField';
import tz from 'compact-timezone-list';
import { currentUserUuidSelector, localeSelector } from '../../../redux/user/userSelectors';

import Accordion from '$components/Accordion/Accordion';
import { SubHeader } from '$components/layout/Header/SubHeader/SubHeader';
import { useTranslate } from '$hooks/useTranslate';
import { updateProfile, updateUserPreferences } from '$redux/user/userActions';

type SettingsUserInterfaceProps = {
    preferences: UserPrefs;
};

export function SettingsUserInterface({ preferences }: SettingsUserInterfaceProps) {
    const dispatch = useDispatch();

    const { t } = useTranslate();

    const fieldId = (field: string) => 'settings-users-ui-' + field;

    const _locale = useSelector(localeSelector);
    const [locale, setLocale] = useState<string>();
    const [timezone, setTimezone] = useState<string>();
    const [ui, setUi] = useState<UserPrefs['ui']>();
    const { currentUserUuid } = useSelector(currentUserUuidSelector);

    useEffect(() => {
        if (preferences && !ui) {
            setUi(preferences.ui);
            setLocale(_locale);
            setTimezone(preferences.timezone);
        }
    }, [preferences, _locale]);

    useEffect(() => {
        if (ui && ui !== preferences.ui) {
            dispatch(updateUserPreferences(currentUserUuid, { ui: ui }));
        }
    }, [ui, preferences]);

    useEffect(() => {
        if (ui && locale !== _locale) {
            dispatch(updateProfile(currentUserUuid, { locale: locale }));
        }
    }, [locale, preferences]);

    useEffect(() => {
        if (ui && timezone !== preferences.timezone) {
            dispatch(updateUserPreferences(currentUserUuid, { timezone: timezone }));
        }
    }, [timezone, preferences]);

    return (
        <>
            <SubHeader subClassName={'settings-sub-header'} title={t('settings.User interface')} />

            <Accordion title={t('settings.General')} isOpen={true}>
                <FormFields
                    formId="settings-preferences"
                    fields={[
                        {
                            type: 'checkbox',
                            id: fieldId('previewOfficeMs'),
                            name: 'ui.previewOfficeMs',
                            label: t(
                                'settings.Preview Microsoft© Office files, this options implies that your are allowing Microsoft© to access these files online'
                            ),
                            onChange: (e: SyntheticEvent<HTMLInputElement>) =>
                                setUi({ ...ui, previewOfficeMs: e.currentTarget.checked }),
                            checked: !!ui?.previewOfficeMs,
                            className: 'is-switch'
                        },
                        {
                            type: 'checkbox',
                            id: fieldId('tooltips'),
                            name: 'ui.tooltips',
                            label: t('settings.Display tooltips'),
                            onChange: (e: SyntheticEvent<HTMLInputElement>) =>
                                setUi({ ...ui, tooltips: e.currentTarget.checked }),
                            checked: !!ui?.tooltips,
                            className: 'is-switch'
                        }
                    ]}
                />
            </Accordion>

            <Accordion title={t('settings.Language')} isOpen={true}>
                <FormFields
                    formId="settings-preferences"
                    fields={[
                        {
                            type: 'select',
                            id: fieldId('locale'),
                            name: 'locale',
                            label: t('settings.Language'),
                            value: locale,
                            onChange: (e: SyntheticEvent<HTMLInputElement>) =>
                                setLocale(e.currentTarget.value),
                            children: [
                                {
                                    label: 'English',
                                    value: 'en'
                                },
                                {
                                    label: 'Deutsch',
                                    value: 'de'
                                },
                                {
                                    label: 'Français',
                                    value: 'fr'
                                }
                            ].map(({ label, value }) => (
                                <option value={value} key={value}>
                                    {label}
                                </option>
                            ))
                        },
                        {
                            type: 'select',
                            id: fieldId('timezone'),
                            name: 'timezone',
                            label: 'Timezone',
                            value: timezone,
                            onChange: (e: SyntheticEvent<HTMLInputElement>) =>
                                setTimezone(e.currentTarget.value),
                            children: tz.map(({ offset, tzCode }) => (
                                <option value={tzCode} key={tzCode}>
                                    {`${offset} ${tzCode}`}
                                </option>
                            ))
                        }
                    ]}
                />
            </Accordion>
        </>
    );
}
