import type React from 'react';
import type { ROLES } from '@he-novation/config/constants/projects.constants';
import type { CalendarZoom } from '@he-novation/config/types/calendar.types';
import type { ClientLabel, ClientLabelType } from '@he-novation/config/types/clientLabel.types';
import { EventIntervalUnit } from '@he-novation/config/types/db/enums';
import type { EventDays, HerawEvent } from '@he-novation/config/types/event.types';
import { Item } from '@he-novation/config/types/item.types';
import { Project } from '@he-novation/config/types/project.types';
import { Task } from '@he-novation/config/types/task.types';
import { WorkspaceUser } from '@he-novation/config/types/workspace-team.types';
import { DragType } from './hooks/useCalendarMouseEvents';

import { ContactType } from '$types/contactTypes';

export type ScrollCalendarRowDataType =
    | {
          users: string[];
      }
    | { labels: string[] }
    | { project: { uuid: string; name: string; company?: { name: string } | null } | null }
    | { items: string[] }
    | null;

export type ScrollCalendarRowType = {
    unAssigned?: boolean;
    label: React.ReactNode;
    filterString: string;
    items: ScrollCalendarItemType[];
    data: ScrollCalendarRowDataType;
    filterGrouper?: string[]; // array of labels uuids for grouper filtering
};

export type ScrollCalendarDisplayedEvent = ScrollCalendarItemType & {
    displayedStartDate: Date;
    displayedEndDate: Date;
    level: number;
    style: {
        left: number;
        width: number;
        isEarly: boolean;
        isLate: boolean;
    };
};
export type ScrollCalendarDisplayedRow = {
    data: ScrollCalendarRowDataType;
    unAssigned?: boolean;
    label: React.ReactNode;
    filterString: string;
    displayedEvents: ScrollCalendarDisplayedEvent[];
    maxRowLines: number;
    rowHeight: number;
};

type BaseScrollCalendarItem = Omit<HerawEvent, 'project' | 'owner' | 'users'> & {
    owner: {
        uuid?: string | null;
        email: string;
    };
    users: string[];
    isTask?: boolean;
    task?: Task;
    editionType?: DragType;
    save?: ScrollCalendarDisplayedEvent;
    project?: { uuid: string; name: string; company?: { name: string } | null } | null;
};

export type ScrollCalendarItemType =
    | (BaseScrollCalendarItem & {
          recurringIndex?: number;
          firstOccurenceDate?: Date;
          isDraft: true;
          draftLevel?: number;
          edits?: { [k: string]: string | number | boolean | null };
          save: ScrollCalendarDisplayedEvent;
      })
    | (BaseScrollCalendarItem & {
          recurringIndex?: number;
          firstOccurenceDate?: Date;
          isDraft?: false;
          draftLevel?: number;
          edits?: { [k: string]: string | number | boolean | null };
      });

export type TimeSlot = {
    start: number;
    end: number;
};

export type GlobalRowsType = {
    users?: (WorkspaceUser | ContactType)[];
    labels?: ClientLabel<ClientLabelType>[];
    items?: Item[];
    projects?: Project[];
};

export enum CalendarItemColorType {
    Project = 'Project',
    Label = 'Label',
    Entity = 'Entity'
}

export enum CalendarFilterEnum {
    Teams = 'Teams',
    Labels = 'Labels',
    Authors = 'Authors'
}

export enum ScrollCalendarGrouper {
    Users = 'users',
    Labels = 'labels',
    Projects = 'projects',
    Items = 'items'
}

export type CalendarPreferences = {
    grouper?: ScrollCalendarGrouper;
    itemColorType?: CalendarItemColorType;
    timeSlots?: TimeSlot;
    grouperFilters?: Record<string, string[]>;
};

export type ScrollCalendarWidths = {
    beforeBusinessHourWidth: number;
    afterBusinessHourWidth: number;
    businessHourWidth: number;
    columnWidth: number;
};

export type HerawEventWithOptionalUuid = Omit<HerawEvent, 'uuid'> & { uuid?: string };
export type OpenContextModal = (
    event: HerawEventWithOptionalUuid,
    item: ScrollCalendarItemType,
    triggerPosition: [number, number]
) => void;

export type ScrollCalendarPosition = { x: number | null; y: number | null };
export type ScrollCalendarDragStart = {
    day?: Date | null;
    row?: ScrollCalendarDisplayedRow | null;
    position?: ScrollCalendarPosition;
    rowIndex?: number | null;
    moveDayGrabbed?: Date;
    isModalOpen?: boolean;
};

export type CalendarZoomConfig = {
    columnWidth: number;
    timeSlot?: number[];
    isLargeView?: boolean;
    range: number;
};

export type CalendarConfig = {
    [key in CalendarZoom]: CalendarZoomConfig;
};

export type QuickEventPayload = {
    uuid?: string;
    title?: string | null;
    description?: string | null;
    startDate: Date;
    endDate: Date;
    duration: number;
    recurrence?: {
        intervalValue: number;
        intervalUnit: EventIntervalUnit;
        days: EventDays;
    };
    canEditAndDelete?: boolean;
    project?: { uuid: string; name: string; role?: ROLES } | null;
    teams?: { uuid: string; name: string }[];
    items?: string[];
    color?: string | null;
    labels?: string[];
    users?: string[];
    client?: { uuid: string; name: string } | null;
    reminders?: unknown[];
};
