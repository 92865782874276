import styles from './SidePanelNotesHeader.module.css';
import formStyles from '@he-novation/design-system/styles/form-styles.module.css';
import React, { useCallback } from 'react';
import { taskStatusToI18n } from '@he-novation/config/constants/projects.constants';
import { TaskStatus } from '@he-novation/config/types/task.types';
import { AbsoluteContentButton } from '@he-novation/design-system/components/buttons/AbsoluteContentButton/AbsoluteContentButton';
import { Button } from '@he-novation/design-system/components/buttons/Button/Button';
import { FormField } from '@he-novation/design-system/components/form/FormField/FormField';
import { DirectionX, DirectionY } from '@he-novation/design-system/utils/getAbsolutePosition';

import { useTranslate } from '$hooks/useTranslate';

export type TaskStatusFilterProps = {
    taskStatusFilters: TaskStatus[];
    setTaskStatusFilters: (taskStatusFilters: TaskStatus[]) => void;
};
export function TaskStatusFilter({
    taskStatusFilters,
    setTaskStatusFilters
}: TaskStatusFilterProps) {
    const onChangeHandlerFactory = useCallback(
        (taskStatus: TaskStatus) => (e: React.ChangeEvent<HTMLInputElement>) => {
            if (e.currentTarget.checked) setTaskStatusFilters([...taskStatusFilters, taskStatus]);
            else setTaskStatusFilters(taskStatusFilters.filter((status) => status !== taskStatus));
        },
        [taskStatusFilters, setTaskStatusFilters]
    );
    const { t } = useTranslate();

    return (
        <AbsoluteContentButton
            tagName={Button}
            icon={[
                {
                    name: 'check_encircled',
                    stroke: 'white'
                }
            ]}
            direction={[DirectionX.RightInner, DirectionY.Bottom]}
            contentClassName={formStyles.dark}
            content={
                <div className={styles.menu} style={{ width: 200 }}>
                    {[
                        TaskStatus.TO_DO,
                        TaskStatus.IN_PROGRESS,
                        TaskStatus.TO_VALIDATE,
                        TaskStatus.DONE
                    ].map((status) => (
                        <FormField
                            key={status}
                            id={`task-status-filter-${status}`}
                            value={status}
                            label={t(taskStatusToI18n(status))}
                            type="checkbox"
                            onChange={onChangeHandlerFactory(status)}
                            checked={taskStatusFilters.includes(status)}
                        />
                    ))}
                </div>
            }
        />
    );
}
