import { sanitizedString } from '@he-novation/utils/zod.utils';
import z, { ZodType } from 'zod';
import { castSelectParams, castSpecificParamsSchema } from './cast.payload';
import { folderParamsSchema, folderUuidSchema } from './folder.payload';
import { workspaceParamsSchema } from './workspace.payload';

export enum ArchiveNodeType {
    File = 'file',
    Folder = 'folder'
}
const archiveFile = z.object({
    type: z.literal(ArchiveNodeType.File),
    bucket: z.string(),
    key: z.string(),
    name: z.string()
});

export type ArchiveFile = z.infer<typeof archiveFile>;

const baseArchiveFolder = z.object({
    type: z.literal(ArchiveNodeType.Folder),
    name: z.string()
});

export type ArchiveFolder = z.infer<typeof baseArchiveFolder> & {
    content: (ArchiveFile | ArchiveFolder)[];
};

export type ArchiveNode = ArchiveFile | ArchiveFolder;

const archiveFolder = baseArchiveFolder.extend({
    content: z.lazy(() => archiveFile.or(archiveFolder).array())
}) as ZodType<ArchiveFolder>;

export type ArchiveBucket = {
    name: string;
    region: string;
    endpoint?: string | null;
    provider?: string;
};
export type ArchivePayload = {
    env: string;
    acl: 'private' | 'bucket-owner-full-control';
    key: string;
    uuid: string;
    size: number;
    bucket: ArchiveBucket;
    content: ArchiveNode[];
    buckets: ArchiveBucket[];
};

export const archiveCreationBodySchema = z.object({
    recursive: z.boolean(),
    email: z.string().optional(),
    password: z.string().optional()
});

export type ArchiveCreationBody = z.infer<typeof archiveCreationBodySchema>;

export const archiveSpecificParamsSchema = z.object({
    archiveUuid: z.string()
});

export type ArchiveSpecificSchema = z.infer<typeof archiveSpecificParamsSchema>;

export const folderArchiveCreationParamsSchema = folderParamsSchema;

export type FolderArchiveCreationParams = z.infer<typeof folderArchiveCreationParamsSchema>;

export const castArchiveCreationParamsSchema = z.object({
    castUid: sanitizedString({ max: 6, min: 6 })
});

export type CastArchiveCreationParams = z.infer<typeof castArchiveCreationParamsSchema>;

export const castArchiveCreationQuerySchema = z.object({
    castFolderUuid: z.string().uuid().optional()
});

export type CastArchiveCreationQuery = z.infer<typeof castArchiveCreationQuerySchema>;
