import { buildApiCall } from '@he-novation/config/apis/api-fetch';
import {
    API_ITEM,
    API_ITEM_EVENTS,
    API_ITEM_KIT,
    API_ITEM_PICTURES
} from '@he-novation/config/apis/item-api';
import { Item, ItemKit, ItemWithEvents } from '@he-novation/config/types/item.types';
import { ItemCreateBody, ItemUpdateBody } from '@he-novation/config/types/payloads/item.payload';
import { isNull, omitBy } from 'lodash/fp';
import { asyncAssetStatusUpdate } from './asset.async';

export const asyncItemsFetch = (workspaceName: string): Promise<Item[]> =>
    buildApiCall(API_ITEM.LIST)({
        params: { workspaceName }
    });

export const asyncItemCreate = (
    workspaceName: string,
    { name, serialNumber, category, description, clientItemLabelUids }: ItemCreateBody
): Promise<Item> =>
    buildApiCall(API_ITEM.POST)({
        params: { workspaceName },
        body: omitBy(isNull, {
            name,
            serialNumber,
            category,
            description,
            clientItemLabelUids
        })
    });

export const asyncItemUpdate = (
    workspaceName: string,
    uuid: string,
    { name, serialNumber, category, description, clientItemLabelUids }: ItemUpdateBody
): Promise<Item> =>
    buildApiCall(API_ITEM.UPDATE)({
        params: { workspaceName, itemUuid: uuid },
        body: omitBy(isNull, {
            name,
            serialNumber,
            category,
            description,
            clientItemLabelUids
        })
    });

export const asyncItemDelete = (workspaceName: string, itemUuid: string) =>
    buildApiCall(API_ITEM.DELETE)({
        params: { workspaceName, itemUuid }
    });

export const asyncItemKitsFetch = (workspaceName: string): Promise<ItemKit[]> =>
    buildApiCall(API_ITEM_KIT.LIST)({
        params: { workspaceName }
    });

export const asyncItemKitCreate = (
    workspaceName: string,
    { name }: { name: string }
): Promise<ItemKit> =>
    buildApiCall(API_ITEM_KIT.POST)({
        params: { workspaceName },
        body: { name }
    });

export const asyncItemKitDelete = (workspaceName: string, itemKitUuid: string) =>
    buildApiCall(API_ITEM_KIT.DELETE)({
        params: { workspaceName, itemKitUuid }
    });

export const asyncItemKitEdit = (
    workspaceName: string,
    { uuid, name }: { uuid: string; name: string }
): Promise<ItemKit> =>
    buildApiCall(API_ITEM_KIT.UPDATE)({
        params: { workspaceName, itemKitUuid: uuid },
        body: { name }
    });

export const asyncItemKitAddItem = (workspaceName: string, itemKitUuid: string, itemUuid: string) =>
    buildApiCall(API_ITEM_KIT.ADD_ITEM)({
        params: { workspaceName, itemKitUuid },
        body: { uuid: itemUuid }
    });

export const asyncItemKitDeleteItem = (
    workspaceName: string,
    itemKitUuid: string,
    itemUuid: string
) =>
    buildApiCall(API_ITEM_KIT.REMOVE_ITEM)({
        params: { workspaceName, itemKitUuid, itemUuid }
    });

export const asyncItemEventsFetch = (
    workspaceName: string,
    itemUuid: string,
    startDate?: Date,
    endDate?: Date
): Promise<ItemWithEvents> =>
    buildApiCall(API_ITEM_EVENTS.LIST)({
        params: { workspaceName, itemUuid },
        query: {
            start: startDate?.getTime(),
            end: endDate?.getTime()
        }
    }).then((r: ItemWithEvents) => {
        r.events = r.events.map((e) => ({
            ...e,
            startDate: new Date(e.startDate),
            endDate: new Date(e.endDate)
        }));
        return r;
    });

export const asyncItemPictureUploadLink = async (
    workspaceName: string,
    itemUuid: string,
    blob: Blob
) => {
    const { link, headers, assetUuid } = await buildApiCall(API_ITEM_PICTURES.POST)({
        params: { workspaceName, itemUuid },
        body: {
            size: blob.size,
            contentType: blob.type
        }
    });

    await fetch(link, {
        method: 'PUT',
        headers: {
            'Content-Type': blob.type,
            ...headers
        },
        body: blob
    });
    return await asyncAssetStatusUpdate(workspaceName, assetUuid, {
        status: 'READY',
        signUrl: true
    });
};
