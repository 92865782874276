import { z } from 'zod';
import { FolderRole } from './db/enums';
import { contentFileSchema } from './file.types';
import { legacyProfileSchema } from './user.types';

export { FolderRole } from './db/enums';

export type FolderMetadataAdmittance = {
    role: string;
    download: 1 | 0 | '0' | '1' | boolean;
    export: 1 | 0 | '0' | '1' | boolean;
};

const folderLiveNotifySchema = z.object({
    access_grant: z
        .union([z.boolean(), z.literal('all'), z.literal('email'), z.literal('desktop')])
        .optional(),
    comment_add: z
        .union([z.boolean(), z.literal('all'), z.literal('email'), z.literal('desktop')])
        .optional(),
    file_download: z
        .union([z.boolean(), z.literal('all'), z.literal('email'), z.literal('desktop')])
        .optional(),
    file_export: z
        .union([z.boolean(), z.literal('all'), z.literal('email'), z.literal('desktop')])
        .optional(),
    file_new: z
        .union([z.boolean(), z.literal('all'), z.literal('email'), z.literal('desktop')])
        .optional(),
    file_set_final: z
        .union([z.boolean(), z.literal('all'), z.literal('email'), z.literal('desktop')])
        .optional()
});

export type FolderLiveNotify = z.infer<typeof folderLiveNotifySchema>;

export const folderMetadataUserSchema = z.object({
    livenotify: folderLiveNotifySchema.optional()
});
export type FolderMetadataUser = z.infer<typeof folderMetadataUserSchema>;

export const folderMetadataRefSchema = z.object({
    name: z.string(),
    parent_uuid: z.string().nullable()
});
export const admittanceSchema = z.object({
    role: z.nativeEnum(FolderRole),
    download: z.union([z.number(), z.string(), z.boolean()]),
    export: z.union([z.number(), z.string(), z.boolean()])
});

export const folderMetadataSchema = z.object({
    icon: z.string().optional(),
    cover: z.string().optional(),
    admittance: z.union([admittanceSchema, z.literal(false)]).optional(),
    user: folderMetadataUserSchema.optional(),
    notes: z.string().optional(),
    ref: folderMetadataRefSchema.optional()
});

export type FolderMetadata = z.infer<typeof folderMetadataSchema>;

export type BaseFolderType = {
    /**
     * @TJS-format uuid
     */
    uuid: string;
    name: string;
};

export const folderContentFileSchema = z
    .object({
        type: z.literal('file'),
        created: z.date().nullable(),
        encoding: z.any().optional().nullable(),
        queued: z.boolean().optional(),
        resolution: z.array(z.number()).optional().nullable(),
        uploadProgress: z.number().optional(),
        castFileUuid: z.string().nullable().optional(),
        timecodestart: z.string().nullable().optional(),
        isTranscoding: z.boolean().optional(),
        transcodingEstimatedEndDate: z.date().optional(),
        workspace: z.object({
            uuid: z.string().uuid(),
            name: z.string()
        })
    })
    .merge(contentFileSchema);

export type FolderContentFile = z.infer<typeof folderContentFileSchema>;

export type FolderContentChildRecursive =
    | FolderContentFile
    | (FolderContentFolder & { children: FolderContentChildRecursive[] });

const folderSchema = z.object({
    uuid: z.string(),
    name: z.string(),
    role: z.nativeEnum(FolderRole),
    created: z.date(),
    updated: z.date().nullable(),
    isEncrypted: z.boolean(),
    canDownload: z.boolean(),
    canExport: z.boolean(),
    labels: z.array(z.string()),
    tags: z.array(z.string()),
    admittance: z.boolean(),
    castTriggers: z.array(
        z.object({
            castUid: z.string(),
            castName: z.string(),
            castFolderUuid: z.string().nullable().optional()
        })
    ),
    userMetadata: folderMetadataUserSchema.optional(),
    folder: z
        .object({
            uuid: z.string(),
            name: z.string()
        })
        .nullable()
        .optional(),
    project: z
        .object({
            uuid: z.string(),
            name: z.string(),
            isProjectRoot: z.boolean(),
            folderUuid: z.string()
        })
        .nullable()
        .optional(),
    creator: z
        .object({
            uuid: z.string(),
            firstname: z.string().optional().nullable(),
            lastname: z.string().optional().nullable(),
            email: z.string(),
            picture: z.string().nullable().optional()
        })
        .nullable(),
    workspace: z.object({
        uuid: z.string(),
        name: z.string()
    })
});
export type Folder = z.infer<typeof folderSchema>;

export const fullFolderSchema = z
    .object({
        metadata: folderMetadataSchema,
        watermark: z.boolean(),
        watermarkUrl: z.string().nullable().optional(),
        exportMode: z.enum(['never', 'always', 'final']),
        defaultPresets: z.any(),
        expires: z.date().optional().nullable(),
        project: z
            .object({
                uuid: z.string(),
                name: z.string(),
                isProjectRoot: z.boolean(),
                folderUuid: z.string()
            })
            .optional(),
        workspace: z.object({
            uuid: z.string(),
            name: z.string()
        }),
        handlesHtmlBundles: z.boolean()
    })
    .merge(folderSchema);

export type FullFolder = z.infer<typeof fullFolderSchema>;

export type FullFolderWithoutRole = Omit<FullFolder, 'role' | 'canDownload' | 'canExport'>;

export const folderContentFolderSchema = z
    .object({
        type: z.literal('folder'),
        created: z.date().nullable().optional(),
        size: z.number().optional(),
        metadata: z.any().optional()
    })
    .merge(folderSchema);

export type FolderContentFolder = z.infer<typeof folderContentFolderSchema>;

export type FolderContentFolderOptionalType = Omit<FolderContentFolder, 'type'> & {
    type?: 'folder';
};

export const folderStateFileSchema = z
    .object({
        created: z.date().nullable(),
        encoding: z.any().optional(),
        queued: z.boolean().optional()
    })
    .merge(folderContentFileSchema);

export type FolderStateFile = z.infer<typeof folderStateFileSchema>;

export const folderContentChildSchema = z.union([
    folderContentFileSchema,
    folderContentFolderSchema
]);
export type FolderContentChild = z.infer<typeof folderContentChildSchema>;
export const folderContentSchema = z.array(folderContentChildSchema);
export type FolderContent = z.infer<typeof folderContentSchema>;

export const folderMemberSchema = legacyProfileSchema.extend({
    created: z.date(),
    canDownload: z.boolean(),
    email: z.string(),
    canExport: z.boolean(),
    role: z.nativeEnum(FolderRole),
    uuid: z.string(),
    pending: z.boolean().optional(),
    locale: z.union([z.literal('en'), z.literal('fr'), z.literal('de')])
});

export type FolderMember = z.infer<typeof folderMemberSchema>;

export type FolderMemberWithWorkspaces = FolderMember & {
    workspaces: {
        uuid: string;
        name: string;
    }[];
};

export type LegacyFolderSizeData = {
    folder: {
        count: number;
        files: number;
        size: number;
    };
};

export const folderSizeSchema = z.object({
    folders: z.number(),
    files: z.number(),
    size: z.number()
});

export type FolderSizeData = z.infer<typeof folderSizeSchema>;

export const breadcrumbSchema = z.object({
    uuid: z.string(),
    name: z.string(),
    project: z
        .object({
            uuid: z.string(),
            name: z.string(),
            rootFolderUuid: z.string().nullable().optional()
        })
        .nullable()
});

export type BreadCrumb = z.infer<typeof breadcrumbSchema>;

export type RecursiveFolder = {
    uuid: string;
    name: string;
    children: RecursiveFolder[];
};

export type CreateFolderData = {
    parentFolderUuid?: string | null;
    copyParentProperties?: boolean;
    name: string;
    projectUuid?: string | null;
    copyParentMembers?: boolean;
    copyParentMembersPending?: boolean;
    labels?: string[];
};

export type FolderShare = {
    canDownload: boolean;
    canExport: boolean;
    role: FolderRole;
    folder: { uuid: string; name: string };
    user: {
        uuid: string;
        email: string;
    };
};

export type RawProjectFolderTree = {
    id: number;
    name: string;
    uuid: string;
    role: FolderRole;
    children: RawProjectFolderTree[];
};

export type ProjectFolderTree = {
    name: string;
    uuid: string;
    role: FolderRole;
    children: ProjectFolderTree[];
};

export type BasicFolder = {
    uuid: string;
    name: string;
    created: Date;
    updated: Date | null;
    type: 'basic-folder';
    role: FolderRole;
    size?: never;
    workspaceName: string;
};

export const trashInfosSchema = z.object({
    uuid: z.string(),
    folders: z.array(z.string()),
    files: z.array(z.string())
});

export type TrashInfos = z.infer<typeof trashInfosSchema>;
