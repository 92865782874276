import styles from './SidePanelWorkspaceTeams.module.css';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    directoryWorkspaceMembersLink,
    directoryWorkspaceTeamLink
} from '@he-novation/config/paths/herawFrontUris';
import {
    DELETE_CONFIRM,
    WORKSPACE_TEAM_ADD,
    WORKSPACE_TEAM_EDIT
} from '@he-novation/config/paths/modals.constants';
import { ClientUserRole } from '@he-novation/config/types/db/enums';
import { Button, ButtonTone } from '@he-novation/design-system/components/buttons/Button/Button';
import { Title } from '@he-novation/design-system/components/text/Title/Title';
import { Loader } from '@he-novation/design-system/components/widgets/Loader/Loader';
import { asyncDeleteWorkspaceTeam } from '@he-novation/front-shared/async/workspace-team.async';
import { useAtomValue } from 'jotai';
import { SidePanelList, SidePanelListItemType } from '../../SidePanel/SidePanelList/SidePanelList';

import { workspaceNameAtom, workspaceRoleAtom } from '$atoms/workspace-atoms';
import { useModal } from '$hooks/useModal';
import { useTranslate } from '$hooks/useTranslate';
import { useWorkspaceTeams } from '$hooks/useWorkspaceTeams';
import { set } from '$redux/route/routeActions';
import { routeParamsSelector } from '$redux/route/routeSelectors';

export default function SidePanelWorkspaceTeams() {
    const dispatch = useDispatch();
    const { t } = useTranslate();

    const { openModal, closeModal } = useModal();

    const workspaceName = useAtomValue(workspaceNameAtom);
    const { workspaceTeams } = useWorkspaceTeams();

    const workspaceRole = useAtomValue(workspaceRoleAtom);
    const { routeParams } = useSelector(routeParamsSelector);

    const addGroup = useCallback(() => openModal(WORKSPACE_TEAM_ADD), []);

    const removeGroup = useCallback(
        (uuid: string, routeParams: { route?: string; subroute?: string }) =>
            openModal(DELETE_CONFIRM, {
                onDelete: () => {
                    if (routeParams.route === 'workspace-team' && routeParams.subroute === uuid)
                        dispatch(set(directoryWorkspaceMembersLink(workspaceName)));

                    asyncDeleteWorkspaceTeam(workspaceName, uuid).then(closeModal);
                },
                message: t('common.Are you sure you want to delete this?'),
                title: t('common.Delete'),
                cancel: t('common.Cancel')
            }),
        []
    );
    const editGroup = useCallback(
        (values: SidePanelListItemType) =>
            openModal(WORKSPACE_TEAM_EDIT, {
                values
            }),
        []
    );

    return (
        <div className={styles.workspaceTeamSidePanel}>
            <Title tag="h2" type="tab" className={styles.title} icon="users">
                {t('contact.Workspace teams')}
            </Title>

            {!workspaceTeams && <Loader />}

            {!!workspaceTeams && (
                <div className={styles.content}>
                    <SidePanelList
                        className={styles.list}
                        items={workspaceTeams.map(({ name, uuid, members }) => ({
                            name,
                            count: members.length,
                            href: directoryWorkspaceTeamLink(workspaceName, uuid),
                            uuid
                        }))}
                        canEdit={(_) => workspaceRole === ClientUserRole.ADMINISTRATOR}
                        editItem={editGroup}
                        deleteItem={({ uuid }) => removeGroup(uuid, routeParams)}
                    />

                    <Button icon={['user', 'plus']} tone={ButtonTone.Outlined} onClick={addGroup}>
                        {t('contact.Create a new team')}
                    </Button>
                </div>
            )}
        </div>
    );
}
