import styles from './ClientLoginPreview.module.css';
import React from 'react';
import cn from 'classnames';

export type ClientLoginPreviewProps = {
    backgroundColor?: string;
    background?: string | null;
    logo?: string | null;
    className?: string;
};

export function ClientLoginPreview({
    backgroundColor,
    background,
    logo,
    className
}: ClientLoginPreviewProps) {
    const backgroundUrl = background?.startsWith('data') ? background : `url(${background})`;

    return (
        <div
            className={cn(styles.projectPreview, className)}
            style={{
                backgroundColor: `${backgroundColor}`,
                backgroundImage: `${backgroundUrl}`
            }}
        >
            <div
                className={styles.backgroundColor}
                style={{
                    background:
                        `linear-gradient(${backgroundColor}33, ${backgroundColor} 100%)` ||
                        '#16181d'
                }}
            >
                {logo && (
                    <div className={styles.logo}>
                        <img src={logo} alt="" />
                    </div>
                )}

                <div className={styles.loginBlock}>
                    <div className={styles.loginField}></div>
                    <div className={styles.loginField}></div>
                    <div
                        className={styles.loginButton}
                        style={{ backgroundColor: backgroundColor || '#16181d' }}
                    ></div>
                </div>
            </div>
        </div>
    );
}
