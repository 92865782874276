import './App.scss';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FILE, PROJECT_ROUTES } from '@he-novation/config/paths/herawFrontUris';
import { HubSpotChat } from '@he-novation/front-shared/components/HubSpotChatScript';
import { getDefaultStore, useAtomValue } from 'jotai';
import { useAtomsDebugValue } from 'jotai-devtools/utils';

import { viewErrorAtom, viewErrorStore } from '$atoms/error-atoms';
import { AbsoluteMenu } from '$components/AbsoluteMenu/AbsoluteMenu';
import { HeaderWrapper } from '$components/layout/Header/HeaderWrapper';
import { Main } from '$components/layout/Main/Main';
import { MainMenu } from '$components/layout/MainMenu/MainMenu';
import ToastContainer from '$components/layout/ToastContainer';
import Modal from '$components/modal/Modal/Modal';
import { PlaylistWrapper } from '$components/Playlist/PlaylistWrapper';
import Router from '$components/router/Router';
import { SidePanel } from '$components/SidePanel/SidePanel';
import { inIframe } from '$helpers/inIframe';
import { useNotifications } from '$hooks/useNotifications';
import { useTranslate } from '$hooks/useTranslate';
import { resetContent } from '$redux/content/contentActions';
import { routeSelector } from '$redux/route/routeSelectors';
import { getFrontRoutesFromContext, locationToContext } from '$views/App/routingContext';

type AppProps = {
    isTeamsApp?: boolean;
};
const getClassName = (url: string) => {
    if (url.startsWith('/cast/')) return 'is-cast';
    if (url.startsWith('/docs/')) return 'is-docs';
    return 'is-app';
};

export function App(props: AppProps) {
    const dispatch = useDispatch();

    const { t } = useTranslate();

    const viewError = useAtomValue(viewErrorAtom, { store: viewErrorStore });
    const { configRoute } = useSelector(routeSelector);

    useEffect(() => {
        if (inIframe() || props.isTeamsApp) {
            document.body.classList.add('msteams-iframe');
        }

        document.getElementById('app')!.classList.add(getClassName(window.location.pathname));
        document
            .getElementById('app')!
            .classList.toggle('no-menu', configRoute === FILE && !viewError);
    }, []);

    useEffect(() => {
        document
            .getElementById('app')!
            .classList.toggle('project-styles', PROJECT_ROUTES.includes(configRoute));
        if (viewError && configRoute === FILE) {
            document.getElementById('app')!.classList.toggle('no-menu', false);
        } else {
            if (configRoute === FILE) {
                document.getElementById('app')!.classList.toggle('no-menu', true);
            } else {
                document.getElementById('app')!.classList.toggle('no-menu', false);
            }
        }
    }, [viewError, configRoute]);

    useNotifications();

    const shouldLoadHubspotChatScript = /prod|production/.test(process.env.NODE_ENV as string);

    const context = locationToContext();

    if (['summary', 'docs'].includes(context)) {
        return <Router routes={getFrontRoutesFromContext(context)} />;
    }

    const content = (
        <>
            {process.env.NODE_ENV === 'development' && <JotaiDebugAtoms />}
            {context === 'app' && (
                <>
                    {(configRoute !== FILE || viewError) && <MainMenu />}
                    <HeaderWrapper />
                </>
            )}
            <Main>
                <Main.Content>
                    <Router
                        genericErrorMessage={t('common.Sorry, something went wrong')}
                        routes={getFrontRoutesFromContext(context)}
                        onUpdate={(prevUrl: string, url: string) =>
                            dispatch(resetContent(prevUrl, url))
                        }
                    />
                    {['app', 'cast'].includes(context) && <PlaylistWrapper />}
                </Main.Content>
                <SidePanel />
                {context === 'app' && <AbsoluteMenu />}
            </Main>
            {context === 'app' && (
                <>
                    <Modal />
                    <ToastContainer />
                </>
            )}
            {context === 'cast' && <Modal />}
            {shouldLoadHubspotChatScript && <HubSpotChat />}
        </>
    );

    return process.env.REACT_STRICT_MODE_DISABLED ? (
        content
    ) : (
        <React.StrictMode>{content}</React.StrictMode>
    );
}

function JotaiDebugAtoms() {
    useAtomsDebugValue();
    useAtomsDebugValue({ store: getDefaultStore() });
    return null;
}

export default App;
