import styles from './Datepicker.module.css';
import React, { SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { DatePicker as DatePickerLib } from 'react-calendars';
import { renderToString } from 'react-dom/server';
import { FieldComponentProps } from 'react-modular-forms';
import cn from 'classnames';
import { doubleDigit } from '../../../../../utils/doubleDigit';
import { FormField } from '../../FormField';
import { Option } from '../ReactSelect/ReactSelect';

const TIME_OPTIONS: Option[] = [];
for (let minutes = 0; minutes < 1440; minutes += 15) {
    const hours = Math.floor(minutes / 60);
    const _minutes = minutes - hours * 60;
    const v = `${doubleDigit(hours)}:${doubleDigit(_minutes)}`;
    TIME_OPTIONS.push({ label: v, value: minutes * 60 * 1000 });
}

const TIME_ZONE_OPTIONS: Option[] = [];
for (let i = -12; i < 14; i++) {
    TIME_ZONE_OPTIONS.push({
        label: `GMT${i < 0 ? ` ${i}` : i > 0 ? ` +${i}` : ''}`,
        value: i
    });
}

type DatePickerProps = FieldComponentProps & {
    withTime?: boolean;
    withTimeZone?: boolean;
    direction?: 'TOP' | 'BOTTOM';
    value?: Date;
    timeLabel?: string;
    inline?: boolean;
};

export function DatePicker({
    id,
    name,
    direction,
    withTime,
    withTimeZone = withTime,
    utcDate,
    value: _value,
    onBlur,
    onFocus,
    onChange,
    componentRef,
    formId,
    timeLabel,
    disabled,
    inline = true,
    readOnly
}: DatePickerProps) {
    const [value, setValue] = useState<Date | null>(_value || null);

    const currentMs = useMemo(
        () => (value ? (value.getHours() * 60 + value.getMinutes()) * 60 * 1000 : 0),
        [value]
    );

    const [time, setTime] = useState(
        TIME_OPTIONS.find((curr) => curr.value >= currentMs)?.value || TIME_OPTIONS[0].value
    );
    const [timezone, setTimezone] = useState(getBrowserTimezone());

    useEffect(() => {
        componentRef.current = _value || null;
        setValue(componentRef.current);
    }, [_value]);

    const updateDate = useCallback(
        (e: SyntheticEvent, date: Date, time?: number) => {
            if (withTime && time) {
                date = applyTime(date, time, timezone) as Date;
            } else {
                if (utcDate) {
                    date = applyTime(date, 0, 0) as Date;
                }
            }
            setValue(date);
            componentRef.current = date;
            onChange?.(e, date);
        },
        [withTime, utcDate, onChange]
    );

    return (
        <>
            <div className={/*cn(styles.dateWrapper,*/ inline ? 'is-inline' : undefined /*)*/}>
                <DatePickerLib
                    displayDayTitles={true}
                    displayYearPicker={false}
                    renderMonthTitle={(month: number, year: number) => (
                        <>
                            {month} {year}
                        </>
                    )}
                    selectedDay={value}
                    disabled={disabled}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    readOnly={readOnly}
                    calendarClassName={styles.datePickerCalendar}
                    className={cn(withTime && styles.withTime, 'c-date-picker')}
                    direction={direction}
                    onChange={(e: SyntheticEvent, date: Date) => updateDate(e, date, time)}
                    onSelect={(e: SyntheticEvent, date: Date) => updateDate(e, date, time)}
                />

                {withTime && (
                    <FormField
                        placeholder="00:00"
                        className={'c-date-picker-time'}
                        menuClassName={styles.datePickerTimeMenu}
                        value={time}
                        isSearchable={!readOnly}
                        onChange={(e, t) => {
                            setTime(t);
                            if (value) {
                                updateDate(e, value, t);
                            }
                        }}
                        id={`${formId}-${id | name}-time`}
                        type="react-select"
                        label={timeLabel}
                        options={TIME_OPTIONS}
                        disabled={disabled}
                        filterOption={(option: Option, input: string) => {
                            const filteredOptionLabel = renderToString(
                                <> {option.label}</>
                            ).replace(/^\s*<!-- -->\s*/g, '');
                            const refiltered = filteredOptionLabel.replace(/^0/, '');
                            const filteredInput = input;
                            return (
                                filteredOptionLabel.startsWith(filteredInput) ||
                                refiltered.startsWith(filteredInput)
                            );
                        }}
                    />
                )}

                {withTime && withTimeZone && (
                    <FormField
                        placeholder="GMT"
                        className={'c-date-picker-timezone'}
                        menuClassName={styles.datePickerTimeMenu}
                        value={timezone}
                        disabled={disabled}
                        onChange={(_, t) => setTimezone(t)}
                        id={`${formId}-${id | name}-timezone`}
                        type="react-select"
                        options={TIME_ZONE_OPTIONS}
                    />
                )}
            </div>
        </>
    );
}

function getBrowserTimezone() {
    return -new Date().getTimezoneOffset() / 60;
}

function applyTime(input: Date | null, msTime: number, timezone: number) {
    if (!input) return null;

    const deltaTimezone = getBrowserTimezone() - timezone;

    const date = new Date(input);

    const timeDate = new Date(msTime);
    date.setHours(timeDate.getUTCHours() + deltaTimezone);
    date.setMinutes(timeDate.getUTCMinutes());
    date.setSeconds(timeDate.getUTCSeconds());
    date.setMilliseconds(timeDate.getUTCMilliseconds());
    return date;
}
