import styles from './SettingsAdminTranscoders.module.css';
import React from 'react';
import { DELETE_CONFIRM } from '@he-novation/config/paths/modals.constants';
import type { Transcoder } from '@he-novation/config/types/transcoding';
import { Button, ButtonTone } from '@he-novation/design-system/components/buttons/Button/Button';
import { asyncTranscoderStop } from '@he-novation/front-shared/async/transcoders.async';

import { useModal } from '$hooks/useModal';

type TranscodersTableProps = {
    transcoders: Transcoder[];
    fetchTranscoders: () => void;
};
export function TranscodersTable({ transcoders, fetchTranscoders }: TranscodersTableProps) {
    const { openModal, closeModal } = useModal();
    return (
        <table className={styles.transcoders}>
            <thead>
                <tr>
                    <th>UUID</th>
                    <th>Instance class</th>
                    <th>Points</th>
                    <th>Tasks (points)</th>
                </tr>
            </thead>
            <tbody>
                {transcoders.map((t) => {
                    return (
                        <tr key={t.uuid}>
                            <td>{t.uuid}</td>
                            <td>{t.instanceClass}</td>
                            <td>{t.transcodingPoints}</td>
                            <td>
                                {t.tasks.length} ({t.tasks.reduce((acc, t) => acc + t.points, 0)})
                            </td>
                            <td className={styles.actions}>
                                <Button
                                    tone={ButtonTone.Alert}
                                    icon="trash"
                                    onClick={() => {
                                        openModal(DELETE_CONFIRM, {
                                            onDelete: async () => {
                                                asyncTranscoderStop(t.uuid).then(() => {
                                                    closeModal();
                                                    fetchTranscoders();
                                                });
                                            }
                                        });
                                    }}
                                ></Button>
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
}
