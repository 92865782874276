import { z } from 'zod';

export const integrationSchema = z.object({
    uuid: z.string(),
    enabled: z.boolean().optional(),
    properties: z.record(z.unknown()).optional(),
    secrets: z.record(z.unknown()).optional(),
    name: z.string(),
    type: z.string()
});

export type Integration = z.infer<typeof integrationSchema>;
