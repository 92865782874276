import React, { useEffect, useState } from 'react';
import { UserNotificationSettingsSchema } from '@he-novation/config/types/payloads/user.payload';
import { DEFAULT_NOTIFICATIONS_SETTINGS } from '@he-novation/config/types/user.types';
import { WorkspaceFeature } from '@he-novation/config/types/workspace.types';
import { featureACL } from '@he-novation/config/utils/acl';
import { Button } from '@he-novation/design-system/components/buttons/Button/Button';
import { Loader } from '@he-novation/design-system/components/widgets/Loader/Loader';
import {
    asyncDefaultProjectUserNotificationSettingsFetch,
    asyncDefaultProjectUserNotificationSettingsPut,
    asyncProjectUserNotificationSettingsPutAll,
    asyncUserNotificationSettingsFetch,
    asyncUserNotificationSettingsPut
} from '@he-novation/front-shared/async/user.async';
import { useAtomValue } from 'jotai';
import { workspaceAtom } from '../../../atoms/workspace-atoms';

import Accordion from '$components/Accordion/Accordion';
import { FormNotifications } from '$components/form/FormNotifications';
import { SubHeader } from '$components/layout/Header/SubHeader/SubHeader';
import { useTranslate } from '$hooks/useTranslate';

export function SettingsUserNotifications() {
    const workspace = useAtomValue(workspaceAtom)!;
    const [notificationSettings, setNotificationSettings] =
        useState<UserNotificationSettingsSchema>();

    useEffect(() => {
        asyncUserNotificationSettingsFetch(workspace.name!).then((payload) =>
            setNotificationSettings(payload)
        );
    }, []);

    const [defaultNotificationSettings, setDefaultNotificationSettings] =
        useState<UserNotificationSettingsSchema>();

    useEffect(() => {
        asyncDefaultProjectUserNotificationSettingsFetch(workspace.name!).then((payload) => {
            if (payload) {
                setDefaultNotificationSettings(payload);
            } else {
                setDefaultNotificationSettings(DEFAULT_NOTIFICATIONS_SETTINGS);
            }
        });
    }, []);

    const { t } = useTranslate();

    return (
        <>
            <SubHeader subClassName={'settings-sub-header'} title={t('settings.Notifications')} />

            <Accordion title={t('settings.DAM Notifications')} isOpen={true}>
                {notificationSettings ? (
                    <FormNotifications
                        formId="dam-notifications"
                        notificationSettings={notificationSettings}
                        onChange={(settings) => {
                            asyncUserNotificationSettingsPut(workspace.name, null, settings);
                        }}
                    />
                ) : (
                    <Loader />
                )}
            </Accordion>
            {featureACL.projects(workspace.features) && (
                <Accordion title={t('settings.Default project notifications')} isOpen={true}>
                    {defaultNotificationSettings ? (
                        <>
                            <FormNotifications
                                formId="default-project-notifications"
                                notificationSettings={defaultNotificationSettings}
                                onChange={(settings) => {
                                    setDefaultNotificationSettings(settings);
                                    asyncDefaultProjectUserNotificationSettingsPut(
                                        workspace.name,
                                        settings
                                    );
                                }}
                            />
                            <Button
                                tone="primary"
                                className="margin-top-20"
                                onClick={() => {
                                    asyncProjectUserNotificationSettingsPutAll(
                                        workspace.name,
                                        defaultNotificationSettings
                                    );
                                }}
                            >
                                {t('settings.Apply to all projects')}{' '}
                            </Button>
                        </>
                    ) : (
                        <Loader />
                    )}
                </Accordion>
            )}
        </>
    );
}
