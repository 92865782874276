import styles from './MainMenu.module.css';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    adminSettingsLink,
    castsLink,
    dashboardLink,
    directoryLink,
    folderLink,
    planningLink,
    projectFoldersLink,
    projectsLink,
    tasksLink,
    userSettingsLink
} from '@he-novation/config/paths/herawFrontUris';
import { ClientUserRole } from '@he-novation/config/types/db/enums';
import { featureACL } from '@he-novation/config/utils/acl';
import {
    AvatarSize,
    AvatarUser
} from '@he-novation/design-system/components/avatars/Avatar/AvatarUser';
import { Button, ButtonTone } from '@he-novation/design-system/components/buttons/Button/Button';
import { MultiActionButton } from '@he-novation/design-system/components/buttons/MultiActionButton/MultiActionButton';
import { MultiActionsList } from '@he-novation/design-system/components/buttons/MultiActionButton/MultiActionButtonList';
import { Tooltip } from '@he-novation/design-system/components/widgets/Tooltip/Tooltip';
import { DirectionX, DirectionY } from '@he-novation/design-system/utils/getAbsolutePosition';
import { logout } from '@he-novation/front-shared/async/user.async';
import { UploadTracker } from '../UploadTracker/UploadTracker';

import { HubspotButton } from '$components/Buttons/HubspotButton/HubspotButton';
import { MainMenuAddButton } from '$components/Buttons/MainMenuAddButton/MainMenuAddButton';
import { useWorkspaceMenuActions } from '$components/Buttons/WorkspaceMenuButton/useWorkspaceMenuActions';
import {
    WorkspaceIcon,
    WorkspaceIconSize
} from '$components/Buttons/WorkspaceMenuButton/WorkspaceIcon';
import { WorkspaceMenuButton } from '$components/Buttons/WorkspaceMenuButton/WorkspaceMenuButton';
import combineSelectors from '$helpers/combineSelectors';
import { useTranslate } from '$hooks/useTranslate';
import { isSmallScreenSelector } from '$redux/config/configSelectors';
import { set } from '$redux/route/routeActions';
import { localeSelector, userEventsSelector, userInfosSelector } from '$redux/user/userSelectors';
import {
    getPreviousProjectFolderUuid,
    getPreviousProjectUuid
} from '$views/projects/project.helper';

export function MainMenu() {
    const dispatch = useDispatch();

    const { userInfos, isSmallScreen } = useSelector(
        combineSelectors(userInfosSelector, isSmallScreenSelector, userEventsSelector)
    );

    const locale = useSelector(localeSelector);

    const { t } = useTranslate();
    const { workspaceMenuActions, currentWorkspace, features } =
        useWorkspaceMenuActions(isSmallScreen);

    let settingsActions: MultiActionsList = [
        { href: userSettingsLink(), icon: 'user', children: t('common.My account') }
    ];

    if (userInfos.isSuperAdmin) {
        settingsActions.push({
            href: adminSettingsLink(),
            icon: 'cog',
            children: 'HERAW admin'
        });
    }

    if (isSmallScreen) {
        settingsActions = [...settingsActions, { separator: true }, ...workspaceMenuActions];
    }

    settingsActions = [
        ...settingsActions,
        { separator: true },
        {
            onClick: () => logout(locale),
            icon: 'exit',
            children: t('common.Sign out')
        }
    ];

    const tooltipDirection = isSmallScreen
        ? [DirectionX.LeftInner, DirectionY.Top]
        : [DirectionX.Right, DirectionY.Middle];

    return (
        <nav id="c-main-menu" className={styles.menu}>
            <ul key={currentWorkspace?.name}>
                {!isSmallScreen && (
                    <li>
                        <WorkspaceMenuButton />
                    </li>
                )}
                <li>
                    <MainMenuAddButton features={features} />
                </li>
                {currentWorkspace && (
                    <>
                        {featureACL.projects(features) && (
                            <li>
                                <Button
                                    id="mm-project"
                                    tone={ButtonTone.Hoverable}
                                    className={
                                        /^\/workspace\/\w+\/project/.test(window.location.pathname)
                                            ? 'is-active'
                                            : null
                                    }
                                    icon="briefcase"
                                    tooltip={t('common.Projects')}
                                    tooltipOptions={{
                                        direction: tooltipDirection
                                    }}
                                    href={projectsLink(currentWorkspace.name)}
                                    onClick={(e: Event) => {
                                        const previousProjectUuid = getPreviousProjectUuid(
                                            currentWorkspace.name
                                        );
                                        const previousProjectFolderUuid =
                                            getPreviousProjectFolderUuid(currentWorkspace.name);

                                        if (
                                            !/^\/workspace\/\w+\/project/.test(
                                                window.location.pathname
                                            ) &&
                                            previousProjectUuid
                                        ) {
                                            e.preventDefault();
                                            dispatch(
                                                set(
                                                    projectFoldersLink(
                                                        currentWorkspace.name,
                                                        previousProjectUuid,
                                                        previousProjectFolderUuid || undefined
                                                    )
                                                )
                                            );
                                        }
                                    }}
                                >
                                    {t('common.Projects')}
                                </Button>
                            </li>
                        )}

                        {window.location.pathname !== '/index_client' &&
                            featureACL.calendar(features) && (
                                <li>
                                    <Button
                                        id="mm-planning"
                                        icon="calendar"
                                        tone={ButtonTone.Hoverable}
                                        tooltip={t('common.Planning')}
                                        tooltipOptions={{
                                            direction: tooltipDirection
                                        }}
                                        href={planningLink(currentWorkspace.name)}
                                        className={
                                            /^\/workspace\/\w+\/planning/.test(
                                                window.location.pathname
                                            )
                                                ? 'is-active'
                                                : null
                                        }
                                    >
                                        {t('common.Planning')}
                                    </Button>
                                </li>
                            )}

                        {featureACL.tasks(features) && (
                            <li>
                                <Button
                                    id="mm-tasks"
                                    icon={'check_encircled'}
                                    tone={ButtonTone.Hoverable}
                                    tooltip={t('common.Tasks')}
                                    tooltipOptions={{
                                        direction: tooltipDirection
                                    }}
                                    href={tasksLink(currentWorkspace.name)}
                                    className={
                                        /^\/workspace\/\w+\/tasks/.test(window.location.pathname)
                                            ? 'is-active'
                                            : null
                                    }
                                >
                                    {t('common.Tasks')}
                                </Button>
                            </li>
                        )}

                        {featureACL.folders(features) && (
                            <li>
                                <Button
                                    id="mm-folder"
                                    icon={[{ name: 'pictures', stroke: 'white', fill: 'white' }]}
                                    href={folderLink(currentWorkspace.name)}
                                    tone={ButtonTone.Hoverable}
                                    tooltip={'DAM'}
                                    tooltipOptions={{
                                        direction: tooltipDirection
                                    }}
                                    className={
                                        /^\/workspace\/\w+\/folder/.test(
                                            window.location.pathname
                                        ) ||
                                        !window.location.pathname ||
                                        window.location.pathname === '/'
                                            ? 'is-active'
                                            : null
                                    }
                                >
                                    DAM
                                </Button>
                            </li>
                        )}

                        {currentWorkspace?.role !== ClientUserRole.BASIC && (
                            <>
                                <li>
                                    <Button
                                        id="mm-casts"
                                        icon="signal"
                                        tone={ButtonTone.Hoverable}
                                        href={castsLink(currentWorkspace.name)}
                                        tooltip={t('common.Casts')}
                                        tooltipOptions={{
                                            direction: tooltipDirection
                                        }}
                                        className={
                                            /^\/workspace\/\w+\/casts/.test(
                                                window.location.pathname
                                            )
                                                ? 'is-active'
                                                : null
                                        }
                                    >
                                        {t('common.Casts')}
                                    </Button>
                                </li>

                                <li>
                                    <Button
                                        id="mm-contacts"
                                        icon="shapes"
                                        tone={ButtonTone.Hoverable}
                                        href={directoryLink(currentWorkspace.name)}
                                        tooltip={t('common.Directory')}
                                        tooltipOptions={{
                                            direction: tooltipDirection
                                        }}
                                        className={
                                            /^\/workspace\/\w+\/directory/.test(
                                                window.location.pathname
                                            )
                                                ? 'is-active'
                                                : null
                                        }
                                    >
                                        {t('common.Directory')}
                                    </Button>
                                </li>
                            </>
                        )}
                    </>
                )}
            </ul>
            <ul>
                {currentWorkspace && (
                    <li>
                        <UploadTracker />
                    </li>
                )}
                <li>
                    <Button
                        icon="dashboard"
                        tone={ButtonTone.Hoverable}
                        id="mm-dashboard"
                        href={dashboardLink()}
                        tooltip={t('common.Dashboard')}
                        tooltipOptions={{
                            direction: tooltipDirection
                        }}
                        onDragStart={(e: Event) => {
                            e.preventDefault();
                            return false;
                        }}
                        className={
                            window.location.pathname.startsWith('/dashboard') ? 'is-active' : null
                        }
                    >
                        {t('common.Dashboard')}
                    </Button>
                </li>
                <li>
                    <HubspotButton />
                </li>
                <li className={styles.settings}>
                    <Tooltip
                        content={t('common.Settings')}
                        direction={[DirectionX.Right, DirectionY.Middle]}
                    >
                        <MultiActionButton
                            actions={settingsActions}
                            fireIfSingle={true}
                            direction={
                                isSmallScreen
                                    ? [DirectionX.RightInner, DirectionY.Top]
                                    : [DirectionX.Right, DirectionY.TopInner]
                            }
                        >
                            <AvatarUser size={AvatarSize.Small} {...userInfos} />
                            {isSmallScreen && currentWorkspace && (
                                <WorkspaceIcon
                                    name={currentWorkspace.displayName}
                                    primary={currentWorkspace.primaryColor}
                                    logoUrl={currentWorkspace.logoUrl}
                                    size={WorkspaceIconSize.Small}
                                    className={styles.settingsWorkspaceIcon}
                                />
                            )}
                        </MultiActionButton>
                    </Tooltip>
                </li>
            </ul>
        </nav>
    );
}
