import { z } from 'zod';
import { ApiMethod } from '../types/api.types';
import { itemKitSchema, itemSchema, itemWithEventsSchema } from '../types/item.types';
import {
    itemCreateBodySchema,
    itemKitAddItemBodySchema,
    itemKitCreateBodySchema,
    itemKitItemSpecificSchema,
    itemKitSpecificSchema,
    itemKitUpdateBodySchema,
    itemParamsSchema,
    itemPictureUploadLinkBody,
    itemStartEndQuerySchema,
    itemUpdateBodySchema
} from '../types/payloads/item.payload';
import { workspaceParamsSchema } from '../types/payloads/workspace.payload';

export const API_ITEM = {
    LIST: {
        method: ApiMethod.GET,
        path: '/workspaces/:workspaceName/items',
        handlerMethod: 'listItems',
        zod: {
            params: workspaceParamsSchema
        },
        responses: {
            200: {
                description: 'List of items in workspace',
                schema: z.array(itemSchema)
            }
        }
    },
    POST: {
        method: ApiMethod.POST,
        path: '/workspaces/:workspaceName/items',
        handlerMethod: 'createItem',
        zod: {
            params: workspaceParamsSchema,
            body: itemCreateBodySchema
        },
        responses: {
            200: {
                description: 'Created item',
                schema: itemSchema
            }
        }
    },
    UPDATE: {
        method: ApiMethod.PUT,
        path: '/workspaces/:workspaceName/items/:itemUuid',
        handlerMethod: 'updateItem',
        zod: {
            params: itemParamsSchema,
            body: itemUpdateBodySchema
        },
        responses: {
            200: {
                description: 'Updated item',
                schema: itemSchema
            }
        }
    },
    DELETE: {
        method: ApiMethod.DELETE,
        path: '/workspaces/:workspaceName/items/:itemUuid',
        handlerMethod: 'deleteItem',
        zod: {
            params: itemParamsSchema
        },
        responses: {
            204: {
                description: 'Item successfully deleted',
                schema: z.unknown()
            }
        }
    }
} as const;

export const API_ITEM_EVENTS = {
    LIST: {
        method: ApiMethod.GET,
        path: '/workspaces/:workspaceName/items/:itemUuid/events',
        handlerMethod: 'listItemEvents',
        zod: {
            params: itemParamsSchema,
            query: itemStartEndQuerySchema
        },
        responses: {
            200: {
                description: 'Item with list of events',
                schema: itemWithEventsSchema
            }
        }
    }
} as const;

export const API_ITEM_PICTURES = {
    POST: {
        method: ApiMethod.POST,
        path: '/workspaces/:workspaceName/items/:itemUuid/pictures',
        handlerMethod: 'createItemPictureUploadLink',
        zod: {
            params: itemParamsSchema,
            body: itemPictureUploadLinkBody
        },
        responses: {
            200: {
                description: 'Item picture upload link',
                schema: z.object({
                    link: z.string(),
                    headers: z.record(z.string()),
                    assetUuid: z.string()
                })
            }
        }
    }
} as const;

export const API_ITEM_KIT = {
    LIST: {
        method: ApiMethod.GET,
        path: '/workspaces/:workspaceName/item-kits',
        handlerMethod: 'listItemKits',
        zod: {
            params: workspaceParamsSchema
        },
        responses: {
            200: {
                description: 'List of items in workspace',
                schema: z.array(itemKitSchema)
            }
        }
    },
    POST: {
        method: ApiMethod.POST,
        path: '/workspaces/:workspaceName/item-kits',
        handlerMethod: 'createItemKit',
        zod: {
            params: workspaceParamsSchema,
            body: itemKitCreateBodySchema
        },
        responses: {
            200: {
                description: 'Created item kit',
                schema: itemKitSchema
            }
        }
    },
    UPDATE: {
        method: ApiMethod.PUT,
        path: '/workspaces/:workspaceName/item-kits/:itemKitUuid',
        handlerMethod: 'updateItemKit',
        zod: {
            params: itemKitSpecificSchema,
            body: itemKitUpdateBodySchema
        },
        responses: {
            200: {
                description: 'Updated item kit',
                schema: itemKitSchema
            }
        }
    },
    DELETE: {
        method: ApiMethod.DELETE,
        path: 'workspaces/:workspaceName/item-kits/:itemKitUuid',
        handlerMethod: 'deleteItemKit',
        zod: {
            params: itemKitSpecificSchema
        },
        responses: {
            204: {
                description: 'Item kit successfully deleted',
                schema: z.unknown()
            }
        }
    },
    ADD_ITEM: {
        method: ApiMethod.POST,
        path: '/workspaces/:workspaceName/item-kits/:itemKitUuid/items',
        handlerMethod: 'addItemToKit',
        zod: {
            params: itemKitSpecificSchema,
            body: itemKitAddItemBodySchema
        },
        responses: {
            204: {
                description: 'Item successfully added to kit',
                schema: z.unknown()
            }
        }
    },
    REMOVE_ITEM: {
        method: ApiMethod.DELETE,
        path: '/workspaces/:workspaceName/item-kits/:itemKitUuid/items/:itemUuid',
        handlerMethod: 'removeItemFromKit',
        zod: {
            params: itemKitItemSpecificSchema
        },
        responses: {
            204: {
                description: 'Item successfully removed to kit',
                schema: z.unknown()
            }
        }
    }
} as const;
