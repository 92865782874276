import styles from './DataLayoutWorkspaceMembers.module.css';
import React from 'react';
import type { ClientUser } from '@he-novation/config/types/client.types';
import { DataLayoutDisplayMode } from '@he-novation/config/types/dataLayout.types';
import { WorkspaceFeature } from '@he-novation/config/types/workspace.types';
import type { WorkspaceUser } from '@he-novation/config/types/workspace-team.types';
import { AvatarUser } from '@he-novation/design-system/components/avatars/Avatar/AvatarUser';
import { useAtomValue } from 'jotai';

import { selectedWorkspaceMembersAtom } from '$atoms/selection-atoms';
import { workspaceAtom } from '$atoms/workspace-atoms';
import { DataLayout } from '$components/DataLayout/DataLayout';
import { type DataLayoutColumn, SelectionAtom } from '$components/DataLayout/DataLayout.types';
import {
    filterSearch,
    highlight,
    useDataLayoutWorkspaceMembersInfoActionsMenu,
    useDataLayoutWorkspaceMembersManagementActionsMenu
} from '$components/DataLayout/DataLayoutWorkspaceMembers/DataLayoutWorkspaceMembers.utils';
import { DataLayoutWorkspaceMembersGridItem } from '$components/DataLayout/DataLayoutWorkspaceMembers/DataLayoutWorkspaceMembersGridItem/DataLayoutWorkspaceMembersGridItem';
import { sorterFunctionFactory } from '$components/DataLayout/sorterFunctions';
import { WorkspaceRole } from '$components/Workspace/WorkspaceRole';
import { useDataLayout } from '$hooks/useDataLayout';
import { Translator, useTranslate } from '$hooks/useTranslate';

type DataLayoutWorkspaceMembersProps = {
    items: WorkspaceUser[];
    search?: string;
    teamUuid?: string;
    dataLayout: ReturnType<typeof useDataLayout>;
    manage?: boolean;
};

export type DataLayoutWorkspaceMembersExtra = {
    search?: string;
    teamUuid?: string;
};

export function DataLayoutWorkspaceMembers({
    items,
    search,
    teamUuid,
    dataLayout,
    manage
}: DataLayoutWorkspaceMembersProps) {
    const { t } = useTranslate();

    const managementItemToActions = useDataLayoutWorkspaceMembersManagementActionsMenu();
    const infoItemToActions = useDataLayoutWorkspaceMembersInfoActionsMenu(teamUuid);

    const workspace = useAtomValue(workspaceAtom)!;

    return (
        <DataLayout
            id="data-layout-workspace-members"
            selectionAtom={manage ? undefined : selectedWorkspaceMembersAtom}
            ItemGridComponent={DataLayoutWorkspaceMembersGridItem}
            itemToId={(item: WorkspaceUser) => item.uuid}
            items={filterSearch(items, search)}
            itemToActions={manage ? managementItemToActions : infoItemToActions}
            extra={
                {
                    search,
                    teamUuid
                } satisfies DataLayoutWorkspaceMembersExtra
            }
            windowed={{
                itemWidth: (displayMode, width) => (displayMode === 'grid' ? 240 : width),
                itemHeight: (displayMode) => (displayMode === 'grid' ? 350 : 70),
                itemsPerRow: (width) => Math.floor((width - 20) / 240)
            }}
            columns={manage ? getManagementColumns(t, workspace.features) : getInfoColumns(t)}
            displayMode={manage ? DataLayoutDisplayMode.List : dataLayout.displayMode}
            grouper={dataLayout.grouper}
            activeColumns={dataLayout.activeColumns}
            sort={dataLayout.sort}
            onSort={dataLayout.onSort}
            onActiveColumnsChange={dataLayout.onActiveColumnsChange}
        />
    );
}

function getManagementColumns(t: Translator, workspaceFeatures: WorkspaceFeature[]) {
    const columns: DataLayoutColumn<ClientUser, SelectionAtom | undefined, unknown>[] = [
        {
            key: 'name',
            header: t('common.Name'),
            width: 300,
            sort: sorterFunctionFactory('alphanumeric', 'lastname'),
            grow: true,
            render: (user: ClientUser, _selection: SelectionAtom, { search }) => {
                const highlighted = highlight(user, search);
                return (
                    <div className={styles.name}>
                        <AvatarUser {...user} />
                        <span>
                            {highlighted.firstname || ''} {highlighted.lastname || ''}
                        </span>
                    </div>
                );
            }
        },
        {
            key: 'email',
            header: t('common.Email'),
            width: 300,
            sort: sorterFunctionFactory('alphanumeric', 'email'),
            render: (user: ClientUser, _selection: SelectionAtom, { search }) => {
                const highlighted = highlight(user, search);
                return highlighted.email;
            }
        },
        {
            key: 'created',
            header: t('common.Creation date'),
            width: 200,
            sort: sorterFunctionFactory('date', 'created')
        },
        {
            key: 'workspaceRole',
            header: t('common.Role'),
            width: 200,
            sort: sorterFunctionFactory('alphanumeric', 'workspaceRole'),
            render: (user: ClientUser) => <WorkspaceRole role={user.workspaceRole} />
        }
    ];

    if (workspaceFeatures.includes(WorkspaceFeature.API)) {
        columns.push({
            key: 'apiKey',
            header: t('settings.API key'),
            width: 290,
            render: (user: ClientUser) => user.apiKey
        });
    }

    return columns;
}

function getInfoColumns(t: Translator) {
    return [
        {
            key: 'name',
            header: t('common.Name'),
            width: 300,
            sort: sorterFunctionFactory('alphanumeric', 'lastname'),
            grow: true,
            render: (user: WorkspaceUser, _selection: SelectionAtom, { search }) => {
                const highlighted = highlight(user, search);
                return (
                    <div className={styles.name}>
                        <AvatarUser {...user} />
                        <span>
                            {highlighted.firstname || ''} {highlighted.lastname || ''}
                        </span>
                    </div>
                );
            }
        },
        {
            key: 'email',
            header: t('common.Email'),
            unfilterable: true,
            grow: true,
            width: 300,
            sort: sorterFunctionFactory('alphanumeric', 'email'),
            render: (user: WorkspaceUser, _selection: SelectionAtom, { search }) => {
                const highlighted = highlight(user, search);
                return highlighted.email;
            }
        },
        {
            key: 'created',
            header: t('common.Creation date'),
            width: 200,
            sort: sorterFunctionFactory('date', 'created')
        },
        {
            key: 'firm',
            header: t('entity.Entity'),
            width: 200,
            sort: sorterFunctionFactory('alphanumeric', 'firm')
        },
        {
            key: 'phone',
            header: t('contact.Phone number'),
            width: 200
        },
        {
            key: 'role',
            header: t('contact.Job'),
            width: 200,
            sort: sorterFunctionFactory('alphanumeric', 'role')
        },
        {
            key: 'labels',
            header: t('common.Labels'),
            width: 200,
            render(item: WorkspaceUser) {
                return item.labels.map((label) => label.name).join(', ');
            }
        }
    ];
}
