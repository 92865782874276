import z from 'zod';
import { contentFileSchema } from '../file.types';
import { subtitleSchema } from '../subtitle.types';
import { uploadCreateResponse } from './upload.responses';

export const fileSubtitlesFindResponse = z.array(subtitleSchema);
export type FileSubtitlesFindResponse = z.infer<typeof fileSubtitlesFindResponse>;

export const fileCreateResponse = uploadCreateResponse.extend({
    file: contentFileSchema
});
export type FileCreateResponse = z.infer<typeof fileCreateResponse>;
