import { z } from 'zod';
import { ApiMethod } from '../types/api.types';
import {
    userFileCreateBodySchema,
    userFileSpecificParamsSchema,
    userFilesSelectQuerySchema
} from '../types/payloads/userFile.payload';
import { workspaceParamsSchema } from '../types/payloads/workspace.payload';
import { userFileSchema } from '../types/user-file.types';

export const API_USER_FILE = {
    LIST: {
        method: ApiMethod.GET,
        path: '/workspaces/:workspaceName/user-files',
        handlerMethod: 'listUserFiles' as 'listUserFiles',
        zod: {
            query: userFilesSelectQuerySchema,
            params: workspaceParamsSchema
        },
        responses: {
            200: {
                description: 'The list of user files found',
                schema: z.array(userFileSchema)
            }
        }
    },
    POST: {
        method: ApiMethod.POST,
        path: '/workspaces/:workspaceName/user-files',
        handlerMethod: 'getUserFileUploadUrl' as 'getUserFileUploadUrl',
        zod: {
            body: userFileCreateBodySchema,
            params: workspaceParamsSchema
        },
        responses: {
            200: {
                description: 'The user file upload link',
                schema: z.object({
                    link: z.string(),
                    headers: z.record(z.string()),
                    userFileUid: z.string()
                })
            }
        }
    },
    PATCH: {
        method: ApiMethod.PATCH,
        path: '/workspaces/:workspaceName/user-files/:userFileUid',
        handlerMethod: 'updateUserFileUrl',
        zod: {
            params: userFileSpecificParamsSchema
        },
        responses: {
            200: {
                description: 'The user file was successfully updated',
                schema: userFileSchema
            }
        }
    },
    DELETE: {
        method: ApiMethod.DELETE,
        path: '/workspaces/:workspaceName/user-files/:userFileUid',
        handlerMethod: 'deleteUserFile',
        zod: {
            params: userFileSpecificParamsSchema
        },
        responses: {
            204: {
                description: 'The user file was successfully deleted'
            }
        }
    }
} as const;
