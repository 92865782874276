import { useEffect } from 'react';
import { useAtomValue, useSetAtom } from 'jotai';

import { titleLocationAtom, titleSubLocationAtom } from '$atoms/document-title-atom';

const documentTitleRoot = `HERAW${process.env.NODE_ENV === 'development' ? ' - development' : ''}`;
export function useTitleLocation() {
    const setLocation = useSetAtom(titleLocationAtom);
    const setSubLocation = useSetAtom(titleSubLocationAtom);

    return {
        setLocation: (location: string | null) => {
            setLocation(location);
            setSubLocation(null);
        },
        setSubLocation
    };
}

export function useDocumentTitle() {
    const location = useAtomValue(titleLocationAtom);
    const subLocation = useAtomValue(titleSubLocationAtom);
    useEffect(() => {
        setTitle(location, subLocation);
    }, [location, subLocation]);

    const setTitle = (location: string | null, subLocation: string | null) => {
        document.title = `${documentTitleRoot} ${
            location ? `| ${location} ${subLocation ? `- ${subLocation}` : ''}` : ''
        }`;
    };
}
