import styles from './MultiActionButtonList.module.css';
import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import cn from 'classnames';
import { zIndexMenu } from '../../../constants/constants.style';
import { computeZIndex } from '../../../utils/dom/zIndex';
import { DirectionX } from '../../../utils/getAbsolutePosition';
import { Icon } from '../../graphics/Icon/Icon';
import { Button, ButtonTone } from '../Button/Button';
import { ActionType, MultiActionButton, MultiActionButtonProps } from './MultiActionButton';

export type MultiActionsList = (ActionType | false | null | undefined)[];
export type MultiActionButtonListProps = {
    actions: MultiActionsList;
    onClickableClick?: (e: MouseEvent) => any;
    className?: string;
    contentZIndex?: number;
};

export function MultiActionButtonList({
    actions,
    onClickableClick,
    className,
    contentZIndex
}: MultiActionButtonListProps) {
    const ref = useRef<HTMLUListElement>(null);
    const [zIndex, setZIndex] = useState(contentZIndex);

    useEffect(() => {
        if (!ref.current) return;

        let timeout: ReturnType<typeof setTimeout>;
        if (!zIndex) {
            timeout = setTimeout(() => setZIndex(computeZIndex(ref.current) || zIndexMenu), 0);
        }

        return () => {
            clearTimeout(timeout);
        };
    }, []);
    return (
        <ul className={cn(styles.multiActionButtonList, className)} ref={ref}>
            {actions
                ?.filter((a) => a)
                .map(({ children, ...a }: ActionType, i) => {
                    if (a.separator)
                        return (
                            <li
                                key={i}
                                className={styles.multiActionButtonListSeparator}
                                aria-hidden={true}
                            />
                        );
                    return (
                        <li key={i}>
                            {a.actions ? (
                                <MultiActionButton
                                    onClickableClick={onClickableClick}
                                    tone={ButtonTone.Plain}
                                    hoverable={true}
                                    {...(a as MultiActionButtonProps)}
                                    contentClassName={classNames(a.contentClassName)}
                                    contentZIndex={zIndex && zIndex + 1}
                                    className={classNames(
                                        styles.multiActionButtonListButton,
                                        a.className,
                                        a.direction?.[0] === DirectionX.Right ||
                                            a.direction?.[0] === DirectionX.RightInner
                                    )}
                                >
                                    {children}
                                    {(!a.fireIfSingle || a.actions.length > 1) && (
                                        <Icon
                                            icon="chevron"
                                            className={styles.multiActionButtonChevron}
                                        ></Icon>
                                    )}
                                </MultiActionButton>
                            ) : (
                                <Button
                                    tone={ButtonTone.Plain}
                                    {...a}
                                    className={cn(styles.multiActionButtonListButton, a.className)}
                                    onClick={(e) => {
                                        a.onClick?.(e);
                                        onClickableClick?.(e);
                                    }}
                                >
                                    {children}
                                </Button>
                            )}
                        </li>
                    );
                })}
        </ul>
    );
}
