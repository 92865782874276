import formStyles from '@he-novation/design-system/styles/form-styles.module.css';
import React, { ReactNode, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    SETTINGS_USER_INTERFACE,
    SETTINGS_USER_NOTIFICATIONS,
    SETTINGS_USER_PLUGINS,
    SETTINGS_USER_PROFILE,
    SETTINGS_USER_RIGHTS,
    SETTINGS_USER_TAGS_AND_LABELS
} from '@he-novation/config/paths/herawFrontUris';
import { UserPrefs } from '@he-novation/config/types/user.types';

import { Header } from '$components/layout/Header/Header';
import { HeaderNav } from '$components/layout/Header/HeaderNavigation/HeaderNavigation';
import { View, ViewContent } from '$components/layout/View/View';
import { Translator, useTranslate } from '$hooks/useTranslate';
import { set as setRoute } from '$redux/route/routeActions';
import { isPrivateSelector, localeSelector, preferencesSelector } from '$redux/user/userSelectors';
import { SettingsPlugins } from '$views/settings/components/SettingsPlugins/SettingsPlugins';
import { SettingsUserInterface } from '$views/settings/SettingsUser/SettingsUserInterface';
import { SettingsUserNotifications } from '$views/settings/SettingsUser/SettingsUserNotifications';
import { SettingsUserProfile } from '$views/settings/SettingsUser/SettingsUserProfile';
import { SettingsUserRights } from '$views/settings/SettingsUser/SettingsUserRights';
import { SettingsUserTagsAndLabels } from '$views/settings/SettingsUser/SettingsUserTagsAndLabels';

type SettingsUserProps = { route: string };

const cleanRoute = (route: string) => route.replace(/\?.*$/g, '');

export function SettingsUser({ route }: SettingsUserProps) {
    const dispatch = useDispatch();

    const { t } = useTranslate();

    const { isPrivate } = useSelector(isPrivateSelector);
    const { preferences: userPreferences } = useSelector(preferencesSelector);
    const locale = useSelector(localeSelector);

    const [mounted, setMounted] = useState(false);

    useEffect(() => {
        if (!route) {
            dispatch(setRoute(SETTINGS_USER_PROFILE));
        } else {
            setMounted(true);
        }
    }, []);

    useEffect(() => {
        if (mounted) {
            window.location.reload();
        }
    }, [locale]);

    const content = getContent(route, userPreferences);

    const settingsUserLinks = getSettingsUserLinks(isPrivate, t);

    return (
        <View id="view-settings-user">
            <Header nav={settingsUserLinks} title={t('common.Settings')} />
            <ViewContent className={formStyles.dark}>{content}</ViewContent>
        </View>
    );
}

function getSettingsUserLinks(isPrivate: boolean, t: Translator): HeaderNav {
    const links: HeaderNav = [
        {
            matchRoute: (activeRoute) => cleanRoute(activeRoute) === SETTINGS_USER_PROFILE,
            href: SETTINGS_USER_PROFILE,
            icon: 'user',
            children: t('common.My account')
        },
        {
            matchRoute: (activeRoute) => cleanRoute(activeRoute) === SETTINGS_USER_NOTIFICATIONS,
            href: SETTINGS_USER_NOTIFICATIONS,
            icon: 'bell',
            children: t('settings.Notifications')
        },
        {
            matchRoute: (activeRoute) => cleanRoute(activeRoute) === SETTINGS_USER_RIGHTS,
            href: SETTINGS_USER_RIGHTS,
            icon: 'folder',
            children: t('settings.Default folder rights')
        },
        {
            matchRoute: (activeRoute) => cleanRoute(activeRoute) === SETTINGS_USER_INTERFACE,
            href: SETTINGS_USER_INTERFACE,
            icon: 'brush',
            children: t('settings.User interface')
        },
        {
            matchRoute: (activeRoute) => cleanRoute(activeRoute) === SETTINGS_USER_TAGS_AND_LABELS,
            href: SETTINGS_USER_TAGS_AND_LABELS,
            icon: 'label',
            children: t('settings.Tags and labels')
        },
        {
            matchRoute: (activeRoute) => cleanRoute(activeRoute) === SETTINGS_USER_PLUGINS,
            href: SETTINGS_USER_PLUGINS,
            icon: 'plug',
            children: t('settings.Integrations')
        }
    ];

    return links;
}

function getContent(activeRoute: string = 'profile', preferences: UserPrefs): ReactNode {
    switch (activeRoute) {
        case 'notifications':
            return <SettingsUserNotifications />;
        case 'rights':
            return <SettingsUserRights preferences={preferences} />;
        case 'interface':
            return <SettingsUserInterface preferences={preferences} />;
        case 'tags-labels':
            return (
                <SettingsUserTagsAndLabels tags={preferences.tags} labels={preferences.labels} />
            );
        case 'plugins':
            return <SettingsPlugins />;
        case 'profile':
        default:
            return <SettingsUserProfile />;
    }
}
