import styles from './DataLayoutCard.module.scss';
import React, { MouseEventHandler, ReactNode } from 'react';
import { Icon, IconProp } from '@he-novation/design-system/components/graphics/Icon/Icon';
import classNames from 'classnames';

type DataLayoutCardProps = {
    className?: string;
    icon?: IconProp;
    picture?: ReactNode | string | null;
    color?: string;
    title?: React.ReactNode;
    children?: React.ReactNode | React.ReactNode[];
    contentRef?: React.RefObject<HTMLDivElement>;
    onClick?: MouseEventHandler<HTMLDivElement>;
};

export function DataLayoutCard({
    picture,
    icon,
    title,
    children,
    color,
    className,
    contentRef,
    onClick
}: DataLayoutCardProps) {
    const visual = typeof picture === 'string' ? <img src={picture} alt="" /> : picture;

    return (
        <div className={classNames(styles.card, className)} onClick={onClick}>
            <div className={styles.content} ref={contentRef}>
                {(picture || color || icon) && (
                    <div className={styles.visual}>
                        {visual ? visual : icon && <Icon icon={icon} />}
                        {color && (
                            <div className={styles.color}>
                                <div style={{ backgroundColor: color }} />
                            </div>
                        )}
                    </div>
                )}
                <div className={styles.infos}>
                    {title && <h3>{title}</h3>}
                    {children}
                </div>
            </div>
        </div>
    );
}
