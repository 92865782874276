import React, { ReactNode } from 'react';
import { ActionType } from '@he-novation/design-system/components/buttons/MultiActionButton/MultiActionButton';
import { PrimitiveAtom } from 'jotai';

export type SelectionAtom = PrimitiveAtom<string[]>;

export enum DataLayoutDisplayMode {
    List = 'list',
    Grid = 'grid'
}

export type DataLayoutGrouperKey = string;

export type DataLayoutGrouper<T> = {
    key: DataLayoutGrouperKey;
    label: ReactNode;
    grouperFunction: DataLayoutGroupBy<T>;
    default?: boolean;
};

export type DataLayoutGroup<T> = {
    key: DataLayoutGrouperKey;
    header: ReactNode | ((key: DataLayoutGrouperKey, items: T[]) => ReactNode);
    items: T[];
};

export type ItemToActions<T> = (item: T) => ActionType[] | null;

export type DataLayoutColumn<T, U extends SelectionAtom | undefined, V> = {
    key: string;
    unfilterable?: boolean;
    maxWidth?: number;
    grow?: boolean;
    width: number;
    header?: ReactNode;
    hidden?: boolean;
    sort?: (a: T, b: T) => number;
    render?: (item: T, selectionAtom: U, extra: V) => ReactNode;
    className?: string;
    icon?: string;
};
export type ItemComponentProps<T, U extends SelectionAtom | undefined, V> = {
    id: string;
    item: T;
    extra: V;
    selectionAtom: U;
};
export type ItemComponent<T, U extends SelectionAtom | undefined, V> = React.ComponentType<
    ItemComponentProps<T, U, V>
>;

export type DataLayoutGroupBy<T> = (items: T[]) => DataLayoutGroup<T>[];

export const DATA_LAYOUT_CLASSES = {
    WRAPPER: 'data-layout',
    CHILD: 'data-layout-child'
};
