import { CameraCoordinates, SceneLightning } from '@he-novation/config/types/threeDimensions.types';
import { atom } from 'jotai';

export const DEFAULT_CAMERA_COORDINATES: CameraCoordinates = {
    position: { x: 20, y: 10, z: 20 },
    target: { x: 0, y: 0, z: 0 }
};

export const cameraCoordinatesAtom = atom<CameraCoordinates | null>(null);

export const DEFAULT_SCENE_LIGHTNING: SceneLightning = {
    environmentIntensity: 1,
    skyLightness: 1,
    groundLightness: 0.25,
    lightIntensity: 3
};

export const sceneLightningAtom = atom<SceneLightning | null>(null);
