import styles from './SidePanelNotesHeader.module.css';
import formStyles from '@he-novation/design-system/styles/form-styles.module.css';
import React from 'react';
import { AbsoluteContentButton } from '@he-novation/design-system/components/buttons/AbsoluteContentButton/AbsoluteContentButton';
import { Button } from '@he-novation/design-system/components/buttons/Button/Button';
import { FormField } from '@he-novation/design-system/components/form/FormField/FormField';
import { DirectionX, DirectionY } from '@he-novation/design-system/utils/getAbsolutePosition';

import { useTranslate } from '$hooks/useTranslate';

export type VersionFilterProps = {
    versions: number[];
    versionFilters: number[];
    setVersionFilters: (versionFilters: number[]) => void;
};
export function VersionFilter({ versions, versionFilters, setVersionFilters }: VersionFilterProps) {
    const { t } = useTranslate();
    if (!versions.length) return null;
    return (
        <AbsoluteContentButton
            tagName={Button}
            icon={[
                {
                    name: 'compare',
                    stroke: 'white'
                }
            ]}
            direction={[DirectionX.RightInner, DirectionY.Bottom]}
            contentClassName={formStyles.dark}
            content={
                <div className={styles.menu} style={{ width: 200 }}>
                    {versions.map((v) => (
                        <FormField
                            key={v}
                            id={`task-version-filter-${v}`}
                            value={v}
                            label={`${t('common.Version')} ${v + 1}`}
                            type="checkbox"
                            onChange={(e) => {
                                if (e.currentTarget.checked)
                                    setVersionFilters([...versionFilters, v]);
                                else
                                    setVersionFilters(
                                        versionFilters.filter((version) => version !== v)
                                    );
                            }}
                            checked={versionFilters.includes(v)}
                        />
                    ))}
                </div>
            }
        />
    );
}
