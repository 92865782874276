import React, { SyntheticEvent } from 'react';
import { FieldComponentProps } from 'react-modular-forms';
import { FormField } from '../FormField/FormField';

type TimeCodeProps = FieldComponentProps & { audio?: boolean };

export const TimeCode: React.FC<TimeCodeProps> = ({
    label,
    name,
    value,
    onChange,
    formId,
    audio,
    onBlur
}) => (
    <FormField
        type="masked-field"
        label={label}
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        mask={audio ? '__:__:__.___' : '__:__:__:__'}
        replacement={{ _: /\d/ }}
        formId={formId}
    />
);
