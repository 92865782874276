import styles from './View.module.css';
import React, { CSSProperties } from 'react';
import cn from 'classnames';

type ViewProps = {
    children?: React.ReactNode;
    id: string;
    className?: string;
    style?: CSSProperties;
};
export function View({ children, className, id, style }: ViewProps) {
    return (
        <div id={id} className={cn(styles.view, className)} style={style}>
            {children}
        </div>
    );
}

type ViewContentProps = { children: React.ReactNode; className?: string; id?: string };

export function ViewContent({ children, className, id }: ViewContentProps) {
    return (
        <section id={id} className={cn(styles.content, className)}>
            {children}
        </section>
    );
}
