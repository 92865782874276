import './Tags.scss';
import React, { ReactNode } from 'react';
import cn from 'classnames';
import { Theme } from '../../../enums';
import { Tag } from '../Tag/Tag';

type TagsProps = {
    className?: string;
    remove?: Function;
    tags: ReactNode[];
    theme?: Theme;
};

export const Tags: React.FC<TagsProps> = ({ className, remove, tags, theme }) => (
    <ul className={cn('c-tags', className)}>
        {tags.map((tag, i) => (
            <li key={i}>
                <Tag theme={theme} remove={remove ? () => remove(tag) : undefined}>
                    {tag}
                </Tag>
            </li>
        ))}
    </ul>
);
