import styles from './AbsoluteContent.module.css';
import React, {
    MutableRefObject,
    ReactNode,
    type RefObject,
    SyntheticEvent,
    useEffect
} from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import { Theme } from '../../../enums';
import { parentHasClass } from '../../../utils/dom/parentHasClass';
import { Direction, DirectionX, DirectionY } from '../../../utils/getAbsolutePosition';
import { isTouchDevice } from '../../../utils/isTouchDevice';
import { onClickOutside } from '../../../utils/onClickOutside';

type AbsoluteContentProps = {
    absoluteRef: RefObject<HTMLDivElement>;
    parentRef?: MutableRefObject<HTMLElement | null>;
    autoClose?: number;
    className?: string;
    closeOnClickOutside?: boolean;
    closeOnContentClick?: boolean;
    content: ReactNode | ((hide: Function) => ReactNode);
    contentClassName?: string;
    direction?: Direction;
    hide: (e: SyntheticEvent<unknown, MouseEvent> | MouseEvent) => void;
    show: (e: SyntheticEvent) => void;
    style?: any;
    theme?: Theme;
    triggerRef?: MutableRefObject<any>;
    triggersOnHover?: boolean;
    triggerOnRightClick?: boolean;
    excludedElements?: HTMLElement[];
    excludedClasses?: string[];
    capture?: boolean;
};

export const AbsoluteContent = ({
    absoluteRef,
    parentRef,
    autoClose,
    closeOnClickOutside,
    closeOnContentClick,
    content,
    contentClassName,
    direction = [DirectionX.Center, DirectionY.Bottom],
    show,
    theme,
    hide,
    triggerRef,
    triggersOnHover,
    triggerOnRightClick,
    style = {},
    excludedElements = [],
    excludedClasses,
    capture: captureEvent = true
}: AbsoluteContentProps) => {
    useEffect(() => {
        const timeout = autoClose ? setTimeout(hide, autoClose) : undefined;
        const unregister = closeOnClickOutside
            ? onClickOutside(absoluteRef, hide, {
                  excludedElements: triggerRef
                      ? [triggerRef.current, ...excludedElements]
                      : excludedElements,
                  excludedClasses,
                  triggerOnRightClick
              })
            : null;

        const capture = (e: any) => (captureEvent ? e.stopPropagation() : null);
        if (!isTouchDevice()) {
            absoluteRef.current!.addEventListener('mousedown', capture, true);
            absoluteRef.current!.addEventListener('mouseup', capture, true);
        }

        return () => {
            clearTimeout(timeout);
            if (unregister) unregister();
            if (!isTouchDevice() && absoluteRef.current) {
                absoluteRef.current.removeEventListener('mousedown', capture, true);
                absoluteRef.current.removeEventListener('mouseup', capture, true);
            }
        };
    }, []);

    const parseHTML = typeof content === 'string';
    const innerContent = !parseHTML
        ? typeof content === 'function'
            ? content(hide)
            : content
        : null;

    return ReactDOM.createPortal(
        <div
            ref={absoluteRef}
            style={{
                position: 'absolute',
                ...style
            }}
            onClick={(e: any) => {
                if (
                    closeOnContentClick &&
                    parentHasClass(e.target, 'c-btn') &&
                    !parentHasClass(e.target, 'prevent-close')
                ) {
                    hide(e);
                }
            }}
            onMouseOver={triggersOnHover ? show : undefined}
            onMouseOut={triggersOnHover ? hide : undefined}
            className={classNames(
                'c-absolute-content',
                styles.absoluteContent,
                contentClassName,
                theme,
                styles[`is-${direction[0]}`],
                styles[`is-${direction[1]}`]
            )}
            dangerouslySetInnerHTML={parseHTML && content ? { __html: content } : undefined}
        >
            {innerContent}
        </div>,
        parentRef?.current ? parentRef.current : document.body
    );
};
