import routes, { castRoutes, docsRoutes, summaryRoutes } from '../../routes';

export function locationToContext() {
    switch (true) {
        case /^\/cast\//.test(window.location.pathname):
            return 'cast';
        case /^\/summary(_avid)?\//.test(window.location.pathname):
            return 'summary';
        case /^\/docs\//.test(window.location.pathname):
            return 'docs';
        default:
            return 'app';
    }
}

export const getFrontRoutesFromContext = (context: 'app' | 'cast' | 'summary' | 'docs') => {
    switch (context) {
        case 'cast':
            return castRoutes;
        case 'summary':
            return summaryRoutes;
        case 'docs':
            return docsRoutes;
        default:
            return routes;
    }
};
