import styles from './Playlist.module.css';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { FileFull } from '@he-novation/config/types/file.types';
import { Button, ButtonTone } from '@he-novation/design-system/components/buttons/Button/Button';
import { apiFolderContentFileToFrontFolderContentFile } from '@he-novation/front-shared/mappers/file.mappers';
import { PlaylistFile } from '@he-novation/front-shared/types/playlist.front.types';
import cn from 'classnames';
import update from 'immutability-helper';
import { useSetAtom } from 'jotai/index';

import { playlistAtom } from '$atoms/file-atoms/playlist-atom';
import { PlaylistFilters } from '$components/Playlist/PlaylistFilters/PlaylistFilters';
import PlaylistItem from '$components/Playlist/PlaylistItem/PlaylistItem';
import PlaylistTracker from '$components/Playlist/PlaylistTracker/PlaylistTracker';
import { useFullscreen } from '$hooks/useFullscreen';
import { usePanel } from '$hooks/usePanel';
import { usePlaylist } from '$hooks/usePlaylist';
import { useSocketIO } from '$hooks/useSocketIO';
import { useWindowing, WindowingDirection, WindowingOptions } from '$hooks/useWindowing';
import { isSmallScreenSelector } from '$redux/config/configSelectors';

const WINDOWING_OPTIONS: WindowingOptions = {
    itemWidth: 146,
    itemHeight: 64,
    itemsPerRow: 1,
    offset: [16, 8],
    direction: WindowingDirection.Horizontal,
    minRows: 100
};

type PlaylistProps = {
    folderUuid?: string;
    fetchContent: () => Promise<PlaylistFile[]>;
    openItemInPlayer: (file: PlaylistFile) => void;
};

export function Playlist({ folderUuid, fetchContent, openItemInPlayer }: PlaylistProps) {
    const {
        isPlaylistOpen,
        isPlaylistPlaying,
        playlistContent,
        playlistCurrentFileUuid,
        playlistFilters,
        playlistFolderUuid,
        setPlaylistFilters,
        setPlaylistIsPlaying,
        setPlaylistIsOpen,
        setPlaylistContent
    } = usePlaylist();
    const { panel } = usePanel();

    const folderSockets = useSocketIO();
    const setPlaylistAtom = useSetAtom(playlistAtom);

    const ref = useRef<HTMLDivElement | null>(null);

    const { isFullscreen } = useFullscreen();
    const { isSmallScreen } = useSelector(isSmallScreenSelector);

    const [windowingOptions, setWindowingOptions] = useState<WindowingOptions | null>(null);

    const windowing = useWindowing(ref, windowingOptions, playlistContent.length);

    useEffect(() => {
        if (folderUuid && playlistFolderUuid !== folderUuid) {
            setPlaylistIsOpen(false);
            setPlaylistIsPlaying(false);
        }
    }, [folderUuid, playlistFolderUuid]);

    useEffect(() => {
        if (ref.current) {
            setWindowingOptions(WINDOWING_OPTIONS);

            const currentFileIndex = playlistContent.findIndex(
                (file) => file.uuid === playlistCurrentFileUuid
            );
            const currentFileRow = currentFileIndex / WINDOWING_OPTIONS.itemsPerRow;
            if (currentFileRow < 0) return;
            const horizontalScroll = WINDOWING_OPTIONS.itemWidth * currentFileRow;
            setTimeout(() => ref.current?.scrollTo(horizontalScroll, 0), 200);
        }
    }, [isPlaylistOpen, playlistContent, playlistCurrentFileUuid]);

    useEffect(() => {
        if (folderUuid && folderUuid !== playlistFolderUuid) {
            setPlaylistContent(folderUuid, fetchContent);
        }
    }, [folderUuid, playlistFolderUuid]);

    useEffect(() => {
        if (folderUuid) {
            folderSockets.subscribe!({
                socket: 'folder',
                room: folderUuid,
                actions: {
                    sioFileSet: (payload: { file: FileFull }) => {
                        setPlaylistAtom((playlist) => {
                            const index = playlist.folderFiles
                                ? playlist.folderFiles.findIndex(
                                      (file) => file.uuid === payload.file.uuid
                                  )
                                : -1;
                            if (index === -1) return playlist;
                            return update(playlist, {
                                folderFiles: {
                                    [index]: {
                                        $set: apiFolderContentFileToFrontFolderContentFile(
                                            payload.file
                                        )
                                    }
                                }
                            });
                        });
                    }
                }
            });
        }

        return () => {
            folderSockets.unsubscribe();
        };
    }, [folderUuid]);

    const onClickItem = useCallback(
        (selectedUuid: string) => {
            if (selectedUuid === playlistCurrentFileUuid) {
                return;
            }
            const file = playlistContent.find(({ uuid }) => uuid === selectedUuid);
            if (file?.isPlayable) {
                openItemInPlayer(file);
            }
        },
        [playlistContent, playlistCurrentFileUuid]
    );

    const windowed: PlaylistFile[] = useMemo(() => {
        if (windowing) {
            return playlistContent.slice(windowing.visibleRange[0], windowing.visibleRange[1] + 1);
        }
        return playlistContent;
    }, [isPlaylistOpen, playlistContent, windowing]);

    if (!isPlaylistOpen || isFullscreen || isSmallScreen) {
        return null;
    }

    return (
        <div className={cn(styles.playlist, panel && styles.panelOpen)} tabIndex={0}>
            <PlaylistTracker openItemInPlayer={openItemInPlayer} />

            <div className={styles.playlistControls}>
                <Button
                    tone={ButtonTone.Hoverable}
                    icon={[{ name: isPlaylistPlaying ? 'pause' : 'play', fill: 'white' }]}
                    onClick={() => {
                        setPlaylistIsPlaying(!isPlaylistPlaying);
                    }}
                />

                <PlaylistFilters activatedFilters={playlistFilters} onChange={setPlaylistFilters} />
            </div>

            <div
                className={styles.playlistContent}
                ref={ref}
                style={windowing?.scrollContainerStyle}
            >
                <ul className={styles.playlistItems} style={windowing?.itemWrapperStyle}>
                    {windowed.map((file, i) => (
                        <li
                            key={file.uuid + playlistCurrentFileUuid}
                            style={windowing?.computeItemStyle(windowing.visibleRange[0] + i)}
                        >
                            <PlaylistItem
                                file={file}
                                onClick={() => onClickItem(file.uuid)}
                                fileUuid={playlistCurrentFileUuid!}
                            />
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
}
