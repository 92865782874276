import styles from './EmailChipsList.module.scss';
import React from 'react';
import { Button } from '../../buttons/Button/Button';
import { SelectedMembersListProps } from '../../form/FormField/components/EmailPicker/WorkspaceMemberPicker';

export function EmailChipsList({ selected, onUpdate }: SelectedMembersListProps) {
    return (
        <div className={styles.emailChips}>
            {selected?.map(({ email }) => (
                <div key={`selected-contact-${email}`} className={styles.emailChip}>
                    <span>{email}</span>
                    <Button
                        icon="plus"
                        type="button"
                        onClick={() => onUpdate(selected.filter((c) => c.email !== email))}
                    />
                </div>
            ))}
        </div>
    );
}
