import './DataLayoutListItem.scss';
import formStyle from '@he-novation/design-system/styles/form-styles.module.css';
import React from 'react';
import { Button, ButtonTone } from '@he-novation/design-system/components/buttons/Button/Button';
import { FormField } from '@he-novation/design-system/components/form/FormField/FormField';
import { Icon } from '@he-novation/design-system/components/graphics/Icon/Icon';
import { getParentWithClass } from '@he-novation/design-system/utils/dom/parentHasClass';
import { DirectionX, DirectionY } from '@he-novation/design-system/utils/getAbsolutePosition';
import { getLocalizedDate } from '@he-novation/front-shared/utils/datetime';
import cn from 'classnames';
import { useAtomValue } from 'jotai/index';
import { property } from 'lodash/fp';

import { localeAtom } from '$atoms/i18n-atoms';
import { useDataLayoutChildSelection } from '$components/DataLayout/components/DataLayoutChildren/useDataLayoutChildSelection';
import {
    DATA_LAYOUT_CLASSES,
    DataLayoutColumn,
    ItemToActions,
    SelectionAtom
} from '$components/DataLayout/DataLayout.types';
import { useAbsoluteMenu } from '$hooks/useAbsoluteMenu';

type DataLayoutItemListProps<T, U extends SelectionAtom | undefined, V> = {
    item: T;
    id: string;
    columns: DataLayoutColumn<T, U, V>[];
    selectionAtom: U;
    itemToActions?: ItemToActions<T>;
    extra: V;
    className: string;
};

export function DataLayoutListItem<T, U extends SelectionAtom | undefined, V>({
    item,
    columns,
    id,
    selectionAtom,
    itemToActions,
    extra,
    className
}: DataLayoutItemListProps<T, U, V>) {
    const locale = useAtomValue(localeAtom);

    const { isSelected, toggle, shiftSelect, isDraggingOver } =
        useDataLayoutChildSelection(selectionAtom);
    const { openAbsoluteMenu } = useAbsoluteMenu();
    return (
        <ul
            className={cn(
                'data-layout-list-item',
                isSelected(id) && 'is-selected project-styles',
                isDraggingOver(id) && 'is-dragging-over',
                className,
                formStyle.dark
            )}
            role="row"
            id={id}
        >
            {selectionAtom && (
                <li className={'data-layout-item-select'}>
                    <FormField
                        key={`data-layout-list-item-checkbox-${id}`}
                        id={`data-layout-list-item-checkbox-${id}`}
                        formId="data-layout-list-item-checkbox"
                        className="ignore-mouseup data-layout-item-checkbox"
                        type="checkbox"
                        label={'-'}
                        checked={isSelected(id)}
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                        wrapperProps={{
                            onClick: (e) => {
                                if (e.shiftKey) {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    const wrapper = getParentWithClass(
                                        e.target,
                                        DATA_LAYOUT_CLASSES.WRAPPER
                                    ) as HTMLElement;

                                    const items = Array.from(
                                        wrapper?.getElementsByClassName(DATA_LAYOUT_CLASSES.CHILD)
                                    ) as HTMLElement[];
                                    shiftSelect(
                                        items.map((item) => item.dataset.id),
                                        id
                                    );
                                }
                            }
                        }}
                        onChange={() => {
                            if (selectionAtom) {
                                toggle(id);
                            }
                        }}
                    />
                </li>
            )}
            {columns.map((column) => {
                const value = property(column.key)(item);
                let content: React.ReactNode;
                if (typeof column.render !== 'undefined')
                    content = column.render(item, selectionAtom, extra);
                else if (value instanceof Date) content = getLocalizedDate(value, locale);
                else if (typeof value === 'boolean')
                    content = (
                        <Button
                            className="data-layout-boolean-button"
                            tone={ButtonTone.Hoverable}
                            icon={value ? 'check' : undefined}
                            disabled
                        />
                    );
                else if (typeof value === 'number') content = value !== 0 ? value : null;
                else content = value;
                return (
                    <li
                        key={column.key}
                        role="cell"
                        className={cn(
                            `data-layout-col-${column.key.replace(/\./g, '-')}`,
                            column.grow && 'left'
                        )}
                        style={{
                            width: column.width,
                            flex: `${column.grow ? 1 : 0} 0 ${
                                column.width ? `${column.width}px` : ''
                            }`
                        }}
                    >
                        <div className={cn('cell-content', column.className)}>
                            {content}
                            {!!content && column.icon && <Icon icon={column.icon} />}
                        </div>
                    </li>
                );
            })}
            {itemToActions && (
                <li className="actions-cell">
                    <Button
                        className="action-cell-inner"
                        direction={[DirectionX.LeftInner, DirectionY.TopInner]}
                        icon={[{ name: 'three_dots', fill: 'white', stroke: 'white' }]}
                        tone={ButtonTone.Hoverable}
                        onClick={(e) => {
                            openAbsoluteMenu(
                                itemToActions(item),
                                e.currentTarget,
                                [DirectionX.Left, DirectionY.BottomInner],
                                [e.currentTarget.offsetWidth, e.currentTarget.offsetHeight]
                            );
                        }}
                    />
                </li>
            )}
        </ul>
    );
}
