import { sanitizedString } from '@he-novation/utils/zod.utils';
import { z } from 'zod';
import { SubtitleType } from '../db/enums';
import { subtitleSchema } from '../subtitle.types';
import { workspaceParamsSchema } from './workspace.payload';

export const subtitleParamsSchema = workspaceParamsSchema.extend({
    subtitleUuid: z.string().uuid()
});

export type SubtitleParamsSchema = z.infer<typeof subtitleParamsSchema>;

export const subtitleUpdateSchema = z.object({
    timecodeIn: z.string().optional(),
    timecodeOut: z.string().optional(),
    content: sanitizedString({ html: true, optional: true }),
    metadata: z.any().optional()
});

export type SubtitleUpdateSchema = z.infer<typeof subtitleUpdateSchema>;

export const subtitleUploadUrlBodySchema = z.object({
    fileUuid: z.string().uuid(),
    fileVersion: z.coerce.number(),
    subtitleFileName: z.string(),
    subtitleFileSize: z.number(),
    isBinary: z.boolean(),
    locale: z.string()
});

export const subtitleCreateBody = z.object({
    fileUuid: z.string().uuid(),
    fileVersion: z.number(),
    name: z.string(),
    locale: z.string(),
    copy: z.string().uuid().optional()
});

export type SubtitleCreateBody = z.infer<typeof subtitleCreateBody>;

export type SubtitleUploadUrlBody = z.infer<typeof subtitleUploadUrlBodySchema>;

export const subtitleUploadBody = z.object({
    fileUuid: z.string().uuid(),
    fileVersion: z.coerce.number(),
    locale: z.string(),
    file: z.any()
});

export type SubtitleUploadBody = z.infer<typeof subtitleUploadBody>;

export const subtitleGenerateBody = z.object({
    fileUuid: z.string().uuid(),
    fileVersion: z.coerce.number(),
    sourceLocale: z.string(),
    targetLocales: z.string().array().nonempty(),
    spotlVerified: z.boolean().optional().default(false)
});

export type SubtitleGenerateBody = z.infer<typeof subtitleGenerateBody>;

export const subtitleEntryParamsSchema = workspaceParamsSchema.extend({
    subtitleUuid: z.string().uuid(),
    entryUuid: z.string().uuid()
});

export type SubtitleEntryParams = z.infer<typeof subtitleEntryParamsSchema>;

export const subtitleQuerySchema = z.object({
    format: z.enum(['srt', 'stl-ebu', 'stl-spruce', 'vtt']),
    timestamp: z.coerce.number().optional(),
    p: z.string().optional()
});

export type SubtitleQuerySchema = z.infer<typeof subtitleQuerySchema>;

export const subtitleEntryBody = z.object({
    timecodeIn: z
        .string()
        .regex(/[\d:;.]/g, 'Invalid timecode')
        .min(11)
        .max(14),
    timecodeOut: z
        .string()
        .regex(/[\d:;.]/g, 'Invalid timecode')
        .min(11)
        .max(14),
    content: sanitizedString({ html: true }),
    metadata: z.any().optional().nullable()
});

export type SubtitleEntryBody = z.infer<typeof subtitleEntryBody>;

export const burntSubtitleParamsSchema = workspaceParamsSchema.extend({
    fileUuid: z.string().uuid(),
    fileVersion: z.coerce.number(),
    subtitleUuid: z.string().uuid()
});

export type BurntSubtitleParamsSchema = z.infer<typeof burntSubtitleParamsSchema>;

export const subtitleGeneratedSchema = z.object({
    uuid: z.string().uuid(),
    fileVersion: z.number(),
    name: z.string(),
    locale: z.string(),
    type: z.nativeEnum(SubtitleType),
    created: z.date(),
    updated: z.date().nullable(),
    spotlTranslation: z.object({
        uuid: z.string().uuid(),
        status: z.string(),
        spotlProjectId: z.string(),
        sourceLocale: z.string(),
        targetLocale: z.string(),
        verified: z.boolean()
    })
});

export type SubtitleGeneratedSchema = z.infer<typeof subtitleGeneratedSchema>;
