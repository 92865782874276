import { apiFetch } from '@he-novation/front-shared/async/apiFetch';
import type { Dispatch } from '@reduxjs/toolkit';

export const asyncActionSuccess = (name: string): string => `${name}/SUCCESS`;
export const asyncActionError = (name: string): string => `${name}/ERROR`;
export const asyncActionProgress = (name: string): string => `${name}/PROGRESS`;

interface XHROptions {
    url: string;
    method: string;
    success?: (dispatch: Dispatch, response: unknown, body: unknown) => void;
    error?: (dispatch: Dispatch, response: unknown, body: unknown) => void;
}

function asyncAction(
    actionName: string,
    xhrOptions: XHROptions,
    extraData?: unknown
): (body: unknown) => (dispatch: Dispatch) => Promise<void> {
    return (body: unknown) => async (dispatch: Dispatch) => {
        dispatch({ type: actionName, body, extraData });
        let response: unknown;
        try {
            response = await apiFetch(xhrOptions.url, {
                method: xhrOptions.method,
                data: JSON.stringify(body)
            });
            dispatch({
                type: asyncActionSuccess(actionName),
                body,
                response,
                extraData
            });
            if (typeof xhrOptions.success === 'function') {
                xhrOptions.success(dispatch, response, body);
            }
        } catch (e) {
            dispatch({ type: asyncActionError(actionName), response, body, extraData });
            if (typeof xhrOptions.error === 'function') {
                xhrOptions.error(dispatch, response, body);
            }
        }
    };
}

export default asyncAction;
