import { z } from 'zod';
import { Group } from '../paths/herawApiPathGroups';
import { ApiMethod } from '../types/api.types';
import { commentSchema, noteSchema } from '../types/note.types';
import {
    oaDeprecatedFileSpecificParams,
    oaFileVersionParamsSchema
} from '../types/open-api/file.open-api.types';
import {
    oaCommentCreateBody,
    oaNoteCreateBody,
    oaNoteSchema,
    oaNoteSpecificParams
} from '../types/open-api/note.open-api.types';
import { fileParamsSchema, fileVersionParamsSchema } from '../types/payloads/file.payload';
import {
    commentCreateBody,
    commentEditBody,
    commentParamsSchema,
    noteAttachmentCreateBody,
    noteCreateBody,
    noteParamsSchema
} from '../types/payloads/note.payload';
import { workspaceParamsSchema } from '../types/payloads/workspace.payload';
import { attachmentUploadUrlResponse } from '../types/responses/note.responses';

export const API_NOTE = {
    LIST: {
        method: ApiMethod.GET,
        path: '/workspaces/:workspaceName/files/:fileUuid/notes',
        handlerMethod: 'listNotes',
        zod: {
            params: fileParamsSchema
        },
        responses: {
            200: {
                description: 'Retrieved notes',
                schema: z.record(z.string().uuid(), noteSchema)
            }
        }
    },
    LIST_OA: {
        method: ApiMethod.GET,
        path: '/public/v1/workspaces/:workspaceName/files/:fileUuid/notes',
        handlerMethod: 'listNotes',
        zod: {
            params: fileParamsSchema
        },
        publicApi: true,
        description: 'Get notes for a file',
        group: Group.Notes,
        responses: {
            200: {
                description: 'Retrieved notes',
                schema: z.record(z.string().uuid(), oaNoteSchema)
            }
        }
    },
    LIST_OA_DEPRECATED: {
        method: ApiMethod.GET,
        path: '/public/v1/file/:fileUuid/v/:fileVersion/notes',
        handlerMethod: 'listNotes',
        zod: {
            params: oaDeprecatedFileSpecificParams
        },
        publicApi: 'deprecated',
        description: 'Get notes for a file',
        group: Group.Notes,
        responses: {
            200: {
                description: 'Retrieved notes',
                schema: z.record(z.string().uuid(), oaNoteSchema)
            }
        }
    },
    POST: {
        method: ApiMethod.POST,
        path: '/workspaces/:workspaceName/notes',
        handlerMethod: 'postNote',
        zod: {
            params: workspaceParamsSchema,
            body: noteCreateBody
        },
        responses: {
            200: {
                description: 'Note created',
                schema: noteSchema
            }
        }
    },
    POST_OA: {
        method: ApiMethod.POST,
        path: '/public/v1/workspaces/:workspaceName/files/:fileUuid/versions/:fileVersion/notes',
        handlerMethod: 'createSimpleNote',
        publicApi: true,
        description: 'Creates a simple note and associated comment',
        zod: {
            params: oaFileVersionParamsSchema,
            body: oaNoteCreateBody
        },
        responses: {
            201: {
                description: 'Note created',
                schema: oaNoteSchema
            },
            404: {
                description: 'File not found or user not authorized to add note to file'
            }
        },
        group: Group.Notes
    },
    POST_OA_DEPRECATED: {
        method: ApiMethod.POST,
        path: '/public/v1/file/:fileUuid/v/:fileVersion/note',
        handlerMethod: 'createSimpleNote',
        publicApi: 'deprecated',
        description: 'Creates a simple note and associated comment',
        zod: {
            params: oaDeprecatedFileSpecificParams,
            body: oaNoteCreateBody
        },
        responses: {
            201: {
                description: 'Note created',
                schema: oaNoteSchema
            },
            404: {
                description: 'File not found or user not authorized to add note to file'
            }
        },
        group: Group.Notes
    },
    DELETE: {
        method: ApiMethod.DELETE,
        path: '/workspaces/:workspaceName/notes/:noteUuid',
        handlerMethod: 'deleteNote',
        zod: {
            params: noteParamsSchema
        }
    },
    AVID_LIST: {
        method: ApiMethod.GET,
        path: '/workspaces/:workspaceName/notes/files/:fileUuid/versions/:fileVersion/avid-summary',
        handlerMethod: 'getAvidSummary',
        contentType: 'text/plain',
        zod: {
            params: fileVersionParamsSchema,
            query: z.object({
                filters: z.any().optional(),
                lineBreak: z.string().optional()
            })
        }
    }
} as const;

export const API_NOTE_COMMENT = {
    POST: {
        method: ApiMethod.POST,
        path: '/workspaces/:workspaceName/notes/:noteUuid/comments',
        handlerMethod: 'postComment',
        zod: {
            params: noteParamsSchema,
            body: commentCreateBody
        },
        responses: {
            200: {
                description: 'Comment created',
                schema: commentSchema
            }
        }
    },
    POST_OA_DEPRECATED: {
        method: ApiMethod.POST,
        path: '/public/v1/note/:noteUuid/comment',
        handlerMethod: 'createSimpleComment',
        publicApi: 'deprecated',
        description: 'Creates a comment associated with a note',
        zod: {
            params: oaNoteSpecificParams,
            body: oaCommentCreateBody
        },
        responses: {
            201: {
                description: 'Note created',
                schema: oaNoteSchema
            },
            404: {
                description: 'File not found or user not authorized to add note to file'
            }
        },
        group: Group.Notes
    },
    PATCH: {
        method: ApiMethod.PATCH,
        path: '/workspaces/:workspaceName/notes/:noteUuid/comments/:commentUuid',
        handlerMethod: 'updateComment',
        zod: {
            params: commentParamsSchema,
            body: commentEditBody
        },
        responses: {
            200: {
                description: 'Comment updated',
                schema: commentSchema
            }
        }
    },
    DELETE: {
        method: ApiMethod.DELETE,
        path: '/workspaces/:workspaceName/notes/:noteUuid/comments/:commentUuid',
        handlerMethod: 'deleteComment',
        zod: {
            params: commentParamsSchema
        }
    }
} as const;

export const API_NOTE_COMMENT_ATTACHMENT = {
    POST: {
        method: ApiMethod.POST,
        path: '/workspaces/:workspaceName/notes/:noteUuid/comments/:commentUuid/attachment',
        handlerMethod: 'postCommentAttachment',
        zod: {
            params: commentParamsSchema,
            body: noteAttachmentCreateBody
        },
        responses: {
            200: {
                description: 'Attachment uploaded',
                schema: attachmentUploadUrlResponse
            }
        }
    }
} as const;
