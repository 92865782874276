import {
    PlaylistFile,
    PlaylistFileFilter
} from '@he-novation/front-shared/types/playlist.front.types';
import { atom } from 'jotai';

export type PlaylistAtom = {
    fileUuid: string | null;
    filters: PlaylistFileFilter[];
    folderFiles: PlaylistFile[] | null;
    folderUuid: string | null;
    isOpen: boolean;
    isPlaying: boolean;
};

export const initialPlaylistAtom: PlaylistAtom = {
    fileUuid: null,
    filters: [],
    folderFiles: [],
    folderUuid: null,
    isOpen: false,
    isPlaying: false
};

export const playlistAtom = atom<PlaylistAtom>(initialPlaylistAtom);
