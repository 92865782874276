import { z } from 'zod';
import { Group } from '../paths/herawApiPathGroups';
import { ApiMethod } from '../types/api.types';
import {
    castCommonSchema,
    castFolderChildFolderSchema,
    castSchema,
    fileCastSchema,
    folderCastSchema
} from '../types/cast.types';
import {
    oaCastSchema,
    oaCastShareBody,
    oaFileCastSchema,
    oaFolderCastSchema
} from '../types/open-api/cast.open-api.types';
import {
    castCreateBody,
    castFileCreationBodySchema,
    castFileGetCastsParams,
    castFileParamsSchema,
    castFolderCreateBody,
    castFolderParamsSchema,
    castFolderUpdateBody,
    castPublicFileParamsSchema,
    castPublicFolderParamsSchema,
    castPublicSpecificParamsSchema,
    castQuerySchema,
    castSearchBodySchema,
    castShareBody,
    castsListQuerySchema,
    castSpecificParamsSchema,
    castUpdateBody,
    folderCastTriggerCreationBodySchema,
    folderCastTriggerParamsSchema
} from '../types/payloads/cast.payload';
import { folderParamsSchema, folderUuidSchema } from '../types/payloads/folder.payload';
import { workspaceParamsSchema } from '../types/payloads/workspace.payload';

export const API_CASTS = {
    LIST: {
        method: ApiMethod.GET,
        path: '/workspaces/:workspaceName/casts',
        handlerMethod: 'listCasts',
        zod: {
            params: workspaceParamsSchema,
            query: castsListQuerySchema
        },
        responses: {
            200: {
                description: 'List of casts',
                schema: z.array(castSchema)
            }
        }
    },
    LIST_OA: {
        method: ApiMethod.GET,
        path: '/public/v1/workspaces/:workspaceName/casts',
        handlerMethod: 'listCasts',
        publicApi: true,
        description: 'Lists casts',
        group: Group.Casts,
        zod: {
            params: workspaceParamsSchema,
            query: castsListQuerySchema
        },
        responses: {
            200: {
                description: 'List of casts',
                schema: z.array(oaCastSchema)
            }
        }
    },
    LIST_OA_DEPRECATED: {
        method: ApiMethod.GET,
        path: '/public/v1/casts',
        handlerMethod: 'listCasts',
        publicApi: 'deprecated',
        description: 'Lists casts',
        group: Group.Casts,
        zod: {
            query: castsListQuerySchema
        },
        responses: {
            200: {
                description: 'List of casts',
                schema: z.array(oaCastSchema)
            }
        }
    },
    SEARCH: {
        method: ApiMethod.POST,
        path: '/workspaces/:workspaceName/search/casts',
        handlerMethod: 'searchCasts',
        zod: {
            params: workspaceParamsSchema,
            body: castSearchBodySchema
        },
        responses: {
            200: {
                description: 'List of casts',
                schema: z.object({
                    results: z.array(castSchema),
                    totalResults: z.number(),
                    totalPages: z.number(),
                    resultsPerPage: z.number(),
                    page: z.number()
                })
            }
        }
    },
    GET: {
        method: ApiMethod.GET,
        path: '/workspaces/:workspaceName/casts/:castUid',
        handlerMethod: 'getCast',
        zod: {
            params: castSpecificParamsSchema
        },
        responses: {
            200: {
                description: 'Cast retrieved',
                schema: castSchema
            }
        }
    },
    POST: {
        method: ApiMethod.POST,
        path: '/workspaces/:workspaceName/casts',
        handlerMethod: 'createCast',
        zod: {
            params: workspaceParamsSchema,
            body: castCreateBody
        },
        responses: {
            200: {
                description: 'The created cast',
                schema: castSchema
            }
        }
    },
    POST_OA: {
        method: ApiMethod.POST,
        path: '/public/v1/workspaces/:workspaceName/casts',
        handlerMethod: 'createCast',
        publicApi: true,
        description: 'Creates a new cast',
        group: Group.Casts,
        zod: {
            params: workspaceParamsSchema,
            body: castCreateBody
        },
        responses: {
            200: {
                description: 'The created cast',
                schema: oaCastSchema
            }
        }
    },
    POST_OA_DEPRECATED: {
        method: ApiMethod.POST,
        path: '/public/v1/cast',
        handlerMethod: 'createCast',
        publicApi: 'deprecated',
        description: 'Creates a new cast',
        group: Group.Casts,
        zod: {
            body: castCreateBody
        },
        responses: {
            200: {
                description: 'The created cast',
                schema: oaCastSchema
            }
        }
    },
    PATCH: {
        method: ApiMethod.PATCH,
        path: '/workspaces/:workspaceName/casts/:castUid',
        handlerMethod: 'updateCast',
        zod: {
            params: castSpecificParamsSchema,
            body: castUpdateBody
        },
        responses: {
            200: {
                description: 'Cast updated',
                schema: castSchema
            }
        }
    },
    PATCH_OA: {
        method: ApiMethod.PATCH,
        path: '/public/v1/workspaces/:workspaceName/casts/:castUid',
        handlerMethod: 'updateCast',
        zod: {
            params: castSpecificParamsSchema,
            body: castUpdateBody
        },
        publicApi: true,
        description: 'Updates a cast',
        group: Group.Casts,
        responses: {
            200: {
                description: 'The updated cast',
                schema: oaCastSchema
            }
        }
    },
    PATCH_OA_DEPRECATED: {
        method: ApiMethod.PATCH,
        path: '/public/v1/cast/:castUid',
        handlerMethod: 'updateCast',
        zod: {
            params: castPublicSpecificParamsSchema,
            body: castUpdateBody
        },
        publicApi: 'deprecated',
        description: 'Updates a cast',
        group: Group.Casts,
        responses: {
            200: {
                description: 'The updated cast',
                schema: oaCastSchema
            }
        }
    },
    DELETE: {
        method: ApiMethod.DELETE,
        path: '/workspaces/:workspaceName/casts/:castUid',
        handlerMethod: 'deleteCast',
        zod: {
            params: castSpecificParamsSchema
        },
        responses: {
            204: {
                description: 'Cast deleted'
            }
        }
    },
    DELETE_OA: {
        method: ApiMethod.DELETE,
        path: '/public/v1/workspaces/:workspaceName/casts/:castUid',
        handlerMethod: 'deleteCast',
        publicApi: true,
        description: 'Deletes a cast',
        group: Group.Casts,
        zod: {
            params: castSpecificParamsSchema
        },
        responses: {
            204: {
                description: 'The cast was successfully deleted'
            }
        }
    },
    DELETE_OA_DEPRECATED: {
        method: ApiMethod.DELETE,
        path: '/public/v1/cast/:castUid',
        handlerMethod: 'deleteCast',
        publicApi: 'deprecated',
        description: 'Deletes a cast',
        group: Group.Casts,
        zod: {
            params: castPublicSpecificParamsSchema
        },
        responses: {
            204: {
                description: 'The cast was successfully deleted'
            }
        }
    },
    SHARE: {
        method: ApiMethod.POST,
        path: '/workspaces/:workspaceName/casts/:castUid/share',
        handlerMethod: 'shareCast',
        zod: {
            params: castSpecificParamsSchema,
            body: castShareBody
        }
    },
    SHARE_OA: {
        method: ApiMethod.POST,
        path: '/public/v1/workspaces/:workspaceName/casts/:castUid/share',
        handlerMethod: 'shareCast',
        publicApi: true,
        description: 'Shares a cast',
        group: Group.Casts,
        zod: {
            params: castSpecificParamsSchema,
            body: castShareBody
        },
        responses: {
            204: {
                description: 'Email(s) sent'
            }
        }
    },
    SHARE_OA_DEPRECATED: {
        method: ApiMethod.POST,
        path: '/public/v1/cast/share',
        handlerMethod: 'oaShareCast',
        publicApi: 'deprecated',
        description: 'Shares a cast',
        group: Group.Casts,
        zod: {
            body: oaCastShareBody
        },
        responses: {
            204: {
                description: 'Email(s) sent'
            }
        }
    }
} as const;

export const API_FOLDER_CAST_TRIGGERS = {
    LIST: {
        method: ApiMethod.GET,
        path: '/workspaces/:workspaceName/folder/:folderUuid/casts',
        handlerMethod: 'listFolderCasts',
        zod: {
            params: folderParamsSchema
        },
        responses: {
            200: {
                description: 'List of folder casts',
                schema: z.array(folderCastSchema)
            }
        }
    },
    LIST_OA: {
        method: ApiMethod.GET,
        path: '/public/v1/workspaces/:workspaceName/folder/:folderUuid/casts',
        handlerMethod: 'listFolderCasts',
        publicApi: true,
        description: 'Get all casts in which the specified folder is synced',
        group: Group.Casts,
        zod: {
            params: folderParamsSchema
        },
        responses: {
            200: {
                description: 'List of folder casts',
                schema: z.array(oaFolderCastSchema)
            }
        }
    },
    LIST_OA_DEPRECATED: {
        method: ApiMethod.GET,
        path: '/public/v1/cast/folder/:folderUuid/triggers',
        handlerMethod: 'listFolderCasts',
        publicApi: 'deprecated',
        description: 'Get all casts in which the specified folder is synced',
        group: Group.Casts,
        zod: {
            params: folderUuidSchema
        },
        responses: {
            200: {
                description: 'List of folder casts',
                schema: z.array(oaFolderCastSchema)
            }
        }
    },
    POST: {
        method: ApiMethod.POST,
        path: '/workspaces/:workspaceName/folder/:folderUuid/casts',
        handlerMethod: 'createFolderCastTrigger',
        zod: {
            params: folderParamsSchema,
            body: folderCastTriggerCreationBodySchema
        },
        responses: {
            200: {
                description: 'The created folder cast trigger',
                schema: z.object({
                    folderCastTriggerUid: z.string()
                })
            }
        }
    },
    DELETE: {
        method: ApiMethod.DELETE,
        path: '/workspaces/:workspaceName/folder/:folderUuid/casts/:folderCastTriggerUid',
        handlerMethod: 'deleteFolderCastTrigger',
        zod: {
            params: folderCastTriggerParamsSchema
        },
        responses: {
            204: {
                description: 'The folder cast trigger was successfully deleted'
            }
        }
    }
} as const;

export const API_CAST_FOLDERS = {
    LIST: {
        method: ApiMethod.GET,
        path: '/workspaces/:workspaceName/casts/:castUid/folders',
        handlerMethod: 'listCastFolders',
        zod: {
            params: castSpecificParamsSchema
        },
        responses: {
            200: {
                description: 'List of cast folders',
                schema: z.array(castFolderChildFolderSchema)
            }
        }
    },
    POST: {
        method: ApiMethod.POST,
        path: '/workspaces/:workspaceName/casts/:castUid/folders',
        handlerMethod: 'createCastFolder',
        zod: {
            params: castSpecificParamsSchema,
            body: castFolderCreateBody
        }
    },
    POST_OA: {
        method: ApiMethod.POST,
        path: '/public/v1/workspaces/:workspaceName/casts/:castUid/folders',
        handlerMethod: 'createCastFolder',
        publicApi: true,
        description: 'Adds a folder to the specified cast',
        group: Group.Casts,
        zod: {
            params: castSpecificParamsSchema,
            body: castFolderCreateBody
        },
        responses: {
            200: {
                schema: oaFolderCastSchema,
                description: 'The created cast folder'
            }
        }
    },
    POST_OA_DEPRECATED: {
        method: ApiMethod.POST,
        path: '/public/v1/cast/:castUid/folder',
        handlerMethod: 'createCastFolder',
        publicApi: 'deprecated',
        description: 'Adds a folder to the specified cast',
        group: Group.Casts,
        zod: {
            params: castPublicSpecificParamsSchema,
            body: castFolderCreateBody
        },
        responses: {
            200: {
                schema: oaFolderCastSchema,
                description: 'The created cast folder'
            }
        }
    },
    PUT: {
        method: ApiMethod.PUT,
        path: '/workspaces/:workspaceName/casts/:castUid/folders/:castFolderUuid',
        handlerMethod: 'updateCastFolder',
        zod: {
            params: castFolderParamsSchema,
            body: castFolderUpdateBody
        },
        responses: {
            204: {
                description: 'The cast folder was successfully updated'
            }
        }
    },
    DELETE: {
        method: ApiMethod.DELETE,
        path: '/workspaces/:workspaceName/casts/:castUid/folders/:castFolderUuid',
        handlerMethod: 'deleteCastFolder',
        zod: {
            params: castFolderParamsSchema
        },
        responses: {
            204: {
                description: 'The cast folder was successfully deleted'
            }
        }
    },
    DELETE_OA: {
        method: ApiMethod.DELETE,
        path: '/public/v1/workspaces/:workspaceName/casts/:castUid/folders/:castFolderUuid',
        handlerMethod: 'deleteCastFolder',
        publicApi: true,
        description: 'Deletes a folder from a cast',
        group: Group.Casts,
        zod: {
            params: castFolderParamsSchema
        },
        responses: {
            204: {
                description: 'The cast folder was successfully deleted'
            }
        }
    },
    DELETE_OA_DEPRECATED: {
        method: ApiMethod.DELETE,
        path: '/public/v1/cast/:castUid/folder/:castFolderUuid',
        handlerMethod: 'deleteCastFolder',
        publicApi: 'deprecated',
        description: 'Deletes a folder from a cast',
        group: Group.Casts,
        zod: {
            params: castPublicFolderParamsSchema
        },
        responses: {
            204: {
                description: 'The cast folder was successfully deleted'
            }
        }
    }
} as const;

export const API_FILE_CASTS = {
    LIST: {
        method: ApiMethod.GET,
        path: '/workspaces/:workspaceName/files/:fileUuid/casts',
        handlerMethod: 'listCastFiles',
        zod: {
            params: workspaceParamsSchema.merge(castFileGetCastsParams)
        },
        responses: {
            200: {
                description: 'List of casts',
                schema: z.array(fileCastSchema)
            }
        }
    },
    LIST_OA: {
        method: ApiMethod.GET,
        path: '/public/v1/workspaces/:workspaceName/files/:fileUuid/casts',
        handlerMethod: 'listCastFiles',
        zod: {
            params: workspaceParamsSchema.merge(castFileGetCastsParams)
        },
        publicApi: true,
        description: 'Get all casts in which the specified file is cast',
        group: Group.Casts,
        responses: {
            200: {
                schema: z.array(oaFileCastSchema),
                description: 'List of file casts'
            }
        }
    },
    LIST_OA_DEPRECATED: {
        method: ApiMethod.GET,
        path: '/public/v1/cast/file/:fileUuid',
        handlerMethod: 'listCastFiles',
        zod: {
            params: castFileGetCastsParams
        },
        publicApi: 'deprecated',
        description: 'Get all casts in which the specified file is cast',
        group: Group.Casts,
        responses: {
            200: {
                schema: z.array(oaFileCastSchema),
                description: 'List of file casts'
            }
        }
    }
} as const;

export const API_CAST_FILES = {
    POST: {
        method: ApiMethod.POST,
        path: '/workspaces/:workspaceName/casts/:castUid/files',
        handlerMethod: 'createCastFile',
        zod: {
            params: castSpecificParamsSchema,
            body: castFileCreationBodySchema
        },
        responses: {
            200: {
                description: 'The created cast file',
                schema: z.object({
                    castFileUuid: z.string()
                })
            }
        }
    },
    POST_OA: {
        method: ApiMethod.POST,
        path: '/public/v1/workspaces/:workspaceName/casts/:castUid/files',
        handlerMethod: 'createCastFile',
        publicApi: true,
        description: 'Adds a file in the specified cast',
        group: Group.Casts,
        zod: {
            params: castSpecificParamsSchema,
            body: castFileCreationBodySchema
        },
        responses: {
            200: {
                description: 'The created cast file',
                schema: z.object({
                    castFileUuid: z.string()
                })
            }
        }
    },
    POST_OA_DEPRECATED: {
        method: ApiMethod.POST,
        path: '/public/v1/cast/:castUid/file',
        handlerMethod: 'createCastFile',
        publicApi: 'deprecated',
        description: 'Adds a file in the specified cast',
        group: Group.Casts,
        zod: {
            params: castPublicSpecificParamsSchema,
            body: castFileCreationBodySchema
        },
        responses: {
            200: {
                description: 'The created cast file',
                schema: z.object({
                    castFileUuid: z.string()
                })
            }
        }
    },
    DELETE: {
        method: ApiMethod.DELETE,
        path: '/workspaces/:workspaceName/casts/:castUid/files/:castFileUuid',
        handlerMethod: 'deleteCastFile',
        zod: {
            params: castFileParamsSchema
        },
        responses: {
            204: {
                description: 'The cast file was successfully deleted'
            }
        }
    },
    DELETE_OA: {
        method: ApiMethod.DELETE,
        path: '/public/v1/workspaces/:workspaceName/casts/:castUid/files/:castFileUuid',
        handlerMethod: 'deleteCastFile',
        publicApi: true,
        description: 'Deletes a file from the specified cast',
        group: Group.Casts,
        zod: {
            params: castFileParamsSchema
        },
        responses: {
            204: {
                description: 'File deleted'
            }
        }
    },
    DELETE_OA_DEPRECATED: {
        method: ApiMethod.DELETE,
        path: '/public/v1/cast/:castUid/file/:castFileUuid',
        handlerMethod: 'deleteCastFile',
        publicApi: 'deprecated',
        description: 'Deletes a file from the specified cast',
        group: Group.Casts,
        zod: {
            params: castPublicFileParamsSchema
        },
        responses: {
            204: {
                description: 'File deleted'
            }
        }
    }
} as const;

export const API_CAST_PRIVATE_VIEW = {
    GET: {
        method: ApiMethod.GET,
        path: '/workspaces/:workspaceName/private-casts/:castUid',
        handlerMethod: 'getCastPrivateView',
        zod: {
            params: castSpecificParamsSchema,
            query: castQuerySchema
        },
        responses: {
            200: {
                description: 'The cast private view',
                schema: castCommonSchema
            }
        }
    }
} as const;
