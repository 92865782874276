import styles from './DataLayoutWorkspaceMembersGridItem.module.css';
import React, { useRef } from 'react';
import type { WorkspaceUser } from '@he-novation/config/types/workspace-team.types';
import {
    AvatarSize,
    AvatarUser
} from '@he-novation/design-system/components/avatars/Avatar/AvatarUser';

import { DataLayoutCard } from '$components/DataLayout/components/DataLayoutCard/DataLayoutCard';
import { ItemComponentProps, SelectionAtom } from '$components/DataLayout/DataLayout.types';
import { DataLayoutWorkspaceMembersExtra } from '$components/DataLayout/DataLayoutWorkspaceMembers/DataLayoutWorkspaceMembers';
import { highlight } from '$components/DataLayout/DataLayoutWorkspaceMembers/DataLayoutWorkspaceMembers.utils';

export function DataLayoutWorkspaceMembersGridItem({
    item,
    extra
}: ItemComponentProps<WorkspaceUser, SelectionAtom, DataLayoutWorkspaceMembersExtra>) {
    const contentRef = useRef<HTMLDivElement>(null);
    const highlighted = highlight(item, extra.search);

    const visual = (
        <div className={styles.workspaceMemberVisual}>
            <AvatarUser size={AvatarSize.Large} {...item} />
            <div className={styles.workspaceMemberDetails}>
                {(item.firstname || item.lastname) && (
                    <h3>
                        {highlighted.firstname} {highlighted.lastname}
                    </h3>
                )}
                <div className={styles.workspaceMemberCompany}>{highlighted.firm}</div>
            </div>
        </div>
    );

    return (
        <DataLayoutCard picture={visual} contentRef={contentRef}>
            <>
                {highlighted.email}
                <br />
                {highlighted.phone}
            </>
        </DataLayoutCard>
    );
}
