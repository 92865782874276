import styles from './Tag.module.scss';
import React, { ReactNode } from 'react';
import cn from 'classnames';
import { Theme } from '../../../enums';
import { Button, ButtonTone } from '../../buttons/Button/Button';

export type TagProps = {
    className?: string;
    theme?: Theme;
    remove?: Function;
    children?: ReactNode | ReactNode[];
};
export const Tag: React.FC<TagProps> = ({ children, className, remove, theme }) => (
    <div className={cn(styles.tag, 'c-tag', theme, className)}>
        {children}
        {remove && (
            <Button
                icon="plus"
                tone={ButtonTone.Hoverable}
                onClick={remove}
                className={styles.closeButton}
            />
        )}
    </div>
);
