import { Entity } from '../entity.types';

export enum WSEntityEventType {
    EntityCreated = 'entity/created',
    EntityUpdated = 'entity/updated',
    EntityDeleted = 'entity/deleted'
}

export type WSEntityEventCreated = {
    type: WSEntityEventType.EntityCreated;
    entity: Entity;
};

export type WSEntityEventUpdated = {
    type: WSEntityEventType.EntityUpdated;
    entity: Entity;
};

export type WSEntityEventDeleted = {
    type: WSEntityEventType.EntityDeleted;
    uuid: string;
};
