import './DataLayoutChildren.scss';
import React, { CSSProperties } from 'react';
import { useDoubleClickHandler } from '@he-novation/design-system/hooks/useDoubleClickHandler';
import cn from 'classnames';

import { DataLayoutGridItem } from '$components/DataLayout/components/DataLayoutChildren/DataLayoutGridItem/DataLayoutGridItem';
import { DataLayoutListItem } from '$components/DataLayout/components/DataLayoutChildren/DataLayoutListItem/DataLayoutListItem';
import {
    DATA_LAYOUT_CLASSES,
    DataLayoutColumn,
    ItemToActions,
    SelectionAtom
} from '$components/DataLayout/DataLayout.types';
import { DataLayoutDisplayMode, ItemComponent } from '$components/DataLayout/DataLayout.types';
import { SelectionOptions } from '$components/Selectable/SelectionHandler';
import { useAbsoluteMenu } from '$hooks/useAbsoluteMenu';

type DataLayoutChildrenProps<T, U extends SelectionAtom | undefined, V> = {
    items: T[];
    displayMode: DataLayoutDisplayMode;
    computeItemStyle?: (index: number) => CSSProperties;
    offset: number;
    columns: DataLayoutColumn<T, U, V>[];
    ItemGridComponent?: ItemComponent<T, U, V>;
    style?: React.CSSProperties;
    group?: string;
    selectionAtom: U;
    extra: V;
    hideHeader?: boolean;
    itemToId: (item: T) => string;
    itemToActions?: ItemToActions<T>;
    className?: string;
    doubleClickHandler?: (e: React.MouseEvent<Element, MouseEvent>, item: T) => void;
};

function DataLayoutChildrenInner<T, U extends SelectionAtom | undefined, V>({
    items,
    computeItemStyle,
    displayMode,
    columns,
    ItemGridComponent,
    itemToId,
    style,
    offset,
    group,
    selectionAtom,
    extra,
    hideHeader,
    itemToActions,
    className,
    doubleClickHandler
}: DataLayoutChildrenProps<T, U, V>) {
    const Component =
        ItemGridComponent && displayMode === DataLayoutDisplayMode.Grid
            ? DataLayoutGridItem
            : DataLayoutListItem;

    const onDoubleClick =
        doubleClickHandler &&
        useDoubleClickHandler(
            (e, element) => {
                const id = element.getAttribute('data-id');
                const item = items.find((item) => itemToId(item) === id);
                if (item && doubleClickHandler) {
                    doubleClickHandler(e, item);
                }
            },
            {
                className: DATA_LAYOUT_CLASSES.CHILD
            }
        );

    return (
        <ul
            className={cn(
                'data-layout-children',
                doubleClickHandler && 'has-links',
                displayMode === DataLayoutDisplayMode.List && !hideHeader && 'has-header',
                className
            )}
            style={style}
            onClick={onDoubleClick}
        >
            {items.map((item, i) => {
                const index = i + offset;
                return (
                    <DataLayoutChild
                        selectionAtom={selectionAtom}
                        key={`${index}-${displayMode}-${itemToId(item)}`}
                        i={index}
                        item={item}
                        extra={extra}
                        columns={columns}
                        Component={Component}
                        //@ts-expect-error weird
                        ItemGridComponent={ItemGridComponent}
                        style={computeItemStyle?.(i + offset) || {}}
                        group={group}
                        itemToActions={itemToActions}
                        displayMode={displayMode}
                        itemToId={itemToId}
                    />
                );
            })}
        </ul>
    );
}

type DataLayoutChildProps<T, U extends SelectionAtom | undefined, V> = {
    i: number;
    item: T;
    extra: V;
    columns: DataLayoutColumn<T, U, V>[];
    ItemGridComponent?: ItemComponent<T, U, V>;
    Component: ItemComponent<T, U, V>;
    selectionAtom: U;
    selectionOptions?: SelectionOptions;
    style?: React.CSSProperties;
    group?: string;
    displayMode: DataLayoutDisplayMode;
    itemToId: (item: T) => string;
    itemToActions?: ItemToActions<T>;
};
function DataLayoutChild<T, U extends SelectionAtom | undefined, V>({
    i,
    item,
    extra,
    columns,
    ItemGridComponent,
    Component,
    selectionAtom,
    style,
    displayMode,
    itemToId,
    itemToActions
}: DataLayoutChildProps<T, U, V>) {
    const id = itemToId(item);
    const componentProps = {
        index: i,
        item,
        extra,
        columns,
        itemToActions,
        ItemGridComponent,
        id,
        selectionAtom
    };

    const { openAbsoluteMenu } = useAbsoluteMenu();

    return (
        <li
            onContextMenu={
                itemToActions
                    ? (e) => {
                          e.preventDefault();
                          openAbsoluteMenu(itemToActions(item), [e.pageX, e.pageY]);
                      }
                    : undefined
            }
            key={`${i}-${displayMode}`}
            className={cn('li-' + i, i % 2 ? 'is-odd' : 'is-even', DATA_LAYOUT_CLASSES.CHILD)}
            data-id={id}
            style={style}
        >
            <Component {...componentProps} />
        </li>
    );
}

export const DataLayoutChildren = React.memo(DataLayoutChildrenInner);
