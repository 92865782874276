import { z } from 'zod';
import { Group } from '../paths/herawApiPathGroups';
import { ApiMethod } from '../types/api.types';
import { clientUserSchema } from '../types/client.types';
import { oaClientUserSchema } from '../types/open-api/client.open-api.types';
import {
    oaWorkspaceTeamWithMembersSchema,
    oaWorkspaceUserSchema
} from '../types/open-api/workspace-teams.open-api.types';
import {
    oaWorkspaceParamsSchema,
    workspaceParamsSchema
} from '../types/payloads/workspace.payload';
import {
    oaWorkspaceTeamParamsSchema,
    workspaceTeamBodySchema,
    workspaceTeamParamsSchema,
    workspaceTeamUserParamsSchema,
    workspaceTeamUsersBodySchema
} from '../types/payloads/workspace-team.payload';
import {
    workspaceTeamSchema,
    workspaceTeamWithMembersSchema,
    workspaceUserSchema
} from '../types/workspace-team.types';
import { workspaceAcl } from '../utils/acl';

export const API_WORKSPACE_TEAM = {
    LIST: {
        method: ApiMethod.GET,
        path: '/workspaces/:workspaceName/teams',
        handlerMethod: 'listWorkspaceTeams',
        zod: {
            params: workspaceParamsSchema
        },
        responses: {
            200: {
                description: 'List of workspace teams',
                schema: z.array(workspaceTeamWithMembersSchema)
            }
        }
    },
    LIST_OA: {
        method: ApiMethod.GET,
        path: '/public/v1/workspaces/:workspaceName/teams',
        handlerMethod: 'listWorkspaceTeams',
        description: 'List of workspace teams',
        group: Group.Workspace,
        publicApi: true,
        zod: {
            params: oaWorkspaceParamsSchema
        },
        responses: {
            200: {
                description: 'List of workspace teams',
                schema: z.array(oaWorkspaceTeamWithMembersSchema)
            }
        }
    },
    GET: {
        method: ApiMethod.GET,
        path: '/workspaces/:workspaceName/teams/:teamUuid',
        handlerMethod: 'getWorkspaceTeam',
        zod: {
            params: workspaceTeamParamsSchema
        },
        responses: {
            200: {
                description: 'The workspace team',
                schema: workspaceTeamWithMembersSchema
            }
        }
    },
    POST: {
        method: ApiMethod.POST,
        path: '/workspaces/:workspaceName/teams',
        handlerMethod: 'createWorkspaceTeam',
        workspaceRoles: workspaceAcl.workspaces.manage,
        zod: {
            params: workspaceParamsSchema,
            body: workspaceTeamBodySchema
        },
        responses: {
            200: {
                description: 'The created workspace team',
                schema: workspaceTeamSchema
            }
        }
    },
    PUT: {
        method: ApiMethod.PUT,
        path: '/workspaces/:workspaceName/teams/:teamUuid',
        handlerMethod: 'updateWorkspaceTeam',
        workspaceRoles: workspaceAcl.workspaces.manage,
        zod: {
            params: workspaceTeamParamsSchema,
            body: workspaceTeamBodySchema
        },
        responses: {
            200: {
                description: 'The updated workspace team',
                schema: workspaceTeamSchema
            }
        }
    },
    DELETE: {
        method: ApiMethod.DELETE,
        path: '/workspaces/:workspaceName/teams/:teamUuid',
        handlerMethod: 'deleteWorkspaceTeam',
        workspaceRoles: workspaceAcl.workspaces.manage,
        zod: {
            params: workspaceTeamParamsSchema
        },
        responses: {
            204: {
                description: 'The workspace team was successfully deleted'
            }
        }
    }
} as const;

export const API_WORKSPACE_TEAM_USER = {
    LIST: {
        method: ApiMethod.GET,
        path: '/workspaces/:workspaceName/teams/:teamUuid/users',
        handlerMethod: 'listWorkspaceTeamUsers',
        zod: {
            params: workspaceTeamParamsSchema
        },
        responses: {
            200: {
                description: 'List of workspace team members',
                schema: z.array(workspaceUserSchema)
            }
        }
    },
    LIST_OA: {
        method: ApiMethod.GET,
        path: '/public/v1/workspaces/:workspaceName/teams/:teamUuid/users',
        handlerMethod: 'listWorkspaceTeamUsers',
        description: 'List of workspace team members',
        publicApi: true,
        group: Group.Workspace,
        zod: {
            params: oaWorkspaceTeamParamsSchema
        },
        responses: {
            200: {
                description: 'List of workspace team members',
                schema: z.array(oaWorkspaceUserSchema)
            }
        }
    },
    POST: {
        method: ApiMethod.POST,
        path: '/workspaces/:workspaceName/teams/:teamUuid/users',
        handlerMethod: 'addUsersToWorkspaceTeam',
        workspaceRoles: workspaceAcl.workspaces.manage,
        zod: {
            params: workspaceTeamParamsSchema,
            body: workspaceTeamUsersBodySchema
        },
        responses: {
            204: {
                description: 'The users were successfully added to the workspace team'
            }
        }
    },
    DELETE: {
        method: ApiMethod.DELETE,
        path: '/workspaces/:workspaceName/teams/:teamUuid/users/:userUuid',
        handlerMethod: 'removeUserFromWorkspaceTeam',
        workspaceRoles: workspaceAcl.workspaces.manage,
        zod: {
            params: workspaceTeamUserParamsSchema
        },
        responses: {
            204: {
                description: 'The user was successfully removed from the workspace team'
            }
        }
    }
} as const;
