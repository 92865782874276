import { buildApiCall } from '@he-novation/config/apis/api-fetch';
import {
    API_WORKSPACE_TEAM,
    API_WORKSPACE_TEAM_USER
} from '@he-novation/config/apis/workspace-team-api';
import { WorkspaceTeamBody } from '@he-novation/config/types/payloads/workspace-team.payload';

export const asyncListWorkspaceTeams = async (workspaceName: string) =>
    buildApiCall(API_WORKSPACE_TEAM.LIST)({ params: { workspaceName } });

export const asyncGetWorkspaceTeam = async (workspaceName: string, workspaceTeamUuid: string) =>
    buildApiCall(API_WORKSPACE_TEAM.GET)({
        params: { workspaceName, teamUuid: workspaceTeamUuid }
    });

export const asyncCreateWorkspaceTeam = async (workspaceName: string, body: WorkspaceTeamBody) =>
    buildApiCall(API_WORKSPACE_TEAM.POST)({ params: { workspaceName }, body });

export const asyncUpdateWorkspaceTeam = async (
    workspaceName: string,
    workspaceTeamUuid: string,
    body: WorkspaceTeamBody
) =>
    buildApiCall(API_WORKSPACE_TEAM.PUT)({
        params: { workspaceName, teamUuid: workspaceTeamUuid },
        body
    });

export const asyncDeleteWorkspaceTeam = async (workspaceName: string, workspaceTeamUuid: string) =>
    buildApiCall(API_WORKSPACE_TEAM.DELETE)({
        params: { workspaceName, teamUuid: workspaceTeamUuid }
    });

export const asyncListWorkspaceTeamUsers = async (
    workspaceName: string,
    workspaceTeamUuid: string
) =>
    buildApiCall(API_WORKSPACE_TEAM_USER.LIST)({
        params: { workspaceName, teamUuid: workspaceTeamUuid }
    });

export const asyncAddUsersToWorkspaceTeam = async (
    workspaceName: string,
    workspaceTeamUuid: string,
    userUuids: string[]
) =>
    buildApiCall(API_WORKSPACE_TEAM_USER.POST)({
        params: { workspaceName, teamUuid: workspaceTeamUuid },
        body: { uuids: userUuids }
    });

export const asyncRemoveUserFromWorkspaceTeam = async (
    workspaceName: string,
    workspaceTeamUuid: string,
    userUuid: string
) =>
    buildApiCall(API_WORKSPACE_TEAM_USER.DELETE)({
        params: { workspaceName, teamUuid: workspaceTeamUuid, userUuid }
    });
