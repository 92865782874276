import { z } from 'zod';
import { ffprobeResultSchema } from '../asset.types';
import { workspaceParamsSchema } from '../payloads/workspace.payload';

export const oaAssetSchema = z.object({
    quality: z.string().nullable(),
    key: z.string().nullable(),
    mimeType: z.string().nullable(),
    size: z.number(),
    type: z.string().nullable(),
    url: z.string().nullable(),
    urlExpires: z.date().nullable(),
    uuid: z.string(),
    version: z.number(),
    collectionId: z.number().nullable().optional(),
    ffprobeResult: ffprobeResultSchema.optional(),
    exifResult: z.any().optional(),
    note: z.object({ uuid: z.string() }).optional(),
    comment: z.object({ uuid: z.string() }).optional(),
    subtitle: z.object({ uuid: z.string() }).optional(),
    name: z.string().optional().nullable()
});

export type OAAsset = z.infer<typeof oaAssetSchema>;

export const oaAssetUploadCommandParam = workspaceParamsSchema.extend({
    assetUuid: z.string().uuid()
});
export type OAAssetUploadCommandParam = z.infer<typeof oaAssetUploadCommandParam>;

export const oaAssetUploadCommandParamDeprecated = z.object({
    assetUuid: z.string().uuid()
});

export type OAAssetUploadCommandParamDeprecated = z.infer<
    typeof oaAssetUploadCommandParamDeprecated
>;

export const oaAssetUploadAbortBody = z.object({
    uploadId: z.string()
});

export type OAAssetUploadAbortBody = z.infer<typeof oaAssetUploadAbortBody>;

export const oaAssetUploadCompleteBody = z.object({
    uploadId: z.string(),
    parts: z.array(
        z.object({
            ETag: z.string(),
            PartNumber: z.number(),
            ChecksumCRC32: z.string().optional(),
            ChecksumCRC32C: z.string().optional(),
            ChecksumSHA1: z.string().optional(),
            ChecksumSHA256: z.string().optional()
        })
    )
});

export type OAAssetUploadCompleteBody = z.infer<typeof oaAssetUploadCompleteBody>;
