import { buildApiCall } from '@he-novation/config/apis/api-fetch';
import {
    API_INTEGRATION,
    APIT_INTEGRATION_AUTH_URL
} from '@he-novation/config/apis/integration-api';
import { IntegrationBody } from '@he-novation/config/types/payloads/integration.payload';

export const asyncIntegrationsFetch = () => buildApiCall(API_INTEGRATION.LIST)({});

export const asyncIntegrationCreate = async (body: IntegrationBody) =>
    buildApiCall(API_INTEGRATION.POST)({
        body
    });

export const asyncIntegrationUpdate = (integrationUuid: string, body: IntegrationBody) =>
    buildApiCall(API_INTEGRATION.PATCH)({
        params: {
            integrationUuid
        },
        body
    });

export const asyncIntegrationDelete = async (integrationUuid: string) =>
    buildApiCall(API_INTEGRATION.DELETE)({
        params: {
            integrationUuid
        }
    });

export const asyncPluginAuthUrlFetch = async (type: string) =>
    buildApiCall(APIT_INTEGRATION_AUTH_URL.GET)({ params: { type } }).then(
        (r: { authUrl: string; state?: string }) => {
            if (r?.authUrl) window.location.href = r.authUrl;
            return r;
        }
    );
