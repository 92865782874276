import { z } from 'zod';

export enum UserFileType {
    BACKGROUND = 'background',
    LOGO = 'logo',
    CLIENT_LOGO = 'client_logo',
    CLIENT_BACKGROUND = 'client_background',
    PROFILE = 'profile',
    WATERMARK = 'watermark'
}

export const userFileSchema = z.object({
    uid: z.string(),
    type: z.nativeEnum(UserFileType).nullable(),
    key: z.string().nullable(),
    url: z.string().nullable(),
    urlExpires: z.date().nullable(),
    thumbnailUrl: z.string().nullable(),
    isPrivate: z.boolean(),
    clientName: z.string().optional().nullable(),
    bucketName: z.string(),
    userUuid: z.string().uuid().optional().nullable()
});

export type UserFile = z.infer<typeof userFileSchema>;
