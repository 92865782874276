import { buildApiCall } from '@he-novation/config/apis/api-fetch';
import {
    API_AUTH_PUBLIC,
    API_AUTH_PUBLIC_PASSWORD
} from '@he-novation/config/apis/public/auth-public-api';
import {
    Auth2FAUpdatePayload,
    AuthPasswordResetPayload
} from '@he-novation/config/types/payloads/auth.payload';
import { API_AUTH_2FA } from '../../config/apis/auth-api';

export const asyncAuthRefresh = () => buildApiCall(API_AUTH_PUBLIC.GET)({});

export const async2FAUpdate = (body: Auth2FAUpdatePayload) =>
    buildApiCall(API_AUTH_2FA.PUT)({ body });

export const asyncSendPasswordResetToken = (email: string) =>
    buildApiCall(API_AUTH_PUBLIC_PASSWORD.TOKEN_POST)({ body: { email } });

export const asyncResetPassword = ({ email, resetToken, newPassword }: AuthPasswordResetPayload) =>
    buildApiCall(API_AUTH_PUBLIC_PASSWORD.RESET)({ body: { email, resetToken, newPassword } });
