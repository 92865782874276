import { useState } from 'react';
import { useSelector } from 'react-redux';
import { DataLayoutDisplayMode } from '@he-novation/config/types/dataLayout.types';
import { currentUserUuidSelector } from '../redux/user/userSelectors';
import { useDataLayoutPrefs } from './useSaveDataLayoutPrefs';

import { DataLayoutGrouper } from '$components/DataLayout/DataLayout.types';

type UseDataLayoutProps<T> = {
    layoutPrefsName?: string | null;
    defaultSort?: [columnKey: string, reversed: boolean];
    displayMode?: DataLayoutDisplayMode;
    groupers?: DataLayoutGrouper<T>[];
    onGrouperChange?: (groupKey?: string | null) => void;
};

export function useDataLayout<T>({
    layoutPrefsName,
    defaultSort,
    groupers,
    displayMode: forcedDisplayMode,
    onGrouperChange
}: UseDataLayoutProps<T>) {
    const { saveLayoutPrefs, layoutPrefs } = useDataLayoutPrefs(layoutPrefsName || null);
    const [grouper, setGrouper] = useState<DataLayoutGrouper<T> | undefined>(
        () =>
            groupers?.find((g) => g.key === layoutPrefs?.grouper) ||
            groupers?.find((g) => g.default)
    );

    const [displayMode, setDisplayMode] = useState<DataLayoutDisplayMode>(
        forcedDisplayMode || layoutPrefs?.displayMode || DataLayoutDisplayMode.Grid
    );
    const { currentUserUuid } = useSelector(currentUserUuidSelector);

    return {
        displayMode,
        setDisplayMode: (displayMode: DataLayoutDisplayMode) => {
            setDisplayMode(displayMode);
            saveLayoutPrefs(currentUserUuid, {
                displayMode
            });
        },
        groupers: groupers || [],
        grouper,
        setGrouper,
        actions: groupers?.map((g) => {
            const isActive = grouper?.key === g.key;
            return {
                children: g.label,
                className: isActive ? 'is-active' : undefined,
                onClick: () => {
                    setGrouper(isActive ? undefined : () => g);
                    const key = isActive ? null : g.key;
                    saveLayoutPrefs(currentUserUuid, {
                        grouper: key
                    });
                    onGrouperChange?.(key);
                }
            };
        }),
        onSort: (column: { key: string }, reversed: boolean) => {
            saveLayoutPrefs(currentUserUuid, {
                sort: [column.key, reversed]
            });
        },
        onActiveColumnsChange: (activeColumns: any) => {
            saveLayoutPrefs(currentUserUuid, {
                activeColumns: activeColumns.map((c) => c.key)
            });
        },
        sort: layoutPrefs?.sort || defaultSort,
        activeColumns: layoutPrefs?.activeColumns,
        saveLayoutPrefs
    };
}
