import React from 'react';
import { ModularForm } from 'react-modular-forms';
import {
    FOLDER_CONVERT_TO_PROJECT,
    MIGRATE_FOLDER_ASSETS
} from '@he-novation/config/paths/modals.constants';
import { Button, ButtonTone } from '@he-novation/design-system/components/buttons/Button/Button';
import { FormField } from '@he-novation/design-system/components/form/FormField/FormField';
import { Theme } from '@he-novation/design-system/enums';
import { asyncFileEncode } from '@he-novation/front-shared/async/file.async';
import { asyncLogsDeleteOld } from '@he-novation/front-shared/async/logs.async';

import Accordion from '$components/Accordion/Accordion';
import { useModal } from '$hooks/useModal';

export function SettingsAdminGeneral() {
    const { openModal } = useModal();
    return (
        <>
            <Accordion title={'Actions'} isOpen={true}>
                <ul>
                    <li>
                        <Button onClick={() => openModal(FOLDER_CONVERT_TO_PROJECT)}>
                            Convert folder to project
                        </Button>
                    </li>
                    <li>
                        <Button onClick={() => openModal(MIGRATE_FOLDER_ASSETS)}>
                            Migrate folder assets to bucket
                        </Button>
                    </li>
                </ul>
            </Accordion>
            <Accordion title={'Old logs'} isOpen={true}>
                <Button onClick={asyncLogsDeleteOld} tone={ButtonTone.Alert}>
                    {'Delete old logs'}
                </Button>
            </Accordion>

            <Accordion title={'Reencode file'} isOpen={true}>
                <ModularForm
                    id="file-reencode"
                    onSubmit={(e, data) => {
                        e.preventDefault();
                        asyncFileEncode(data.workspaceName, data.uuid);
                    }}
                >
                    <FormField
                        formId="file-reencode"
                        name="workspaceName"
                        label="Workspace"
                        type="text"
                        theme={Theme.Dark}
                    />

                    <FormField
                        formId="file-reencode"
                        name="uuid"
                        label="Uuid"
                        type="text"
                        theme={Theme.Dark}
                    />

                    <Button type="submit" tone={ButtonTone.Primary}>
                        {'Reencode'}
                    </Button>
                </ModularForm>
            </Accordion>
        </>
    );
}
