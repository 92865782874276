import { z } from 'zod';
import { taskSchema } from '../task.types';
import { uploadCreateResponse } from './upload.responses';

export const taskCreateOrUpdateResponse = z.object({
    task: taskSchema,
    attachments: z.array(uploadCreateResponse).optional()
});

export type TaskCreateOrUpdateResponse = z.infer<typeof taskCreateOrUpdateResponse>;
