import { buildApiCall } from '../apis/api-fetch';
import { API_CAST_PUBLIC_SUBTITLES } from '../apis/public/cast-public-api';
import { API_SUBTITLE } from '../apis/subtitle-api';
import { workspace } from '../utils/sockets/sockets.client';
import { pathWithParams } from './herawApiPaths';

export const doubleDigit = (number: number) => (number < 10 ? '0' + number : number);

export const PUBLIC_FILE = '/public-file';

export const API_DOCS = '/docs/api/:version/?groupSlug';
export const CAST_FOLDER = '/cast/:castUid/?castFolderUuid';
export const CAST_FILE = '/cast/:castUid/file/:castFileUuid/:version';
export const CAST = '/workspace/:workspaceName/casts/:castUid/?castFolderUuid';
export const CASTS = '/workspace/:workspaceName/casts';
export const CHAT_ROOM = '/chat/room/:roomUuid';
export const DASHBOARD = '/dashboard';
export const DIRECTORY = '/workspace/:workspaceName/directory/?route/?subroute';
export const DIRECTORY_ENTITIES = '/workspace/:workspaceName/directory/entities';
export const DIRECTORY_CUSTOM_FIELDS = '/workspace/:workspaceName/directory/custom-fields';
export const DIRECTORY_ITEMS = '/workspace/:workspaceName/directory/items';
export const DIRECTORY_ITEM_KIT = '/workspace/:workspaceName/directory/item-kit/:uuid';
export const DIRECTORY_WORKSPACE_MEMBERS = '/workspace/:workspaceName/directory/workspace-members';
export const DIRECTORY_WORKSPACE_TAGS_AND_LABELS =
    '/workspace/:workspaceName/directory/tags-and-labels';
export const DIRECTORY_WORKSPACE_TEAM = '/workspace/:workspaceName/directory/workspace-team/:uuid';
export const FILE = '/player/:fileUuid/v/:fileVersion/?extra';
export const FILE_COMPARE = '/player/:fileUuid/v/:fileVersion/compare/:comparisonVersion';
export const HOME = '';
export const FOLDER = '/workspace/:workspaceName/folders/?folderUuid';
export const PLAYER = '/player/:fileUuid/v/:fileVersion';
export const PDF_FILE_SUMMARY = '/summary/file/:fileUuid/v/:fileVersion';
export const PDF_FOLDER_SUMMARY = '/summary/folder/:folderUuid';
export const AVID_FILE_SUMMARY = '/summary_avid/file/:fileUuid/v/:fileVersion';
export const PLANNING = '/workspace/:workspaceName/planning/?route';
export const PROJECTS = '/workspace/:workspaceName/projects';
export const PROJECT = '/workspace/:workspaceName/project/:uuid/?route';
export const PROJECT_CASTS = '/workspace/:workspaceName/project/:uuid/casts';
export const PROJECT_CAST = '/workspace/:workspaceName/project/:uuid/cast/:castUid/?castFolderUuid';
export const PROJECT_FOLDERS = '/workspace/:workspaceName/project/:uuid/folders/?folderUuid';
export const PROJECT_STATS = '/workspace/:workspaceName/project/:uuid/stats';
export const PROJECT_ACTIVITY = '/workspace/:workspaceName/project/:uuid/activity';
export const PROJECT_PLANNING = '/workspace/:workspaceName/project/:uuid/planning';
export const PROJECT_SEARCH = '/workspace/:workspaceName/project/:projectUuid/search/?query';
export const PROJECT_TASKS = '/workspace/:workspaceName/project/:uuid/tasks';
export const PROJECT_ROUTES = [
    PROJECT,
    PROJECT_CASTS,
    PROJECT_CAST,
    PROJECT_FOLDERS,
    PROJECT_STATS,
    PROJECT_ACTIVITY,
    PROJECT_PLANNING,
    PROJECT_SEARCH,
    PROJECT_TASKS
];
export const RIGHTS = '/:locale/rights';
export const SETTINGS = '/settings/?mode/?route';
export const SETTINGS_ADMIN = '/settings/admin/?route';
export const SETTINGS_ADMIN_GENERAL = '/settings/admin/general';
export const SETTINGS_ADMIN_WORKSPACES = '/settings/admin/workspaces';
export const SETTINGS_ADMIN_TRANSCODERS = '/settings/admin/transcoders';
export const SETTINGS_ADMIN_WORDING = '/settings/admin/wording';

export const SETTINGS_USER = '/settings/user/?route';
export const SETTINGS_USER_INTERFACE = '/settings/user/interface';
export const SETTINGS_USER_NOTIFICATIONS = '/settings/user/notifications';
export const SETTINGS_USER_PROFILE = '/settings/user/profile';
export const SETTINGS_USER_PLUGINS = '/settings/user/plugins';
export const SETTINGS_USER_RIGHTS = '/settings/user/rights';
export const SETTINGS_USER_TAGS_AND_LABELS = '/settings/user/tags-labels';

export const SETTINGS_WORKSPACE = '/workspace/:workspaceName/settings/?route';
export const SETTINGS_WORKSPACE_DASHBOARD = '/workspace/:workspaceName/settings/dashboard';
export const SETTINGS_WORKSPACE_INTERFACE = '/workspace/:workspaceName/settings/interface';
export const SETTINGS_WORKSPACE_MISC = '/workspace/:workspaceName/settings/misc';
export const SETTINGS_WORKSPACE_SECURITY = '/workspace/:workspaceName/settings/security';
export const SETTINGS_WORKSPACE_USERS = '/workspace/:workspaceName/settings/users';
export const SETTINGS_WORKSPACE_SUBSCRIPTION = '/workspace/:workspaceName/settings/subscription';

export const STRIPE_PAYMENT_ISSUE = '/stripe/payment-issue';

export const TASKS = '/workspace/:workspaceName/tasks';
export const OFFICE = 'https://view.officeapps.live.com/op/embed.aspx?';
export const OFFICE_FILE =
    'src=:baseUrl/proxy/office/:accessToken/:requestToken/:fileUuid/:fileVersion/:fileName';
export const SEARCH = '/search/?query';
export const TRASH = '/trash/?folderUuid';

export const WORKSPACE_CREATION = '/new-workspace';
export const WORKSPACE_UPGRADE = '/workspace/:workspaceName/upgrade';

export const heraw = () => 'https://heraw.com';

export const homeLink = () => '/';

export const adminSettingsLink = () => SETTINGS_ADMIN;

export const castsLink = (workspaceName: string) => uriWithParams(CASTS, { workspaceName });

export const castLink = (workspaceName: string, castUid: string, castFolderUuid?: string) =>
    uriWithParams(CAST, { workspaceName, castUid, castFolderUuid });

export const chatRoom = (roomUuid: string) => uriWithParams(CHAT_ROOM, { roomUuid });

export const dashboardLink = () => DASHBOARD;

export const demoLink = () => '/demo';

export const directoryLink = (workspaceName: string) => uriWithParams(DIRECTORY, { workspaceName });

export const directoryEntitiesLink = (workspaceName: string) =>
    uriWithParams(DIRECTORY_ENTITIES, { workspaceName });

export const directoryCustomFields = (workspaceName: string) =>
    uriWithParams(DIRECTORY_CUSTOM_FIELDS, { workspaceName });

export const directoryItemsLink = (workspaceName: string) =>
    uriWithParams(DIRECTORY_ITEMS, { workspaceName });

export const directoryItemKitLink = (workspaceName: string, uuid: string) =>
    uriWithParams(DIRECTORY_ITEM_KIT, { workspaceName, uuid });

export const directoryWorkspaceMembersLink = (workspaceName: string) =>
    uriWithParams(DIRECTORY_WORKSPACE_MEMBERS, { workspaceName });

export const directoryWorkspaceTagsAndLabelsLink = (workspaceName: string) =>
    uriWithParams(DIRECTORY_WORKSPACE_TAGS_AND_LABELS, { workspaceName });

export const directoryWorkspaceTeamLink = (workspaceName: string, uuid: string) =>
    uriWithParams(DIRECTORY_WORKSPACE_TEAM, { workspaceName, uuid });

export const folderLink = (workspaceName: string, folderUuid?: string) =>
    uriWithParams(FOLDER, { workspaceName, folderUuid });

export const castFileLink = (castUid: string, castFileUuid: string, version: string | number) =>
    uriWithParams(CAST_FILE, { castUid, castFileUuid, version });

export const castFolderLink = (castUid: string, castFolderUuid?: string) =>
    uriWithParams(CAST_FOLDER, { castUid, castFolderUuid });

export const indexLink = () => '/';

export const planningLink = (workspaceName: string, date?: Date, eventUuid?: string) =>
    uriWithParams(PLANNING, { workspaceName }, dateToStartQueryParams(date, eventUuid));
export const planningItemsLink = (workspaceName: string, date?: Date) =>
    uriWithParams(PLANNING, { workspaceName, route: 'items' }, dateToStartQueryParams(date));
export const planningGlobalLink = (workspaceName: string, date?: Date) =>
    uriWithParams(PLANNING, { workspaceName, route: 'global' }, dateToStartQueryParams(date));

export const playerLink = (
    fileUuid: string,
    fileVersion: number | string,
    {
        noteUuid,
        sidePanel,
        selected_note
    }: { noteUuid?: string; sidePanel?: string; selected_note?: string } = {}
) =>
    uriWithParams(
        FILE,
        { fileUuid, fileVersion },
        {
            filters:
                noteUuid || selected_note
                    ? encodeURIComponent(JSON.stringify({ noteUuid, selected_note }))
                    : undefined,
            sidePanel
        }
    );

export const playerCompareLink = (
    comparisonVersion: number,
    fileUuid: string,
    fileVersion: number
) => uriWithParams(FILE_COMPARE, { fileUuid, fileVersion, comparisonVersion });

export const pdfFileSummaryLink = (fileUuid: string, fileVersion: number) =>
    uriWithParams(PDF_FILE_SUMMARY, { fileUuid, fileVersion });

export const avidFileSummaryLink = (fileUuid: string, fileVersion: number) =>
    uriWithParams(AVID_FILE_SUMMARY, { fileUuid, fileVersion });

export const pdfFolderSummaryLink = (folderUuid: string) =>
    uriWithParams(PDF_FOLDER_SUMMARY, { folderUuid });

export const officeLink = (assetSignedUrl: string) =>
    OFFICE + 'src=' + encodeURIComponent(assetSignedUrl);

export const projectsLink = (workspaceName: string) => uriWithParams(PROJECTS, { workspaceName });

export const projectStatsLink = (workspaceName: string, uuid: string) =>
    uriWithParams(PROJECT_STATS, { workspaceName, uuid });

export const projectCastLink = (
    workspaceName: string,
    uuid: string,
    castUid: string,
    castFolderUuid?: string
) => uriWithParams(PROJECT_CAST, { workspaceName, uuid, castUid, castFolderUuid });

export const projectActivityLink = (workspaceName: string, uuid: string) =>
    uriWithParams(PROJECT_ACTIVITY, { workspaceName, uuid });

export const projectPlanningLink = (workspaceName: string, uuid: string, date?: Date) =>
    uriWithParams(PROJECT_PLANNING, { workspaceName, uuid }, dateToStartQueryParams(date));

export const projectCastsLink = (workspaceName: string, uuid: string) =>
    uriWithParams(PROJECT_CASTS, { workspaceName, uuid });

export const projectFoldersLink = (workspaceName: string, uuid: string, folderUuid?: string) =>
    uriWithParams(PROJECT_FOLDERS, { workspaceName, uuid, folderUuid });

export function projectSearchLink(workspaceName: string, projectUuid: string, q?: string) {
    return uriWithParams(PROJECT_SEARCH, { workspaceName, projectUuid }, { q });
}
export const projectTasksLink = (workspaceName: string, uuid: string, taskUuid?: string) =>
    uriWithParams(PROJECT_TASKS, { workspaceName, uuid }, { task: taskUuid });

export const rightsLink = (locale: string) =>
    process.env.MARKETING_URL + uriWithParams(RIGHTS, { locale });

export function searchLink(search?: string, folder?: { uuid: string; name: string }) {
    return uriWithParams(
        SEARCH,
        {},
        { q: search, folderUuid: folder?.uuid, folderName: folder?.name }
    );
}
export const userSettingsLink = (route?: string) => uriWithParams(SETTINGS_USER, { route });

export const sharedRootLink = () => '/shared';

export const subtitlesFileLink = (
    workspaceName: string,
    subtitleUuid: string,
    format: string,
    timestamp?: number
) =>
    process.env.API_URL +
    pathWithParams(API_SUBTITLE.GET.path, { workspaceName, subtitleUuid }, { format, timestamp });

export const subtitlesCastFileLink = (
    castWorkspaceName: string,
    castUid: string,
    subtitleUuid: string,
    format: 'srt' | 'stl-ebu' | 'stl-spruce' | 'vtt',
    timestamp?: number,
    p?: string
) =>
    process.env.API_URL +
    pathWithParams(
        API_CAST_PUBLIC_SUBTITLES.GET.path,
        { castUid, subtitleUuid },
        { castWorkspaceName, format, timestamp, p }
    );

export const tasksLink = (workspaceName: string) => uriWithParams(TASKS, { workspaceName });

export const trashLink = (folderUuid?: string) => uriWithParams(TRASH, { folderUuid });

export const workspaceCreateLink = (options?: { type?: string; licenses?: number | string }) =>
    uriWithParams(WORKSPACE_CREATION, {}, options);

export const workspaceSettingsLink = (workspaceName: string, route?: string) =>
    uriWithParams(SETTINGS_WORKSPACE, { workspaceName, route });

export const workspaceSettingsDashboardLink = (workspaceName: string) =>
    uriWithParams(SETTINGS_WORKSPACE_DASHBOARD, { workspaceName });

export const workspaceSettingsInterfaceLink = (workspaceName: string) =>
    uriWithParams(SETTINGS_WORKSPACE_INTERFACE, { workspaceName });

export const workspaceSettingsMiscLink = (workspaceName: string) =>
    uriWithParams(SETTINGS_WORKSPACE_MISC, { workspaceName });

export const workspaceSettingsSecurityLink = (workspaceName: string) =>
    uriWithParams(SETTINGS_WORKSPACE_SECURITY, { workspaceName });

export const workspaceSettingsUsersLink = (workspaceName: string) =>
    uriWithParams(SETTINGS_WORKSPACE_USERS, { workspaceName });

export const workspaceSettingsSubscriptionLink = (workspaceName: string) =>
    uriWithParams(SETTINGS_WORKSPACE_SUBSCRIPTION, { workspaceName });

export function workspaceUpgradeLink(workspaceName: string, licenses?: number) {
    return uriWithParams(WORKSPACE_UPGRADE, { workspaceName }, { licenses });
}

export const uriWithParams = (
    uri: string,
    params: Record<string, string | number | undefined>,
    queryParams?: Record<string, string | number | undefined>
) =>
    uri
        .replace(/\/\?([a-zA-Z]+)/g, (full, g1) => (params[g1] ? '/' + params[g1] : '') as string)
        .replace(/[:?]([a-zA-Z]+)/g, (full, g1) => params[g1]?.toString() as string) +
    (queryParams && Object.keys(queryParams).length
        ? '?' +
          Object.keys(queryParams)
              .map((key) =>
                  typeof queryParams[key] !== 'undefined' ? `${key}=${queryParams[key]}` : ''
              )
              .join('&')
        : ''
    ).replace(/[&?]$/, '');
const dateToStartQueryParams = (date?: Date, event?: string) => {
    const queryParams: any = {};
    if (date) {
        queryParams.start = `${date.getFullYear()}-${doubleDigit(
            date.getMonth() + 1
        )}-${doubleDigit(date.getDate())}`;
    }
    if (event) queryParams.event = event;
    return queryParams;
};
