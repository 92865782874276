import React, { type ReactNode, useState } from 'react';
import { PAYMENT_METHOD_UPDATE } from '@he-novation/config/paths/modals.constants';
import type { Subscription } from '@he-novation/config/types/subscription.types';
import { Button, ButtonTone } from '@he-novation/design-system/components/buttons/Button/Button';
import { Error } from '@he-novation/design-system/components/text/Error/Error';
import { loadStripe } from '@stripe/stripe-js';
import { useModal } from '../../../../hooks/useModal';
import { useTranslate } from '../../../../hooks/useTranslate';

export function SubscriptionPaymentError({ stripe }: { stripe: Subscription['stripe'] }) {
    const { t } = useTranslate();
    const { openModal } = useModal();
    const [state, setState] = useState<string | null>(null);

    let message: ReactNode = t('settings.There was an error processing the payment.');
    const retryButton = (
        <Button
            style={{ display: 'block' }}
            onClick={() => openModal(PAYMENT_METHOD_UPDATE, { retryPayment: true })}
            tone={ButtonTone.Neutral}
        >
            {t('settings.Change the payment method and retry')}
        </Button>
    );

    if (stripe?.paymentIntent && stripe.paymentIntent.status === 'requires_action') {
        message = (
            <>
                {t('settings.Your payment requires confirmation.')}
                <br />
                <Button
                    style={{ display: 'block' }}
                    onClick={async () => {
                        setState('loading');
                        const _stripe = await loadStripe(process.env.STRIPE_PUBLISHABLE_KEY);
                        await _stripe?.confirmCardPayment(stripe.paymentIntent!.clientSecret);
                        setState('hidden');
                    }}
                    isLoading={state === 'loading'}
                    tone={ButtonTone.Neutral}
                >
                    {t('settings.Confirm the payment')}
                </Button>
            </>
        );
    }
    if (state === 'hidden') {
        return null;
    }

    switch (stripe!.status) {
        case 'card_declined':
            message = (
                <>
                    {t('settings.Your card was declined.')}
                    <br />
                    {retryButton}
                </>
            );
            break;
        case 'expired_card':
            message = (
                <>
                    {t('settings.Your card is expired.')}
                    <br />
                    {retryButton}
                </>
            );
            break;
        case 'insufficient_funds':
            message = (
                <>
                    {t('settings.Your account has insufficient funds.')}
                    <br />
                    {retryButton}
                </>
            );
            break;
        default:
    }

    return <Error error={message} style={{ textAlign: 'left', maxWidth: 640 }} />;
}
