import { coercedBoolean } from '@he-novation/utils/zod.utils';
import { z } from 'zod';
import type { BaseClientType } from '../../client.types';
import { EventIntervalUnit } from '../../db/enums';
import { EventDays } from '../../event.types';
import type { BaseFileType } from '../../file.types';
import type { BaseFolderType } from '../../folder.types';
import type { BaseProjectType } from '../../project.types';
import type { BaseUserType } from '../../user.types';
import { CommentLogKey, LogCommentPayload } from './comment-logs.payload';
import { EventLogKey, LogEventPayload } from './event-logs.payload';
import { FileLogKey, LogFilePayload } from './file-logs.payload';
import { FolderLogKey, LogFolderPayload } from './folder-logs.payload';
import { LogProjectPayload, ProjectLogKey } from './project-logs.payload';
import { LogTaskPayload, TaskLogKey } from './task-logs.payload';
import { LogWorkspacePayload } from './workspace-logs.payload';

export type BaseLogPayload = {
    owner: BaseUserType;
    client: {
        name: string;
        uuid?: string;
    };
    requestUser: BaseUserType;
    users: BaseUserType[];
    project?: BaseProjectType | null;
};

export interface BaseFileLogPayload extends Omit<BaseLogPayload, 'owner'> {
    file: BaseFileType & {
        thumbnail?: string | null;
    };
    parentFolder: BaseFolderType;
}

export interface BaseCommentLogPayload extends Omit<BaseFileLogPayload, 'requestUser'> {
    requestUser: BaseUserType | { email: string; locale?: string };
    note: {
        uuid: string;
    };
    comment: {
        uuid: string;
        content: string;
    };
}

export interface BaseProjectLogPayload extends Omit<BaseLogPayload, 'project'> {
    project: BaseProjectType;
}

export interface BaseEventLogPayload extends BaseLogPayload {
    event: {
        uuid: string;
        title: string | null;
        description: string | null;
        startDate: Date;
        endDate: Date;
        duration: number;
        recurrence?: {
            intervalValue: number;
            intervalUnit: EventIntervalUnit;
            days: EventDays;
        };
    };
}

export type AnyLogPayload =
    | LogEventPayload
    | LogFilePayload
    | LogFolderPayload
    | LogCommentPayload
    | LogProjectPayload
    | LogTaskPayload
    | LogWorkspacePayload;

export type AnyLogKey =
    | keyof typeof EventLogKey
    | keyof typeof FileLogKey
    | keyof typeof FolderLogKey
    | keyof typeof CommentLogKey
    | keyof typeof TaskLogKey
    | keyof typeof ProjectLogKey;

export const anyLogKey = {
    ...EventLogKey,
    ...FileLogKey,
    ...FolderLogKey,
    ...CommentLogKey,
    ...TaskLogKey,
    ...ProjectLogKey
};

export const logSearchBody = z.object({
    keys: z.string().array().optional(),
    collapse: z.string().optional(),
    fileUuids: z.string().array().optional(),
    folderUuids: z.string().array().optional(),
    userUuids: z.string().array().optional(),
    projectUuid: z.string().optional(),
    clientUuid: z.string().optional(),
    offset: z.coerce.number().optional(),
    results: z.coerce.number().optional(),
    hidden: coercedBoolean().optional(),
    order: z.enum(['desc', 'asc']).optional(),
    startDate: z.date().optional(),
    endDate: z.date().optional()
});
export type LogSearchBody = z.infer<typeof logSearchBody>;
