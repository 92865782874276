import { buildApiCall } from '@he-novation/config/apis/api-fetch';
import { API_USER_FILE } from '@he-novation/config/apis/userFile-api';
import { UserFile, UserFileType } from '@he-novation/config/types/user-file.types';

export const asyncWorkspaceUserFilesFetch = (
    workspaceName: string,
    type?: UserFileType
): Promise<UserFile[]> =>
    buildApiCall(API_USER_FILE.LIST)({ params: { workspaceName }, query: { type } });

export const asyncWorkspaceUserFileCreate = async (
    workspaceName: string,
    type: UserFileType,
    file: File
): Promise<UserFile> => {
    const { link, headers, userFileUid } = await buildApiCall(API_USER_FILE.POST)({
        params: { workspaceName },
        body: {
            type,
            size: file.size,
            contentType: file.type
        }
    });

    await fetch(link, {
        method: 'PUT',
        headers: {
            'Content-Type': file.type,
            ...headers
        },
        body: file
    });

    return await buildApiCall(API_USER_FILE.PATCH)({ params: { workspaceName, userFileUid } });
};

export const asyncWorkspaceUserFileDelete = (workspaceName: string, userFileUid: string) =>
    buildApiCall(API_USER_FILE.DELETE)({ params: { workspaceName, userFileUid } });
