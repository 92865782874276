import type { ProjectsState } from '@he-novation/config/types/project.types';
import { subDays } from '@he-novation/utils/datetime';
import update from 'immutability-helper';
import { cloneDeep } from 'lodash/fp';
import mapFetchProjectToUpdate from '../../content/projects/maps/mapFetchProjectToUpdate';
import sqlDatesToJsDates from '../../content/projects/maps/sqlDatesToJsDates';
import { SET } from '../../route/routeActions';
import { FETCH_FILE_VIEW } from '../contentActions';
import {
    FETCH_CURRENT_USER_PROJECTS,
    FETCH_PLANNING_TASKS,
    FETCH_PROJECT,
    FETCH_PROJECT_ACTIVITY,
    FETCH_PROJECT_COMPANIES,
    FETCH_PROJECT_LAST_ACTIVITY,
    FETCH_PROJECT_TASKS,
    FETCH_PROJECTS,
    FETCH_TASK_ASSETS,
    PROJECT_SET_NOTIFICATION_SETTINGS,
    SET_LAST_PROJECT_UUID,
    SET_SELECTED_TEAM,
    UPDATE_TASK_STATUS_ORDER,
    UPDATE_USER_METADATA,
    WS_TASK_ATTACHMENT_UPLOAD,
    WS_TASK_CREATE,
    WS_TASK_DELETE,
    WS_TASK_UPDATE,
    WS_UPDATE_CURRENT_PROJECT
} from './projectsActions';

import { asyncActionError, asyncActionSuccess } from '$helpers/asyncAction';
import { browserSessionUuid } from '$helpers/browserSession';
import { publish, PubSubEvent } from '$hooks/useSubscribe';

export const projectsInitialState: ProjectsState = {
    companies: [],
    currentProject: null
};

const projectsReducer = (
    state: ProjectsState = projectsInitialState,
    action: { type: string; [key: string]: any }
) => {
    if (action.type === asyncActionSuccess(FETCH_PROJECT)) {
        return update(state, {
            currentProject: {
                $set: action.project
            }
        });
    }

    switch (action.type) {
        case WS_TASK_CREATE: {
            if (
                !state.currentProject ||
                state.currentProject.tasks?.find((t) => t.uuid === action.task.uuid)
            ) {
                return state;
            }
            const task = sqlDatesToJsDates(action.task);
            // quick fix to avoid task one day ahead
            if (task.estimatedEndDate) {
                task.estimatedEndDate = subDays(new Date(task.estimatedEndDate), 1);
            }
            if (task.endDate) {
                task.endDate = subDays(new Date(task.estimatedEndDate), 1);
            }

            publish(PubSubEvent.TaskCreated, task);
            const _state = state.currentProject.tasks
                ? state
                : update(state, { currentProject: { tasks: { $set: [] } } });
            return update(_state, {
                currentProject: {
                    tasks: {
                        $push: [task]
                    }
                }
            });
        }

        case WS_TASK_UPDATE: {
            if (!state.currentProject) return state;
            const _update = {
                currentProject: { tasks: {} }
            };
            if (action.task) {
                const taskIndex = state.currentProject.tasks.findIndex(
                    ({ uuid }) => uuid === action.task.uuid
                );
                _update.currentProject.tasks[taskIndex] = {
                    $set: sqlDatesToJsDates(action.task)
                };
            }

            if (
                action.newOrderingAndStatusByUuid &&
                action.browserSessionUuid !== browserSessionUuid
            ) {
                for (const taskUuid in action.newOrderingAndStatusByUuid) {
                    const taskIndex = state.currentProject!.tasks.findIndex(
                        ({ uuid }) => uuid === taskUuid
                    );

                    if (taskIndex < 0) {
                        continue;
                    }

                    _update.currentProject.tasks[taskIndex] = {
                        ordering: {
                            $set: action.newOrderingAndStatusByUuid[taskUuid].ordering
                        },
                        status: {
                            $set:
                                action.newOrderingAndStatusByUuid[taskUuid].status ||
                                state.currentProject!.tasks[taskIndex].status
                        }
                    };
                }
            }

            return update(state, _update);
        }

        case WS_TASK_ATTACHMENT_UPLOAD: {
            if (!state.currentProject) return state;
            const taskIndex = state.currentProject.tasks.findIndex(
                ({ uuid }) => uuid === action.taskUuid
            );
            if (taskIndex < 0) return state;
            const attachmentIndex = state.currentProject.tasks[taskIndex].assets.findIndex(
                ({ uuid }) => uuid === action.attachment.uuid
            );
            if (attachmentIndex < 0) return state;
            return update(state, {
                currentProject: {
                    tasks: {
                        [taskIndex]: {
                            assets: {
                                [attachmentIndex]: {
                                    $set: action.attachment
                                }
                            }
                        }
                    }
                }
            });
        }

        case WS_TASK_DELETE: {
            if (!state.currentProject) return state;
            const i = state.currentProject.tasks.findIndex(({ uuid }) => uuid === action.taskUuid);
            if (i === -1) return state;
            return update(state, {
                currentProject: {
                    tasks: {
                        $splice: [[i, 1]]
                    }
                }
            });
        }

        case WS_UPDATE_CURRENT_PROJECT: {
            return update(state, {
                currentProject: {
                    $merge: mapFetchProjectToUpdate(action.project)
                }
            });
        }

        case SET_LAST_PROJECT_UUID:
            return update(state, {
                lastProjectUuid: { $set: action.uuid }
            });

        case asyncActionSuccess(FETCH_PROJECT):
            return update(state, {
                currentProject: {
                    $set: action.project
                }
            });

        case asyncActionError(FETCH_PROJECT):
            return state;

        case asyncActionSuccess(FETCH_PROJECTS):
            return {
                ...state,
                projects: action.projects
            };

        case asyncActionSuccess(FETCH_PROJECT_ACTIVITY): {
            return update(state, {
                currentProject: {
                    activity: {
                        [action.page !== 0 ? '$push' : '$set']: action.response
                    }
                }
            });
        }

        case asyncActionSuccess(FETCH_PROJECT_LAST_ACTIVITY):
            return update(state, {
                currentProject: {
                    $merge: { lastActivity: action.activity }
                }
            });

        case asyncActionSuccess(FETCH_PROJECT_COMPANIES):
            return update(state, {
                companies: { $set: action.companies }
            });

        case asyncActionSuccess(FETCH_PROJECT_TASKS): {
            if (!state.currentProject) return state;
            return update(state, {
                currentProject: {
                    tasks: {
                        $set: action.tasks
                    }
                }
            });
        }

        case asyncActionSuccess(FETCH_TASK_ASSETS): {
            if (!state.currentProject) return state;
            return update(state, {
                currentProject: {
                    tasks: {
                        $set: action.assets.reduce((tasks, asset) => {
                            const _index = tasks.findIndex(({ uuid }) => uuid === asset.task_uuid);
                            if (_index === -1) return tasks;
                            if (asset.type === 'task') {
                                if (!tasks[_index].attachments) tasks[_index].attachments = [];
                                tasks[_index].attachments.push(asset);
                            } else {
                                if (!tasks[_index].note) tasks[_index].note = {};
                                if (asset.type === 'thumbnail') {
                                    tasks[_index].note.fileThumbnail = asset.url;
                                    // note asset: min, sd, detail or max
                                } else if (
                                    asset.type === 'note' &&
                                    (asset.quality === 'sd' || asset.quality === 'min')
                                ) {
                                    tasks[_index].note.thumbnail = asset.url;
                                }
                            }
                            return tasks;
                        }, cloneDeep(state.currentProject.tasks))
                    }
                }
            });
        }

        case asyncActionSuccess(FETCH_FILE_VIEW):
            if (!action.projectState) return state;
            return update(state, {
                currentProject: {
                    [state.currentProject ? '$merge' : '$set']: action.projectState.currentProject
                }
            });

        case SET:
            if (
                state.currentProject?.uuid ===
                action.route?.replace(/.*project\/([a-z0-9-]+)(?:.*)?/, '$1')
            ) {
                return state;
            }
            return update(state, {
                currentProject: { $set: null }
            });

        case SET_SELECTED_TEAM:
            return update(state, {
                currentProject: {
                    selectedTeam: { $set: action.selectedTeam }
                }
            });

        case asyncActionSuccess(FETCH_CURRENT_USER_PROJECTS):
            return {
                ...state,
                projects: action.response.result
            };

        case asyncActionError(FETCH_CURRENT_USER_PROJECTS):
            return state;

        case asyncActionSuccess(FETCH_PLANNING_TASKS): {
            return update(state, {
                planningTasks: {
                    $set: action.planningTasks
                }
            });
        }

        case PROJECT_SET_NOTIFICATION_SETTINGS:
            return update(state, {
                currentProject: {
                    hasNotificationSettings: {
                        $set: true
                    }
                }
            });

        case asyncActionSuccess(UPDATE_USER_METADATA):
            if (!state?.currentProject?.uuid || state.currentProject.uuid !== action.projectUuid)
                return state;

            return update(state, {
                currentProject: {
                    isFavorite: {
                        $set: action.isFavorite
                    }
                }
            });

        case asyncActionSuccess(UPDATE_TASK_STATUS_ORDER): {
            if (!state.currentProject) return state;
            const _tasksUpdate = {};

            for (const taskUuid in action.changes) {
                const taskIndex = state.currentProject.tasks.findIndex((t) => t.uuid === taskUuid);
                if (taskIndex > -1) {
                    _tasksUpdate[taskIndex] = {
                        ordering: {
                            $set: action.changes[taskUuid].ordering
                        },
                        status: {
                            $set:
                                action.changes[taskUuid].status ||
                                state.currentProject!.tasks[taskIndex].status
                        }
                    };
                }
            }

            return update(state, {
                currentProject: {
                    tasks: _tasksUpdate
                }
            });
        }

        default:
            return state;
    }
};

export default projectsReducer;
