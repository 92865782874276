import { Item, ItemKit } from '../item.types';

export enum WSItemEventType {
    ItemCreated = 'item/created',
    ItemUpdated = 'item/updated',
    ItemDeleted = 'item/deleted'
}

export type WSItemEventCreated = {
    type: WSItemEventType.ItemCreated;
    item: Item;
};

export type WSItemEventUpdated = {
    type: WSItemEventType.ItemUpdated;
    item: Item;
};

export type WSItemEventDeleted = {
    type: WSItemEventType.ItemDeleted;
    uuid: string;
};

export enum WSItemKitEventType {
    ItemKitCreated = 'item-kit/created',
    ItemKitUpdated = 'item-kit/updated',
    ItemKitDeleted = 'item-kit/deleted',
    ItemKitItemAdded = 'item-kit/item-added',
    ItemKitItemRemoved = 'item-kit/item-removed'
}

export type WSItemKitEventCreated = {
    type: WSItemKitEventType.ItemKitCreated;
    itemKit: ItemKit;
};

export type WSItemKitEventUpdated = {
    type: WSItemKitEventType.ItemKitUpdated;
    itemKit: ItemKit;
};

export type WSItemKitEventDeleted = {
    type: WSItemKitEventType.ItemKitDeleted;
    uuid: string;
};

export type WSItemKitEventItemAdded = {
    type: WSItemKitEventType.ItemKitItemAdded;
    itemKitUuid: string;
    itemUuid: string;
};

export type WSItemKitEventItemRemoved = {
    type: WSItemKitEventType.ItemKitItemRemoved;
    itemKitUuid: string;
    itemUuid: string;
};
