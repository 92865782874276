const colors = [
    '#222222',
    '#bfbf72',
    '#72bf9b',
    '#bfa472',
    '#72bf91',
    '#bf7c72',
    '#abbf72',
    '#bfae72',
    '#7abf72',
    '#b3bf72',
    '#bf8172',
    '#bf8f72',
    '#72a7bf',
    '#7a72bf',
    '#729ebf',
    '#7a72bf',
    '#bf7e72',
    '#72bbbf',
    '#8172bf',
    '#72bf98',
    '#8172bf',
    '#bf72bd',
    '#7278bf',
    '#9dbf72',
    '#bbbf72',
    '#7287bf',
    '#bf7279',
    '#728fbf',
    '#7294bf',
    '#72bfb9',
    '#bf728b',
    '#7285bf',
    '#9072bf',
    '#72a3bf',
    '#bf8872',
    '#bf727b',
    '#72bfb4',
    '#72bf73',
    '#bf8372',
    '#a2bf72',
    '#72acbf',
    '#72bf8c',
    '#bf729e',
    '#bf7290',
    '#bf8b72',
    '#8bbf72',
    '#72a0bf',
    '#8bbf72',
    '#72bfaf',
    '#72bf73',
    '#93bf72',
    '#72bf79',
    '#72bf87',
    '#90bf72',
    '#bf8072',
    '#72bfa8',
    '#72b1bf',
    '#7d72bf',
    '#72b9bf',
    '#bf7672',
    '#bf8572',
    '#72a8bf',
    '#bfab72',
    '#8172bf',
    '#a072bf',
    '#72bf9e',
    '#727bbf',
    '#95bf72',
    '#7285bf',
    '#72bf8c',
    '#727dbf',
    '#9abf72',
    '#728fbf',
    '#bf72b7',
    '#9dbf72',
    '#72bfbb',
    '#75bf72',
    '#96bf72',
    '#bf72b2',
    '#bf7283',
    '#72bf76',
    '#90bf72',
    '#78bf72',
    '#72bfa5',
    '#72bf8a',
    '#bf9472',
    '#bfae72',
    '#bfb872',
    '#bf729a',
    '#bf7c72',
    '#89bf72',
    '#8cbf72',
    '#bfa472',
    '#7299bf',
    '#bf9e72',
    '#bebf72',
    '#72bf8a',
    '#bf7c72',
    '#afbf72',
    '#bf7286',
    '#9a72bf',
    '#bf7280',
    '#72bf87',
    '#bf7c72',
    '#9372bf',
    '#72bf82',
    '#ac72bf',
    '#a4bf72',
    '#72bf7d',
    '#9372bf',
    '#a7bf72',
    '#bd72bf',
    '#72bfbb',
    '#bfa472',
    '#7296bf',
    '#72bebf',
    '#72b1bf',
    '#7dbf72',
    '#bf7292',
    '#7291bf',
    '#728ebf',
    '#7299bf',
    '#72bf9b',
    '#b1bf72',
    '#a772bf',
    '#bf7294',
    '#8472bf',
    '#72bf74',
    '#7291bf',
    '#7cbf72',
    '#afbf72',
    '#a4bf72',
    '#bf8872',
    '#bf72b5',
    '#a0bf72',
    '#72b9bf',
    '#72b6bf',
    '#bf9072',
    '#7274bf',
    '#9172bf',
    '#bf729e',
    '#a7bf72',
    '#bf7283',
    '#72bf8f',
    '#bf9772',
    '#bf8b72',
    '#7572bf',
    '#72bf79',
    '#72bfb2',
    '#bfbf72',
    '#bfb572',
    '#72bf9c',
    '#728ebf',
    '#bfa172',
    '#aebf72',
    '#72bf79',
    '#72b2bf',
    '#af72bf',
    '#ac72bf',
    '#75bf72',
    '#a7bf72',
    '#b372bf',
    '#9f72bf',
    '#bf8d72',
    '#93bf72',
    '#bfab72',
    '#bfad72',
    '#bf72a6',
    '#7abf72',
    '#bfbf72',
    '#bf8d72',
    '#bf9972',
    '#72bf91',
    '#bfae72',
    '#89bf72',
    '#7293bf',
    '#72a3bf',
    '#72bf76',
    '#af72bf',
    '#7289bf',
    '#bf7295',
    '#7278bf',
    '#bf729a',
    '#bf8072',
    '#abbf72',
    '#9abf72',
    '#bdbf72',
    '#bf8b72',
    '#b472bf',
    '#729ebf',
    '#72bf92',
    '#bfa872',
    '#84bf72',
    '#89bf72',
    '#bf727e',
    '#7c72bf',
    '#7282bf',
    '#727dbf',
    '#bf7472',
    '#7cbf72',
    '#bf7c72',
    '#bf9072',
    '#72bfa8',
    '#7293bf',
    '#84bf72',
    '#7fbf72',
    '#bf729a',
    '#bfb072',
    '#aa72bf',
    '#bd72bf',
    '#78bf72',
    '#9d72bf',
    '#a272bf',
    '#bf9c72',
    '#93bf72',
    '#bfb872',
    '#7a72bf',
    '#a4bf72',
    '#72bfad',
    '#72bf80',
    '#bf7272',
    '#bf8372',
    '#bf72bd',
    '#bf7281',
    '#72bf76',
    '#729ebf',
    '#bf8672',
    '#bfa872',
    '#bf72a1',
    '#bfa472',
    '#bfab72',
    '#bf7286',
    '#7294bf',
    '#b172bf',
    '#bf7c72',
    '#b1bf72',
    '#72bfa8',
    '#bf7c72',
    '#7fbf72',
    '#bf72bd',
    '#b972bf',
    '#bf8072',
    '#87bf72',
    '#72bf83',
    '#72a2bf',
    '#72afbf',
    '#bf7281',
    '#abbf72',
    '#7a72bf',
    '#72bfab',
    '#9072bf',
    '#72b7bf',
    '#bfb872',
    '#72aabf',
    '#ae72bf',
    '#7291bf',
    '#a0bf72',
    '#9672bf',
    '#72bf91',
    '#bf8a72',
    '#bf7277',
    '#7289bf',
    '#89bf72',
    '#72bf78',
    '#75bf72',
    '#72bfb1',
    '#72bfaf',
    '#72bf94',
    '#72bf88',
    '#7280bf',
    '#bf9e72',
    '#72bf9e',
    '#7289bf',
    '#727dbf',
    '#bfb772',
    '#72a3bf',
    '#8772bf',
    '#84bf72',
    '#bf72ba',
    '#bf7272',
    '#bf72ba',
    '#72bfab',
    '#bfab72',
    '#72bf8c',
    '#72bf8c',
    '#bf8672',
    '#bf9f72',
    '#bfb072',
    '#72bfa8',
    '#7289bf',
    '#bfa872',
    '#72bfb2',
    '#7299bf',
    '#7280bf',
    '#aabf72',
    '#727dbf',
    '#82bf72',
    '#bbbf72',
    '#bf729f',
    '#72bfb7',
    '#bfb872',
    '#bf8a72',
    '#8bbf72',
    '#bf7272',
    '#bf9472',
    '#bbbf72',
    '#9f72bf',
    '#bf7280',
    '#72a5bf',
    '#77bf72',
    '#91bf72',
    '#bf728b',
    '#bfb572',
    '#72bf7b',
    '#b8bf72',
    '#bf729e',
    '#b872bf',
    '#bf727b',
    '#78bf72',
    '#72b4bf',
    '#bf7277',
    '#82bf72',
    '#bf72b7',
    '#7296bf',
    '#bf8072',
    '#72bf76',
    '#bf728a',
    '#729dbf',
    '#72a0bf',
    '#72bfbc',
    '#b3bf72',
    '#b5bf72',
    '#7772bf',
    '#8ebf72',
    '#bf727b',
    '#bfb772',
    '#7293bf',
    '#ac72bf',
    '#9abf72',
    '#72bf80',
    '#bbbf72',
    '#89bf72',
    '#bf7290',
    '#bf729a',
    '#bf7297',
    '#72bf88',
    '#b4bf72',
    '#72bebf',
    '#bf9472',
    '#bf9072',
    '#7284bf',
    '#aebf72',
    '#a472bf',
    '#8cbf72',
    '#bf72a8',
    '#72bfb1',
    '#72bfa3',
    '#72bf83',
    '#7293bf',
    '#be72bf',
    '#bfb572',
    '#bf8172',
    '#bfae72',
    '#72bfab',
    '#bf727b',
    '#bf7272',
    '#72bfa5',
    '#72bf94',
    '#72bf79',
    '#bf72b0',
    '#bf72ad',
    '#7280bf',
    '#bf72b2',
    '#72bfbb',
    '#9a72bf',
    '#72bfa5',
    '#77bf72',
    '#bf7286',
    '#7282bf',
    '#bf7299',
    '#a2bf72',
    '#72bf78',
    '#72bfa5',
    '#bf8372',
    '#7274bf',
    '#bf72b7',
    '#bf8b72',
    '#a9bf72',
    '#bf72a8',
    '#a572bf',
    '#8272bf',
    '#728fbf',
    '#72bfa3',
    '#7287bf',
    '#72bf8c',
    '#9372bf',
    '#78bf72',
    '#72bbbf',
    '#75bf72',
    '#bf7279',
    '#72bf8a',
    '#bf7281',
    '#7285bf',
    '#72bf87',
    '#b5bf72',
    '#bfb872',
    '#bf9072',
    '#72bfaf',
    '#bfbd72',
    '#bf72a8',
    '#72bf79',
    '#72bfbc',
    '#7299bf',
    '#7372bf',
    '#72bf91',
    '#7284bf',
    '#bfb572',
    '#bf72a6',
    '#72bfa7',
    '#bf7285',
    '#bfb072',
    '#7299bf',
    '#bf9272',
    '#bf72ae',
    '#72bfb7',
    '#96bf72',
    '#8972bf',
    '#7fbf72',
    '#7f72bf',
    '#7cbf72',
    '#bfa172',
    '#bf9072',
    '#90bf72',
    '#bf9272',
    '#bf729f',
    '#bf9772',
    '#bf8072',
    '#b8bf72',
    '#a7bf72',
    '#72bfaa',
    '#bfa672',
    '#bf8572',
    '#728cbf',
    '#9872bf',
    '#bfa872',
    '#72bfb4',
    '#bf7280',
    '#7293bf',
    '#9dbf72',
    '#bfbf72',
    '#72bf94',
    '#72bf87',
    '#bf727c',
    '#7abf72',
    '#728fbf',
    '#72aabf',
    '#90bf72',
    '#ac72bf',
    '#72bfa5',
    '#9abf72',
    '#bf8672',
    '#9dbf72',
    '#8e72bf',
    '#bf72b8',
    '#afbf72',
    '#b5bf72',
    '#7872bf',
    '#afbf72',
    '#bf72b8',
    '#9dbf72',
    '#abbf72',
    '#bf7281',
    '#7276bf',
    '#bf7290',
    '#72adbf',
    '#bf9e72',
    '#bf8372',
    '#72bf94',
    '#a4bf72',
    '#bebf72',
    '#7274bf',
    '#a772bf',
    '#bfb372',
    '#9172bf',
    '#b1bf72',
    '#bfb072',
    '#72bf8c',
    '#b8bf72',
    '#91bf72',
    '#72bfbc',
    '#bf728f',
    '#bf727c',
    '#bf72b7',
    '#72bfa5',
    '#bf8a72',
    '#bf9a72',
    '#bf7772',
    '#7285bf',
    '#72b1bf',
    '#72bf85',
    '#72bfbe',
    '#72bf97',
    '#bf7281',
    '#acbf72',
    '#bf7972',
    '#72bf9e',
    '#b5bf72',
    '#72bf87',
    '#bfb772',
    '#729dbf',
    '#9f72bf',
    '#a0bf72',
    '#72bfa2',
    '#bfa172',
    '#72a3bf',
    '#bfa172',
    '#93bf72',
    '#7296bf',
    '#bf9772',
    '#72bfaf',
    '#a4bf72',
    '#72bf94',
    '#b472bf',
    '#72bf7b',
    '#72bf73',
    '#72bfb6',
    '#bf9a72',
    '#7278bf',
    '#72bf91',
    '#bfae72',
    '#77bf72',
    '#bf7294',
    '#82bf72',
    '#7291bf',
    '#72bf92',
    '#72bfb6',
    '#bf728a',
    '#72bfac',
    '#84bf72',
    '#bfab72',
    '#84bf72',
    '#bf9072',
    '#bf72b0',
    '#72bf88',
    '#82bf72',
    '#77bf72',
    '#bfbc72',
    '#a0bf72',
    '#b1bf72',
    '#7293bf',
    '#72bf9e',
    '#bf8372',
    '#a072bf',
    '#72bf9c',
    '#bf7294',
    '#bf728a',
    '#bfb572',
    '#72bfb2',
    '#bf72b7',
    '#9dbf72',
    '#72a5bf',
    '#bf72ae',
    '#bf727e',
    '#bfa172',
    '#72bf94',
    '#84bf72',
    '#7294bf',
    '#bf7279',
    '#72bfb9',
    '#72bfb4',
    '#72bfb4',
    '#72bfa5',
    '#bf7277',
    '#b9bf72',
    '#bfa472',
    '#bf9f72',
    '#82bf72',
    '#bf727b',
    '#b472bf',
    '#bf9772',
    '#72bbbf',
    '#8172bf',
    '#9b72bf',
    '#bf7c72',
    '#7296bf',
    '#73bf72',
    '#729dbf',
    '#7f72bf',
    '#9872bf',
    '#bf72a4',
    '#72bfaf',
    '#96bf72',
    '#bfbd72',
    '#bf9972',
    '#72bf8a',
    '#bf9a72',
    '#bf8a72',
    '#72bf9b',
    '#72a2bf',
    '#72bf8a',
    '#bf72a9',
    '#bf72ad',
    '#bf7274',
    '#bf7672',
    '#8672bf',
    '#93bf72',
    '#72bfad',
    '#72bf80',
    '#bf9f72',
    '#72bf7b',
    '#bf9a72',
    '#72a0bf',
    '#727dbf',
    '#72bbbf',
    '#7fbf72',
    '#72bf96',
    '#a9bf72',
    '#a272bf',
    '#bfae72',
    '#8c72bf',
    '#727dbf',
    '#728fbf',
    '#bf72b2',
    '#bf9472',
    '#7299bf',
    '#7c72bf',
    '#bf9a72',
    '#bf7292',
    '#bf72a8',
    '#7293bf',
    '#aebf72',
    '#bf8572',
    '#72bf78',
    '#72bfb6',
    '#bf7276',
    '#7274bf',
    '#72aabf',
    '#bf7279',
    '#bf7b72',
    '#bf7c72',
    '#a472bf',
    '#728fbf',
    '#8bbf72',
    '#b872bf',
    '#8772bf',
    '#72bfb7',
    '#bf8f72',
    '#bf9c72',
    '#bf8672',
    '#bf72a4',
    '#81bf72',
    '#72bf96',
    '#be72bf',
    '#72aabf',
    '#bf9a72',
    '#9572bf',
    '#bf727c',
    '#72bf79',
    '#72bf76',
    '#72bf7b',
    '#bf727e',
    '#bfbc72',
    '#72adbf',
    '#bfae72',
    '#7fbf72',
    '#72a8bf',
    '#b372bf',
    '#72bfb2',
    '#bf9a72',
    '#728cbf',
    '#a0bf72',
    '#72bfb9',
    '#bf8872',
    '#7372bf',
    '#bf7294',
    '#bf7295',
    '#8172bf'
];

export const initialsColor = (initials: string) => {
    let fst = 1;
    let scd = 1;

    if (initials && initials.toUpperCase().charCodeAt(0) > 64) {
        fst = initials.toUpperCase().charCodeAt(0) - 64;
    }

    if (initials && initials.toUpperCase().charCodeAt(1) > 64) {
        scd = initials.toUpperCase().charCodeAt(1) - 64;
    }

    return colors[fst * scd];
};

type RGB = [r: number, g: number, b: number];

export const hexToRgb = (c: string): RGB => {
    c = c.replace(/^#/, ''); // strip #
    const rgb = parseInt(c, 16); // convert rrggbb to decimal
    const r = (rgb >> 16) & 0xff; // extract red
    const g = (rgb >> 8) & 0xff; // extract green
    const b = (rgb >> 0) & 0xff; // extract blue
    return [r, g, b];
};
export const colorToRgb = (color: string, alpha = 1): RGB => {
    return color?.includes('rgb')
        ? (color
              .replace(/[^\d,]/g, '')
              .split(',')
              .map((n) => parseInt(n)) as RGB)
        : hexToRgb(color);
};

export const colorToAlpha = (color: string, alpha: number) => {
    const rgb = colorToRgb(color);
    return `rgba(${rgb[0]},${rgb[1]},${rgb[2]}, ${alpha.toString()})`;
};

export const rgbToLuma = ([r, g, b]: RGB) => 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709

export const backgroundToTextColor = (color: string, alpha = 1) => {
    const rgb = colorToRgb(color);
    const primaryLuma = rgbToLuma(rgb);
    return primaryLuma > 125 ? `rgba(0,0,0,${alpha})` : `rgba(255,255,255,${alpha})`;
};

export const setContrast = (
    rgb?: [number, number, number] | null,
    darkest = 'black',
    lightest = 'white'
) => {
    if (!rgb) rgb = [255, 255, 255];
    // see https://www.w3.org/TR/AERT/#color-contrast
    const brightness = Math.round(rgb[0] * 299 + rgb[1] * 587 + rgb[2] * 114) / 1000;

    return brightness > 125 ? darkest : lightest;
};
