import styles from './SettingsUserProfile.module.css';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
    DELETE_CONFIRM,
    PASSWORD_UPDATE_FORM,
    TWO_FACTOR_AUTHENTICATION,
    USER_PROFILE_FORM
} from '@he-novation/config/paths/modals.constants';
import { Button, ButtonTone } from '@he-novation/design-system/components/buttons/Button/Button';
import { Icon } from '@he-novation/design-system/components/graphics/Icon/Icon';
import { logout, requestDelete } from '@he-novation/front-shared/async/user.async';
import { getDisplayName } from '@he-novation/utils/user';
import { useAtomValue } from 'jotai';

import { workspaceAtom } from '$atoms/workspace-atoms';
import Accordion from '$components/Accordion/Accordion';
import { EditableAvatar } from '$components/EditableAvatar/EditableAvatar';
import { SubHeader } from '$components/layout/Header/SubHeader/SubHeader';
import combineSelectors from '$helpers/combineSelectors';
import { useModal } from '$hooks/useModal';
import { useTranslate } from '$hooks/useTranslate';
import {
    currentUserUuidSelector,
    isPrivateSelector,
    totpEnabledSelector,
    userInfosAsInfosSelector
} from '$redux/user/userSelectors';

export function SettingsUserProfile() {
    const { openModal, closeModal } = useModal();

    const workspace = useAtomValue(workspaceAtom)!;

    const { infos, isPrivate, totpEnabled } = useSelector(
        combineSelectors(userInfosAsInfosSelector, isPrivateSelector, totpEnabledSelector)
    );
    const { currentUserUuid } = useSelector(currentUserUuidSelector);

    const { t, locale } = useTranslate();
    const openModalPassword = useCallback(() => openModal(PASSWORD_UPDATE_FORM), []);
    const openModalProfile = useCallback(() => openModal(USER_PROFILE_FORM), []);
    const openModalDeleteAccount = useCallback(() => {
        openModal(DELETE_CONFIRM, {
            message: t('settings.__ACCOUNT_DELETE_WARNING'),
            title: t('settings.Delete my account'),
            confirmButtonLabel: t('common.Delete'),
            confirmCheckbox: true,
            alert: true,
            onDelete: async () => {
                await requestDelete(currentUserUuid);
                closeModal();
                logout();
            }
        });
    }, []);

    return (
        <>
            <SubHeader subClassName={'settings-sub-header'} title={t('common.My account')} />

            <Accordion title={t('settings.My personal information')} isOpen={true}>
                <div className={styles.userProfileAvatarAndInfos}>
                    {infos?.email ? <EditableAvatar {...infos} /> : <Icon icon="round-users" />}

                    <div className={styles.userProfileInfos}>
                        <h2>{getDisplayName(infos)}</h2>
                        <p>
                            {infos.role && (
                                <>
                                    {infos.role}
                                    <br />
                                </>
                            )}
                            {infos.phone && (
                                <>
                                    {infos.phone}
                                    <br />
                                </>
                            )}
                            {infos.email && (
                                <>
                                    {infos.email}
                                    <br />
                                </>
                            )}
                        </p>
                    </div>
                </div>

                <Button tone={ButtonTone.Outlined} onClick={openModalProfile}>
                    {t('settings.My personal information')}
                </Button>
            </Accordion>

            <Accordion title={t('settings.Security')} isOpen={true} icon="lock">
                <p className="white">{t('settings.__PASSWORD_TEXT')}</p>
                <p>
                    {t('settings.Last password change')}:{' '}
                    {(infos.passwordReset || infos.created)?.toLocaleString(locale, {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric'
                    })}
                </p>

                <Button tone={ButtonTone.Outlined} onClick={openModalPassword}>
                    {t('settings.Change password')}
                </Button>
            </Accordion>

            <Accordion
                title={t('settings.Two-factor authentication')}
                isOpen={true}
                icon={[{ name: 'lock', circle: true, stroke: 'white' }]}
            >
                <p className="white">
                    {t('settings.Two-step validation via athenticator application')}
                </p>

                <Button
                    tone={ButtonTone.Outlined}
                    disabled={totpEnabled && workspace.totpForced}
                    onClick={() => openModal(TWO_FACTOR_AUTHENTICATION)}
                >
                    {totpEnabled ? t('common.Disable') : t('common.Enable')}
                </Button>
            </Accordion>

            {isPrivate && (
                <Accordion title={t('settings.Delete account')} isOpen={true} icon={'trash'}>
                    <p className="white">{t('settings.__DELETE_ACCOUNT_TEXT')}</p>

                    <Button tone={ButtonTone.Outlined} onClick={openModalDeleteAccount}>
                        {t('settings.Delete account')}
                    </Button>
                </Accordion>
            )}
        </>
    );
}
