import { sanitizedString } from '@he-novation/utils/zod.utils';
import { z } from 'zod';
import { ROLES } from '../../constants/projects.constants';
import { TEAM_STATUS } from '../../constants/teams.constants';

export const oaMemberSchema = z.object({
    uuid: z.string().uuid().optional(),
    email: z.string().email(),
    projectRole: z.nativeEnum(ROLES),
    canDownload: z.boolean(),
    canExport: z.boolean()
});

export const oaTeamInviteBodySchema = z.object({
    projectUuid: z.string().uuid(),
    members: z.array(oaMemberSchema),
    message: z.string().optional(),
    castTeamAccess: z.boolean().optional(),
    teamColor: z.string().optional()
});

export const oaTeamNameSchema = z.object({
    teamName: z.string()
});

export type OATeamNameSchema = z.infer<typeof oaTeamNameSchema>;
export type OATeamInviteBody = z.infer<typeof oaTeamInviteBodySchema>;

export const oaTeamParamsSchema = z.object({
    workspaceName: sanitizedString(),
    projectUuid: z.string().uuid(),
    teamUuid: z.string().uuid()
});

export const oaTeamPostBody = z.object({
    name: z.string(),
    teamColor: z.string().optional(),
    castTeamAccess: z.boolean().optional()
});

export const oaTeamUsersPostBody = z.object({
    members: z.array(
        z.object({
            uuid: z.string().uuid().optional(),
            email: z.string().email(),
            projectRole: z.nativeEnum(ROLES),
            canDownload: z.boolean().optional(),
            canExport: z.boolean().optional()
        })
    ),
    message: sanitizedString({ optional: true })
});

export const oaTeamSchema = z.object({
    uuid: z.string().uuid(),
    name: z.string(),
    color: z.string().nullable(),
    status: z.nativeEnum(TEAM_STATUS).optional(),
    castTeamAccess: z.boolean().optional(),
    ownTeam: z.boolean().optional()
});

export const oaTeamWithMembersSchema = oaTeamSchema.extend({
    members: z.array(oaMemberSchema)
});
