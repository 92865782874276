import {
    LOAD_QUERY_PARAMS,
    RESET_QUERY_PARAMS,
    SAVE_QUERY_PARAMS,
    SET,
    SET_FILTER,
    SET_FILTERS,
    SET_ROUTE
} from './routeActions';
import { deserialize, serialize } from '$components/Filters/helpers';
import { isEqual } from 'lodash/fp';
import strToQueryParams from '@he-novation/config/paths/utils/strToQueryParams';
import { getFrontRoutesFromContext, locationToContext } from '$views/App/routingContext';

export const queryParamsToStr = (params) => {
    let str = '';
    let keys = Object.keys(params).filter((k) => typeof params[k] !== 'undefined');
    if (keys.length) str = '?';
    keys = keys.map((k) => `${k}=${params[k]}`);
    return str + keys.join('&');
};

const getRouteMatch = (currentRouteParts, routeParts) => {
    let match = (!routeParts.length && !currentRouteParts.length) || routeParts.length;
    const params = {};
    let queryParams = {};

    if (match) {
        for (let i = 0, iLength = currentRouteParts.length; i < iLength; i++) {
            if (!match) break;
            if (/^:/.test(routeParts[i])) {
                match = !!currentRouteParts[i];
                params[routeParts[i].replace(/^:/, '')] = currentRouteParts[i];
            } else if (/^\?/.test(routeParts[i])) {
                match = true;
                params[routeParts[i].replace(/^\?/, '')] = currentRouteParts[i];
            } else {
                match = routeParts[i] === currentRouteParts[i];
            }
        }
    }

    return {
        match,
        params,
        queryParams
    };
};

const findRouteData = (routeString, routes) => {
    const queryParams = strToQueryParams(routeString);

    const currentRouteParts = routeString
        .split('?')[0]
        .split('/')
        .filter((v) => v);

    for (let i = 0, iLength = routes.length; i < iLength; i++) {
        const { match, params } = getRouteMatch(
            currentRouteParts,
            routes[i].split('/').filter((v) => v)
        );
        if (match)
            return {
                configRoute: routes[i],
                params,
                queryParams
            };
    }

    return {
        configRoute: null,
        params: {},
        queryParams: queryParams
    };
};

const routes = getFrontRoutesFromContext(locationToContext());

const set = (action, state) => {
    let savedQueryParams = { ...state.savedQueryParams };
    if (routes?.[state.configRoute]?.preserveQueryParams) {
        savedQueryParams[state.configRoute] = state.queryParams;
    }
    const route = action.route;

    const data = findRouteData(route, state.routes);
    if (route === state.route && isEqual(action.modalPayload, state.modalPayload)) return state;

    if (!action.silent && !action.replace) {
        history.pushState(null, null, route);
    } else if (action.replace) {
        history.replaceState(null, null, route);
    }
    return {
        ...state,
        savedQueryParams,
        route,
        extraParams: action.extraParams,
        modalPayload: action.modalPayload,
        ...data
    };
};

const silentlySetParams = (params) =>
    history.replaceState(null, null, window.location.href.split('?')[0] + queryParamsToStr(params));

const route = window.location.href.replace(window.location.origin, '');

const _routes = [];

for (let key in routes) {
    _routes.push(key);
}
const initialState = {
    savedQueryParams: {},
    routes: _routes,
    route,
    ...findRouteData(route, _routes)
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case RESET_QUERY_PARAMS:
            return set(
                {
                    route: `${window.location.pathname}${window.location.hash.replace(/\?.+/, '')}`,
                    replace: true
                },
                state
            );

        case SET:
        case SET_ROUTE:
            return set(action, state);

        case SET_FILTERS: {
            const params = strToQueryParams(window.location.href);
            params.filters = serialize(action.filters);
            if (!params.filters) delete params.filters;
            action.modalPayload = state.modalPayload;
            action.route = `${window.location.pathname}${window.location.hash.replace(
                /\?.+/,
                ''
            )}${queryParamsToStr(params)}`;
            return set(action, state);
        }
        case SET_FILTER: {
            const params = strToQueryParams(window.location.href);
            params.filters = params.filters ? deserialize(params.filters) : {};
            if (!action.filterValue) delete params.filters[action.filterName];
            else params.filters[action.filterName] = action.filterValue;
            params.filters = serialize(params.filters);
            if (!params.filters) delete params.filters;
            action.modalPayload = state.modalPayload;
            action.route = `${window.location.pathname}${window.location.hash.replace(
                /\?.+/,
                ''
            )}${queryParamsToStr(params)}`;
            return set(action, state);
        }

        case SAVE_QUERY_PARAMS:
            return {
                ...state,
                savedQueryParams: {
                    ...state.savedQueryParams,
                    [action.routeName]: action.queryParamsToSave
                }
            };
        case LOAD_QUERY_PARAMS:
            if (!state.savedQueryParams[action.routeName]) return state;
            silentlySetParams(state.savedQueryParams[action.routeName]);
            return {
                ...state,
                queryParams: state.savedQueryParams[action.routeName]
            };

        default:
            return state;
    }
};
