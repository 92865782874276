import './EditableAvatar.scss';
import React from 'react';
import { PROFILE_PICTURE } from '@he-novation/config/paths/modals.constants';
import type { AvatarUserProps } from '@he-novation/design-system/components/avatars/Avatar/AvatarUser';
import {
    AvatarSize,
    AvatarUser
} from '@he-novation/design-system/components/avatars/Avatar/AvatarUser';
import { Icon } from '@he-novation/design-system/components/graphics/Icon/Icon';

import { useModal } from '$hooks/useModal';

export function EditableAvatar(avatar: AvatarUserProps) {
    const { openModal } = useModal();

    return (
        <AvatarUser
            className="c-editable-avatar"
            size={AvatarSize.ExtraLarge}
            {...avatar}
            onClick={() => openModal(PROFILE_PICTURE)}
        >
            <Icon icon="pencil" />
        </AvatarUser>
    );
}
