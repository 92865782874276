import { useDispatch, useSelector } from 'react-redux';
import { DataLayoutPrefs } from '@he-novation/config/types/dataLayout.types';
import { UserPrefs } from '@he-novation/config/types/user.types';

import { updateUserPreferences } from '$redux/user/userActions';
import { preferencesSelector } from '$redux/user/userSelectors';

export function useDataLayoutPrefs(name: string | null): {
    saveLayoutPrefs: (userUuid: string, prefs: DataLayoutPrefs) => void;
    layoutPrefs?: DataLayoutPrefs;
} {
    const { preferences }: { preferences: UserPrefs } = useSelector(preferencesSelector);
    const dispatch = useDispatch();

    return {
        saveLayoutPrefs: (userUuid, prefs: DataLayoutPrefs) => {
            if (!name || !preferences) return;
            if (!preferences.ui.dataLayouts) preferences.ui.dataLayouts = {};
            preferences.ui.dataLayouts[name] = { ...preferences.ui.dataLayouts[name], ...prefs };
            dispatch(updateUserPreferences(userUuid, preferences));
        },
        layoutPrefs: name ? preferences?.ui.dataLayouts?.[name] : undefined
    };
}
