import React from 'react';

type TranscoderPointsProps = {
    className?: string;
    transcodingPoints: number;
    transcodingTaskPoints: number;
    runningTaskPoints: number;
    unassignedTasks: { points: number }[];
    unassignedTaskPoints: number;
};

export function TranscoderPointsTable({
    className,
    transcodingPoints,
    transcodingTaskPoints,
    runningTaskPoints,
    unassignedTasks,
    unassignedTaskPoints
}: TranscoderPointsProps) {
    return (
        <table className={className}>
            <thead>
                <tr>
                    <th>Transcoding capacity</th>
                    <th>Total task points</th>
                    <th>Running / Assigned task points</th>
                    <th>Unassigned tasks (points)</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>{transcodingPoints} points</td>
                    <td>{transcodingTaskPoints + unassignedTaskPoints}</td>
                    <td>
                        {runningTaskPoints} running / {transcodingTaskPoints} assigned
                    </td>
                    <td>
                        {unassignedTasks.length} tasks ({unassignedTaskPoints} points)
                    </td>
                </tr>
            </tbody>
        </table>
    );
}
