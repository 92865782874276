import styles from './WorkspaceMemberPicker.module.scss';
import React, { useCallback, useState } from 'react';
import { FieldComponentProps } from 'react-modular-forms';
import { ClientTier, ClientUserRole } from '@he-novation/config/types/db/enums';
import {
    WorkspaceTeamWithMembers,
    WorkspaceUser
} from '@he-novation/config/types/workspace-team.types';
import { Icon } from '../../../../graphics/Icon/Icon';
import { EmailChipsList } from '../../../../lists/EmailChipsList/EmailChipsList';
import { Loader, LoaderSize } from '../../../../widgets/Loader/Loader';
import { FormField } from '../../FormField';
import { ReactSelectLabel } from '../ReactSelectLabel/ReactSelectLabel';
import { WorkspaceMemberOption } from './WorkspaceMemberOption';

export type WorkspaceMemberPickerUser = {
    uuid: string;
    email: string;
    firstname?: string | null;
    lastname?: string | null;
    workspaceRole: ClientUserRole;
};

export type WorkspaceMemberPickerNewUser = Omit<WorkspaceMemberPickerUser, 'uuid'> & {
    uuid?: never;
};

export type MixedWorkspaceMemberPickerUser =
    | WorkspaceMemberPickerUser
    | WorkspaceMemberPickerNewUser;

export type SelectedMembersListProps = {
    selected: MixedWorkspaceMemberPickerUser[];
    onUpdate: (items: MixedWorkspaceMemberPickerUser[]) => void;
};

export type WorkspaceMemberPickerProps = FieldComponentProps & {
    items: WorkspaceUser[];
    groups?: WorkspaceTeamWithMembers[];
    onCreate?: (
        payload: WorkspaceMemberPickerNewUser
    ) => Promise<WorkspaceMemberPickerNewUser | undefined>;
    onCreateFilter?: (newItem: WorkspaceMemberPickerNewUser) => boolean;
    ListComponent?: React.ComponentType<SelectedMembersListProps>;
    workspaceTier: ClientTier;
};

export function WorkspaceMemberPicker({
    formId,
    name,
    onCreate,
    items,
    workspaceTier,
    groups = [],
    onChange,
    onCreateFilter = (_: WorkspaceMemberPickerNewUser) => true,
    ListComponent = EmailChipsList
}: WorkspaceMemberPickerProps) {
    const [selectedItems, setSelectedItems] = useState<MixedWorkspaceMemberPickerUser[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    const options = itemsToOptions(items, selectedItems);

    const groupOptions = groups
        .sort((a, b) => a.name.localeCompare(b.name))
        .map(
            (group) =>
                ({
                    label: (
                        <ReactSelectLabel size="little">
                            <Icon icon="users" />
                            {group.name}
                        </ReactSelectLabel>
                    ),
                    searchValue: group.name,
                    value: group.uuid,
                    items: itemsToOptions(group.members, selectedItems)
                }) as WorkspaceMemberGroupOption
        );

    const updateItems = useCallback(
        (newSelectedItems: MixedWorkspaceMemberPickerUser[]) => {
            setSelectedItems((selectedItems) => {
                if (selectedItems !== newSelectedItems && onChange) {
                    onChange(newSelectedItems);
                }
                return newSelectedItems;
            });
        },
        [onChange]
    );

    const components = {
        ClearIndicator: (): null => null,
        MultiValue: (_: any): null => null
    };

    if (isLoading) {
        components['ValueContainer'] = () => (
            <Loader className={styles.loader} size={LoaderSize.Small} />
        );
    }

    return (
        <FormField
            formId={formId}
            name={name}
            id={`${formId}-${name}-select`}
            type="react-select"
            isMulti
            menuClassName="is-workspace-member-picker"
            hideSelectedOptions
            isSearchable
            creatable={!!onCreate}
            disabled={isLoading}
            onChange={(e, emails: string[]) => {
                const newSelectedItems = [
                    ...selectedItems,
                    ...emails
                        .map((email) => items.find((c) => c.email === email))
                        .filter((c) => c !== undefined)
                ];
                updateItems(newSelectedItems);
            }}
            onCreateOption={async (value: string) => {
                if (!onCreate) return;

                const newItem = {
                    email: value,
                    workspaceRole:
                        workspaceTier === ClientTier.enterprise
                            ? ClientUserRole.BASIC
                            : ClientUserRole.USER
                } satisfies WorkspaceMemberPickerNewUser;

                if (onCreateFilter(newItem)) {
                    setIsLoading(true);
                    const createdItem = await onCreate(newItem);
                    if (createdItem) {
                        updateItems([...selectedItems, createdItem]);
                    }
                    setIsLoading(false);
                }
            }}
            coerceType={'array'}
            value={selectedItems.map((c) => c.email)}
            filterBy={'searchValue'}
            components={components}
            options={options}
            groups={groupOptions}
            after={<ListComponent selected={selectedItems} onUpdate={updateItems} />}
        />
    );
}

type WorkspaceMemberPickerOption = {
    searchValue: string;
    label: React.ReactNode;
    value: string;
};

type WorkspaceMemberGroupOption = WorkspaceMemberPickerOption & {
    items: WorkspaceMemberPickerOption[];
};

function itemsToOptions(
    items: MixedWorkspaceMemberPickerUser[],
    selectedItems: MixedWorkspaceMemberPickerUser[]
): WorkspaceMemberPickerOption[] {
    return items
        .filter((c) => selectedItems.find((sc) => sc.email === c.email) === undefined)
        .sort((a, b) => a.email.localeCompare(b.email))
        .map((item) => ({
            searchValue: item.email,
            label: <WorkspaceMemberOption item={item} key={`contact-option-${item.email}`} />,
            value: item.email
        }));
}
