import { useMemo } from 'react';
import type { WorkspaceUser } from '@he-novation/config/types/workspace-team.types';
import { asyncListWorkspaceTeams } from '@he-novation/front-shared/async/workspace-team.async';
import { useAtom } from 'jotai/index';

import { workspaceTeamsAtom } from '$atoms/workspace-atoms';

export function useWorkspaceTeams() {
    const [workspaceTeams, setWorkspaceTeams] = useAtom(workspaceTeamsAtom);

    const fetchWorkspaceTeams = (workspaceName: string) => {
        setWorkspaceTeams(null);
        asyncListWorkspaceTeams(workspaceName).then(setWorkspaceTeams);
    };

    const workspaceTeamUsers = useMemo(() => {
        return (
            workspaceTeams?.reduce((acc: WorkspaceUser[], team) => {
                if (team.members) {
                    acc.push(
                        ...team.members.filter((member) => !acc.find((m) => m.uuid === member.uuid))
                    );
                }
                return acc;
            }, []) || []
        );
    }, [workspaceTeams]);

    return { workspaceTeams, fetchWorkspaceTeams, workspaceTeamUsers };
}
