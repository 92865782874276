import styles from './CheckboxSearchableList.module.scss';
import { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import cn from 'classnames';
import { useTranslate } from '../../../hooks/useTranslate';
import { Checkbox } from '../../../types';
import { SearchField } from '../SearchField/SearchField';
import { CheckboxList } from './CheckboxList';

export type CheckboxSearchableListProps = {
    checkboxes: Checkbox[];
    formId?: string;
    className?: string;
    listClassName?: string;
    checkboxClassName?: string;
    onChange?: (checked: Checkbox[]) => void;
    search?: {
        value?: string | null;
        setValue: (s: string) => void;
        reset: (elementToFocus: string) => void;
    };
    hasSelectAllButton?: boolean;
    displayAll?: boolean;
};

const MAX_NB_OF_CHECKBOXES = [5, 10];

export function CheckboxSearchableList({
    checkboxes: _checkboxes,
    formId,
    className,
    listClassName,
    checkboxClassName,
    onChange: _onChange,
    search,
    hasSelectAllButton,
    displayAll
}: CheckboxSearchableListProps) {
    const [checkboxes, setCheckboxes] = useState<Checkbox[]>([]);
    const [checked, setChecked] = useState<Checkbox[]>([]);
    const [nbOfCheckboxes, setNbOfCheckboxes] = useState<number>(_checkboxes.length);
    const [seeAll, setSeeAll] = useState<boolean>(!!displayAll);

    useEffect(() => {
        if (checkboxes.length !== _checkboxes.length) {
            setCheckboxes(_checkboxes);
        }
    }, [_checkboxes]);

    useEffect(() => {
        setChecked(checkboxes.filter((c) => c.checked));
        setNbOfCheckboxes(checkboxes.length);
    }, [checkboxes]);

    const onChange = useCallback(
        (checkboxes: Checkbox[]) => {
            setCheckboxes(checkboxes);
            _onChange?.(checkboxes.filter((c) => c.checked));
        },
        [_onChange]
    );

    const onSelectAll = useCallback(
        (e: SyntheticEvent<HTMLAnchorElement>, selectAll: boolean) => {
            e.preventDefault();
            const values = checkboxes.map((chk) => ({ ...chk, checked: selectAll }));
            onChange(values);
        },
        [checkboxes, onChange]
    );

    const onSearch = useCallback((found: Checkbox[]) => setNbOfCheckboxes(found.length), []);

    const onToggleSeeAll = useCallback(() => setSeeAll(!seeAll), [seeAll]);
    const { t } = useTranslate();

    return (
        <div className={cn(styles.checkboxSearchableList, 'c-checkbox-searchable-list', className)}>
            {search && (
                <SearchField
                    className={styles.searchField}
                    onInput={(e) => search.setValue(e.target.value)}
                    onReset={() => search.reset('.search-field input')}
                    value={search.value}
                />
            )}

            {hasSelectAllButton && (
                <div className={styles.selectAll}>
                    <a
                        className={checked.length === checkboxes.length ? 'is-disabled' : undefined}
                        onClick={(e) => onSelectAll(e, true)}
                    >
                        {t('Select all')}
                    </a>
                    /
                    <a
                        className={checked.length === 0 ? 'is-disabled' : undefined}
                        onClick={(e) => onSelectAll(e, false)}
                    >
                        {t('Deselect all')}
                    </a>
                </div>
            )}

            <CheckboxList
                checkboxes={checkboxes}
                formId={formId}
                className={cn(styles.checkboxList, listClassName)}
                fieldClassName={checkboxClassName}
                onChange={(e, checkboxes) => {
                    e.preventDefault();
                    onChange(checkboxes);
                }}
                search={search?.value}
                onSearch={onSearch}
                nbOfDisplayedItems={seeAll ? MAX_NB_OF_CHECKBOXES[1] : MAX_NB_OF_CHECKBOXES[0]}
            />

            {!displayAll && nbOfCheckboxes > MAX_NB_OF_CHECKBOXES[0] && (
                <button className={styles.seeMore} type="button" onClick={onToggleSeeAll}>
                    {seeAll ? t('Less') : t('More')}
                </button>
            )}
        </div>
    );
}
