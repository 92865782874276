import { z } from 'zod';
import { ApiMethod } from '../../types/api.types';
import { workspaceAndLocaleParamsSchema } from '../../types/payloads/workspace.payload';

export const API_WORKSPACE_PUBLIC = {
    TERMS_GET: {
        method: ApiMethod.GET,
        path: '/workspaces-public/:workspaceName/terms/:locale',
        handlerMethod: 'getTerms',
        isPublic: true as true,
        zod: {
            params: workspaceAndLocaleParamsSchema
        },
        responses: {
            200: {
                description: "The workspace's terms of use",
                schema: z.string().nullable()
            }
        }
    },

    OIDC_LINK_GET: {
        method: ApiMethod.GET,
        path: '/workspaces-public-oidc/link',
        isPublic: true,
        handlerMethod: 'findClientOIDCLink',
        allowNoMfa: true,
        responses: {
            200: {
                description: 'OIDC link found',
                schema: z
                    .object({
                        label: z.string().nullable().optional(),
                        href: z.string()
                    })
                    .nullable()
            }
        }
    }
} as const;
