import { sanitizedString } from '@he-novation/utils/zod.utils';
import { z } from 'zod';
import { ClientUserRole } from '../db/enums';

export const workspaceParamsSchema = z.object({
    workspaceName: sanitizedString({ min: 3, max: 255 })
});

export type WorkspaceParams = z.infer<typeof workspaceParamsSchema>;

export const oaWorkspaceParamsSchema = z.object({
    workspaceName: sanitizedString({ min: 3, max: 255 })
});

export const workspaceAndLocaleParamsSchema = z.object({
    workspaceName: sanitizedString({ min: 3, max: 255 }),
    locale: z.enum(['fr', 'de', 'en'])
});

export const workspaceUserParamsSchema = z.object({
    workspaceName: sanitizedString({ min: 3, max: 255 }),
    userUuid: z.string().uuid()
});

export const workspaceUserTransferParamsSchema = z.object({
    workspaceName: sanitizedString({ min: 3, max: 255 }),
    userUuid: z.string().uuid(),
    userDstUuid: z.string().uuid()
});

export const workspaceDomainParamsSchema = z.object({
    workspaceName: sanitizedString({ min: 3, max: 255 }),
    domainUuid: z.string()
});

export const userWorkspaceRightsSchema = z.object({
    castAdmin: z.boolean().optional(),
    companyAdd: z.boolean().optional(),
    itemAdd: z.boolean().optional(),
    projectAdd: z.boolean().optional(),
    projectAdmin: z.boolean().optional(),
    contactAdd: z.boolean().optional(),
    contactAdmin: z.boolean().optional()
});
export type UserWorkspaceRights = z.infer<typeof userWorkspaceRightsSchema>;

export const workspaceUserBodySchema = z.object({
    email: z.string().email(),
    translationCredits: z.number().optional().nullable(),
    storage: z.number().optional(),
    role: z.nativeEnum(ClientUserRole)
});

export type WorkspaceUserBody = z.infer<typeof workspaceUserBodySchema>;

export const passwordConstraintsSchema = z.object({
    constraints: z.object({
        min_length: z.coerce.number(),
        min_lower: z.coerce.number(),
        min_number: z.coerce.number(),
        min_special: z.coerce.number(),
        min_upper: z.coerce.number()
    }),
    expires: z.coerce.boolean(),
    expires_days: z.coerce.number().optional()
});

export type PasswordConstraints = z.infer<typeof passwordConstraintsSchema>;

export const workspaceAdminUpdateBodySchema = z.object({
    totpForced: z.boolean().optional(),
    defaultCastTeamAccess: z.boolean().optional(),
    disableProjectCasts: z.boolean().optional(),
    passwordConstraints: passwordConstraintsSchema.optional(),
    disableDownloads: z.boolean().optional()
});

export type WorkspaceAdminUpdateBody = z.infer<typeof workspaceAdminUpdateBodySchema>;

export const DEFAULT_PASSWORD_CONSTRAINTS: PasswordConstraints = {
    constraints: {
        min_length: 8,
        min_lower: 1,
        min_number: 1,
        min_special: 0,
        min_upper: 1
    },
    expires: false,
    expires_days: 0
};

export const workspaceOwnerBodySchema = z.object({
    ownerUuid: z.string().uuid()
});
