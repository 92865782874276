import styles from './SubHeader.module.css';
import React, { ReactNode } from 'react';
import { createPortal } from 'react-dom';
import cn from 'classnames';

import {
    HeaderActions,
    HeaderActionsProps
} from '$components/layout/Header/HeaderActions/HeaderActions';
import {
    HeaderBackLink,
    HeaderBackLinkProps
} from '$components/layout/Header/HeaderBackLink/HeaderBackLink';

type SubHeaderProps = {
    backLink?: HeaderBackLinkProps;
    children?: ReactNode | ReactNode[];
    after?: ReactNode | ReactNode[];
    title?: ReactNode;
    titleClassName?: string;
    subClassName?: string;
} & HeaderActionsProps;

export function SubHeader({
    backLink,
    children,
    title,
    titleClassName,
    subClassName,
    actions,
    search,
    filters,
    groupers,
    displayMode,
    after
}: SubHeaderProps) {
    const subHeader = document.getElementById('sub-header');
    if (!subHeader) return null;

    return createPortal(
        <div className={cn(styles.sub, subClassName)}>
            {backLink && <HeaderBackLink {...backLink} />}
            {title && <h2 className={titleClassName}>{title}</h2>}
            {children}
            {actions || search || filters ? (
                <HeaderActions
                    className={styles.actions}
                    actions={actions}
                    filters={filters}
                    groupers={groupers}
                    displayMode={displayMode}
                    search={search}
                />
            ) : undefined}
            {after}
        </div>,
        subHeader
    );
}
