import styles from './FormNote.module.css';
import formStyles from '@he-novation/design-system/styles/form-styles.module.css';
import React, { type FormEvent, SyntheticEvent, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TEAM_CAST } from '@he-novation/config/constants/projects.constants';
import type { FileEnum } from '@he-novation/config/types/file.types';
import { TeamMember, TeamWithMembers } from '@he-novation/config/types/team.types';
import { Button, ButtonTone } from '@he-novation/design-system/components/buttons/Button/Button';
import { MultiActionButton } from '@he-novation/design-system/components/buttons/MultiActionButton/MultiActionButton';
import {
    FormField,
    ModularForm
} from '@he-novation/design-system/components/form/FormField/FormField';
import { Icon } from '@he-novation/design-system/components/graphics/Icon/Icon';
import { DirectionX, DirectionY } from '@he-novation/design-system/utils/getAbsolutePosition';
import cn from 'classnames';
import { useAtomValue } from 'jotai';
import { useAtom } from 'jotai/index';

import { areaSelectionAtom } from '$atoms/file-atoms/area-selection-atom';
import { cameraCoordinatesAtom, sceneLightningAtom } from '$atoms/file-atoms/threeDimensions-atom';
import { creatingTaskAtom } from '$atoms/note-atoms';
import { AttachmentButton } from '$components/SidePanel/SidePanelFile/SidePanelNotes/AttachmentButton/AttachmentButton';
import { FormNoteChips } from '$components/SidePanel/SidePanelFile/SidePanelNotes/FormNote/FormNoteChips';
import { FormNoteControls } from '$components/SidePanel/SidePanelFile/SidePanelNotes/FormNote/FormNoteControls';
import { FormNoteLogin } from '$components/SidePanel/SidePanelFile/SidePanelNotes/FormNote/FormNoteLogin';
import { NoteTagButton } from '$components/SidePanel/SidePanelFile/SidePanelNotes/FormNote/NoteLabelButton/NoteTagButton';
import { TaskDatePicker } from '$components/SidePanel/SidePanelFile/SidePanelNotes/FormNote/TaskDatePicker/TaskDatePicker';
import { TeamMemberPicker } from '$components/SidePanel/SidePanelFile/SidePanelNotes/FormNote/TeamMemberPicker/TeamMemberPicker';
import { useGenerateThumbnail } from '$hooks/useGenerateThumbnail';
import { useCreateNotesAndComments } from '$hooks/useNotes';
import { useOnKeyDown } from '$hooks/useOnKeyDown';
import { useProjectTeams } from '$hooks/useProjectTeams';
import { useTranslate } from '$hooks/useTranslate';
import {
    activeAssetSelector,
    fileHighlightedVersionSelector,
    fileNameUuidAndVersionSelector,
    fileTypeSelector,
    heightAndWidthSelector,
    pageAndPageNumberSelector
} from '$redux/content/file/fileSelectors';
import { folderProjectSelector } from '$redux/content/folder/folderSelectors';
import mapPayloadToMetadata from '$redux/content/note/map/mapPayloadToMetadata';
import { castFileUuidRouteSelector } from '$redux/route/routeSelectors';
import { toggleKeyboardListeners } from '$redux/ui/uiActions';
import { currentUserUuidSelector, userUuidSelector } from '$redux/user/userSelectors';
import { useCastPassword } from '$views/cast/castHelpers';

type FormData = {
    content: string;
    tcIn?: string;
    tcOut?: string;
    type: 'sequence' | 'areaselection' | 'global';
    teamUuid?: string;
    recaptcha?: string;
    attachments: File[];
    estimatedEndDate?: Date;
    teamMembers?: TeamMember[];
    metadata?: any;
    imageData?: { min?: string; max?: string; detail?: string };
};

type FormNoteProps = {
    formId: string;
    toggled: boolean;
    userUuid?: string | null;
    setToggled: (toggled: boolean) => void;
    project?: { uuid: string; name: string; isProjectRoot: boolean; folderUuid: string } | null;
    creatingTask?: boolean;
    fileType: FileEnum;
    onSubmit: (e: FormEvent<HTMLFormElement>, data: FormData) => Promise<void>;
    children?: React.ReactNode | React.ReactNode[];
    extra?: React.ReactNode;
    tags: string[];
    setTags: (tags: string[]) => void;
    disableSubmit?: boolean;
    beforeForm?: React.ReactNode;
};
export function FormNote({
    toggled,
    setToggled,
    onSubmit,
    children,
    formId,
    project,
    creatingTask,
    extra,
    fileType,
    tags,
    setTags,
    disableSubmit,
    beforeForm
}: FormNoteProps) {
    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(false);
    const [selectedTeam, setSelectedTeam] = useState<TeamWithMembers | undefined>();
    const [teamMembers, setTeamMembers] = useState<TeamMember[]>([]);
    const [estimatedEndDate, setEstimatedEndDate] = useState<Date | undefined>();
    const [attachments, setAttachments] = useState<File[]>([]);
    const submitButton = useRef<HTMLButtonElement>(null);
    const cameraCoordinates = useAtomValue(cameraCoordinatesAtom);
    const sceneLightning = useAtomValue(sceneLightningAtom);
    const activeAsset = useSelector(activeAssetSelector);
    const areaSelection = useAtomValue(areaSelectionAtom);

    const generateThumbnail = useGenerateThumbnail();
    const { page } = useSelector(pageAndPageNumberSelector);
    const { width, height } = useSelector(heightAndWidthSelector);
    const [pageWidth, pageHeight] = useMemo(() => {
        if (page !== undefined && (!width || !height)) {
            const pageSize = activeAsset?.metadata?.pageSizes?.[page!];
            return [pageSize?.width, pageSize?.height];
        }
        return [width, height];
    }, [page, width, height]);

    const { t } = useTranslate();

    const { accessibleTeams, teams } = useProjectTeams();

    const { currentUserUuid } = useSelector(currentUserUuidSelector);

    useEffect(() => {
        if (!selectedTeam) {
            if (accessibleTeams.length > 0) {
                setSelectedTeam(
                    accessibleTeams.find((t) =>
                        t.members.find((m) => m.uuid === currentUserUuid)
                    ) || accessibleTeams[0]
                );
            }
        }
    }, [accessibleTeams, teams, selectedTeam]);

    useOnKeyDown(
        'enter',
        (e) => {
            if (e.shiftKey) {
                e.preventDefault();
                submitButton.current?.click();
                dispatch(toggleKeyboardListeners(true));
            }
        },
        [],
        {
            useKeyboardListenersSelector: false
        }
    );

    const onFocus = () => {
        dispatch(toggleKeyboardListeners(false));
    };
    const onBlur = () => {
        dispatch(toggleKeyboardListeners(true));
    };

    const formRef = useRef<HTMLFormElement>();

    return (
        <>
            {!toggled && (
                <Button
                    onClick={() => setToggled(true)}
                    className={cn(styles.toggle, styles.formNote)}
                >
                    {t('player.Add note')}
                </Button>
            )}
            {toggled && (
                <div className={cn(styles.form, styles.formNote, formStyles.light)}>
                    {beforeForm}
                    <ModularForm
                        id={formId}
                        ref={formRef}
                        onSubmit={async (
                            e,
                            data: Omit<FormData, 'attachments' | 'estimatedEndDate' | 'teamMembers'>
                        ) => {
                            e.preventDefault();
                            setIsLoading(true);

                            const metadata = mapPayloadToMetadata({
                                tcIn: data.tcIn,
                                tcOut: data.tcOut,
                                areaSelection,
                                camera: cameraCoordinates || undefined,
                                lightning: sceneLightning || undefined,
                                height: pageHeight,
                                width: pageWidth,
                                page
                            });

                            const imageData = metadata.rectangle
                                ? await generateThumbnail({
                                      ...metadata,
                                      rectangle: metadata.rectangle!
                                  })
                                : undefined;
                            await onSubmit(e, {
                                ...data,
                                estimatedEndDate,
                                attachments,
                                teamMembers,
                                metadata,
                                imageData
                            });

                            setIsLoading(false);
                            setToggled(false);
                            setAttachments([]);
                            setTeamMembers([]);
                            setTags([]);
                            (document.getElementById(formId) as HTMLFormElement).reset();
                        }}
                    >
                        {children}
                        {project && (
                            <FormField
                                formId={formId}
                                name="teamUuid"
                                type="react-select"
                                className={styles.teamSelect}
                                value={selectedTeam?.uuid}
                                validation={{
                                    required: true
                                }}
                                options={accessibleTeams
                                    .filter((t) => t.name !== TEAM_CAST)
                                    .map((team) => ({
                                        value: team.uuid,
                                        label: (
                                            <span
                                                className={styles.team}
                                                style={{ color: team.color || undefined }}
                                            >
                                                <Icon
                                                    icon={[
                                                        {
                                                            name: 'users',
                                                            stroke: 'none',
                                                            fill: team.color!
                                                        }
                                                    ]}
                                                />
                                                {team.name}
                                            </span>
                                        )
                                    }))}
                                onChange={(_e, value) => {
                                    const newTeam = accessibleTeams.find((t) => t.uuid === value);
                                    if (newTeam) {
                                        setSelectedTeam(newTeam);
                                    }
                                }}
                            />
                        )}
                        <FormNoteControls formId={formId} fileType={fileType} />

                        <FormField
                            formId={formId}
                            name="content"
                            type="textarea"
                            validation={{
                                required: true,
                                validateOnBlur: false
                            }}
                            autoFocus
                            onFocus={onFocus}
                            onBlur={onBlur}
                        />

                        <FormNoteChips
                            teamMembers={teamMembers}
                            setTeamMembers={setTeamMembers}
                            estimatedEndDate={estimatedEndDate}
                            setEstimatedEndDate={setEstimatedEndDate}
                            attachments={attachments}
                            setAttachments={setAttachments}
                            tags={tags}
                            setTags={setTags}
                        />

                        <footer className={styles.footer}>
                            <div className={styles.options}>
                                <AttachmentButton
                                    attachments={attachments}
                                    setAttachments={setAttachments}
                                />
                                {creatingTask && selectedTeam && (
                                    <TeamMemberPicker
                                        team={selectedTeam}
                                        teamMembers={teamMembers}
                                        pickMember={(member) =>
                                            setTeamMembers([...teamMembers, member])
                                        }
                                    />
                                )}

                                {creatingTask && (
                                    <TaskDatePicker
                                        date={estimatedEndDate}
                                        pickDate={setEstimatedEndDate}
                                    />
                                )}
                                {extra}
                            </div>

                            <div className={styles.buttons}>
                                <Button
                                    tone={ButtonTone.Secondary}
                                    type={'button'}
                                    onClick={() => setToggled(false)}
                                >
                                    {t('common.Cancel')}
                                </Button>

                                <div className={styles.submitOrDraft}>
                                    <Button
                                        tone={ButtonTone.Primary}
                                        isLoading={isLoading}
                                        className={styles.submit}
                                        ref={submitButton}
                                        disabled={isLoading || disableSubmit}
                                    >
                                        {t('common.Publish')}
                                    </Button>

                                    <MultiActionButton
                                        className={styles.orDraft}
                                        contentClassName={styles.submitOrMenu}
                                        tone={ButtonTone.Primary}
                                        type={'button'}
                                        disabled={isLoading || disableSubmit}
                                        actions={[
                                            {
                                                id: 'submit-note-draft',
                                                form: formId,
                                                type: 'submit',
                                                children: t('player.Save as draft')
                                            }
                                        ]}
                                        direction={[DirectionX.RightInner, DirectionY.Top]}
                                        icon={'chevron'}
                                    />
                                </div>
                            </div>
                        </footer>
                    </ModularForm>
                </div>
            )}
        </>
    );
}

export function FileFormNote({
    toggled,
    setToggled
}: {
    toggled: boolean;
    setToggled: (toggled: boolean) => void;
}) {
    const formId = 'form-note';
    const castFileUuid = useSelector(castFileUuidRouteSelector);
    const [creatingTask, setCreatingTask] = useAtom(creatingTaskAtom);
    const { fileType } = useSelector(fileTypeSelector);
    const { fileUuid, fileVersion } = useSelector(fileNameUuidAndVersionSelector);
    const { fileHighlightedVersion } = useSelector(fileHighlightedVersionSelector);
    const project = useSelector(folderProjectSelector);
    const [tags, setTags] = useState<string[]>([]);

    const { noteCreate } = useCreateNotesAndComments();
    const { t } = useTranslate();

    useEffect(() => {
        return () => {
            setCreatingTask(false);
        };
    }, []);

    return (
        <FormNote
            formId={formId}
            toggled={toggled}
            setToggled={setToggled}
            project={project}
            creatingTask={creatingTask}
            fileType={fileType}
            tags={tags}
            setTags={setTags}
            onSubmit={async (e: SyntheticEvent<HTMLFormElement, SubmitEvent>, data: FormData) => {
                e.preventDefault();

                const draft = e.nativeEvent.submitter?.id === 'submit-note-draft';

                await noteCreate({
                    content: data.content,
                    draft,
                    fileVersion:
                        fileHighlightedVersion === undefined ? fileVersion : fileHighlightedVersion,
                    isTask: creatingTask,
                    metadata: data.metadata,
                    imageData: data.imageData,
                    notify: false,
                    teamUuid: data.teamUuid,
                    estimatedEndDate: data.estimatedEndDate,
                    assignees: data.teamMembers?.map((member) => member.uuid),
                    type: data.type,
                    tags: castFileUuid ? undefined : tags,
                    fileUuid,
                    attachments: data.attachments
                }).catch((e) => console.error(e));
                setCreatingTask(false);
            }}
            extra={<NoteTagButton tags={tags} setTags={setTags} />}
        >
            {project && (
                <FormField
                    formId={formId}
                    name="noteType"
                    type="radio-toggle"
                    style="tabs"
                    value={creatingTask ? 'task' : 'note'}
                    onChange={(e) => setCreatingTask(e.target.value === 'task')}
                    options={[
                        {
                            label: (
                                <div className={styles.mode}>
                                    <Icon icon="check_encircled" stroke="white" />{' '}
                                    {t('project.Task')}
                                </div>
                            ),
                            value: 'task'
                        },
                        {
                            label: (
                                <div className={styles.mode}>
                                    <Icon icon="pencil" stroke="white" /> {t('player.Note')}
                                </div>
                            ),
                            value: 'note'
                        }
                    ]}
                />
            )}
        </FormNote>
    );
}

export function CastFormNote({
    castUid,
    castFileUuid,
    projectUuid,
    toggled,
    setToggled
}: {
    toggled: boolean;
    setToggled: (toggled: boolean) => void;
    castUid: string;
    castFileUuid: string;
    projectUuid?: string;
}) {
    const [postedAs, setPostedAs] = useState<string | undefined>(undefined);
    const { password } = useCastPassword(castUid);
    const [tags, setTags] = useState<string[]>([]);
    const [creatingTask, setCreatingTask] = useAtom(creatingTaskAtom);
    const project = useSelector(folderProjectSelector);
    const { fileType } = useSelector(fileTypeSelector);

    const { castNoteCreate } = useCreateNotesAndComments();

    useEffect(() => {
        setCreatingTask(!!castFileUuid && !!projectUuid);
    }, [castFileUuid, projectUuid]);

    const formId = 'form-note';
    const { teams } = useProjectTeams();
    const [selectedTeam, setSelectedTeam] = useState<TeamWithMembers | undefined>();
    const { userUuid } = useSelector(userUuidSelector);

    useEffect(() => {
        if (teams.length > 0) {
            setSelectedTeam(teams.find((t) => t.name === TEAM_CAST));
        }
    }, [teams]);

    return (
        <>
            <FormNote
                toggled={toggled}
                setToggled={setToggled}
                formId={formId}
                project={project}
                creatingTask={creatingTask}
                fileType={fileType}
                tags={tags}
                disableSubmit={!userUuid && !postedAs}
                setTags={setTags}
                beforeForm={!userUuid ? <FormNoteLogin setPostedAs={setPostedAs} /> : undefined}
                onSubmit={async (e, data) => {
                    if (!userUuid && !postedAs) {
                        return;
                    }

                    await castNoteCreate(
                        castUid,
                        castFileUuid,
                        {
                            attachments: data.attachments,
                            content: data.content,
                            notify: false,
                            teamUuid: selectedTeam?.uuid,
                            estimatedEndDate: data.estimatedEndDate,
                            assignees: data.teamMembers?.map((member) => member.uuid),
                            type: data.type,
                            tags: tags,
                            recaptcha: data.recaptcha,
                            metadata: data.metadata,
                            imageData: data.imageData,
                            postedAs
                        },
                        password
                    );
                }}
            >
                {castFileUuid && (
                    <FormField
                        formId={formId}
                        type="recaptcha"
                        name="recaptcha"
                        siteKey={process.env.RECAPTCHA_FRONT_KEY}
                        action={'note'}
                    />
                )}
                <FormField
                    formId={formId}
                    type="hidden"
                    name="noteType"
                    value={creatingTask ? 'task' : 'note'}
                />
            </FormNote>
        </>
    );
}
