import { CastWorkspace } from '@he-novation/config/types/cast.types';
import { ClientUser } from '@he-novation/config/types/client.types';
import { type ClientLabel, ClientLabelType } from '@he-novation/config/types/clientLabel.types';
import { ClientUserRole } from '@he-novation/config/types/db/enums';
import { UserWorkspaceRights } from '@he-novation/config/types/payloads/workspace.payload';
import { UserWorkspace } from '@he-novation/config/types/user.types';
import { WorkspaceLabels } from '@he-novation/config/types/workspace.types';
import {
    WorkspaceTeamWithMembers,
    WorkspaceUser
} from '@he-novation/config/types/workspace-team.types';
import { atom } from 'jotai';

export const workspaceAtom = atom<UserWorkspace | null>(null);
export const castWorkspaceAtom = atom<CastWorkspace | null>(null);

export const workspaceNameAtom = atom((get) => {
    const workspace = get(workspaceAtom);
    const castWorkspace = get(castWorkspaceAtom);
    if (workspace) {
        return workspace.name;
    }
    if (castWorkspace) {
        return castWorkspace.name;
    }
    throw new Error('workspace atom is null');
});

export const workspaceUuidAtom = atom((get) => {
    const workspace = get(workspaceAtom);
    if (workspace) {
        return workspace.uuid;
    }
    throw new Error('workspace atom is null');
});

export const workspaceFeaturesAtom = atom((get) => {
    const workspace = get(workspaceAtom);
    if (workspace) {
        return workspace.features;
    }
    return [];
});

export const workspaceRoleAtom = atom((get) => {
    const workspace = get(workspaceAtom);
    if (workspace) {
        return workspace.role;
    }
    return ClientUserRole.BASIC;
});

export const workspaceRightsAtom = atom<UserWorkspaceRights>((get) => {
    const workspace = get(workspaceAtom);
    if (workspace) {
        return workspace.rights;
    }
    return {};
});

export const workspacesAtom = atom<UserWorkspace[] | null>(null);

export const workspaceMembersAtom = atom<WorkspaceUser[]>([]);

export const workspaceAdminMembersAtom = atom<ClientUser[]>([]);

export const workspaceTeamsAtom = atom<WorkspaceTeamWithMembers[] | null>(null);

const INITIAL_WORKSPACE_LABELS: WorkspaceLabels = {
    [ClientLabelType.Event]: [],
    [ClientLabelType.Contact]: [],
    [ClientLabelType.Item]: [],
    [ClientLabelType.Cast]: []
};

export const workspaceLabelsAtom = atom<WorkspaceLabels>(INITIAL_WORKSPACE_LABELS);

const workspaceTypedLabelsAtomFactory = <T extends ClientLabelType>(type: T) =>
    atom(
        (get) => get(workspaceLabelsAtom)[type],
        (_, set, values: ClientLabel<T>[]) =>
            set(workspaceLabelsAtom, (labels) => ({ ...labels, [type]: values }))
    );

export const workspaceCastLabelsAtom = workspaceTypedLabelsAtomFactory(ClientLabelType.Cast);
export const workspaceContactLabelsAtom = workspaceTypedLabelsAtomFactory(ClientLabelType.Contact);
export const workspaceEventLabelsAtom = workspaceTypedLabelsAtomFactory(ClientLabelType.Event);
export const workspaceItemLabelsAtom = workspaceTypedLabelsAtomFactory(ClientLabelType.Item);
