const path = '/hpi/';

export const paths = {
    castNotes: {
        root: `${path}cast/:castUid/f/:castFileUuid/:fileVersion/notes`,
        single: `${path}cast/note`,
        comment: `${path}cast/note/:noteUuid/comment`
    },
    chat: {
        root: `${path}chat`,
        single: `${path}chat/:roomUuid`,
        invite: `${path}chat/:roomUuid/invite`
    },
    client: {
        root: `${path}client`,
        multiple: `${path}clients`,
        specific: `${path}clients/:clientUuid`,
        storage: `${path}client/storage`,
        usersStorage: `${path}client/users-storage`,
        oidcLink: `${path}client/oidc/link`,
        oidc: `${path}client/oidc`,
        styles: `${path}client-styles`,
        defaultCastStyle: `${path}client/default-cast-style`,
        css: '/css/v2/:clientName.css',
        marketingStyles: '/css/v2/marketing',
        users: `${path}client/users`,
        guests: `${path}client/guests`,
        terms: `${path}client/:clientName/terms/:locale`,
        stats: `${path}client/stats`,
        profile: `${path}client/profile`,
        projects: `${path}client/projects`,
        user: `${path}client/user`,
        userSpecific: `${path}client/user/:userUuid`,
        userSpecificPassword: `${path}client/user/:userUuid/password`,
        userSpecificRemove: `${path}client/user/:userUuid/remove`,
        userTransfer: `${path}client/user/:userUuid/transferTo/:userDstUuid`
    },
    contact: {
        root: `${path}contacts`,
        single: `${path}contact`,
        specific: `${path}contact/:contactUuid`,
        group: `${path}contacts/group/:groupUuid`
    },
    contactGroup: {
        multiple: `${path}contact-groups`,
        multipleContacts: `${path}contact-groups/contacts`,
        single: `${path}contact-group`,
        specific: `${path}contact-group/:contactGroupUuid`
    },
    contactGroupContact: {
        single: `${path}contact-group/:contactGroupUuid/contact`
    },
    projects: {
        single: `${path}projects/:projectUuid`,
        folder: `${path}projects/:projectUuid/folder`,
        tasks: `${path}projects/:projectUuid/tasks`,
        getTeamsByProject: `${path}projects/:projectUuid/teams`,
        getProjectTasksByTeam: `${path}projects/:projectId/teams/:teamId/tasks`,
        updateCurrentUserMetadata: `${path}projects/:projectUuid/user/metadata`,
        member: `${path}projects/:projectUuid/user/:userUuid`,
        getAvailableCompanies: `${path}projects/companies`,
        taskAssets: `${path}projects/:projectUuid/taskAssets`,
        updateProjectStatus: `${path}projects/:projectUuid/status`,
        updateFromFolder: `${path}projects/:projectId/updateFromFolder`,
        updateFolderProjectId: `${path}projects/:projectUuid/folder/:folderUuid`,
        clientEventLabels: `${path}projects/:projectUuid/client-event-labels`,
        stats: `${path}projects/:projectUuid/stats`,
        statsXLSX: `${path}projects/:projectUuid/stats.xlsx`
    },
    teams: {
        inviteToTeam: `${path}teams/:teamName`,
        single: `${path}teams/:teamUuid`,
        updateStatus: `${path}teams/:teamUuid/status`
    },
    users: {
        root: `${path}user`,
        account: `${path}user/account`,
        login: `${path}user/login`,
        profile: `${path}user/profile`,
        appInfos: `${path}user/infos`,
        licenses: `${path}user/licenses`
    },
    rpc: {
        thumbnailCast: `${path}rpc/thumbnailCast/:encodingOrJob/:status/:uuid`
    },
    wording: {
        single: `${path}wording`,
        specific: `${path}wording/:identifier`
    },
    wordingList: {
        multiple: `${path}wording-lists`,
        single: `${path}wording-list`,
        specific: `${path}wording-list/:listName`
    },
    wordingListEntry: {
        single: `${path}wording-list/:listName/entry`
    }
};

export const clientPaths = {
    preferences: `${path}client/preferences`,
    domains: `${path}client/domains`,
    domainSpecific: `${path}client/domain/:clientDomainUuid`
};
export const clientCastLabelPaths = {
    multiple: `${path}client-cast-labels`,
    single: `${path}client-cast-label`,
    specific: `${path}client-cast-label/:uid`,
    associationSingle: `${path}client-cast-label/:uid/associate/:associationUid`
};

export const stripePaths = {
    config: `${path}stripe/config`
};

export const userPaths = {
    oidcLogin: `/oidc/:provider/login`
};

// export const subtitlesPaths = {
//     single: `${path}subtitle`,
//     upload: `${path}subtitle-upload`,
//     generatedSingle: `${path}subtitle-generated`,
//     specific: `${path}subtitle/:subtitleUuid`,
//     link: `${path}subtitle/:subtitleUuid/link`,
//     entrySingle: `${path}subtitle/:subtitleUuid/entry`,
//     entrySpecific: `${path}subtitle/:subtitleUuid/entry/:entryUuid`,
//     spotlLanguages: `${path}subtitles/spotl-languages`,
//     burn: `${path}subtitle/:subtitleUuid/burn/:fileUuid/:fileVersion`
// };

export const transcoderPaths = {
    transcoders: '/transcoders',
    transcoderSpecific: '/transcoders/:transcoderUuid',
    availableInstances: '/transcoder-instances',
    unassignedTasks: '/transcoder-unassigned-tasks',
    pid: '/transcoder-pid'
};

export const logPaths = {
    root: `${path}logs`,
    old: `${path}logs/old`
};

export const notePaths = {
    file: `${path}notes/file/:fileUuid/:fileVersion`,
    root: `${path}notes`,
    single: `${path}notes/:noteUuid`,
    comment: `${path}notes/:noteUuid/comment`,
    commentSingle: `${path}notes/:noteUuid/comment/:commentUuid`,
    commentAttachment: `${path}notes/:noteUuid/comment/:commentUuid/attachment`,
    avidSummary: `${path}notes/file/:fileUuid/:fileVersion/avid-summary`
};

export const castNotePaths = {
    root: `${path}cast/:castUid/f/:castFileUuid/:fileVersion/notes`,
    single: `${path}cast/note`,
    comment: `${path}cast/note/:noteUuid/comment`
};

export const projectPaths = {
    fromFolder: `${path}project-from-folder`
};

export const webhookPaths = {
    spotl: `${path}webhooks/spotl`
};

export const publicApiV1Paths = {
    asset: {
        upload: `/public/v1/asset/:assetUuid/upload`
    },
    auth: {
        user: '/public/v1/auth/user'
    },
    contact: {
        byEmail: '/public/v1/contacts/by-email'
    },
    contactGroup: {
        specific: '/public/v1/contact-group/:groupUuid'
    },
    folder: {
        base: '/public/v1/workspaces/:workspaceName/folder',
        specific: '/public/v1/workspaces/:workspaceName/folder/:folderUuid',
        invite: '/public/v1/folder/:folderUuid/invite',
        public: '/public/v1/folder/:folderUuid/public',
        teams: '/public/v1/folder/:folderUuid/teams',
        legacySpecific: '/public/v1/folder/:folderUuid',
        legacySingle: '/public/v1/folder'
    },
    folderContent: {
        specific: '/public/v1/workspaces/:workspaceName/folder/:folderUuid/content',
        legacySpecific: '/public/v1/folder-content/:folderUuid'
    },
    note: {
        multiple: '/public/v1/file/:fileUuid/v/:fileVersion/notes',
        single: '/public/v1/file/:fileUuid/v/:fileVersion/note',
        comment: '/public/v1/note/:noteUuid/comment'
    },
    projects: {
        multiple: '/public/v1/projects',
        specific: '/public/v1/workspaces/:workspaceName/projects/:projectUuid',
        status: '/public/v1/projects/:projectUuid/status',
        member: `/public/v1/projects/:projectUuid/users/:userUuid`,
        teams: '/public/v1/projects/:projectUuid/teams'
    },
    search: {
        root: '/public/v1/search'
    },
    subtitle: {
        single: '/public/v1/subtitle',
        specific: '/public/v1/subtitle/:subtitleUuid'
    },
    task: {
        single: '/public/v1/task'
    },
    teams: {
        inviteToTeam: `/public/v1/teams/:teamName`
    },
    versions: {
        multiple: '/public/v1/versions'
    }
};

export const pathWithParams = (
    p: string,
    params: Record<string, string | number | undefined> = {},
    query: Record<string, string | number | undefined | null | string[] | Date> = {}
) => {
    if (!params) params = {};
    let uri = '';
    const paramKeys = Object.keys(params);

    uri += paramKeys.reduce(
        (acc, curr) =>
            Array.isArray(params[curr])
                ? curr
                : curr === '*'
                ? acc.replace('*', params[curr] as string)
                : acc.replace(
                      new RegExp(`:${curr}\\??|\\?${curr}`, 'g'),
                      (typeof params[curr] !== 'undefined' ? params[curr] : '') as string
                  ),
        p
    );

    if (query) {
        const queryKeys = Object.keys(query);
        if (queryKeys.length) {
            uri += queryKeys.reduce((acc, curr) => {
                if (typeof query[curr] === 'undefined') return acc;
                if (Array.isArray(query[curr])) {
                    return (
                        acc +
                        (query[curr] as string[]).reduce(
                            (acc2, curr2: string) =>
                                `${curr}=${encodeURIComponent(curr2 as string)}&${acc2}`,
                            ''
                        )
                    );
                }
                return (
                    acc +
                    `${curr}=${encodeURIComponent(
                        (typeof query[curr] !== 'string'
                            ? query[curr] instanceof Date
                                ? query[curr].toISOString()
                                : JSON.stringify(query[curr])
                            : query[curr]) as string
                    )}&`
                );
            }, '?');
        }
    }
    return uri.replace(/[?&]$/, '');
};

export default paths;
