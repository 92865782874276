import './TagsField.scss';
import React, { SyntheticEvent, useRef, useState } from 'react';
import { Theme } from '../../../enums';
import { Button, ButtonTone } from '../../buttons/Button/Button';
import { Tags } from '../../text/Tags/Tags';
import { FormField } from '../FormField/FormField';

type TagsFieldProps = {
    autocompleteList?: string[];
    disabled?: boolean;
    readOnly?: boolean;
    tags: string[];
    theme?: Theme;
    setTags: (tags: string[]) => void;
    toggleOnSpace?: boolean;
    value?: string;
    button?: boolean;
    [key: string]: any;
};

export const TagsField: React.FC<TagsFieldProps> = ({
    tags = [],
    setTags,
    readOnly,
    disabled,
    theme = Theme.Dark,
    value: _value,
    toggleOnSpace,
    autocompleteList = [],
    button,
    ...rest
}) => {
    const ref = useRef<any>();

    return (
        <div className="c-tags-field">
            <FormField
                id="tags-from-field"
                name="tags-field"
                componentRef={ref}
                options={autocompleteList.map((v) => ({ label: v, value: v }))}
                type="react-select"
                creatable
                isMulti
                isSearchable
                isClearable
                value={tags}
                components={{
                    ClearIndicator: (): null => null,
                    MultiValue: (_: any): null => null
                }}
                onChange={(
                    e: React.ChangeEvent<HTMLInputElement>,
                    value: string[],
                    ...args: any[]
                ) => {
                    const _tags = value.map((v) => v.trim());
                    setTags(_tags);
                    if (rest.onChange) rest.onChange(e, _tags, ...args);
                }}
                after={
                    button ? (
                        <Button
                            className="tag-button"
                            icon="cross"
                            tone={ButtonTone.Hoverable}
                            theme={theme}
                            onClick={(e: SyntheticEvent) => {
                                e.preventDefault();
                                const value = ref.current.getValue();
                                if (value) {
                                    setTags([...tags, value.trim()]);
                                }
                            }}
                        />
                    ) : undefined
                }
                readOnly={readOnly}
                disabled={disabled}
                {...rest}
            />
            <Tags
                theme={theme}
                tags={tags}
                remove={
                    !disabled && !readOnly
                        ? (tag: string) => setTags(tags.filter((t) => t !== tag))
                        : undefined
                }
            />
        </div>
    );
};
