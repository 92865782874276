import styles from '../SettingsWorkspaceSubscription.module.css';
import { bytesToSize } from '@he-novation/utils/bytes';
import Chips, { ChipsTheme } from '../../../../components/base/Chips/Chips';
import { useTranslate } from '../../../../hooks/useTranslate';

interface SubscriptionFeaturesProps {
    maxProjects: number;
    licenses: number;
    storage: number;
    locale: string;
}

export function SubscriptionFeatures({
    maxProjects,
    licenses,
    storage,
    locale
}: SubscriptionFeaturesProps) {
    const { t } = useTranslate();

    return (
        <ul className={styles.features}>
            <li>
                <Chips theme={ChipsTheme.Square} background="#273043">
                    {maxProjects === -1
                        ? t('common.Unlimited')
                        : t('settings.{{n}} project(s)', {
                              n: maxProjects
                          })}
                </Chips>
            </li>
            <li>
                <Chips theme={ChipsTheme.Square} background="#273043">
                    {t('settings.{{n}} users', { n: licenses })}
                </Chips>
            </li>
            <li>
                <Chips theme={ChipsTheme.Square} background="#273043">
                    {bytesToSize(storage, locale)}
                </Chips>
            </li>
        </ul>
    );
}
