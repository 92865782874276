import { buildApiCall } from '@he-novation/config/apis/api-fetch';
import {
    API_CLIENT_LABEL,
    API_CLIENT_LABEL_ASSOCIATION
} from '@he-novation/config/apis/client-label-api';
import { ClientLabel, ClientLabelType } from '@he-novation/config/types/clientLabel.types';
import type {
    ClientLabelBody,
    ClientLabelUpdateBody
} from '@he-novation/config/types/payloads/client-label.payload';

export function asyncClientLabelsFetch<T extends ClientLabelType>(workspaceName: string, type: T) {
    return buildApiCall(API_CLIENT_LABEL.LIST)({
        params: { workspaceName },
        query: { type }
    }) as Promise<ClientLabel<T>[]>;
}

export const asyncClientLabelCreate = <T extends ClientLabelType>(
    workspaceName: string,
    type: T,
    body: ClientLabelBody
) =>
    buildApiCall(API_CLIENT_LABEL.POST)({
        params: { workspaceName },
        query: { type },
        body
    }) as Promise<ClientLabel<T>>;

export const asyncClientLabelUpdate = <T extends ClientLabelType>(
    workspaceName: string,
    type: T,
    uid: string,
    body: ClientLabelUpdateBody
) =>
    buildApiCall(API_CLIENT_LABEL.PUT)({
        params: { workspaceName, uid },
        query: { type },
        body
    });

export const asyncClientLabelDelete = (workspaceName: string, type: ClientLabelType, uid: string) =>
    buildApiCall(API_CLIENT_LABEL.DELETE)({
        params: { workspaceName, uid },
        query: { type }
    });

export const asyncLabelAssociationInsert = (
    workspaceName: string,
    type: ClientLabelType,
    associationUuid: string,
    uid: string
) =>
    buildApiCall(API_CLIENT_LABEL_ASSOCIATION.POST)({
        params: { workspaceName, uid, associationUuid },
        query: { type }
    });

export const asyncLabelAssociationDelete = (
    workspaceName: string,
    type: ClientLabelType,
    associationUuid: string,
    uid: string
) =>
    buildApiCall(API_CLIENT_LABEL_ASSOCIATION.DELETE)({
        params: { workspaceName, uid, associationUuid },
        query: { type }
    });
