import styles from './SettingsWorkspaceDashboard.module.css';
import React, { useEffect, useState } from 'react';
import { trashLink } from '@he-novation/config/paths/herawFrontUris';
import { ClientLicensesStats, ClientStats } from '@he-novation/config/types/client.types';
import { Loader } from '@he-novation/design-system/components/widgets/Loader/Loader';
import { clientFetchStats } from '@he-novation/front-shared/async/client.async';
import { bytesToSize } from '@he-novation/utils/bytes';
import cn from 'classnames';
import { useAtomValue } from 'jotai';

import { workspaceNameAtom } from '$atoms/workspace-atoms';
import { SubHeader } from '$components/layout/Header/SubHeader/SubHeader';
import Link from '$components/router/Link';
import { useTranslate } from '$hooks/useTranslate';
import { ClientDashboardChart } from '$views/settings/SettingsWorkspace/components/ClientDashboardChart';

export function SettingsWorkspaceDashboard() {
    const { t, locale } = useTranslate();

    const [stats, setStats] = useState<ClientStats>();

    const workspaceName = useAtomValue(workspaceNameAtom);

    useEffect(() => {
        clientFetchStats(workspaceName).then(setStats);
    }, []);

    return (
        <>
            <SubHeader subClassName={'settings-sub-header'} title={t('common.Dashboard')} />

            {!stats && <Loader />}

            {stats && (
                <section className={styles.articles}>
                    <LicensesArticle
                        licenses={stats.licenses.standardLicenses}
                        label={'STANDARD'}
                    />
                    <LicensesArticle licenses={stats.licenses.basicLicenses} label={'BASIC'} />

                    {!!stats.storage && (
                        <article className={cn(styles.article, styles.withChart)}>
                            <h3>{t('common.Storage')}</h3>

                            <div className={styles.content}>
                                <div className={styles.legend}>
                                    <div
                                        className={cn(
                                            styles.bigLegend,
                                            stats.storage.used > stats.storage.total &&
                                                styles.exceeding
                                        )}
                                    >
                                        {bytesToSize(stats.storage.used, locale, 1)}
                                    </div>
                                    <div className={styles.smallLegend}>
                                        {stats.storage!.total === -1
                                            ? t('common.Unlimited')
                                            : bytesToSize(stats.storage.total, locale, 1)}
                                    </div>
                                </div>

                                <ClientDashboardChart
                                    className={styles.chart}
                                    value={stats.storage.used}
                                    valueToString={(value) => bytesToSize(value, locale, 1)}
                                    valueLabel={t('settings.Used')}
                                    total={stats.storage.total}
                                />
                            </div>

                            <Link className={styles.trash} href={trashLink()}>
                                {t('settings.Show trash')}
                            </Link>
                        </article>
                    )}

                    <article className={styles.article}>
                        <h3>{t('project.Tasks in progress')}</h3>

                        <div className={styles.legend}>
                            <div className={styles.bigLegend}>{stats!.tasks}</div>
                        </div>
                    </article>

                    <article className={styles.article}>
                        <h3>{t('project.Active projects')}</h3>

                        <div className={styles.legend}>
                            <div className={styles.bigLegend}>{stats!.projects}</div>
                        </div>
                    </article>

                    <article className={styles.article}>
                        <h3>{t('common.Files')}</h3>

                        <div className={styles.legend}>
                            <div className={styles.bigLegend}>{stats!.files}</div>
                        </div>
                    </article>
                </section>
            )}
        </>
    );
}

type LicensesArticleProps = {
    licenses: ClientLicensesStats;
    label: 'STANDARD' | 'BASIC';
};

function LicensesArticle({ licenses, label }: LicensesArticleProps) {
    const { t } = useTranslate();

    return (
        <article className={cn(styles.article, styles.withChart)}>
            <h3>{t('settings.{{type}} licenses', { type: label })}</h3>

            <div className={styles.content}>
                <div className={styles.legend}>
                    <div
                        className={cn(
                            styles.bigLegend,
                            licenses.used > licenses.total && styles.exceeding
                        )}
                    >
                        {t('settings.{{n}} active licenses', {
                            n: licenses.used
                        })}
                    </div>
                    <div className={styles.smallLegend}>
                        {t('settings.out of {{n}}', {
                            n: licenses.total
                        })}
                    </div>
                </div>

                <ClientDashboardChart
                    className={styles.chart}
                    value={licenses.used}
                    valueLabel={t('common.Enabled')}
                    total={licenses.total}
                />
            </div>
        </article>
    );
}
