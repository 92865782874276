const PREVIOUS_PROJECT_KEY = 'previousProject';
const PREVIOUS_PROJECT_FOLDER_KEY = 'previousProjectFolder';

export const storePreviousProjectUuid = (workspaceName: string, projectUuid: string) =>
    window.localStorage.setItem(`${PREVIOUS_PROJECT_KEY}-${workspaceName}`, projectUuid);

export const getPreviousProjectUuid = (workspaceName: string) =>
    window.localStorage.getItem(`${PREVIOUS_PROJECT_KEY}-${workspaceName}`);

export const removePreviousProjectUuid = (workspaceName: string) =>
    window.localStorage.removeItem(`${PREVIOUS_PROJECT_KEY}-${workspaceName}`);

export const storePreviousProjectFolderUuid = (workspaceName: string, projectUuid: string) =>
    window.localStorage.setItem(`${PREVIOUS_PROJECT_FOLDER_KEY}-${workspaceName}`, projectUuid);

export const getPreviousProjectFolderUuid = (workspaceName: string) =>
    window.localStorage.getItem(`${PREVIOUS_PROJECT_FOLDER_KEY}-${workspaceName}`);

export const removePreviousProjectFolderUuid = (workspaceName: string) =>
    window.localStorage.removeItem(`${PREVIOUS_PROJECT_FOLDER_KEY}-${workspaceName}`);
