import { z } from 'zod';
import { ClientUserRole } from './db/enums';
import { clientContactLabelSchema } from './clientLabel.types';

export const workspaceUserSchema = z.object({
    uuid: z.string().uuid(),
    firstname: z.string().nullable(),
    lastname: z.string().nullable(),
    phone: z.string().nullable(),
    firm: z.string().nullable(),
    email: z.string().email(),
    picture: z.string().nullable(),
    created: z.date(),
    updated: z.date().nullable(),
    workspaceRole: z.nativeEnum(ClientUserRole),
    labels: z.array(clientContactLabelSchema)
});

export type WorkspaceUser = z.infer<typeof workspaceUserSchema>;

export const workspaceTeamSchema = z.object({
    uuid: z.string().uuid(),
    name: z.string()
});

export type WorkspaceTeam = z.infer<typeof workspaceTeamSchema>;

export const workspaceTeamWithMembersSchema = workspaceTeamSchema.extend({
    members: z.array(workspaceUserSchema)
});

export type WorkspaceTeamWithMembers = z.infer<typeof workspaceTeamWithMembersSchema>;
