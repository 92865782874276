import { CLIENT } from '@he-novation/config/paths/modals.constants';
import { Client } from '@he-novation/config/types/client.types';
import { getHighlightObject } from '@he-novation/front-shared/utils/propsToHighlightedSearchNode';
import objectSearchFactory from '@he-novation/utils/objectSearchFactory';
import { pick } from 'lodash/fp';

import { useModal } from '$hooks/useModal';
import { useTranslate } from '$hooks/useTranslate';

export function useDataLayoutClientsActionsMenu(updateClient: (client: Client) => void) {
    const { t } = useTranslate();
    const { openModal } = useModal();

    return function itemToActions(item: Client) {
        return [
            {
                children: t('common.Edit'),
                onClick: () => openModal(CLIENT, { data: item, updateClient })
            }
        ];
    };
}

export const searchProperties = ['name', 'created'];
export const filterSearch = objectSearchFactory(searchProperties);

export const highlight = (item: Client, search?: string) =>
    getHighlightObject(pick(searchProperties)(item), search);
