import formStyles from '@he-novation/design-system/styles/form-styles.module.css';
import React, { ReactNode, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
    SETTINGS_ADMIN_GENERAL,
    SETTINGS_ADMIN_TRANSCODERS,
    SETTINGS_ADMIN_WORKSPACES
} from '@he-novation/config/paths/herawFrontUris';
import { asyncResetFileAssetsFromS3Objects } from '@he-novation/front-shared/async/asset.async';
import { SettingsAdminTranscoders } from './SettingsAdminTranscoders/SettingsAdminTranscoders';

import { Header } from '$components/layout/Header/Header';
import { HeaderNav } from '$components/layout/Header/HeaderNavigation/HeaderNavigation';
import { View, ViewContent } from '$components/layout/View/View';
import { set as setRoute } from '$redux/route/routeActions';
import { SettingsAdminGeneral } from '$views/settings/SettingsAdmin/SettingsAdminGeneral';
import { SettingsAdminWorkspaces } from '$views/settings/SettingsAdmin/SettingsAdminWorkspaces';

type SettingsAdminProps = { route: string };

const cleanRoute = (route: string) => route.replace(/\?.*$/g, '');

export function SettingsAdmin({ route }: SettingsAdminProps) {
    const dispatch = useDispatch();

    if (!route) {
        dispatch(setRoute(SETTINGS_ADMIN_GENERAL));
    }

    const links: HeaderNav = [
        {
            matchRoute: (activeRoute) => cleanRoute(activeRoute) === SETTINGS_ADMIN_GENERAL,
            href: SETTINGS_ADMIN_GENERAL,
            icon: 'cog',
            children: 'General'
        },
        {
            matchRoute: (activeRoute) => cleanRoute(activeRoute) === SETTINGS_ADMIN_WORKSPACES,
            href: SETTINGS_ADMIN_WORKSPACES,
            icon: 'users',
            children: 'Workspaces'
        },
        {
            matchRoute: (activeRoute) => cleanRoute(activeRoute) === SETTINGS_ADMIN_TRANSCODERS,
            href: SETTINGS_ADMIN_TRANSCODERS,
            icon: 'cog',
            children: 'Transcoders'
        }
    ];

    const content = getContent(route);

    useEffect(() => {
        //@ts-expect-error global debug helper for admin
        window.asyncResetFileAssetsFromS3Objects = asyncResetFileAssetsFromS3Objects;
    });

    return (
        <View id="view-settings-user">
            <Header nav={links} title={'Administration'} />
            <ViewContent className={formStyles.dark}>{content}</ViewContent>
        </View>
    );
}

function getContent(activeRoute: string = 'general'): ReactNode {
    switch (activeRoute) {
        case 'workspaces':
            return <SettingsAdminWorkspaces />;
        case 'transcoders':
            return <SettingsAdminTranscoders />;
        case 'general':
        default:
            return <SettingsAdminGeneral />;
    }
}
