import styles from './SettingsWorkspaceInterface.module.css';
import React from 'react';
import { featureACL } from '@he-novation/config/utils/acl';
import { useAtomValue } from 'jotai';
import { ClientCastStyleForm } from './components/ClientCastStyleForm';
import { ClientStyleForm } from './components/ClientStyleForm';

import { workspaceFeaturesAtom } from '$atoms/workspace-atoms';
import Accordion from '$components/Accordion/Accordion';
import { SubHeader } from '$components/layout/Header/SubHeader/SubHeader';
import { useTranslate } from '$hooks/useTranslate';

export function SettingsWorkspaceInterface() {
    const { t } = useTranslate();
    const workspaceFeatures = useAtomValue(workspaceFeaturesAtom);

    return (
        <>
            <SubHeader subClassName={'settings-sub-header'} title={t('settings.User interface')} />

            <Accordion
                title={t('misc.Style')}
                icon={'brush'}
                isOpen={true}
                contentClassName={styles.clientStyle}
            >
                <ClientStyleForm />
            </Accordion>
            {featureACL.casts.design(workspaceFeatures) && (
                <Accordion
                    title={t('settings.Default cast design')}
                    icon={'brush'}
                    isOpen={true}
                    contentClassName={styles.castStyle}
                >
                    <ClientCastStyleForm />
                </Accordion>
            )}
        </>
    );
}
