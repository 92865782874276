import styles from './SidePanelSubtitles.module.css';
import formStyles from '@he-novation/design-system/styles/form-styles.module.css';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { VariableSizeList } from 'react-window';
import { DELETE_CONFIRM } from '@he-novation/config/paths/modals.constants';
import { SubtitleEntryBody } from '@he-novation/config/types/payloads/subtitle.payload';
import {
    MappedSubtitle,
    MappedSubtitleEntry,
    SubtitleEntry,
    SubtitleWithLink
} from '@he-novation/config/types/subtitle.types';
import { Button } from '@he-novation/design-system/components/buttons/Button/Button';
import { Icon } from '@he-novation/design-system/components/graphics/Icon/Icon';
import {
    subtitleEntryCreate,
    subtitleEntryDelete,
    subtitleEntryUpdate
} from '@he-novation/front-shared/async/subtitle.async';
import { timeCodeToSeconds } from '@he-novation/lib-timecodes';
import cn from 'classnames';
import update from 'immutability-helper';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';

import {
    activeSubtitlesAtom,
    subtitlesAtom,
    subtitlesTimeStampAtom
} from '$atoms/file-atoms/subtitle-atoms';
import { videoAtom } from '$atoms/file-atoms/video-atom';
import { workspaceNameAtom } from '$atoms/workspace-atoms';
import {
    FormSubtitle,
    FormSubtitleOutput
} from '$components/SidePanel/SidePanelFile/SidePanelSubtitles/components/FormSubtitle';
import { SidePanelSubtitlesHeader } from '$components/SidePanel/SidePanelFile/SidePanelSubtitles/SidePanelSubtitlesHeader';
import {
    SidePanelSubtitlesRow,
    SubtitleRow
} from '$components/SidePanel/SidePanelFile/SidePanelSubtitles/SidePanelSubtitlesRow';
import { useModal } from '$hooks/useModal';
import { useTranslate } from '$hooks/useTranslate';
import { useVideoControls } from '$hooks/useVideoControls';
import { fileNameUuidAndVersionSelector } from '$redux/content/file/fileSelectors';

const SUBTITLE_ROW_LAYOUT = {
    edition: {
        headerHeight: 32,
        buttonsHeight: 32
    },
    gap: 8,
    headerHeight: 32,
    lineHeight: 24,
    padding: {
        top: 16,
        bottom: 32
    },
    width: 360
};

export function SidePanelSubtitles() {
    const { openModal, closeModal } = useModal();

    const [subtitles, setSubtitles] = useAtom(subtitlesAtom);
    const activeSubtitles = useAtomValue(activeSubtitlesAtom);
    const updateSubtitlesTimeStamp = useSetAtom(subtitlesTimeStampAtom);

    const { fileUuid, fileVersion } = useSelector(fileNameUuidAndVersionSelector);

    const videoState = useAtomValue(videoAtom);

    const [selectedSubtitle, setSelectedSubtitle] = useState<MappedSubtitle>();
    const [editing, setEditing] = useState<string[]>([]);
    const [lastActiveEntryUuid, setLastActiveEntryUuid] = useState<string>();
    const [subtitleEntriesInPlayer, setSubtitleEntriesInPlayer] = useState<string[]>([]);
    const [formToggled, setFormToggled] = useState(false);

    const listRef = useRef<VariableSizeList<SubtitleRow>>(null);

    const { setCurrentTime } = useVideoControls();

    useEffect(() => {
        let active: SubtitleWithLink | undefined;
        for (const activeSubtitleUuid of activeSubtitles) {
            active = subtitles.find(
                (s) => s.uuid === activeSubtitleUuid && Array.isArray(s.entries)
            );
            if (active) break;
        }
        setSelectedSubtitle(
            active ? mapSubs(active, videoState.frameRate, videoState.secondsOffset) : undefined
        );
        setEditing([]);
    }, [activeSubtitles, subtitles]);

    useEffect(() => {
        setSubtitleEntriesInPlayer(
            selectedSubtitle
                ? getSubtitlesEntryUuidsInPlayer(selectedSubtitle, videoState.currentTime)
                : []
        );
    }, [videoState.currentTime, selectedSubtitle, videoState.secondsOffset]);

    useEffect(() => {
        if (subtitleEntriesInPlayer?.[0] && !editing?.length) {
            setLastActiveEntryUuid(subtitleEntriesInPlayer[0]);
        }
    }, [subtitleEntriesInPlayer]);

    useEffect(() => {
        if (selectedSubtitle && lastActiveEntryUuid) {
            scrollTo(selectedSubtitle.entries.findIndex((e) => e.uuid === lastActiveEntryUuid));
        }
    }, [editing, selectedSubtitle, lastActiveEntryUuid]);

    const scrollTo = useCallback((index: number) => {
        if (listRef.current) listRef.current.scrollToItem(index, 'center');
    }, []);

    const updateSelectedSubtitle = (updated: MappedSubtitle) => {
        updated.entries = updated.entries.sort((a, b) => a.timeIn - b.timeIn);
        setSelectedSubtitle(updated);
        setSubtitles((subtitles) => subtitles.map((s) => (s.uuid === updated.uuid ? updated : s)));
        updateSubtitlesTimeStamp();
    };

    const appHeight = document.getElementById('main')?.offsetHeight || 0;
    const tabsHeaderHeight = document.getElementById('tabs-header')?.offsetHeight || 0;
    const subtitlesHeader =
        document.getElementById('side-panel-subtitles-header')?.offsetHeight || 0;

    const { t } = useTranslate();

    const workspaceName = useAtomValue(workspaceNameAtom);

    return (
        <div className={styles.subtitlesSidePanel}>
            <SidePanelSubtitlesHeader
                fileUuid={fileUuid}
                fileVersion={fileVersion}
                activeSubtitle={selectedSubtitle}
                subtitles={subtitles}
                frameRate={videoState.frameRate}
                className={styles.header}
            />

            {selectedSubtitle && (
                <>
                    <VariableSizeList
                        ref={listRef}
                        key={
                            'side-panel-subtitles-' +
                            selectedSubtitle.uuid +
                            '-' +
                            editing.join('-')
                        }
                        className={styles.list}
                        height={appHeight - tabsHeaderHeight - subtitlesHeader - 48}
                        width={SUBTITLE_ROW_LAYOUT.width}
                        itemCount={selectedSubtitle.entries.length}
                        estimatedItemSize={
                            SUBTITLE_ROW_LAYOUT.padding.top +
                            SUBTITLE_ROW_LAYOUT.headerHeight +
                            SUBTITLE_ROW_LAYOUT.gap +
                            SUBTITLE_ROW_LAYOUT.lineHeight +
                            SUBTITLE_ROW_LAYOUT.padding.bottom
                        }
                        itemSize={(index: number) => {
                            if (!selectedSubtitle.entries[index]) return 0;

                            const numberOfLines =
                                selectedSubtitle.entries[index].content.split('<br/>').length;

                            let itemHeight =
                                SUBTITLE_ROW_LAYOUT.padding.top +
                                SUBTITLE_ROW_LAYOUT.headerHeight +
                                SUBTITLE_ROW_LAYOUT.gap;

                            if (editing.includes(selectedSubtitle.entries[index].uuid)) {
                                itemHeight +=
                                    SUBTITLE_ROW_LAYOUT.gap +
                                    SUBTITLE_ROW_LAYOUT.edition.headerHeight +
                                    SUBTITLE_ROW_LAYOUT.gap +
                                    SUBTITLE_ROW_LAYOUT.edition.buttonsHeight +
                                    SUBTITLE_ROW_LAYOUT.gap;
                            }

                            itemHeight +=
                                SUBTITLE_ROW_LAYOUT.lineHeight * numberOfLines +
                                SUBTITLE_ROW_LAYOUT.padding.bottom;

                            return itemHeight;
                        }}
                        itemKey={(index) => selectedSubtitle.entries[index].uuid}
                        itemData={{
                            subtitleEntries:
                                selectedSubtitle?.entries.sort((a, b) => a.timeIn - b.timeIn) || [],
                            subtitleEntriesInPlayer,
                            frameRate: videoState.frameRate,
                            editing: editing,
                            onClickDelete: (entryUuid: string) => {
                                openModal(DELETE_CONFIRM, {
                                    onDelete: async () => {
                                        await subtitleEntryDelete(
                                            workspaceName,
                                            selectedSubtitle.uuid,
                                            entryUuid
                                        );
                                        closeModal();

                                        const updated = update(selectedSubtitle, {
                                            entries: {
                                                $splice: [
                                                    [
                                                        selectedSubtitle.entries.findIndex(
                                                            (e) => e.uuid === entryUuid
                                                        ),
                                                        1
                                                    ]
                                                ]
                                            }
                                        });

                                        updateSelectedSubtitle(updated);
                                    }
                                });
                            },
                            onClickEdit: (entryUuid: string) => {
                                if (editing.includes(entryUuid)) {
                                    const newEditing = editing.filter((e) => e !== entryUuid);
                                    setEditing(newEditing);

                                    if (newEditing.length > 0) {
                                        setLastActiveEntryUuid(newEditing[newEditing.length - 1]);
                                    } else {
                                        setLastActiveEntryUuid(entryUuid);
                                    }
                                } else {
                                    setEditing([...editing, entryUuid]);
                                    setLastActiveEntryUuid(entryUuid);
                                }
                            },
                            saveChanges: async (data: FormSubtitleOutput) => {
                                const originalEntry = selectedSubtitle.entries.find(
                                    (e) => e.uuid === data.uuid
                                )!;

                                const editedEntry = mapCreatedSubtitleEntry(
                                    { ...data, uuid: data.uuid! },
                                    videoState.frameRate
                                );

                                const _update = {
                                    content: editedEntry.content,
                                    timecodeIn: editedEntry.timecodeIn,
                                    timecodeOut: editedEntry.timecodeOut,
                                    metadata: {
                                        ...originalEntry.metadata,
                                        ...editedEntry.metadata
                                    }
                                };

                                await subtitleEntryUpdate(
                                    workspaceName,
                                    selectedSubtitle.uuid,
                                    editedEntry.uuid,
                                    _update
                                );

                                const updated = update(selectedSubtitle, {
                                    entries: {
                                        [selectedSubtitle.entries.findIndex(
                                            (e) => e.uuid === editedEntry.uuid
                                        )]: { $merge: _update }
                                    }
                                });
                                updateSelectedSubtitle(updated);

                                setEditing(editing.filter((uuid) => uuid !== editedEntry.uuid));
                            },
                            goToTime: (time: number) => {
                                setCurrentTime(time);
                            }
                        }}
                    >
                        {SidePanelSubtitlesRow}
                    </VariableSizeList>

                    {!formToggled && (
                        <Button
                            className={styles.addButton}
                            onClick={() => setFormToggled(!formToggled)}
                        >
                            {t('subtitles.Add new subtitle entry')}
                        </Button>
                    )}

                    {formToggled && (
                        <FormSubtitle
                            formId={'new-subtitle-form'}
                            visualIdentifier={<Icon icon={'subtitles'} />}
                            onSubmit={async (e, data) => {
                                const { uuid: createdSubtitleEntryUuid } =
                                    await subtitleEntryCreate(
                                        workspaceName,
                                        selectedSubtitle.uuid,
                                        data as SubtitleEntryBody
                                    );

                                const updated = update(selectedSubtitle, {
                                    entries: {
                                        $push: [
                                            mapCreatedSubtitleEntry(
                                                { ...data, uuid: createdSubtitleEntryUuid },
                                                videoState.frameRate
                                            )
                                        ]
                                    }
                                });
                                updateSelectedSubtitle(updated);

                                const newIndex = updated.entries.findIndex(
                                    (e) => e.uuid === createdSubtitleEntryUuid
                                );
                                setTimeout(() => {
                                    setFormToggled(!formToggled);
                                    scrollTo(newIndex);
                                }, 100);
                            }}
                            onCancel={() => setFormToggled(!formToggled)}
                            className={cn(styles.addForm, formStyles.light)}
                        />
                    )}
                </>
            )}
        </div>
    );
}

function mapSubs(
    subtitle: SubtitleWithLink,
    frameRate: number,
    secondsOffset?: number
): MappedSubtitle {
    return {
        ...subtitle,
        entries: subtitle.entries.map((e) => mapEntry(e, frameRate, secondsOffset))
    };
}

function mapEntry(e: SubtitleEntry, frameRate: number, secondsOffset = 0): MappedSubtitleEntry {
    return {
        ...e,
        timeIn: timeCodeToSeconds(e.timecodeIn, frameRate) - secondsOffset,
        timeOut: timeCodeToSeconds(e.timecodeOut, frameRate) - secondsOffset
    };
}

function mapCreatedSubtitleEntry(
    data: Required<FormSubtitleOutput>,
    frameRate: number
): MappedSubtitleEntry {
    const timeIn = timeCodeToSeconds(data.timecodeIn, frameRate);
    const timeOut = timeCodeToSeconds(data.timecodeOut, frameRate);

    return {
        ...data,
        start: timeIn,
        end: timeOut,
        timeIn,
        timeOut
    };
}

function getSubtitlesEntryUuidsInPlayer(
    activeSubtitle: MappedSubtitle,
    currentTime: number
): string[] {
    return activeSubtitle
        ? activeSubtitle.entries
              .filter((e) => e.timeIn <= currentTime && e.timeOut >= currentTime)
              .map(({ uuid }) => uuid)
        : [];
}
