import styles from './LineBreakString.module.css';
import { parseContentLinks } from '@he-novation/utils/string.utils';
import cn from 'classnames';

interface Props {
    string: string;
    className?: string;
    parseLinks?: boolean;
}

export default function LineBreakString({ string, className, parseLinks }: Props) {
    return (
        <>
            {string.split(/\r?\n/g).map((el, i) => (
                <p
                    key={i}
                    className={cn(className, styles.p)}
                    dangerouslySetInnerHTML={{ __html: parseLinks ? parseContentLinks(el) : el }}
                />
            ))}
        </>
    );
}
