import React from 'react';
import { DataLayoutDisplayMode } from '@he-novation/config/types/dataLayout.types';
import { ButtonTone } from '@he-novation/design-system/components/buttons/Button/Button';
import { MultiActionButton } from '@he-novation/design-system/components/buttons/MultiActionButton/MultiActionButton';
import type { IconProp } from '@he-novation/design-system/components/graphics/Icon/Icon';
import { zIndexMenu } from '@he-novation/design-system/constants/constants.style';
import { DirectionX, DirectionY } from '@he-novation/design-system/utils/getAbsolutePosition';

import { useTranslate } from '$hooks/useTranslate';

export type DataLayoutDisplayModeButtonProps = {
    displayMode: DataLayoutDisplayMode;
    iconStroke?: string;
    setDisplayMode: (displayMode: DataLayoutDisplayMode) => void;
    icons?: Record<DataLayoutDisplayMode, IconProp>;
};

export function DataLayoutDisplayModeButton({
    displayMode,
    iconStroke = 'white',
    setDisplayMode,
    icons
}: DataLayoutDisplayModeButtonProps) {
    const { t } = useTranslate();

    return (
        <MultiActionButton
            icon={
                icons?.[displayMode] || {
                    name: displayMode === DataLayoutDisplayMode.List ? 'list' : 'squares',
                    stroke: iconStroke
                }
            }
            tone={ButtonTone.Hoverable}
            direction={[DirectionX.Center, DirectionY.Bottom]}
            actions={[
                {
                    children: t('common.List view'),
                    icon: icons?.[DataLayoutDisplayMode.List] || 'list',
                    onClick: () => setDisplayMode(DataLayoutDisplayMode.List)
                },
                {
                    children: t('common.Grid view'),
                    icon: icons?.[DataLayoutDisplayMode.Grid] || 'squares',
                    onClick: () => setDisplayMode(DataLayoutDisplayMode.Grid)
                }
            ]}
            contentZIndex={zIndexMenu}
        />
    );
}
