import styles from './FormCastDesign.module.css';
import { useEffect, useState } from 'react';
import { Cast, DefaultCastStyle } from '@he-novation/config/types/cast.types';
import { UserFile, UserFileType } from '@he-novation/config/types/user-file.types';
import { GraphicItem } from '@he-novation/design-system/components/form/FormField/components/GraphicPicker/GraphicPicker';
import { FormField } from '@he-novation/design-system/components/form/FormField/FormField';
import {
    asyncWorkspaceUserFileCreate,
    asyncWorkspaceUserFileDelete,
    asyncWorkspaceUserFilesFetch
} from '@he-novation/front-shared/async/workspaceUserFile.async';
import cn from 'classnames';
import update from 'immutability-helper';
import { useAtomValue } from 'jotai';
import { PreviewData, SetPreviewData } from './useFormCast';

import { workspaceNameAtom } from '$atoms/workspace-atoms';
import { CastPreview } from '$components/form/FormCast/CastPreview/CastPreview';
import { useTranslate } from '$hooks/useTranslate';

type CastUserFile = Omit<UserFile, 'isPrivate'> | undefined | null;

type FormCastDesignProps = {
    previewData: PreviewData;
    setPreviewData: SetPreviewData;
    cast?: Partial<Cast>;
    defaultCastStyle?: DefaultCastStyle;
    setLogoUserFile: (value: CastUserFile | null) => void;
    setUserFile: (value: CastUserFile | undefined | null) => void;
    setBackgroundColor: (value: string | null) => void;
    projectUuid?: string;
    className?: string;
};

export function FormCastDesign({
    previewData,
    setPreviewData,
    defaultCastStyle,
    cast,
    setLogoUserFile,
    setUserFile,
    setBackgroundColor,
    projectUuid,
    className
}: FormCastDesignProps) {
    const [logoUserFiles, setLogoUserFiles] = useState<UserFile[]>([]);
    const [backgroundUserFiles, setBackgroundUserFiles] = useState<UserFile[]>([]);

    const [defaultLogoUserFile, setDefaultLogoUserFile] = useState<UserFile | undefined>();
    const [defaultBackgroundUserFile, setDefaultBackgroundUserFile] = useState<
        UserFile | undefined
    >();

    const workspaceName = useAtomValue(workspaceNameAtom);

    useEffect(() => {
        asyncWorkspaceUserFilesFetch(workspaceName, UserFileType.LOGO).then((logoUserFiles) => {
            setLogoUserFiles(logoUserFiles);
        });

        asyncWorkspaceUserFilesFetch(workspaceName, UserFileType.BACKGROUND).then(
            (backgroundUserFiles) => {
                setBackgroundUserFiles(backgroundUserFiles);
            }
        );
    }, []);

    useEffect(() => {
        let defaultLogoFileUid: string | undefined;
        if (!cast && defaultCastStyle && defaultCastStyle.logoUserFileUid) {
            defaultLogoFileUid = defaultCastStyle.logoUserFileUid;
        } else if (cast?.logoUserFile) {
            defaultLogoFileUid = cast!.logoUserFile!.uid;
        }

        if (defaultLogoFileUid) {
            const defaultCastStyleLogo = logoUserFiles.find((f) => f.uid === defaultLogoFileUid);
            if (!defaultLogoUserFile) setLogoUserFile(defaultCastStyleLogo);
            setDefaultLogoUserFile(defaultCastStyleLogo);
        }

        let defaultBackgroundFileUid: string | undefined;
        if (!cast && defaultCastStyle && defaultCastStyle.userFileUid) {
            defaultBackgroundFileUid = defaultCastStyle.userFileUid;
        } else if (cast?.bgUserFile) {
            defaultBackgroundFileUid = cast!.bgUserFile!.uid;
        }

        if (defaultBackgroundFileUid) {
            const defaultCastStyleBackground = backgroundUserFiles.find(
                (f) => f.uid === defaultBackgroundFileUid
            );
            if (!defaultBackgroundUserFile) setUserFile(defaultCastStyleBackground);
            setDefaultBackgroundUserFile(defaultCastStyleBackground);
        }

        if (defaultCastStyle?.backgroundColor) {
            setBackgroundColor(defaultCastStyle.backgroundColor);
        }
    }, [defaultCastStyle, logoUserFiles, backgroundUserFiles]);

    const { t } = useTranslate();

    return (
        <div className={cn(styles.wrapper, className)}>
            <div className={styles.content}>
                <CastPreview
                    className={styles.preview}
                    name={previewData.name}
                    backgroundColor={previewData.backgroundColor}
                    bgUserFile={previewData.bgUserFile}
                    logoUserFile={previewData.logoUserFile}
                />

                <div className={styles.form}>
                    <FormField
                        className={styles.logo}
                        name="logoImage"
                        formId="form-cast"
                        label={t('misc.Logo')}
                        type={'graphic-picker'}
                        value={defaultLogoUserFile}
                        values={logoUserFiles}
                        onAdd={(file: File) => {
                            asyncWorkspaceUserFileCreate(
                                workspaceName,
                                UserFileType.LOGO,
                                file
                            ).then((userFile) => {
                                setLogoUserFiles([...logoUserFiles, userFile]);
                            });
                        }}
                        onChange={(item: GraphicItem | null) => {
                            const userFile = item && logoUserFiles.find((f) => f.uid == item.uid);

                            setLogoUserFile(userFile);

                            const updateParams = userFile
                                ? {
                                      logoUserFile: { $set: userFile }
                                  }
                                : {
                                      $unset: ['logoUserFile']
                                  };

                            setPreviewData(update(previewData, updateParams));
                        }}
                        onRemove={({ uid }: GraphicItem) => {
                            asyncWorkspaceUserFileDelete(workspaceName, uid).then(() => {
                                setLogoUserFiles(logoUserFiles.filter((f) => f.uid !== uid));
                            });
                        }}
                    />

                    <FormField
                        className={styles.background}
                        name="backgroundImage"
                        formId="form-cast"
                        label={t('misc.Background')}
                        type={'graphic-picker'}
                        value={defaultBackgroundUserFile}
                        values={backgroundUserFiles}
                        onAdd={(file: File) => {
                            asyncWorkspaceUserFileCreate(
                                workspaceName,
                                UserFileType.BACKGROUND,
                                file
                            ).then((userFile) => {
                                setBackgroundUserFiles([...backgroundUserFiles, userFile]);
                            });
                        }}
                        onChange={(item: GraphicItem | null) => {
                            const userFile =
                                item && backgroundUserFiles.find((f) => f.uid == item.uid);

                            setUserFile(userFile);

                            const updateParams = userFile
                                ? {
                                      bgUserFile: { $set: userFile }
                                  }
                                : {
                                      $unset: ['bgUserFile']
                                  };

                            setPreviewData(update(previewData, updateParams));
                        }}
                        onRemove={({ uid }: GraphicItem) => {
                            asyncWorkspaceUserFileDelete(workspaceName, uid).then(() => {
                                setBackgroundUserFiles(
                                    backgroundUserFiles.filter((f) => f.uid !== uid)
                                );
                            });
                        }}
                        noTopMargin={true}
                    />

                    <FormField
                        formId="form-cast"
                        name="backgroundColor"
                        label={t('common.Color')}
                        type="color-picker"
                        value={previewData.backgroundColor}
                        onChange={(e) => {
                            const value = e.target.value;
                            setBackgroundColor(value);
                        }}
                        noTopMargin={true}
                    />
                </div>
            </div>
            {projectUuid && (
                <div>
                    <FormField
                        type={'checkbox'}
                        className={styles.save}
                        id={'project-default-cast-design'}
                        name={'saveDefaultCastDesign'}
                        formId="form-cast"
                        label={t('common.Set as default design')}
                    />
                </div>
            )}
        </div>
    );
}
