import styles from './MainMenuAddButton.module.css';
import React from 'react';
import { EVENT, ITEM, PROJECT_FORM, TASK_FORM } from '@he-novation/config/paths/modals.constants';
import { ClientUserRole } from '@he-novation/config/types/db/enums';
import { WorkspaceFeature } from '@he-novation/config/types/workspace.types';
import { featureACL } from '@he-novation/config/utils/acl';
import {
    ActionType,
    MultiActionButton
} from '@he-novation/design-system/components/buttons/MultiActionButton/MultiActionButton';
import { colorBgLight, zIndexMenu } from '@he-novation/design-system/constants/constants.style';
import { DirectionX, DirectionY } from '@he-novation/design-system/utils/getAbsolutePosition';
import { useAtomValue } from 'jotai';

import { workspaceRightsAtom, workspaceRoleAtom } from '$atoms/workspace-atoms';
import { useModal } from '$hooks/useModal';
import { useTranslate } from '$hooks/useTranslate';

export function MainMenuAddButton({ features }: { features: WorkspaceFeature[] }) {
    const { t } = useTranslate();

    const { openModal } = useModal();

    const workspaceRights = useAtomValue(workspaceRightsAtom);
    const workspaceRole = useAtomValue(workspaceRoleAtom);

    const actions: ActionType[] = [];
    if (workspaceRole === ClientUserRole.BASIC) return null;

    if (featureACL.calendar(features)) {
        actions.push({
            icon: ['calendar', 'plus'],
            children: t('common.New booking'),
            onClick: () => openModal(EVENT)
        });
    }

    if (featureACL.tasks(features)) {
        actions.push({
            icon: ['check_encircled', 'plus'],
            children: t('common.New task'),
            onClick: () => openModal(TASK_FORM)
        });
    }
    if (featureACL.items(features)) {
        actions.push({
            icon: ['shapes', 'plus'],
            children: t('common.New item'),
            onClick: () => openModal(ITEM)
        });
    }

    if (featureACL.projects(features) && workspaceRights.projectAdd) {
        actions.unshift({
            icon: ['briefcase', 'plus'],
            children: t('common.New project'),
            onClick: () => openModal(PROJECT_FORM)
        });
    }

    return (
        <MultiActionButton
            icon={[{ name: 'plus', stroke: colorBgLight }]}
            className={styles.button}
            direction={[DirectionX.Right, DirectionY.BottomInner]}
            actions={actions}
            contentZIndex={zIndexMenu}
        ></MultiActionButton>
    );
}
