import styles from './SettingsPlugins.module.css';
import React, { useEffect } from 'react';
import { INTEGRATIONS } from '@he-novation/config/constants/integration.constants';
import { useUserIntegrations } from '../../../../hooks/useUserIntegrations';

import Accordion from '$components/Accordion/Accordion';
import { SubHeader } from '$components/layout/Header/SubHeader/SubHeader';
import { useTranslate } from '$hooks/useTranslate';
import { SettingsPluginsPlugin } from '$views/settings/components/SettingsPlugins/SettingsPluginsPlugin';

export function SettingsPlugins() {
    const { userIntegrations, fetchIntegrations } = useUserIntegrations();

    useEffect(() => {
        fetchIntegrations();
    }, []);

    const configurable = INTEGRATIONS.filter((p) => p.configurable);
    const notConfigurable = INTEGRATIONS.filter((p) => !p.configurable);

    const { t } = useTranslate();
    return (
        <div id="settings-plugins">
            <SubHeader subClassName={'settings-sub-header'} title={t('settings.Integrations')} />

            <Accordion title={t('settings.Integrations')} isOpen={true}>
                <ul className={styles.plugins}>
                    {configurable.map((p) => {
                        const configurations = userIntegrations.filter(
                            ({ type }) => type === p.type
                        );
                        return (
                            <SettingsPluginsPlugin
                                key={p.type}
                                {...p}
                                configurations={configurations}
                            />
                        );
                    })}
                </ul>
            </Accordion>

            <Accordion title={t('settings.Features')} isOpen={true}>
                <ul className={styles.plugins}>
                    {notConfigurable.map((p) => {
                        const configurations = userIntegrations.filter(
                            ({ type }) => type === p.type
                        );
                        return (
                            <SettingsPluginsPlugin
                                key={p.type}
                                {...p}
                                configurations={configurations}
                            />
                        );
                    })}
                </ul>
            </Accordion>
        </div>
    );
}
