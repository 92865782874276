import type { BaseFolderType, FolderRole } from '../../folder.types';
import type { BaseUserType } from '../../user.types';
import type { BaseLogPayload } from './log.payload';

export interface BaseFolderLogPayload extends Omit<BaseLogPayload, 'owner'> {
    folder: BaseFolderType;
    parentFolder?: BaseFolderType;
}
export enum FolderLogKey {
    FolderAccess = 'folder_access',
    FolderAccessGrant = 'access_grant',
    FolderAccessRequest = 'access_request',
    FolderAccessRevoke = 'access_revoke',
    FolderAdd = 'folder_add',
    FolderCopyTree = 'folder_copy_tree',
    FolderDelete = 'folder_delete',
    FolderDownload = 'folder_download',
    FolderRestore = 'folder_restore',
    FolderSetRecursive = 'folder_set_recursive',
    FolderUpdate = 'folder_update'
}
export interface LogFolderAccessPayload extends BaseFolderLogPayload {
    key: FolderLogKey.FolderAccess;
    hidden: boolean;
}
export interface LogFolderAccessGrantPayload extends BaseFolderLogPayload {
    key: FolderLogKey.FolderAccessGrant;
    projectRootFolderUuid?: string;
    invitedUsers: (BaseUserType & { role: FolderRole })[];
}
export interface LogFolderAccessRequestPayload extends BaseFolderLogPayload {
    key: FolderLogKey.FolderAccessRequest;
    projectRootFolderUuid?: string;
    invitedUser: BaseUserType & { role: FolderRole };
}
export interface LogFolderAccessRevokePayload extends BaseFolderLogPayload {
    key: FolderLogKey.FolderAccessRevoke;
    targetUser: BaseUserType;
}
export interface LogFolderAddPayload extends BaseFolderLogPayload {
    key: FolderLogKey.FolderAdd;
}
export interface LogFolderCopyTreePayload extends BaseFolderLogPayload {
    key: FolderLogKey.FolderCopyTree;
    sourceParentFolder: BaseFolderType;
    sourceUser: BaseUserType;
}
export interface LogFolderDeletePayload extends BaseFolderLogPayload {
    key: FolderLogKey.FolderDelete;
}
export interface LogFolderDownloadPayload extends Omit<BaseFolderLogPayload, 'requestUser'> {
    key: FolderLogKey.FolderDownload;
    requestUser?: BaseUserType | null;
}

export interface LogFolderRestorePayload extends BaseFolderLogPayload {
    key: FolderLogKey.FolderRestore;
}
export interface LogFolderSetRecursivePayload extends BaseFolderLogPayload {
    key: FolderLogKey.FolderSetRecursive;
}

export interface FolderChanges {
    admittance?: [boolean, boolean];
    canExport?: [boolean, boolean];
    defaultPresets?: [boolean, boolean];
    encrypted?: [boolean, boolean];
    isPublic?: [boolean, boolean];
    name?: [string, string];
    publicDownload?: [boolean, boolean];
    publicPassword?: [string | null | undefined, string | null | undefined];
    triggers?: [string[], string[]];
    watermark?: [boolean, boolean];
    parentFolder?: [BaseFolderType, BaseFolderType];
}
export interface LogFolderUpdatePayload extends BaseFolderLogPayload {
    key: FolderLogKey.FolderUpdate;
    changes: FolderChanges;
}

export type LogFolderPayload =
    | LogFolderAccessPayload
    | LogFolderAccessGrantPayload
    | LogFolderAccessRequestPayload
    | LogFolderAccessRevokePayload
    | LogFolderAddPayload
    | LogFolderCopyTreePayload
    | LogFolderDeletePayload
    | LogFolderDownloadPayload
    | LogFolderRestorePayload
    | LogFolderSetRecursivePayload
    | LogFolderUpdatePayload;

export type FolderLogKeyToPayload<T extends FolderLogKey> = Extract<LogFolderPayload, { key: T }>;

export type FolderLogExtra<T extends FolderLogKey> = Omit<
    FolderLogKeyToPayload<T>,
    keyof BaseFolderLogPayload | 'key'
>;
