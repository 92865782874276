import { z } from 'zod';

export const authLoginPayload = z.object({
    email: z.string().email(),
    password: z.string(),
    totp: z.string().optional(),
    verificationToken: z.string().optional()
});

export type AuthLoginPayload = z.infer<typeof authLoginPayload>;

export const authClientLoginPayload = z.object({
    clientName: z.string(),
    password: z.string(),
    totp: z.string().optional()
});

export type AuthClientLoginPayload = z.infer<typeof authClientLoginPayload>;
export const authPasswordUpdatePayload = z.object({
    oldPassword: z.string(),
    newPassword: z.string()
});

export type AuthPasswordUpdatePayload = z.infer<typeof authPasswordUpdatePayload>;

export const authPasswordResetPayload = z.object({
    email: z.string(),
    resetToken: z.string(),
    newPassword: z.string()
});

export type AuthPasswordResetPayload = z.infer<typeof authPasswordResetPayload>;

export const auth2FAUpdatePayload = z.object({
    totpEnabled: z.boolean(),
    totp: z.string().optional(),
    password: z.string()
});

export type Auth2FAUpdatePayload = z.infer<typeof auth2FAUpdatePayload>;

export const authPasswordContraintsPayload = z.object({
    email: z.string(),
    verificationToken: z.string()
});
