import { atom } from 'jotai';

export const selectedFolderChildrenAtom = atom<string[]>([]);
export const selectedCastsAtom = atom<string[]>([]);
export const selectedCustomFieldsAtom = atom<string[]>([]);
export const selectedEntitiesAtom = atom<string[]>([]);
export const selectedContactsAtom = atom<string[]>([]);
export const selectedWorkspaceMembersAtom = atom<string[]>([]);

export const selectedItemsAtom = atom<string[]>([]);
export const selectedProjectsAtom = atom<string[]>([]);

export const draggingOverAtom = atom<string | null>(null);

export const selectedCastChildrenAtom = atom<string[]>([]);
