import { z } from 'zod';

export const subscriptionCustomerPayloadSchema = z.object({
    email: z.string().email(),
    firstname: z.string(),
    lastname: z.string(),
    address: z.object({
        city: z.string(),
        country: z.string(),
        zipcode: z.string(),
        street1: z.string(),
        state: z.string().optional()
    }),
    company: z
        .object({
            name: z.string(),
            vatNumber: z.string()
        })
        .optional()
        .nullable()
});

export type SubscriptionCustomerPayload = z.infer<typeof subscriptionCustomerPayloadSchema>;

export const subscriptionPayloadSchema = z.object({
    priceId: z.string(),
    licenses: z.number().max(15)
});

export type SubscriptionPayload = z.infer<typeof subscriptionPayloadSchema>;
