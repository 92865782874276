import styles from './ModalContent.module.css';
import React, { HTMLProps } from 'react';
import cn from 'classnames';

type ModalContentProps = {
    children: React.ReactNode | React.ReactNode[];
} & HTMLProps<HTMLDivElement>;
export const ModalContent = React.forwardRef(function ModalContent(
    { children, className, ...props }: ModalContentProps,
    ref?: React.Ref<HTMLElement>
) {
    return (
        <section ref={ref} className={cn('modal-content', styles.content, className)} {...props}>
            {children}
        </section>
    );
});
