import { LanguageAndCountryCode } from '@he-novation/config/types/locale.types';

export type I18nState = {
    languagesAndCountryCodes: LanguageAndCountryCode[];
};
export enum I18nAction {
    LanguagesAndCountryCodesFetch = `i18n/LanguagesAndCountryCodesFetch`,
    LanguagesAndCountryCodesFetchSuccess = `i18n/LanguagesAndCountryCodesFetch/success`
}

export type LanguagesAndCountryCodesFetchSuccess = {
    type: I18nAction.LanguagesAndCountryCodesFetchSuccess;
    languagesAndCountryCodes: LanguageAndCountryCode[];
};

export type I18nActionResult = LanguagesAndCountryCodesFetchSuccess;
