import { z } from 'zod';
import { ClientStatus, ClientTier, ClientUserRole } from './db/enums';
import { UserNotificationSettingsSchema } from './payloads/user.payload';
import {
    passwordConstraintsSchema,
    UserWorkspaceRights,
    userWorkspaceRightsSchema
} from './payloads/workspace.payload';
import { CalendarZoom } from './calendar.types';
import { defaultCastStyleSchema } from './cast.types';
import { ClientPreferences } from './client.types';
import { dataLayoutPrefsSchema } from './dataLayout.types';
import { Locale, LocaleList } from './i18n.types';
import { WorkspaceFeature } from './workspace.types';

export const legacyProfileSchema = z.object({
    firstname: z.string().nullable().optional(),
    lastname: z.string().nullable().optional(),
    role: z.string().nullable().optional(),
    phone: z.string().nullable().optional(),
    firm: z.string().nullable().optional(),
    city: z.string().nullable().optional(),
    country: z.string().nullable().optional()
});

export type LegacyProfile = z.infer<typeof legacyProfileSchema>;

export type User = {
    uuid: string;
    email: string;
    passwordReset: Date | null;
    created: Date;
    expires: Date | null;
    deleteRequested: Date | null;
    client: { name: string; uuid: string };
    locale: 'fr' | 'en' | 'de';
    hasAccount: boolean;
} & LegacyProfile;

export type UserSessionWorkspace = {
    id: number;
    uuid: string;
    name: string;
    rights: UserWorkspaceRights;
    role: ClientUserRole;
    preferences: ClientPreferences | null;
    strictOIDCEnabled: boolean;
    forceTotp: boolean;
    legacy?: boolean;
    lastUsed?: Date;
    features: WorkspaceFeature[];
};

export type UserSession<WorkspaceRequest = false> = LegacyProfile & {
    uuid: string;
    email: string;
    locale: Locale;
    totpEnabled: boolean;
    client?: {
        uuid: string;
        name: string;
        apiKey: string;
        rights: UserWorkspaceRights;
        role: ClientUserRole;
        preferences: ClientPreferences | null;
        strictOIDCEnabled: boolean;
        forceTotp: boolean;
        legacy?: boolean;
    };
    workspaces: UserSessionWorkspace[];
    workspace: WorkspaceRequest extends true ? UserSessionWorkspace : never;
    isSuperAdmin?: boolean;
    //@todo mark as required when deployed for some time
    connection?:
        | {
              type: 'password';
          }
        | { type: 'oidc'; client: string };
};

export type BaseUserType = LegacyProfile & {
    uuid: string;
    email: string;
    locale: 'fr' | 'en' | 'de';
};

export const userTypeSchema = legacyProfileSchema.extend({
    uuid: z.string().uuid(),
    email: z.string(),
    locale: z.enum(LocaleList)
});

export type UserType = z.infer<typeof userTypeSchema>;

export const contentUserSchema = z.object({
    uuid: z.string(),
    email: z.string(),
    firstname: z.string().nullable().optional(),
    lastname: z.string().nullable().optional(),
    role: z.string().nullable().optional(),
    phone: z.string().nullable().optional(),
    picture: z.string().nullable().optional()
});

export type ContentUser = z.infer<typeof contentUserSchema>;

export const userWorkspaceSchema = z.object({
    name: z.string(),
    uuid: z.string().uuid(),
    displayName: z.string(),
    primaryColor: z.string().nullable(),
    logoUrl: z.string().nullable(),
    backgroundUrl: z.string().nullable(),
    role: z.nativeEnum(ClientUserRole),
    rights: userWorkspaceRightsSchema,
    totpForced: z.boolean(),
    defaultCastStyle: defaultCastStyleSchema,
    defaultCastTeamAccess: z.boolean(),
    disableProjectCasts: z.boolean(),
    features: z.array(z.nativeEnum(WorkspaceFeature)),
    translationCredits: z.number(),
    passwordConstraints: passwordConstraintsSchema.optional(),
    disableDownloads: z.boolean(),
    strictOIDCEnabled: z.boolean(),
    locale: z.enum(LocaleList),
    mailPrefix: z.string().nullable(),
    tier: z.nativeEnum(ClientTier),
    ownerUuid: z.string(),
    current: z.boolean().optional(),
    status: z.nativeEnum(ClientStatus)
});

export type UserWorkspace = z.infer<typeof userWorkspaceSchema>;

export const uiPrefsSchema = z.object({
    dataLayouts: z.record(z.string(), dataLayoutPrefsSchema).optional(),
    sortersAndGroupers: z
        .record(
            z.string(),
            z.object({
                grouper: z.string().optional(),
                currentGroupsOpened: z.array(z.string()).optional(),
                display: z.string().optional(),
                groupFilters: z.record(z.string(), z.array(z.string())).optional()
            })
        )
        .optional(),
    calendarZoom: z.nativeEnum(CalendarZoom).optional(),
    previewOfficeMs: z.boolean().optional(),
    video_preview: z.boolean().optional(),
    tooltips: z.boolean().optional(),
    alwaysHD: z.boolean().optional(),
    displayProjectSize: z.boolean().optional(),
    loop: z.boolean().optional(),
    note_default_draft: z.boolean().optional(),
    playerBackgroundColor: z.string().optional(),
    playerSidePanel: z.boolean().optional(),
    activeProjectFolderTree: z.boolean().optional()
});

export const userPrefsSchema = z.object({
    calendar: z
        .object({
            timeSlots: z
                .object({
                    start: z.number(),
                    end: z.number()
                })
                .optional(),
            globalPlanning: z
                .object({
                    grouper: z.string().optional(),
                    itemColorType: z.string().optional(),
                    grouperFilters: z.array(z.string()).optional()
                })
                .optional()
        })
        .optional(),
    timezone: z.string().optional(),
    rights: z
        .object({
            role: z.string().optional(),
            download: z.boolean().optional(),
            export: z.boolean().optional()
        })
        .optional(),
    trigger_bundles: z.boolean().optional(),
    trigger_metadata: z.boolean().optional(),
    encode_legacy: z.boolean().optional(),
    ui: uiPrefsSchema,
    newsletter: z
        .object({
            news: z.boolean().optional(),
            tips: z.boolean().optional()
        })
        .optional(),
    mail: z
        .object({
            notify_digest: z.string().optional(),
            reset_password: z.boolean().optional(),
            notify_final_voted_for: z.boolean().optional(),
            invite_copy: z.boolean().optional()
        })
        .optional(),
    livenotify_desktop: z.boolean().optional(),
    livenotify_email: z.boolean().optional(),
    livenotify: z
        .object({
            access_grant: z.boolean().optional(),
            comment_add: z.boolean().optional(),
            file_download: z.boolean().optional(),
            file_export: z.boolean().optional(),
            file_new: z.boolean().optional(),
            file_set_final: z.boolean().optional()
        })
        .optional(),
    tags: z.array(z.string()).optional(),
    labels: z.array(z.string()).optional(),
    recursive: z
        .object({
            admittance: z.boolean().optional(),
            default_presets: z.boolean().optional(),
            encrypted: z.boolean().optional(),
            export_mode: z.boolean().optional(),
            labels: z.boolean().optional(),
            metadata_admittance: z.boolean().optional(),
            metadata_cover: z.boolean().optional(),
            metadata_notes: z.boolean().optional(),
            metadata_user_livenotify: z.boolean().optional(),
            public: z.boolean().optional(),
            public_download: z.boolean().optional(),
            public_password: z.boolean().optional(),
            tags: z.boolean().optional(),
            trigger_bundles: z.boolean().optional(),
            trigger_metadata: z.boolean().optional(),
            watermark: z.boolean().optional()
        })
        .optional()
});

export type UserPrefs = z.infer<typeof userPrefsSchema>;

export const userAccountSchema = z.object({
    locale: z.string(),
    preferences: userPrefsSchema,
    totp_enabled: z.boolean(),
    ipAddress: z.string().optional(),
    workspaces: z.array(userWorkspaceSchema),
    isTeamsApp: z.boolean(),
    isClient: z.literal(false)
});

export type UserAccount = z.infer<typeof userAccountSchema>;

export type UserState = {
    clientName: string;
    email: string;
    fetchedAccount: boolean;
    fetchingAccount: boolean;
    storageTotal: number;
    storageUsed: number;
    clientRights: any;
    ipAddress?: string;
    clientUuid?: string;
    isClient?: boolean;
    locale: Locale;
    preferences?: UserPrefs;
    uuid: string;
    created?: Date;
    folders?: { uuid: string }[];
    //@deprecated use workspaces
    rootFolderUuid?: string;
    password_reset?: string;
    client_uuid?: string;
    client_name?: string;
    storage_expires?: Date | null;
    events?: number;
    projects?: number;
    firstname?: string | null;
    lastname?: string | null;
    phone?: string | null;
    firm?: string | null;
    role?: string | null;
    city?: string | null;
    country?: string | null;
    picture?: string | null;
    tags?: string[];
    labels?: string[];
    requestToken?: string;
    accessToken?: string;
    totp_enabled: boolean;
    passwordReset?: Date | null;
};

export const useAppInfosSchema = z.object({
    uuid: z.string().uuid(),
    email: z.string(),
    created: z.date(),
    picture: z.string().nullable().optional(),
    passwordReset: z.date().nullable().optional(),
    profile: legacyProfileSchema,
    isSuperAdmin: z.boolean()
});

export type UserAppInfos = z.infer<typeof useAppInfosSchema>;

export type UserWithNotificationSettings = LegacyProfile & {
    notifications: UserNotificationSettingsSchema;
    email: string;
    uuid: string;
    locale: Locale;
    teamUuid?: string;
    teamName?: string;
};

export const DEFAULT_NOTIFICATIONS_SETTINGS: UserNotificationSettingsSchema = {
    digestFrequencyMinutes: 0,
    commentCreated: false,
    fileCreated: false,
    fileFinalized: false,
    fileDownloaded: false,
    fileUnfinalized: false,
    eventCreated: false,
    eventInvitation: false,
    eventUpdated: false,
    memberJoined: false,
    taskCreated: false,
    taskUpdated: false,
    taskAssigned: false
};

export type DecodedSignedToken = {
    userUuid: string;
    userEmail: string;
    jti: string;
    exp: number;
    iat: number;
};
export type DecodedSessionInfos = { decodedToken: DecodedSignedToken; session: UserSession<false> };
