import styles from './ResponsiveFilterCheckboxes.module.css';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Button, ButtonTone } from '@he-novation/design-system/components/buttons/Button/Button';

import {
    FilterCheckboxes,
    FilterCheckboxTag,
    FiltersCheckboxProps
} from '$components/Filters/FilterCheckboxes';
import { FILTERS_PANEL } from '$constants/constants.sidePanels';
import { usePanel } from '$hooks/usePanel';
import { isSmallScreenSelector } from '$redux/config/configSelectors';

type ResponsiveFiltersProps = {
    filters: FiltersCheckboxProps[];
};

export function ResponsiveFilterCheckboxes({ filters }: ResponsiveFiltersProps) {
    const { isSmallScreen } = useSelector(isSmallScreenSelector);
    const { panel, closePanel, openPanel } = usePanel();

    useEffect(() => {
        if (!isSmallScreen) {
            closePanel();
        }
    }, []);

    if (isSmallScreen) {
        return (
            <Button
                icon={'filters'}
                className={styles.filterButton}
                tone={ButtonTone.Hoverable}
                onClick={() =>
                    panel === FILTERS_PANEL ? closePanel() : openPanel(FILTERS_PANEL, { filters })
                }
            />
        );
    }

    return filters.map((props, index) => (
        <FilterCheckboxes tag={FilterCheckboxTag.DropDown} key={index} {...props} />
    ));
}
