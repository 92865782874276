import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TagsField } from '@he-novation/design-system/components/form/TagsField/TagsField';
import { currentUserUuidSelector } from '../../../redux/user/userSelectors';

import Accordion from '$components/Accordion/Accordion';
import { SubHeader } from '$components/layout/Header/SubHeader/SubHeader';
import { useTranslate } from '$hooks/useTranslate';
import { updateUserPreferences } from '$redux/user/userActions';

type SettingsUserLabelsProps = {
    labels?: string[];
    tags?: string[];
};

export function SettingsUserTagsAndLabels({
    labels: _labels = [],
    tags: _tags = []
}: SettingsUserLabelsProps) {
    const dispatch = useDispatch();

    const [labels, setLabels] = useState<string[]>(_labels);
    const [tags, setTags] = useState<string[]>(_tags);
    const { currentUserUuid } = useSelector(currentUserUuidSelector);

    useEffect(() => {
        dispatch(updateUserPreferences(currentUserUuid, { labels, tags }));
    }, [labels, tags]);
    const { t } = useTranslate();

    return (
        <>
            <SubHeader subClassName={'settings-sub-header'} title={t('settings.Tags and labels')} />

            <Accordion
                title={t('common.Tags')}
                icon={[{ name: 'label', fill: 'white' }]}
                isOpen={true}
            >
                <TagsField
                    label={t('common.Tags')}
                    tags={tags}
                    autocompleteList={tags}
                    setTags={(t) => setTags(t)}
                    toggleOnSpace
                />
            </Accordion>

            <Accordion
                title={t('common.Labels')}
                icon={[{ name: 'label', fill: 'white' }]}
                isOpen={true}
            >
                <TagsField
                    label={t('common.Labels')}
                    tags={labels}
                    autocompleteList={labels}
                    setTags={(t) => setLabels(t)}
                    toggleOnSpace
                />
            </Accordion>
        </>
    );
}
