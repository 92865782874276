import React, {
    CSSProperties,
    ElementType,
    MutableRefObject,
    ReactNode,
    type RefObject,
    SyntheticEvent,
    useEffect,
    useRef,
    useState
} from 'react';
import cn from 'classnames';
import useWindowSize from '../../../hooks/useWindowSize';
import {
    Direction,
    DirectionX,
    DirectionY,
    getAbsolutePosition
} from '../../../utils/getAbsolutePosition';
import { IconProp } from '../../graphics/Icon/Icon';
import { ButtonTone } from '../Button/Button';
import { AbsoluteContent } from './AbsoluteContent';

export type AbsoluteContentButtonProps = {
    autoClose?: number;
    className?: string;
    closeOnClickOutside?: boolean;
    closeOnTriggerClick?: boolean;
    closeOnContentClick?: boolean;
    content: ReactNode | ((hide: Function) => ReactNode);
    contentClassName?: string;
    contentParentRef?: RefObject<HTMLElement>;
    contentSizedByTrigger?: boolean;
    delay?: number;
    direction?: Direction;
    tagName?: ElementType;
    triggersOnHover?: boolean;
    triggersOnClick?: boolean;
    controlRef?: MutableRefObject<{ setIsVisible: (isVisible: boolean) => void } | undefined>;
    capture?: boolean;
    isVisible?: boolean;
    style?: any;
    contentZIndex?: number;
    children?: ReactNode | ReactNode[];
    icon?: SVGElement | IconProp;
    onClick?: (e: SyntheticEvent<unknown, MouseEvent>) => void;
    disabled?: boolean;
    iconAfter?: boolean;
    tone?: ButtonTone;
    onMouseOver?: (e: SyntheticEvent<unknown, MouseEvent>) => void;
    mouseRelative?: {
        mouseX: boolean;
        mouseY: boolean;
    };
    type?: string;
};

export function AbsoluteContentButton({
    autoClose,
    className,
    children,
    closeOnClickOutside,
    closeOnTriggerClick = true,
    closeOnContentClick,
    content,
    contentClassName,
    contentParentRef,
    contentSizedByTrigger,
    delay = 0,
    direction = [DirectionX.LeftInner, DirectionY.Top],
    tagName: Component = 'div',
    triggersOnHover = true,
    triggersOnClick = false,
    style: _style,
    contentZIndex = 10,
    capture = true,
    controlRef,
    onClick,
    mouseRelative,
    isVisible: _isVisible = false,
    ...rest
}: AbsoluteContentButtonProps) {
    const [isVisible, setIsVisible] = useState<boolean>(_isVisible);
    const [style, setStyle] = useState<CSSProperties>({});
    const mousePositionRef = useRef<[number?, number?] | undefined>([undefined, undefined]);
    const triggerRef = useRef<HTMLElement>();
    const absoluteRef = useRef<HTMLDivElement>(null);
    const isVisibleRef = useRef<boolean>(false);
    const timeout = useRef<any>();
    const size = useWindowSize();

    useEffect(() => {
        isVisibleRef.current = isVisible;
        if (isVisible) {
            setStyle({
                ...getAbsolutePosition(
                    triggerRef,
                    absoluteRef,
                    direction,
                    contentSizedByTrigger,
                    contentParentRef,
                    mousePositionRef.current
                ),
                zIndex: contentZIndex
            });
        }
    }, [isVisible, size]);

    useEffect(() => {
        if (controlRef) {
            controlRef.current = { setIsVisible };
        }

        const unmountActions = [() => clearTimeout(timeout.current)];

        if (triggerRef.current) {
            if (triggersOnHover) {
                const onMouseOver = (e) => {
                    if (!isVisible && mouseRelative) {
                        mousePositionRef.current = [
                            mouseRelative.mouseX ? e.pageX : undefined,
                            mouseRelative.mouseY ? e.pageY : undefined
                        ];
                    }
                    clearTimeout(timeout.current);
                    setIsVisible(true);
                };
                triggerRef.current.addEventListener('mouseover', onMouseOver);
                unmountActions.push(() =>
                    triggerRef.current?.removeEventListener('mouseover', onMouseOver)
                );
                const onMouseOut = () => {
                    timeout.current = setTimeout(() => setIsVisible(false), delay);
                };
                triggerRef.current.addEventListener('mouseout', onMouseOut);
                unmountActions.push(() =>
                    triggerRef.current?.removeEventListener('mouseout', onMouseOut)
                );
            }
            if (triggersOnClick || onClick) {
                const onTriggerClick = (e) => {
                    onClick?.(e);
                    if ((triggersOnClick && !isVisibleRef.current) || closeOnTriggerClick)
                        setIsVisible(!isVisibleRef.current);
                };

                triggerRef.current.addEventListener('click', onTriggerClick);
                unmountActions.push(() =>
                    triggerRef.current?.removeEventListener('click', onTriggerClick)
                );
            }
        }

        return () => {
            unmountActions.forEach((a) => a());
        };
    }, []);
    return (
        <>
            <Component
                ref={triggerRef}
                className={cn(className, isVisible && 'is-visible')}
                style={_style}
                {...rest}
            >
                {children}
            </Component>
            {isVisible && (
                <AbsoluteContent
                    capture={capture}
                    autoClose={autoClose}
                    absoluteRef={absoluteRef}
                    parentRef={contentParentRef}
                    triggerRef={triggerRef}
                    closeOnContentClick={closeOnContentClick}
                    closeOnClickOutside={closeOnClickOutside}
                    content={content}
                    contentClassName={contentClassName}
                    direction={direction}
                    style={style}
                    triggersOnHover={triggersOnHover}
                    hide={() => {
                        timeout.current = setTimeout(() => setIsVisible(false), delay);
                    }}
                    show={() => {
                        clearTimeout(timeout.current);
                        setIsVisible(true);
                    }}
                />
            )}
        </>
    );
}
