import { z } from 'zod';

export const bucketSchema = z.object({
    name: z.string(),
    ingest: z.string().nullable().optional(),
    provider: z.string(),
    credentialsProfile: z.string(),
    credentialsFilePath: z.string().optional(),
    cloudfrontDomain: z.string().nullable().optional(),
    cloudfrontKeypairId: z.string().nullable().optional(),
    endpoint: z.string().nullable(),
    region: z.string(),
    created: z.date(),
    updated: z.date().nullable()
});

export type Bucket = z.infer<typeof bucketSchema>;
