import { z } from 'zod';
import { ApiMethod } from '../types/api.types';
import {
    userInfoSchema,
    userNotificationSettingsSchema,
    userPreferencesSchema,
    userUuidSchema
} from '../types/payloads/user.payload';
import { workspaceParamsSchema } from '../types/payloads/workspace.payload';
import { useAppInfosSchema, userAccountSchema } from '../types/user.types';

export const API_USER = {
    GET: {
        method: ApiMethod.GET,
        path: '/account',
        handlerMethod: 'findAccount',
        passResAndNextToHandler: true,
        allowNoMfa: true,
        responses: {
            200: {
                description: 'User account',
                schema: userAccountSchema
            }
        }
    },
    PATCH: {
        method: ApiMethod.PATCH,
        path: '/users/:userUuid',
        handlerMethod: 'updateUserInfo',
        zod: {
            params: userUuidSchema,
            body: userInfoSchema
        },
        allowNoMfa: true
    },
    DELETE: {
        method: ApiMethod.DELETE,
        path: '/users/:userUuid',
        handlerMethod: 'requestDelete',
        passResAndNextToHandler: true,
        zod: {
            params: userUuidSchema
        }
    },
    GET_INFOS: {
        method: ApiMethod.GET,
        path: '/user/infos',
        handlerMethod: 'getAppInfos',
        allowNoMfa: true,
        responses: {
            200: {
                description: 'User app infos',
                schema: useAppInfosSchema
            }
        }
    },
    GET_TRANSLATION_CREDITS: {
        method: ApiMethod.GET,
        path: '/workspaces/:workspaceName/user/translation-credits',
        handlerMethod: 'getTranslationCredits',
        zod: {
            params: workspaceParamsSchema
        },
        responses: {
            200: {
                description: 'Returns the number of translation credits the user has',
                schema: z.object({
                    credits: z.number()
                })
            }
        }
    }
} as const;

export const API_USER_PREFERENCES = {
    PATCH: {
        method: ApiMethod.PATCH,
        path: '/users/:userUuid/preferences',
        handlerMethod: 'updateUserPreferences',
        passResAndNextToHandler: true,
        zod: {
            params: userUuidSchema,
            body: userPreferencesSchema
        },
        allowNoMfa: true
    }
} as const;

export const API_USER_NOTIFICATIONS = {
    GET: {
        method: ApiMethod.GET,
        path: '/workspaces/:workspaceName/user-notifications',
        handlerMethod: 'findUserNotificationSettings',
        zod: {
            params: workspaceParamsSchema
        },
        responses: {
            200: {
                description: 'Returns the user notification settings',
                schema: userNotificationSettingsSchema
            }
        }
    },
    PUT: {
        method: ApiMethod.PUT,
        path: '/workspaces/:workspaceName/user-notifications',
        handlerMethod: 'putUserNotificationSettings',
        zod: {
            params: workspaceParamsSchema,
            body: userNotificationSettingsSchema
        }
    }
};
