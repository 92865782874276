import { SubtitleWithLink } from '@he-novation/config/types/subtitle.types';
import { atom } from 'jotai';

export const subtitlesAtom = atom<SubtitleWithLink[]>([]);
export const activeSubtitlesAtom = atom<string[]>([]);

const subtitlesRequestTimeStampAtom = atom<number>(new Date().getTime());

export const subtitlesTimeStampAtom = atom<number, never[], void>(
    (get) => get(subtitlesRequestTimeStampAtom),
    (_get, set, _) => set(subtitlesRequestTimeStampAtom, new Date().getTime())
);
