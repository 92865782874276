import { ClientUser } from '../client.types';
import { WorkspaceTeam, WorkspaceTeamWithMembers, WorkspaceUser } from '../workspace-team.types';

export enum WSWorkspaceTeamEventType {
    TeamCreated = 'team/created',
    TeamDeleted = 'team/deleted',
    TeamUpdated = 'team/updated'
}

export type WSWorkspaceEventTeamCreated = {
    type: WSWorkspaceTeamEventType.TeamCreated;
    team: WorkspaceTeam;
};

export type WSWorkspaceEventTeamUpdated = {
    type: WSWorkspaceTeamEventType.TeamUpdated;
    team: WorkspaceTeamWithMembers | (WorkspaceTeam & { members?: undefined });
};

export type WSWorkspaceEventTeamDeleted = {
    type: WSWorkspaceTeamEventType.TeamDeleted;
    teamUuid: string;
};

export enum WSWorkspaceAdminUserEventType {
    UserAdded = 'admin/user/added',
    UserRemoved = 'admin/user/removed',
    UserUpdated = 'admin/user/updated'
}

export type WSWorkspaceAdminEventUserAdded = {
    type: WSWorkspaceAdminUserEventType.UserAdded;
    clientUser: ClientUser;
};

export type WSWorkspaceAdminEventUserUpdated = {
    type: WSWorkspaceAdminUserEventType.UserUpdated;
    clientUser: ClientUser;
};

export type WSWorkspaceAdminEventUserRemoved = {
    type: WSWorkspaceAdminUserEventType.UserRemoved;
    userUuid: string;
};

export enum WSWorkspaceUserEventType {
    UserAdded = 'user/added',
    UserRemoved = 'user/removed',
    UserUpdated = 'user/updated'
}

export type WSWorkspaceEventUserAdded = {
    type: WSWorkspaceUserEventType.UserAdded;
    workspaceUser: WorkspaceUser;
};

export type WSWorkspaceEventUserUpdated = {
    type: WSWorkspaceUserEventType.UserUpdated;
    workspaceUser: WorkspaceUser;
};

export type WSWorkspaceEventUserRemoved = {
    type: WSWorkspaceUserEventType.UserRemoved;
    userUuid: string;
};

export enum WSWorkspaceEventType {
    WorkspaceUpdated = 'workspace/updated'
}

export type WSWorkspaceEventUpdated = {
    type: WSWorkspaceEventType.WorkspaceUpdated;
    totpForced?: boolean;
    defaultCastTeamAccess?: boolean;
    translationCredits?: number;
    ownerUuid?: string;
};
