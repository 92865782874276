export const STORAGE_GB = 1024 * 1024 * 1024;

const bytesLocale: Record<string, Record<string, string>> = {
    fr: {
        BYTES: 'octets',
        KB: 'Ko',
        MB: 'Mo',
        GB: 'Go',
        TB: 'To',
        PB: 'Po',
        EB: 'Eo',
        ZB: 'Zo',
        YB: 'Yo'
    },
    en: {
        BYTES: 'bytes',
        KB: 'KB',
        MB: 'MB',
        GB: 'GB',
        TB: 'TB',
        PB: 'PB',
        EB: 'EB',
        ZB: 'ZB',
        YB: 'YB'
    }
};
export function byteUnitTranslate(unit: string, locale: string) {
    return bytesLocale[locale]?.[unit] || bytesLocale.en[unit] || unit;
}
export const bytesToGigaBytes = (bytes?: number) => (bytes ? bytes / STORAGE_GB : 0);
export const bytesToGigaBytesDisplay = (
    bytes: number,
    locale: string,
    fractionalDigits?: number
) => {
    if (!bytes) return '0 ' + byteUnitTranslate('GB', locale);
    if (typeof fractionalDigits === 'undefined' || !Number.isInteger(fractionalDigits))
        fractionalDigits = 1;
    return (bytes / STORAGE_GB).toFixed(fractionalDigits) + ` ${byteUnitTranslate('GB', locale)}`;
};

export const gigaBytesToBytes = (gbytes: number) => gbytes * STORAGE_GB;

export const bytesToSize = (bytes: number, locale: string, fractionalDigits?: number) => {
    if (!bytes) return '0 ' + byteUnitTranslate('MB', locale);

    if (typeof fractionalDigits === 'undefined') fractionalDigits = 1;

    const units = [
        byteUnitTranslate('BYTES', locale),
        byteUnitTranslate('KB', locale),
        byteUnitTranslate('MB', locale),
        byteUnitTranslate('GB', locale),
        byteUnitTranslate('TB', locale),
        byteUnitTranslate('PB', locale),
        byteUnitTranslate('EB', locale),
        byteUnitTranslate('ZB', locale),
        byteUnitTranslate('YB', locale)
    ];

    const i = Math.floor(Math.log(bytes) / Math.log(1024));

    if (i === 0) {
        return bytes / Math.pow(1024, i) + ' ' + units[i];
    }

    return (bytes / Math.pow(1024, i)).toFixed(fractionalDigits) + ' ' + units[i];
};

export function bitsToSize(bits: number, fractionalDigits?: number): string {
    if (!bits) return '0 b';

    if (!fractionalDigits) fractionalDigits = 1;

    const tenPower = Math.floor(Math.log10(Math.abs(bits)));
    const nearestThousandPower = tenPower - (tenPower % 3);
    const thousandPower = nearestThousandPower / 3;

    const prefixes = ['', 'k', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y'];

    return (
        (bits / Math.pow(10, nearestThousandPower)).toFixed(fractionalDigits) +
        ' ' +
        prefixes[thousandPower] +
        'b'
    );
}
