import { coercedBoolean, sanitizedString } from '@he-novation/utils/zod.utils';
import { z } from 'zod';
import { baseUploadPayload } from './asset.payload';
import { folderParamsSchema } from './folder.payload';
import { subtitleQuerySchema } from './subtitle.payload';

export const castCreateBody = z.object({
    name: sanitizedString(),
    download: z.coerce.boolean(),
    downloadProxies: z.coerce.boolean(),
    comment: z.coerce.boolean(),
    expires: z.coerce.date().optional().nullable(),
    maxViews: z.coerce.number().default(0),
    password: sanitizedString({ optional: true }),
    userFileUid: z.string().optional().nullable(),
    logoUserFileUid: z.string().optional().nullable(),
    filesToCast: z.array(z.string()).optional(),
    projectUuid: z.string().optional(),
    backgroundColor: z.string().optional(),
    textColor: z.string().optional(),
    saveDefaultCastDesign: z.coerce.boolean().optional().nullable(),
    folderUuid: z.string().optional(),
    clientCastLabelUids: z.string().array().optional()
});

export type CastCreateBody = z.infer<typeof castCreateBody>;

export type CastCreatePayload = Omit<
    CastCreateBody,
    'saveDefaultCastDesign' | 'logoFiles' | 'files'
>;
export const castSpecificParamsSchema = z.object({
    workspaceName: sanitizedString({ min: 3, max: 255 }),
    castUid: z.string()
});
export type CastSpecificParams = z.infer<typeof castSpecificParamsSchema>;

export const castPublicSpecificParamsSchema = z.object({
    castUid: z.string()
});
export type CastPublicSpecificParams = z.infer<typeof castPublicSpecificParamsSchema>;

export const castsListQuerySchema = z.object({
    projectUuid: z.string().uuid().optional()
});

export type CastListQuery = z.infer<typeof castsListQuerySchema>;

export const castUpdateBody = castCreateBody.extend({
    name: sanitizedString({ optional: true })
});

export type CastUpdateBody = z.infer<typeof castUpdateBody>;

export const castFolderUpdateBody = z.object({
    name: sanitizedString()
});

export type CastFolderUpdateBody = z.infer<typeof castFolderUpdateBody>;

export const castFolderCreateBody = z.object({
    name: sanitizedString(),
    castFolderUuid: z.string().uuid().optional()
});

export type CastFolderCreateBody = z.infer<typeof castFolderCreateBody>;

export const castSelectParams = z.object({
    castUid: z.string(),
    castFolderUuid: z.string().uuid().optional()
});

export type CastSelectParams = z.infer<typeof castSelectParams>;

export const castQuerySchema = z.object({
    castFolderUuid: z.string().optional()
});
export type CastQuery = z.infer<typeof castQuerySchema>;

export const castPublicViewQuerySchema = z.object({
    castFolderUuid: z.string().optional(),
    recursive: coercedBoolean().optional(),
    p: z.string().optional()
});

export type CastPublicViewQuery = z.infer<typeof castPublicViewQuerySchema>;

export const castFileCreationBodySchema = z.object({
    fileUuid: z.string().uuid(),
    castFolderUuid: z.string().uuid().optional()
});

export type CastFileCreateBody = z.infer<typeof castFileCreationBodySchema>;

export const castFileCreated = z.object({
    uuid: z.string().uuid()
});

export type CastFileCreated = z.infer<typeof castFileCreated>;

export const castFileParamsSchema = castSpecificParamsSchema.extend({
    castFileUuid: z.string().uuid()
});

export const castPublicFileParamsSchema = z.object({
    castFileUuid: z.string().uuid(),
    castUid: z.string()
});

export const castFolderCreated = z.object({
    uuid: z.string().uuid(),
    exists: z.boolean()
});

export type CastFolderCreated = z.infer<typeof castFolderCreated>;

export const castFolderParamsSchema = castSpecificParamsSchema.extend({
    castFolderUuid: z.string().uuid()
});

export type CastFolderParams = z.infer<typeof castFolderParamsSchema>;

export const castPublicFolderParamsSchema = z.object({
    castFolderUuid: z.string().uuid(),
    castUid: z.string()
});

export const castFileGetCastsParams = z.object({
    fileUuid: z.string().uuid()
});

export type CastFileGetCastsParams = z.infer<typeof castFileGetCastsParams>;

export const castFileGetCasts = z.object({
    castFileUuid: z.string(),
    castFileName: sanitizedString(),
    uuid: z.string(),
    name: z.string(),
    castUid: z.string(),
    castOwnerUuid: z.string(),
    castFolderUuid: z.string().nullable(),
    castFolderName: z.string().nullable(),
    expired: z.coerce.boolean()
});

export type CastFileGetCasts = z.infer<typeof castFileGetCasts>;

export const castFromFolderParam = z.object({
    folderUuid: z.string().uuid()
});

export type CastFileFromFolderParam = z.infer<typeof castFromFolderParam>;

export const castShareBody = z.object({
    emails: z.array(z.string().email()),
    castName: sanitizedString(),
    message: sanitizedString({ optional: true, nullable: true })
});

export type CastShareBody = z.infer<typeof castShareBody>;

export const castFileVersionParamsSchema = z.object({
    castUid: z.string(),
    castFileUuid: z.string().uuid()
});

export const folderCastTriggerCreationBodySchema = z.object({
    castUid: z.string(),
    castFolderUuid: z.string().uuid().optional()
});
export type FolderCastTriggerCreationBody = z.infer<typeof folderCastTriggerCreationBodySchema>;

export const folderCastTriggerParamsSchema = folderParamsSchema.extend({
    folderCastTriggerUid: z.string()
});

export type FolderCastTriggerParams = z.infer<typeof folderCastTriggerParamsSchema>;

export const castPublicPasswordQuerySchema = z.object({
    p: z.string().optional()
});

export const castBreadcrumbSchema = z.object({
    uuid: z.string().uuid(),
    name: z.string()
});

export type CastBreadcrumb = z.infer<typeof castBreadcrumbSchema>;

export const castNoteParamsSchema = z.object({
    castUid: z.string(),
    castFileUuid: z.string().uuid(),
    noteUuid: z.string().uuid()
});

export const castCommentParamsSchema = z.object({
    castUid: z.string(),
    castFileUuid: z.string().uuid(),
    noteUuid: z.string().uuid(),
    commentUuid: z.string().uuid()
});

export const castCommentAttachmentCreateBody = z.object({
    files: z.array(baseUploadPayload),
    fromCast: z.boolean().optional(),
    castFileUuid: z.string().uuid().optional(),
    recaptcha: z.string().optional()
});

export const castCommentAssetParamsSchema = z.object({
    castUid: z.string(),
    castFileUuid: z.string().uuid(),
    noteUuid: z.string().uuid(),
    commentUuid: z.string().uuid(),
    assetUuid: z.string().uuid()
});

export const castFileDownloadParamsSchema = z.object({
    castUid: z.string(),
    castFileUuid: z.string().uuid()
});

export const castFileDownloadUrlQuerySchema = z.object({
    quality: sanitizedString({ min: 2 }),
    p: sanitizedString({ optional: true })
});

export const castSubtitlesParamsSchema = z.object({
    castUid: z.string(),
    subtitleUuid: z.string().uuid()
});

export const castSubtitleQuerySchema = subtitleQuerySchema.extend({
    castWorkspaceName: sanitizedString({ min: 3, max: 255 })
});

export const castSearchBodySchema = z.object({
    name: sanitizedString().optional(),
    page: z.number(),
    resultsPerPage: z.number().max(100),
    projectUuid: z.string().uuid().nullable().optional()
});

export type CastSearchBody = z.infer<typeof castSearchBodySchema>;
