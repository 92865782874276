import React, { ReactNode } from 'react';
import { Button, ButtonTone } from '@he-novation/design-system/components/buttons/Button/Button';
import cn from 'classnames';

export type HeaderBackLinkProps = {
    href: string;
    text?: ReactNode;
    className?: string;
};
export function HeaderBackLink({ href, text, className }: HeaderBackLinkProps) {
    return (
        <Button
            href={href}
            icon="chevron"
            tone={ButtonTone.Hoverable}
            className={cn(className, 'header-back-link')}
        >
            {text}
        </Button>
    );
}
