export const LocaleList = ['en', 'fr', 'de'] as const;
export type Locale = (typeof LocaleList)[number];

export type List =
    | 'admin'
    | 'calendar'
    | 'cast'
    | 'common'
    | 'custom-fields'
    | 'entity'
    | 'contact'
    | 'email'
    | 'folder'
    | 'item'
    | 'logs'
    | 'misc'
    | 'new'
    | 'planning'
    | 'player'
    | 'plugins'
    | 'project'
    | 'settings'
    | 'subtitles';

export type TranslateKey = `${List}.${string}`;

export type Translator = (
    key: TranslateKey,
    obj?: Record<string, string | number> | null
) => string;

export type I18nList = Record<Locale, Record<string, string>>;
